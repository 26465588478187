import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';
import { isElement } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class DynamicComponentCreatorService {

  private activatedComponents: Map<Type<any>, any> = new Map();

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef
  ) {
  }

  public create<T>(component: Type<T>, initInput: (componentInstance: T, componentRef: ComponentRef<T>) => void, target?: HTMLElement, skipDelay?: boolean): JQuery<HTMLElement> {
    const factory: ComponentFactory<T> = this.resolver.resolveComponentFactory(component);
    const newNode: HTMLElement = document.createElement('div');
    const htmlOutput: JQuery<HTMLElement> = $(newNode);
    const componentRef: ComponentRef<T> = factory.create(this.injector, [], newNode);
    this.activatedComponents.set(component, componentRef);

    const attachView = () => {
      initInput(componentRef.instance, componentRef);
      this.applicationRef.attachView(componentRef.hostView);

      if (target && isElement(target)) {
        const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
        target.appendChild(domElem);
      }
    };

    if (skipDelay) {
      attachView();
    } else {
      setTimeout(() => attachView());
    }

    return htmlOutput;
  }

  public destroy<IComponent>(component: Type<IComponent>): void {
    const componentRef: ComponentRef<IComponent> = this.activatedComponents.get(component);

    if (componentRef) {
      this.applicationRef.detachView(componentRef.hostView);
      componentRef.destroy();
      this.activatedComponents.delete(component);
    }
  }
}
