import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { UserdataService } from '@services/userdata/userdata.service';
import {
  cloneDeep,
  each,
  every,
  filter,
  findLast,
  get,
  has,
  includes,
  intersection,
  isEmpty,
  uniq,
  values
} from 'lodash';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';


export interface UpdateObjectParams {
  objectUUID?: string;
  objectID?: number;
  mediaType?: string;
  description?: string;
  type?: string;
  subtype?: string;
  state?: string;
  isPublic?: number;
  folderID?: number;
}

export interface UpdateMultipleObjectParams {
  objects: { [objectID: number]: UpdateObjectParams }
}

export interface ObjectItem {
  contentCategory: number;
  createdAt: number;
  creatorID: number;
  creatorShift: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  isPublic: number;
  folderID: number;
  lastUpdate: number;
  mediaType: string;
  objectID: number;
  revision: string;
  objectUUID: string;
  state: string;
  subtype: string;
  tags: number[];
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
  public filterObject: any = {};
  private objectsByType: IObjectStringKeyMap<IObjectStringKeyMap<ObjectItem>> = {};
  private lastRequest: number;

  constructor(
    private comms: CommsService,
    private subscriber: SubscriberService,
    private userdataService: UserdataService,
    private events: Events
  ) {}

  /**
   *
   * @param objectID  the ID of the object from the object_map table
   * @param thumbnail whether or not to request the thumbnail version
   *
   */
  public URI(objectID: number, thumbnail: boolean = false, oldStyle: boolean = false) {
    let cmd = '';
    const token = this.comms.token || 'none';
    let ret = '';

    if (oldStyle) {
      cmd = this.comms.serviceURL.replace('corvex.cgi', 'getObject.cgi');

      ret = cmd +
        '?cmd=getObject&token=' + this.comms.token +
        '&subscriberID=' + this.subscriber.subInfo.subscriberID +
        '&objectID=' + objectID;

      if (thumbnail) {
        ret += '&thumb=1';
      }
    } else {
      cmd = this.comms.serviceURL.replace('api/v2', '');
      ret = `${cmd}objects/${this.subscriber.subInfo.subscriberID}/${token}/${objectID}`;
      if (thumbnail) {
        ret += '/thumb';
      }
    }
    return ret;
  }

  public removeObject(id) {
    const fData = {cmd: 'deleteObject', objectID: id};
    return this.comms.sendMessage(fData);
  }

  public refresh(): Promise<IObjectStringKeyMap<ObjectItem[]>> {
    const requestData = {
      cmd: 'getObjectList',
      lastRequest: this.lastRequest,
      types: ['content'],
      states: JSON.stringify(['active', 'inactive']),
      includeDisabled: false,
      incremental: 1
    };

    return this.comms.sendMessage(requestData, false, false).then((data) => {
      const objects = get(data, 'result.objects') || [];

      if (data?.reqStatus === 'OK') {
        this.updateCache(data);
      }

      return objects;
    });
  }

  public updateCache(data) {
    each(data?.result?.objects, (object: ObjectItem) => {
      if (!this.objectsByType[object.type]) {
        this.objectsByType[object.type] = {};
      }
      this.objectsByType[object.type][object.objectID] = object;
    });

    each(data?.result?.removed, (object: ObjectItem) => {
      const targetObject = this.getObjectById(object.objectID);

      if (targetObject) {
        delete this.objectsByType[targetObject.type][targetObject.objectID];
      }
    });

    this.lastRequest = data.result.timestamp;
    this.events.publish('ccs:objectsUpdate', true);
  }

  public getObjectList(types, includeDisabled, filters?: any) {
    const fData = {
      cmd: 'getObjectList',
      types: JSON.stringify(types || []),
      includeDisabled,
      ...filters
    };

    return this.comms.sendMessage(fData).then((data) => {
      if (data?.reqStatus === 'OK') {
        this.updateCache(data);
      }
      return get(data, 'result.objects') || [];
    });
  }

  public updateObject(params: UpdateObjectParams | UpdateMultipleObjectParams): Promise<any> {
    const requestParams = {
      cmd: 'updateObject',
      ...params
    };

    return this.comms.sendMessage(requestParams);
  }

  public getCachedObjectById(id: number, alias?: string, translated?: boolean): ObjectItem {
    let object: ObjectItem;

    if (alias) {
      object = this.objectsByType?.[alias]?.[id];
    } else {
      object = this.getObjectById(id);
    }

    if (translated) {
      const translatedLanguage: any = findLast(get(object, 'translations.objects'), {language: this.userdataService.getLanguage()});

      if (translatedLanguage) {
        object = this.getCachedObjectById(translatedLanguage.value, alias);
      }
    }

    return object;
  }

  public getCachedObjectByIds(ids: number[], onlyActive?: boolean): ObjectItem[] {
    const items: ObjectItem[] = [];

    each(uniq(ids), (id) => {
      const item = this.getObjectById(id);

      if (onlyActive && get(item, 'state') === 'active' || !onlyActive) {
        items.push(item);
      }
    });

    return items;
  }

  public getCachedObjectByAlias(alias: string): ObjectItem[] {
    return cloneDeep(values(this.objectsByType[alias])) || [];
  }

  public getIconByType(mediaType: string): string {
    return this.getIconByBaseType(this.getObjectType(mediaType));
  }

  public getIconByBaseType(baseType: string = 'other'): string {
    const iconMap = {
      pdf: 'pdf.svg',
      image: 'image.svg',
      video: 'video.svg',
      audio: 'audio.svg',
      word: 'word-file-type-svgrepo-com.svg',
      excel: 'excel-file-type-svgrepo-com.svg',
      ppt: 'ppt-file-type-svgrepo-com.svg',
      html: 'html-file-type-svgrepo-com.svg',
      other: 'other-file-type-svgrepo-com.svg',
      csv: 'text/csv'
    };

    return iconMap[baseType] ? `assets/icons/media_types/${iconMap[baseType]}` : null;
  }

  public getObjectType(mediaType: string) {
    let type: 'image' | 'pdf' | 'video' | 'audio' | 'word' | 'excel' | 'ppt' | 'csv' | 'other' = 'other';

    const map: any = {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
      'application/vnd.ms-excel': 'excel',
      'application/xhtml+xml':'html',
      'application/html':'html',
      'text/html':'html',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
      'application/msword': 'word',
      'text/csv': 'csv',
      'application/pdf': 'pdf'
    };

    if (includes(mediaType, 'video/')) {
      type = 'video';
    } else if (includes(mediaType, 'audio/')) {
      type = 'audio';
    } else if (includes(mediaType, 'image/')) {
      type = 'image';
    } else {
      if (has(map, mediaType)) {
        type = map[mediaType];
      }
    }
    return type;
  }

  public filterData(data) {
    if (isEmpty(this.filterObject)) {
      return data;
    } else {
      return filter(data, (dataItem) => this.checkFilter(dataItem));
    }
  }

  private checkFilter(dataItem: any): boolean {
    const keyMap = {
      permissions: 'permissionLevel',
      locations: 'locations',
      roles: 'roles',
      shifts: 'shift',
      certifications: 'certifications'
    };

    return every(keyMap, (filterKey: string, dataKey: string) => {
      const filter = this.filterObject[filterKey];
      const dataByKey = dataItem[dataKey];
      const matched = intersection(filter, dataByKey).length > 0;
      const dataIsEmpty = dataByKey.length === 0;

      return filter?.length ? (dataIsEmpty || matched) : true;
    });
  }

  private getObjectById(id: number): ObjectItem {
    let object: ObjectItem;

    each(this.objectsByType, (objectsByType) => {
      if (objectsByType[id]) {
        object = objectsByType[id];
        return;
      }
    });

    return cloneDeep(object);
  }
}
