import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, differenceWith, get, isEqual } from 'lodash';


@Component({
  selector: 'worker-point',
  templateUrl: './worker-point-grid.component.html',
  styleUrls: ['./worker-point-grid.component.scss'],
})
export class WorkerPointGridComponent implements OnInit {

  @Input() gridObject: any;
  @Output() onChanged: EventEmitter<void> = new EventEmitter<void>();

  public dropOption: any = [
    {text: this.translate.instant('SUB_Settings.No_Points'), id: 0},
    {text: this.translate.instant('SUB_Settings.1_Point'), id: 1},
    {text: this.translate.instant('SUB_Settings.2_Points'), id: 2},
    {text: this.translate.instant('SUB_Settings.3_Points'), id: 3},
    {text: this.translate.instant('SUB_Settings.4_Points'), id: 4},
    {text: this.translate.instant('SUB_Settings.5_Points'), id: 5},
  ];

  public options = {
    minimumResultsForSearch: Infinity,
    sorter: (data) => data
  };

  private originGridObject: any = {};

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.originGridObject = cloneDeep(this.gridObject);
  }

  public dropDownChanged(val, item) {
    if (item.value !== +val.value) {
      item.value = +val.value;
      this.onChanged.emit();
    }
  }

  public getChangedPoints(): any {
    return differenceWith(get(this.originGridObject, 'fieldValues'), get(this.gridObject, 'fieldValues'), isEqual).length;
  }

}
