import { Component, EventEmitter, Output } from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

import { LoadingService, MessagesService, NotificationService, SubscriberService } from '@services';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';

import { TranslateService } from '@ngx-translate/core';
import { find, get, includes } from 'lodash';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
})
export class NotificationMenuComponent {

  @Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();

  public id: number;
  public notification: any = {};
  public src: string;
  public buttonName: string = this.getNameByBrand();
  public showButton = false;

  constructor(
    private messageService: MessagesService,
    private menu: MenuController,
    private loadingService: LoadingService,
    private observationTableService: ObservationTableService,
    private alertController: AlertController,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private subscriber: SubscriberService
  ) {
  }

  public closeFor(id: number): void {
    if (id === this.id) {
      this.menu.isOpen('notification').then((isOpen: boolean) => {
        if (isOpen) {
          this.close();
        }
      });
    }
  }

  public setId(id: number): void {
    this.id = id;
    this.notification = find(this.messageService.messageData.data, <any>{messageID: id});
    this.src = this.notificationService.getIcon(this.notification);
    const src = get(this.notification, 'source');
    if (!src || src === 'check') {
      this.showButton = false;
    } else {
      this.showButton = true;
    }
    if (src === 'beacon') {
      this.buttonName = 'SHARED.Site_Management';
    } else if (includes(['observation', 'asset'], src)) {
      this.buttonName = this.getNameByBrand();
    } else {
      this.showButton = false;
      this.buttonName = '';
    }
  }

  public close(): Promise<boolean> {
    return this.menu.close('notification');
  }

  public remove(): void {
    this.loadingService.enable();

    this.messageService.changeRecipientState(this.id, 'removed').then(() => {
      this.loadingService.disable();
      this.close();
      this.refreshTable.emit();
    }).catch(() => {
      this.loadingService.disable();
    });
  }

  public openObservationDetail(): void {
    const notificationSource = this.notification?.source;

    if (notificationSource === 'beacon') {
      this.router.navigate(['pages/site-management']);
    } else if (notificationSource === 'asset') {
      this.router.navigate([`/pages/dashboard/asset/${this.notification.sourceID}`]);
    } else {
      const isOpened: boolean = this.observationTableService.openObservationDetail(+this.notification.observationID);

      if (!isOpened) {
        this.alertController.create({
          header: this.translate.instant('COMMUNICATIONS.Menu_header'),
          message: this.translate.instant('COMMUNICATIONS.Menu_message'),
          cssClass: 'custom-alert',
          buttons: [{text: this.translate.instant('SHARED.OK')}]
        }).then((alertElement: HTMLIonAlertElement) => {
          alertElement.present();
        });
      }
    }
  }

  public getNameByBrand() {
    return 'COMMUNICATIONS.View_Observation_Detail';
  }
}
