import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserdataService } from '@services';
import { filter, map } from 'rxjs/operators';
import { get } from 'lodash';

declare let gtag: Function;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {

  @HostBinding('class.standalone-mode') public isStandaloneMode: boolean = get(window.navigator, 'standalone');

  public isLayoutLoaded = false;
  public isLoadingAvailable = true;

  private isLoadingDeferred: boolean;
  private readonly pages: { [key: string]: string } = {
    login: '/pages/login',
    preparing: '/pages/login/preparing'
  };

  constructor(private router: Router,
              private userdata: UserdataService) {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const u = this.userdata.getUserID();
          const page = event.urlAfterRedirects.replace(/;.*$/, '');
          gtag('config', 'G-5CRX864MF4',
            {
              page_path: page,
              traffic_type: 'internal',
              user_id: u
            }
          );
          gtag('set', 'user_properties', {
            sub_id: u
          });
        }
      }
    );
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    ).subscribe((url: string) => {
      if (this.isLoadingDeferred) {
        this.isLoadingAvailable = true;
      }

      if (url === this.pages.preparing) {
        this.isLoadingDeferred = true;
      }
    });
  }

  public onChangeStack(stack: any) {
    if (this.isLoadingDeferred && this.isLoadingAvailable) {
      this.isLayoutLoaded = true;
      this.isLoadingDeferred = false;
    } else {
      if (stack.enteringView.url === this.pages.login) {
        this.isLoadingAvailable = false;
        this.isLayoutLoaded = false;
      } else if (stack.enteringView.url === this.pages.preparing) {
        this.isLoadingDeferred = true;
      }
    }
  }

}
