import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { ChangeContext, Options } from '@angular-slider/ngx-slider';

export interface ISliderConfig {
  min: number;
  max: number;
  value: number;
  showTicks?: boolean;
  showTicksValues?: boolean;
  getLegend?: any;
}

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, AfterContentChecked {

  @ViewChild('sliderWrapper', {static: true}) sliderWrapper: ElementRef;
  @Input() config: ISliderConfig;
  @Output() onChange: EventEmitter<number> = new EventEmitter();
  public isVisible = false;
  public value = 10;
  public options: Options | any = {
    floor: 1,
    ceil: 10
  };
  @HostBinding('class.ticks') private isTicksClassEnabled: boolean;

  constructor() {
  }

  @Input() getValue: () => number = () => this.value;

  ngAfterContentChecked(): void {
    if (this.isVisible === false && this.sliderWrapper.nativeElement.offsetParent != null) {
      this.isVisible = true;
    } else if (this.isVisible === true && this.sliderWrapper.nativeElement.offsetParent == null) {
      this.isVisible = false;
    }
  }

  ngOnInit() {
    Object.assign(this.options, {
      floor: this.config.min,
      ceil: this.config.max,
      showTicks: this.config.showTicks,
      showTicksValues: this.config.showTicksValues,
      getLegend: this.config.getLegend
    });
    this.value = this.config.value;

    this.isTicksClassEnabled = this.options.showTicks && this.options.showTicksValues;
  }

  public onChangedValue(changeContext: ChangeContext): void {
    this.onChange.emit(changeContext.value);
  }

  public refresh() {
    this.ngOnInit();
  }

}
