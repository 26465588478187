import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MenuItem, MenuService } from '@services/menu/menu.service';
import { IModuleAccess, ModuleAccessService } from '@services/moduleAccess/module-access.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { AccessService } from '@services/access/access.service';

import { environment } from '@env';
import { Observable } from 'rxjs';
import { Brands } from '@services/brand/brand.service';
import { filter, find, get, includes } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ModuleGuard implements CanActivate {

  constructor(
    private router: Router,
    private menuService: MenuService,
    private moduleAccessService: ModuleAccessService,
    private permissionsService: PermissionsService,
    private userDataService: UserdataService,
    private accessService: AccessService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const moduleAccess: IModuleAccess = get(next.data, 'moduleAccess');
    const permission: Permission = get(next.data, 'permission');
    const nextAvailablePage: string = this.getNextUrl();

    if (this.isEnabled(moduleAccess, permission, state.url)) {
      return true;
    } else if (nextAvailablePage) {
      this.router.navigate([nextAvailablePage]);
    } else {
      this.accessService.goToAccessPage();
    }

    return false;
  }

  private isEnabled(moduleAccess: IModuleAccess, permission: Permission, url: string): boolean {
    // ensure that the module us enabled, the feature is enabled by the subscriber, AND that the current user
    // has permission based upon built-in or subscriber settings
    let isPermissionAllowed = this.menuService.checkMenuItemByUrl(url);

    // let isPermissionAllowed: boolean = permission ? this.permissionsService.canView(permission as string) : true;

    if (moduleAccess.subscriberAccess) {
      isPermissionAllowed = this.menuService.checkPermission(moduleAccess);
    }

    const checkRelatedFeatures = moduleAccess.checkRelatedFeatures;

    return this.moduleAccessService.isModuleEnabled(moduleAccess.module, checkRelatedFeatures)
      && this.moduleAccessService.isFeatureEnabled(moduleAccess.feature, checkRelatedFeatures)
      && isPermissionAllowed;
  }

  private getFirstAvailablePage(): MenuItem {
    const availablePages: MenuItem[] = this.getAvailablePages();
    const brand = (environment as any).data?.brand;

    if (includes(Brands, brand)) {
      const defaultPage = find(availablePages, { defaultPageFor: brand });

      if (defaultPage) {
        const brandDefaultPage = this.getFirstAvailableModule([defaultPage]);

        if (brandDefaultPage) {
          return brandDefaultPage;
        }
      }
    }

    return this.getFirstAvailableModule(availablePages);
  }

  private getFirstAvailableModule(availablePages: MenuItem[]) {
    return find(availablePages, (page) => {
      const availableChildren = find(page.children, 'isAvailable');

      // Menu item should have an url to navigate or any available children
      return !!page.url || !!availableChildren;
    });
  }

  private getNextUrl(): string {
    let url: string;
    const availablePage = this.getFirstAvailablePage();

    if (availablePage) {
      if (availablePage.url) {
        url = availablePage.url;
      } else if (availablePage.children) {
        url = get(find(availablePage.children, 'isAvailable'), 'url');
      }

      const lastOpenedDashboardPage: MenuItem = this.getLastOpenedAvailablePage();

      if (lastOpenedDashboardPage) {
        url = lastOpenedDashboardPage.url;
      }
    }

    return url;
  }

  private getAvailablePages(): MenuItem[] {
    return filter(this.menuService.getMenuItems(), 'isAvailable');
  }

  private getLastOpenedAvailablePage(): MenuItem {
    const availablePages: MenuItem[] = this.getAvailablePages();
    const dashboardMenuPage: MenuItem = find(availablePages, {icon: 'dashboard'});
    const lastDashboardType: string = this.userDataService.getPreference('lastDashboardType');
    let lastOpenedDashboardPage: MenuItem;

    if (dashboardMenuPage && lastDashboardType) {
      lastOpenedDashboardPage = find(dashboardMenuPage.children, (dashboardMenuChildPage: MenuItem) => dashboardMenuChildPage.isAvailable && includes(lastDashboardType, dashboardMenuChildPage.url));
    }

    return lastOpenedDashboardPage;
  }

}
