import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import {
  IOnNameChangeEvent,
  IQuestionGroup,
  ISelectedQuestionsEvent,
  QuestionGroupComponent
} from './../question-group/question-group.component';
import { QuestionService } from './../../services/question/question.service';
import { ChecksService, LoadingService, PopoverService, UtilsService } from '@services';
import { IPopoverConfig } from '@shared/components';
import { ICheck, ICheckGroup, IQuestion } from '@modules/management/pages/details/check/models';
import { IObjectStringKeyMap } from '@shared/models';

import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import {
  cloneDeep,
  each,
  filter,
  find,
  flatten,
  includes,
  isEqual,
  keys,
  map,
  orderBy,
  remove,
  some, sum,
  values
} from 'lodash';

@Component({
  selector: 'app-check-build',
  templateUrl: './check-build.page.html',
  styleUrls: ['./check-build.page.scss'],
})
export class CheckBuildPage {

  @ViewChildren(QuestionGroupComponent) questionGroupElement: QueryList<QuestionGroupComponent>;

  @Input() checkId: string;
  @Output() onSaveDeploy: EventEmitter<void> = new EventEmitter<void>();

  public questions: IQuestion[] = [];
  public groups: IQuestionGroup[] = [];
  public originalGroups: IQuestionGroup[] = [];
  public removalPanelConfig = {
    count: 0,
    title: '',
    subTitle: this.translate.instant('MGMT_DETAILS.Question_Item'),
    removeButtonTitle: this.translate.instant('MGMT_DETAILS.Delete_Question_Item')
  };
  private pendingChanges: any = {};
  private selectedQuestions: IObjectStringKeyMap<string[]> = {};
  private isActivated: boolean;
  private orderCheckGroups: ICheckGroup[];
  public changeDetectionPanel = {
    handleUpdate: async () => {
      const requestParams = {checkID: this.checkId, checkGroups: JSON.stringify(filter(this.orderCheckGroups))};
      await this.loadingService.enable();
      await this.checksService.updateCheck(requestParams);
      this.loadingService.disable();
      this.resetChangesState();
    },
    onCancel: () => {
      const isReorderChange: boolean = some(keys(this.pendingChanges), (key) => includes(key, 'Reorder') || includes(key, 'newGroup'));
      if (isReorderChange) {
        this.groups = cloneDeep(this.originalGroups);
      } else {
        this.questionGroupElement.forEach((questionGroup: QuestionGroupComponent) => {
          questionGroup.resetName();
          questionGroup.renderGroupTable();
        });
      }
      this.resetChangesState();
    },
    changedCount: 0
  };

  constructor(
    private questionService: QuestionService,
    private loadingService: LoadingService,
    private checksService: ChecksService,
    private utils: UtilsService,
    private popoverService: PopoverService,
    private translate: TranslateService,
    private router: Router
  ) {
  }

  public createGroup(): void {
    const id: string = uuid.v4();
    const title = `${this.translate.instant('MGMT_DETAILS.Group')} ${this.groups.length + 1}`;
    this.questionService.setTemporaryGroup(id, title);

    this.pendingChanges[`${id}newGroup`] = 1;
    this.calculateChangesCount();
    this.addGroup(title, id, [], null, true);
    this.defineOrderCheckGroups();
    if (this.orderCheckGroups.length === 0) {
      const firstGroup = this.groups[0];
      this.orderCheckGroups.push({groupID: firstGroup.uuid, questions: [], title: firstGroup.title});
    }
    this.orderCheckGroups.push({groupID: id, questions: [], title});
  }

  public getQuestions() {
    const questions: IQuestion[] = this.questionService.getQuestionsByCheckId(this.checkId);
    this.addQuestionsOrder(questions);
    this.questions = questions;
  }

  public onActive(): void {
    if (!this.isActivated) {
      this.isActivated = true;
      this.defineGroups();
    }
  }

  public onQuestionChecked(selectedQuestions: ISelectedQuestionsEvent): void {
    this.selectedQuestions[selectedQuestions.groupId] = selectedQuestions.ids;
    this.removalPanelConfig.count = flatten(values(this.selectedQuestions)).length;
    this.removalPanelConfig.title = `${this.removalPanelConfig.count} ${this.translate.instant('SHARED.Selected')}`;
  }

  public onDuplicateQuestion() {
    const popoverConfig: IPopoverConfig = {
      title: this.translate.instant('MGMT_DETAILS.Duplicate_Question_Popover_Title'),
      description: this.translate.instant('MGMT_DETAILS.Duplicate_Question_Popover_Description'),
      onSave: () => this.duplicateQuestion()
    };
    this.popoverService.show(popoverConfig);
  }

  public async duplicateQuestion() {
    await this.loadingService.enable();
    await this.questionService.duplicateQuestions(this.checkId, this.selectedQuestions);
    await this.checksService.refresh();
    this.loadingService.disable();
    this.selectedQuestions = {};
  }

  public onRemoveQuestion() {
    const popoverConfig: IPopoverConfig = {
      title: this.translate.instant('MGMT_DETAILS.Delete_Question_Popover_Title'),
      description: this.translate.instant('MGMT_DETAILS.Delete_Question_Popover_Description'),
      onSave: () => this.removeQuestions()
    };
    this.popoverService.show(popoverConfig);
  }

  public onRemoveGroup(id: string) {
    const popoverConfig: IPopoverConfig = {
      title: this.translate.instant('MGMT_DETAILS.Delete_Question_Group_Popover_Title'),
      description: this.translate.instant('MGMT_DETAILS.Delete_Question_Group_Popover_Description'),
      onSave: () => this.removeGroup(id)
    };
    this.popoverService.show(popoverConfig);
  }

  public async updateData(force: boolean = false) {
    if (!this.isActivated) {
      return;
    }

    this.getQuestions();
    this.removalPanelConfig.count = 0;

    if (force) {
      if (this.questionService.getTemporaryGroupCount()) {
        this.updateGroups(false);
      } else {
        this.defineGroups();
        this.resetChangesState();
      }
    } else {
      this.updateGroups();
      this.originalGroups = cloneDeep(this.groups);
      this.resetChangesState();
    }
  }

  public onRowReorder(groupId: string) {
    const currentCheck: ICheck = this.getCheck();
    this.defineOrderCheckGroups();
    const orderCheckGroup: ICheckGroup = find(this.orderCheckGroups, {groupID: groupId});
    const questionGroup: IQuestionGroup = find(this.groups, {uuid: groupId});

    if (orderCheckGroup) {
      orderCheckGroup.questions = map(orderBy(questionGroup.questions, 'orderNumber'), 'questionID');
      this.pendingChanges[`${groupId}rowReorder`] = isEqual(currentCheck.checkGroups, this.orderCheckGroups) ? 0 : 1;
      this.calculateChangesCount();
    }
  }

  public onItemReorder(event: any) {
    this.defineOrderCheckGroups();

    const draggedGroup = this.groups.splice(event.detail.from, 1)[0];
    this.groups.splice(event.detail.to, 0, draggedGroup);

    if (this.orderCheckGroups.length > 1) {
      const draggedCheckGroup = this.orderCheckGroups.splice(event.detail.from, 1)[0];
      this.orderCheckGroups.splice(event.detail.to, 0, draggedCheckGroup);

      this.pendingChanges.groupReorder = 1;
      this.calculateChangesCount();
    }

    event.detail.complete();
  }

  public onNameChange(onNameChangeEvent: IOnNameChangeEvent) {
    this.defineOrderCheckGroups();
    const orderCheckGroup: ICheckGroup = find(this.orderCheckGroups, {groupID: onNameChangeEvent.groupId});
    if (orderCheckGroup) {
      orderCheckGroup.title = onNameChangeEvent.formData.title || orderCheckGroup.title;
      orderCheckGroup.translations = onNameChangeEvent.formData.translations || orderCheckGroup.translations;
      this.pendingChanges[`${onNameChangeEvent.groupId}nameChange`] = onNameChangeEvent.isEqual ? 0 : 1;
      this.questionService.setTemporaryGroup(onNameChangeEvent.groupId, orderCheckGroup.title);
      this.calculateChangesCount();
    }
  }

  public openChecksPage() {
    this.router.navigate(['/pages/management/checks']);
  }

  public async removeGroup(groupId: string) {
    const check: ICheck = this.getCheck();
    const selectedCheckGroup: ICheckGroup = find(check.checkGroups, {groupID: groupId});

    if (selectedCheckGroup) {
      const groupQuestions = {[groupId]: selectedCheckGroup.questions};

      await this.loadingService.enable();
      await this.questionService.removeQuestions(this.checkId, groupQuestions);
      await this.checksService.refresh();
      this.loadingService.disable();
      this.updateData(true);
    } else {
      remove(this.groups, {uuid: groupId});
      remove(this.orderCheckGroups, {groupID: groupId});
      delete this.pendingChanges[`${groupId}newGroup`];

      this.calculateChangesCount();
    }
  }

  public isFormChanged(): boolean {
    return this.changeDetectionPanel.changedCount > 0 || this.removalPanelConfig.count > 0;
  }

  public async saveDeploy() {
    if (this.changeDetectionPanel.changedCount) {
      await this.changeDetectionPanel.handleUpdate();
    }
    this.onSaveDeploy.emit();
  }

  public onGroupRowReorder(data: any) {
    const questionGroup: QuestionGroupComponent = this.questionGroupElement.find((questionGroup: QuestionGroupComponent) => questionGroup.tableId === data.tableId);

    if (questionGroup) {
      questionGroup.group.questions.push(data.rowData);
      this.defineOrderCheckGroups();

      each(this.orderCheckGroups, (checkGroup) => {
        const group = find(this.groups, {uuid: checkGroup.groupID});
        checkGroup.questions = map(orderBy(group.questions, 'orderNumber'), 'questionID');
      });

      this.pendingChanges.groupRowReorder = 1;
      this.calculateChangesCount();
    }
  }

  public trackByFunction(index: number, group: IQuestionGroup): string {
    return group.uuid;
  }

  private defineOrderCheckGroups(): void {
    const currentCheck: ICheck = this.getCheck();

    if (!this.orderCheckGroups) {
      this.orderCheckGroups = cloneDeep(currentCheck.checkGroups);
    }
  }

  private addQuestionsOrder(questions: IQuestion[]): void {
    const check: ICheck = this.getCheck();

    each(check.checkGroups, (checkGroup) => {
      each(checkGroup.questions, (questionId, index) => {
        const question: IQuestion = find(questions, {questionID: questionId});

        if (question) {
          (<any>question).orderNumber = index;
        }
      });
    });
  }

  private calculateChangesCount(): void {
    this.changeDetectionPanel.changedCount = sum(values(this.pendingChanges));
  }

  private resetChangesState() {
    this.pendingChanges = {};
    this.calculateChangesCount();
    this.orderCheckGroups = null;
    this.questionService.clearTemporaryGroups();
  }

  private getCheck(): ICheck {
    return this.checksService.getCheck(this.checkId);
  }

  private defineGroups() {
    this.groups = [];
    const check: ICheck = this.getCheck() || <ICheck>{};

    each(check.checkGroups, (checkGroup: ICheckGroup) => {
      const groupQuestions: IQuestion[] = this.questionService.getQuestionsByIds(checkGroup.questions);
      const groupTitle: string = checkGroup.title;
      const translations = checkGroup.translations ? JSON.parse(checkGroup.translations) : {};
      this.addGroup(groupTitle, checkGroup.groupID, groupQuestions, translations);
    });

    if (this.groups.length === 0) {
      this.addGroup();
    }
  }

  private async removeQuestions() {
    await this.loadingService.enable();
    await this.questionService.removeQuestions(this.checkId, this.selectedQuestions);
    await this.checksService.refresh();
    this.loadingService.disable();
    this.selectedQuestions = {};
  }

  private addGroup(title?: string, id: string = uuid.v4(), questions: IQuestion[] = [], translations?: any, skipSaving?: boolean): void {
    const groupName: string = title || `${this.translate.instant('MGMT_DETAILS.Group')} ${this.groups.length + 1}`;
    const group = {title: groupName, uuid: id, questions, translations};

    this.utils.decodeTranslations(group, ['translation_title'], ['title']);
    this.groups.push(group);

    if (!skipSaving) {
      this.originalGroups = cloneDeep(this.groups);
    }
  }

  private updateGroups(updateTitle: boolean = true): void {
    const check: ICheck = this.getCheck();

    each(check.checkGroups, (checkGroup: ICheckGroup) => {
      const groupQuestions: IQuestion[] = this.questionService.getQuestionsByIds(checkGroup.questions);
      const group: QuestionGroupComponent = this.questionGroupElement.find((questionGroup: QuestionGroupComponent) => questionGroup.group.uuid === checkGroup.groupID);

      if (group) {
        group.group.questions = groupQuestions;
        if (checkGroup.title && updateTitle) {
          group.group.title = checkGroup.title || group.group.title;
          group.defineOriginalNameFormData();
          group.resetName();
        }
        group.renderGroupTable(updateTitle);
      }
    });
  }
}
