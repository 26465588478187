import { Injectable, Input } from '@angular/core';
import { filter, intersection, map } from 'lodash';


@Injectable()
export abstract class BaseItemList {

  @Input() selectedLocationIds?: string[] = [];

  protected filterByLocation(items: any[]): any[] {
    if (this.selectedLocationIds?.length && this.selectedLocationIds[0] !== 'all') {
      const selectedLocationIds: string[] = map(this.selectedLocationIds, String);

      return filter(items, (item) => {
        const itemLocations: string[] = map(item.locations, String);
        const emptyItemLocations = itemLocations.length === 0;
        const matchedLocations = intersection(selectedLocationIds, itemLocations).length === selectedLocationIds.length;

        return matchedLocations || emptyItemLocations;
      });
    } else {
      return items;
    }
  }

}
