import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, Feature, LoadingService, SubscriberService, UserdataService } from '@services';
import { ConsentService } from '@services/consent/consent.service';
import { awaitHandler } from '@utils/awaitHandler';
import { toInteger } from 'lodash';

@Component({
  selector: 'app-obtain-consent',
  templateUrl: './obtain-consent.page.html',
  styleUrls: ['./obtain-consent.page.scss'],
})
export class ObtainConsentPage implements OnInit {

  public theForm = '';

  constructor(
    private auth: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private consentService: ConsentService,
    private translate: TranslateService,
    private subscriber: SubscriberService,
    private userData: UserdataService
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (this.subscriber.usesFeature(Feature.CONSENT)) {
      const data = this.consentService.get();
      if (data && data.value && data.value != '') {
        this.theForm = data.value;
      } else {
        this.theForm = this.translate.instant('AUTH.Consent');
      }
    }
  }

  public async confirmConsent() {
    // update the userData service
    this.userData.consentDate = toInteger(Date.now() / 1000);
    this.userData.consented = true;
    this.userData.consentWithdrawnDate = 0;
    // tell the backend this user has consented

    // then navigate
    this.loadingService.enable();

    await awaitHandler(this.userData.updateConsent(true));
    this.loadingService.disable();
    if (this.userData.isFirstLogin) {
      this.next();
    } else {
      this.auth.navigateToHomePage();
    }
  }

  public next() {
    if (this.subscriber.getFeature('first_password_update') === 1) {
      this.router.navigate(['pages/login/onboarding']);
    } else if (this.subscriber.getFeature('sq_password_recovery') === 1) {
      this.router.navigate(['pages/login/onboarding/security-questions'], {queryParams: {hideHeader: true}});
    } else {
      this.router.navigate(['/pages/dashboard/main']);
    }
  }

  public declineConsent() {
    // then navigate
    this.loadingService.enable();
    this.userData.updateConsent(false)
      .then(() => {
        this.loadingService.disable();
        this.userData.consentWithdrawnDate = toInteger(Date.now() / 1000);
        this.userData.consented = false;
        this.auth.handleNoAuth();
      })
      .catch(err => {
        this.loadingService.disable();
        this.userData.consentWithdrawnDate = toInteger(Date.now() / 1000);
        this.userData.consented = false;
        this.auth.handleNoAuth();
      });
    // update backend
  }

}
