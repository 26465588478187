import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { ICheck, IResponse, IResponseCollection, Response } from '@modules/management/pages/details/check/models';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';
import { AssetsService } from '@services/assets/assets.service';
import { UserService } from '@services/user/user.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { Events } from '@services/events/events.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { ChecksService } from '@services/checks/checks.service';
import { UtilsService } from '@services/utils/utils.service';
import { TeamsService } from '@services/teams/teams.service';
import { TranslateService } from '@ngx-translate/core';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { awaitHandler } from '@utils/awaitHandler';
import { IObjectStringKeyMap } from '@modules/shared/models';
import { LocalDBService } from '@services/localdb.service.ts/localdb';
import { IDBUpdate, IUpdateTime } from 'app/db.consts';
import { WebWorkerService } from '@services/web-worker/web-worker.service';
import {
  assign,
  clone, cloneDeep,
  each,
  filter,
  find, flattenDeep,
  forEach,
  get,
  has,
  includes,
  indexOf,
  intersection,
  isEmpty, keyBy,
  keys,
  map,
  memoize, some,
  sortBy,
  split,
  toLower,
  values
} from 'lodash';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import { FoldersDataService } from '@services/folders/folders-data.service';

import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CheckResponseService {

  private responses: { data: IObjectStringKeyMap<IResponse>; lastRequest: number } = {
    data: null,
    lastRequest: null
  };

  public responseFilterObject: any = {};
  public lastStorageTime = 0;

  private responseCacheData: {
    startTime: number;
    endTime: number;
  } = {startTime: null, endTime: null};

  private responseItemCache: any = {};

  private initialCacheFill = false;
  private partialLoad = null;
  private originalLastRequest = null;

  private updatingCounter = 0;
  private refreshing = false;
  private lastChunkSize = 0;
  private chunkSize = 50000;

  private upToDate = false;

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private deployment: DeploymentService,
    private assetsService: AssetsService,
    private userService: UserService,
    private userDataService: UserdataService,
    private accountsService: AccountsService,
    private events: Events,
    private utils: UtilsService,
    private checkService: ChecksService,
    private translate: TranslateService,
    private teamsService: TeamsService,
    private permissions: PermissionsService,
    private localdb: LocalDBService,
    private webworkers: WebWorkerService,
    private foldersDataService: FoldersDataService
  ) {
  }

  public async refresh(updated: number = 0): Promise<boolean> {
    if (this.responses && this.responses?.data && this.responses?.lastRequest) {
      if (updated && updated < this.responses.lastRequest) {
        this.logger.log(`local response cache already up to date: ${updated}, ${this.responses.lastRequest}`);
        return Promise.resolve(true);
      }
      // new logic - only do a refresh if we have some responses cached.  This will keep the
      // local collection up to date.
      return new Promise(async (resolve, reject) => {
        const r = {
          locations: JSON.stringify(this.userDataService.locations),
          status: JSON.stringify(['available', 'inProgress', 'complete', 'groupsCompleted', 'skipped', 'notCompleted', 'missed' ]),
          trim: 1,
          lastRequest: this.responses.lastRequest,
          changesOnly: 1
        };
        await this.getResponses(r, true, true, false);
        resolve(true);
      });
    // } else {
    //   this.logger.debug('attempting a local restore');
    //   return this.restoreFromLocalDB();
    }
  }

  public async updateCache(data, honorLastRequest: boolean = true) {
    // is this an incremental update?
    const responses = values(data.result.responses);

    if (this.responses.data && this.responses.lastRequest) {
      if (has(data.result, 'removed')) {
        if (has(data.result.removed, 'abandoned')) {
          each(data.result.removed.abandoned, (item) => {
            delete this.responses.data[item];
          });
          this.deleteFromLocalDB(data.result.removed.abandoned);
        }
        if (has(data.result.removed, 'canceled')) {
          each(data.result.removed.canceled, (item) => {
            delete this.responses.data[item];
          });
          this.deleteFromLocalDB(data.result.removed.canceled);
        }
      }
      await this.utils.iterateWithDelay(responses, (item) => {
        this.responses.data[item.responseID] = item;
      });
      if (honorLastRequest) {
        this.responses.lastRequest = data.result.timestamp;
      }
    } else {
      if (honorLastRequest) {
        this.responses.lastRequest = data.result.timestamp;
      }
      // await this.utils.iterateWithDelay(responses, (item) => {
      //   const deployment = this.deployment.getDeployment(item.deploymentID);
      //   const check = this.checkService.getCheck(deployment.checkID) || <ICheck>{};

      //   item = defaults(item, deployment);
      //   item.checkType = check.checkType;
      //   item.deploymentAssignedTo = deployment.assignedTo;
      // });
      this.responses.data = data.result.responses;
    }
    this.logger.log('Now we have ' + keys(this.responses.data).length + ' responses cached');
    if (this.updatingCounter) {
      this.updatingCounter--;
      if (this.updatingCounter) {
        this.logger.log(`There are still ${this.updatingCounter} pending updates`);
      }
    }

    // if (updateStorage && !this.updatingCounter) {
    //   if (!this.lastStorageTime || this.responses.lastRequest > this.lastStorageTime + 60 * 1000 * 1000) {
    //     this.lastStorageTime = this.responses.lastRequest;
    //     this.storageService.store('responses', this.responses, 'data', 'responseID', 50000);
    //   } else {
    //     this.logger.log('too soon for cache update');
    //   }
    // }
  }

  public clearCache() {
    this.upToDate = false;
    this.responses.data = null;
    this.responses.lastRequest = null;
    this.lastStorageTime = 0;
    this.originalLastRequest = null;
    this.partialLoad = null;
    this.responseItemCache = {};
    this.updatingCounter = 0;
    this.responseCacheData.startTime = null;
  }

  public async getResponseAsync(id: string): Promise<Response | null> {
    return new Promise((resolve) => {
      let m = find(this.responses.data, {responseID: id}) as IResponse | null;
      if (m) {
        const dep = this.deployment.getDeployment(m.deploymentID);
        resolve(new Response(m, this.checkService.getCheck(dep.checkID), dep));
      } else {
        this.getResponses({responses: JSON.stringify([id]), trim: 1, status: '[]', changesOnly: true})
          .then((data) => {

            m = find(data, {responseID: id}) as IResponse | null;
            if (m) {
              if (!this.responses.data) {
                this.responses.data = {};
              }
              this.responses.data[m.responseID] = m;
              const dep = this.deployment.getDeployment(m.deploymentID);
              resolve(new Response(m, this.checkService.getCheck(dep.checkID), dep));
            }
          }).catch(err => {
          resolve(null);
        });
      }
    });
  }

  public async getDeploymentByResponseID(rid: string): Promise<string> {
    const respObj: any = await this.getResponseAsync(rid);
    const deployment: any = this.deployment.getDeployment(respObj.deploymentID);
    if (deployment) {
      return deployment.title;
    } else {
      return '';
    }
  }

  public getDeploymentByID(did: string): string {
    const deployment: any = this.deployment.getDeployment(did);
    if (deployment) {
      return deployment.title;
    } else {
      return '';
    }
  }

  public async getResponseByObservationID(oid: number) {
    const ridObj: any = this.findRespItemByObsID(oid);
    if (!ridObj) {
      return null;
    }

    const respObj: any = await this.getResponseAsync(ridObj.responseID);
    if (!respObj) {
      return null;
    }
    const deployment: any = this.deployment.getDeployment(respObj.deploymentID);
    const splitSig = split(respObj.targetSignature, ':');
    // Translate these stuff
    let targStr = '';
    if (splitSig[0] === 'loc') {
      targStr = 'Zone' + ': ' + this.userService.findAnyZoneNoLoc(splitSig[2]).name;
    } else if (splitSig[0] === 'asset') {
      const targObj = this.assetsService.getAssetById(+splitSig[1]);
      targStr = 'Asset' + ': ' + targObj[0].name;
    } else if (splitSig[0] === 'worker') {
      targStr = 'Worker' + ': ' + this.accountsService.fullname(+splitSig[1]);
    }
    ridObj.checkID = respObj.checkID;
    ridObj.owner = respObj.owner;
    ridObj.targetSignature = targStr;
    ridObj.deployment = deployment ? deployment.title : '';
    return ridObj;
  }


  public getAssigneeNames(assignedTo: any, deployment?: any, dereferenceSupervisors?: boolean, targetSignature?: string): string {
    let assigned = '';
    let separate = false;

    if (get(assignedTo, 'teams.length')) {
      const teams: string[] = [];
      assignedTo.teams.forEach((teamID) => {
        if (teamID === -1) {
          teams.push(this.translate.instant('MGMT_DETAILS.Workers_Primary_Team'));
        } else {
          teams.push(this.teamsService.teamNameByID(teamID));
        }
      });
      if (assignedTo.teams.length === 1) {
        assigned += this.translate.instant('SHARED.Team') + ': ' + map(teams).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.TEAMS') + ': ' + map(teams).join(', ');
      }
      separate = true;
    } else if (assignedTo.teams || (deployment && deployment.target.targetType === 'workers')) {
      assigned += separate ? '<hr>' : '';
      assigned += this.translate.instant('SHARED.TEAMS') + ': ' + this.translate.instant('SHARED.Any_Team');
      separate = true;
    }

    if (assignedTo.users && assignedTo.users.length) {
      const users: string[] = [];
      assignedTo.users.forEach((userID) => {
        if (userID === -1) {
          users.push(this.translate.instant('MGMT_DETAILS.Self_Check'));
        } else if (userID === -2) {
          if (dereferenceSupervisors && targetSignature && targetSignature.match(/^worker/)) {
            const t = targetSignature.split(/:/);
            const u = this.accountsService.getAccount(+t[1]);
            if (u) {
              if (u.supervisorID) {
                users.push(this.userService.getFullname(u.supervisorID));
              } else {
                users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
              }
            } else {
              users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
            }
          } else {
            users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
          }
        } else {
          users.push(this.userService.getFullname(userID));
        }
      });
      assigned += separate ? '<hr>' : '';
      if (users.length === 1) {
        assigned += this.translate.instant('SHARED.USER') + ': ' + map(users).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.USERS') + ': ' + map(users).join(', ');
      }
      separate = true;
    }

    if (get(assignedTo, 'permissions.length')) {
      const permissions: string[] = [];
      assignedTo.permissions.forEach((permID) => {
        const perm: any = find(this.permissions.permissions.data, {id: permID});
        permissions.push(this.translate.instant(perm.description));
      });
      assigned += separate ? '<hr>' : '';
      if (assignedTo.permissions.length === 1) {
        assigned += this.translate.instant('MGMT_LIST.Permission') + ': ' + map(permissions).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + map(permissions).join(', ');
      }
    } else if (assignedTo.permissions || (deployment && deployment.target.targetType === 'workers')) {
      assigned += separate ? '<hr>' : '';
      assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + this.translate.instant('SHARED.Any_Permissions');
    }
    return assigned || this.translate.instant('SHARED.None');
  }

  public getTargetInfo(targetSignature) {
    if (!targetSignature) {
      return;
    }
    const splitSig = split(targetSignature, ':');
    // Translate these stuff
    let targStr = '';
    if (splitSig[0] === 'loc') {
      targStr = this.getTargetZoneName(+splitSig[1], +splitSig[2]);
    } else if (splitSig[0] === 'asset') {
      const targObj = this.assetsService.getAssetById(+splitSig[1]);
      targStr = this.translate.instant('SHARED.Asset') + ': ' + toLower(targObj[0].name);
    } else if (splitSig[0] === 'worker') {
      targStr = this.translate.instant('SHARED.Worker') + ': ' + toLower(this.accountsService.getCompactName(+splitSig[1]));
    }
    return targStr;
  }

  public async getMatchingCheckResponses(filters: any): Promise<IResponseCollection> {
    /*
    logic: mashall the filters into a request for getResponseIDs
    await the answer from that request
    if it returns a list of responses, then loop over them:
    if one is in memory and up to date, next
    if one is in the cache and up to date, put that in a list to pull from cache
    otherwise put it in a list to load from the backend

    Start the backend request if needed
    Start the cache pull request if needed

    when complete, return an object with all of the responses keyed by responseid
    */

    const f = cloneDeep(filters);
    if (!has(f, 'startTime')) {
      throw new Error('startTime is a required parameter');
    }
    f.startTime = this.utils.toSeconds(f.startTime);
    if (has(f, 'endTime')) {
      f.endTime = this.utils.toSeconds(f.endTime);
    }

    if (f?.locationTime) {
      // we are using the location time - get the timezone
      f.tz = moment.tz.guess();
    }

    if (!has(f, 'checkStatus') || f.checkStatus.length === 0) {
      f.checkStatus = ['available', 'inProgress', 'complete', 'groupsCompleted', 'skipped', 'notCompleted', 'missed' ];
    }

    if (!has(f, 'states') || f.states.length === 0) {
      f.states = ['available', 'inProgress', 'complete', 'groupsCompleted', 'skipped', 'notCompleted', 'missed' ];
    }

    const msg = {
      cmd: 'getResponseIDs',
      selectors: f,
      useUpdateTime: 1
    };

    this.logger.debug(`calling getResponseIDs`);
    const [ resp, err ] = await awaitHandler(this.commsService.sendMessage(msg));

    if (err) {
      this.logger.error(`request failed: ${err}`);
      throw new Error(err);
    }

    const responses = {};
    if (resp.reqStatus === 'OK') {
      // okay we got a response
      const matches = resp.result.responses;
      this.logger.debug(`got a reply from getResponseIDs - collecting ${matches.length} responses`);
      const loaded = [];
      const needed = [];

      each(matches, item => {
        if (has(this.responses.data, item.responseID) && item.updatedAt <= this.responses.data[item.responseID].updatedAt) {
          loaded.push(item.responseID);
          responses[item.responseID] = this.responses.data[item.responseID];
        } else {
          needed.push(item);
        }
      });

      if (needed.length) {
        const missed = await this.loadFromCache(needed);
        if (missed?.length) {
          // some were not in cache either
          const rows = [];
          each(missed, item => {
            rows.push(item.row);
          });
          const [fetched, err2] = await awaitHandler(this.getResponsesByRow(rows));
          if (err2) {
            this.logger.error(`Request for rows failed: ${err2}`);
          } else if (fetched && fetched.length !== missed.length) {
            this.logger.error('fetched count does not equal needed');
            // which ones did we miss?
          }
        }
        // these should all be in responses now
        each(needed, item => {
          if (has(this.responses.data, item.responseID)) {
            responses[item.responseID] = this.responses.data[item.responseID];
          } else {
            this.logger.error(`item ${item.responseID} is not in memory but it should be`);
          }
        });
      } else {
        this.logger.debug(`everything was already in memory`);
      }
    } else {
      this.logger.error(`request for matching responses failed: ${resp.reqStatusText}`);
    }
    return this.buildClasses(responses);
  }

  private async getResponsesByRow(rows: number[], updateCache: boolean = true ): Promise<number[]> {
    const theList = clone(rows).sort((a,b) => b - a);
    const req = {
      cmd: 'getAvailableResponses',
      trim: 1,
      rows: theList,
      useUpdateTime: 1
    };

    const [data, err] = await awaitHandler(this.chunkedLoad(req, updateCache));

    const retrieved = [];
    if (data && data.reqStatus === 'OK') {
      if (!this.responses.data) {
        this.responses.data = {};
      }
      each(data.result.responses, item => {
        retrieved.push(item.rowID);
        this.responses.data[item.responseID] = item;
      });
    } else if (err) {
      this.logger.error(`getAvailableResponses request failed: ${data.reqStatus}`);
      throw new Error(err);
    }

    // when we are all done, return a list of the rows actually retrieved

    return retrieved;
  }

  private async loadFromCache(responses: { responseID: string; updatedAt: number; row: number }[]): Promise<any[]> {
    const list = [];
     each(responses, item => {
      list.push(item.responseID);
    });

    let matchCount = 0;
    this.logger.debug('attempting restore from localdb');
    const tRef = this.localdb.table('responses');
    const fromCache = await tRef.where('responseID').anyOf(list).toArray(); // bulkGet(list);
    this.logger.debug('fetch from localdb complete');
    if (!fromCache.length) {
      // nothing matched
      this.logger.debug(`no matches in cache`);
      return responses;
    }

    const missed = [];
    if (!this.responses.data) {
      this.responses.data = {};
    }
    const byID = keyBy(fromCache, 'responseID');
    each(responses, item => {
      if (byID[item.responseID] && byID[item.responseID].updatedAt >= item.updatedAt) {
        this.responses.data[item.responseID] = byID[item.responseID];
        matchCount++;
      } else {
        missed.push(item);
      }
    });

    this.logger.debug(`matched ${matchCount}; missed ${missed.length}`);
    return missed;
  }

  // @@@@@@@@@ making everything async
  public async getAllCheckResponses(range, teams: any = null, dontFilter: boolean = false) {
    const startTime = this.utils.toSeconds(range.startTime);
    // make sure all in range are loaded
    const filter = {
      locations: this.userDataService.locations,
      skipFindAvailableTime: 1,
      startTime,
      endTime: 2000000000
    };

    const items = await this.getMatchingCheckResponses(filter);
    if (dontFilter) {
      return items;
    } else {
      const filteredResponses = this.filterResponsesByDate(items, range.startTime, range.endTime);
      if (!teams) {
        return filteredResponses;
      } else {
        return this._filterResponsesByVisibility(filteredResponses, teams);
      }
    }
  }

  public async getAvailableChecksResponses(range, teams, filter = {}) {
    if (range.startTime && range.endTime) {
      return [];
    }

    const availableResponses = await this.getResponses(filter, false);

    return this._filterResponsesByVisibility(availableResponses, teams);
  }

  public async getCheckResponseByCheckIDStatus(checkType: number, range, teams: any, targetSignature: string, status?: string, targetResponses?: Response[]) {
    const s = this.utils.toSeconds(range.startTime);
    const e = this.utils.toSeconds(range.endTime);
    const responses: Response[] = targetResponses || values(await this.getMatchingCheckResponses({
      startTime: s,
      endTime: e || 2000000000,
      skipFindAvailableTime: 1,
      teams,
      signatures: [targetSignature]
    }));

    // this.fillResponses(responses);
    if (!status) {
      // first filter by teams
      const filtered = this._filterResponsesByVisibility(filter(responses, {checkType, targetSignature}), teams);

      if (s && e) { // this means yesterday
        const yesterdayFilter = filter(filtered, (data: any) => {
          if (data.status === 'available' || data.status === 'inProgress' || data.status === 'pending') {
            return false;
          }
          //check if completed
          if (data.completionTime) {
            if (data.completionTime > s && data.completionTime < e) {
              return true;
            } // did it expire on its own?
          } else if (data.expiresTime > s && data.expiresTime < e) {
            return true;
          }
        });
        return yesterdayFilter;
      } else if (s && !e) {
        const timeFiltered = filter(filtered, (data: any) => {
          if (data.status === 'available') {
            return true;
          } else {
            if (data.completionTime > s) {
              return true;
            } else if (data.expiresTime > s) {
              return true;
            }
          }
        });
        return timeFiltered;
      } else {
        return filtered;
      }
    } else {

      const filtered = this._filterResponsesByVisibility(filter(responses, {
        checkType,
        status,
        targetSignature
      }), teams);
      if (s && e) { // this means yesterday
        const yesterdayFilter = filter(filtered, (data: any) => {
          //check if completed
          if (data.completionTime) {
            if (data.completionTime > s && data.completionTime < e) {
              return true;
            } // did it expire on its own?
          } else if (data.expiresTime > s && data.expiresTime < e) {
            return true;
          }
        });
        return yesterdayFilter;
      } else if (s && !e) {
        const timeFiltered = filter(filtered, (data: any) => {
          if (data.completionTime > s) {
            return true;
          } else if (data.expiresTime > s) {
            return true;
          }
        });
        return timeFiltered;
      } else {
        return filtered;
      }
    }
  }

  public checkResponse(ref, opts) {
    const type = ref.type;
    const target = ref.target;
    let result = ref.result;
    const assignedTo = ref.assignedTo;
    const owner = ref.owner;
    // ownerData is empty if there is no owner
    const ownerData: any = owner ? this.accountsService.getAccount(ref.owner) : {};

    if (!opts.includingAll) {
      const primary = opts.primary;
      const secondary = opts.secondary;
      const observationCount = includes(opts.extraColumns, 'observationCount');
      const issueCount = includes(opts.extraColumns, 'issueCount');
      const includingList: string[] = ['responder', 'responderTeam', 'responder_shift'];
      if (includes(includingList, primary) || includes(includingList, secondary)) {
        if (!owner) {
          // this has no owner / responder and we are organizing by responder, so bail out
          return false;
        }
      }
      if (observationCount && !get(ref, 'observationCount') || issueCount && !get(ref, 'issueCount')) {
        return false;
      }
    }
    if (opts.obstype && opts.obstype.length) {
      if (!includes(opts.obstype, type)) {
        return false;
      }
    }

    if (opts.checkStatus && opts.checkStatus.length) {
      if (!includes(opts.checkStatus, ref.instanceStatus || ref.status)) {
        return false;
      }
    }

    if (opts.checkResults && opts.checkResults.length) {
      if (result === '' || result === 'unknown') {
        result = 'none';
      }
      if (!includes(opts.checkResults, result)) {
        return false;
      }
    }

    if (opts.checkType && opts.checkType.length) {
      if (has(ref, 'checkType')) {
        if (!includes(opts.checkType, ref.checkType.toString())) {
          return false;
        }
      }
    }

    if (opts.checkName && opts.checkName.length) {
      if (!includes(opts.checkName, ref.checkID)) {
        return false;
      }
    }

    if (opts.checkDetailName && opts.checkDetailName.length) {
      if (!includes(opts.checkDetailName, ref.checkID)) {
        return false;
      }
    }

    if (opts.deploymentType && opts.deploymentType.length) {
      if (!includes(opts.deploymentType, target.targetType)) {
        return false;
      }
    }

    if (opts.deploymentName && opts.deploymentName.length) {
      if (!includes(opts.deploymentName, ref.deploymentID)) {
        return false;
      }
    }

    if (opts.targetLocations && opts.targetLocations.length) {
      const targetTypes = opts.targetLocations.map(i => Number(i));
      if (ref.locationID) {
        if (indexOf(targetTypes, ref.locationID) === -1) {
          return false;
        }
      } else {
        if (!intersection(targetTypes, target.locations).length) {
          return false;
        }
      }
    }

    if (opts.targetZones && opts.targetZones.length) {
      const targetZones = opts.targetZones.map(i => Number(i));
      if (!intersection(targetZones, target.zones).length) {
        return false;
      }
    }

    if (opts.targetAssetType && opts.targetAssetType.length) {
      const assetTypes = opts.targetAssetType.map(i => Number(i));
      if (!intersection(assetTypes, target.assetTypes).length) {
        return false;
      }
    }

    if (opts.targetAssets && opts.targetAssets.length) {
      const [target, assetID] = split(ref.targetSignature, ':');

      if (target === 'asset') {
        const assetFolderTree = this.foldersDataService.getFolderTreeBy(FolderDataType.ASSET);
        const refFolders: number[] = flattenDeep(ref.target?.folders);

        const isMatch = some(opts.targetAssets, (assetFolder) => {
          if (assetFolder.assetID) {
            return +assetID === assetFolder.assetID;
          } else {
            return this.foldersDataService.hasInFolders(assetFolder.folderID, refFolders, assetFolderTree);
          }
        });

        if (!isMatch) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetTeams && opts.targetTeams.length) {
      const targetTeams = opts.targetTeams.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      let user = null;
      let groups = [];
      if (targetSignature) {
        const splitSig = split(targetSignature, ':');
        // eslint-disable-next-line eqeqeq
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
          if (userID) {
            user = this.accountsService.getByID(userID);
            groups = user ? user.groups : [];
          }
        }
      }
      if (userID && user && groups.length) {
        if (!intersection(targetTeams, groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetPermissions && opts.targetPermissions.length) {
      if (!intersection(opts.targetPermissions, target.permissions).length) {
        return false;
      }
    }

    if (opts.targetUsers && opts.targetUsers.length) {
      const targetUsers = opts.targetUsers.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      if (targetSignature) {
        const splitSig = split(targetSignature, ':');
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
        }
      }
      if (userID) {
        if (!includes(targetUsers, userID)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.users && opts.users.length && ref.assignedTo.users && ref.assignedTo.users.length) {
      if (assignedTo.users && assignedTo.users.length) {
        if (!intersection(opts.users, assignedTo.users).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assigned && opts.assigned.length) {
      if (assignedTo.users && assignedTo.users.length) {
        if (assignedTo.users[0] === -1) {
          const splitSig = split(ref.targetSignature, ':');
          if (+splitSig[1]) {
            assignedTo.users[0] = +splitSig[1];
          }
        } else if (assignedTo.users[0] === -2) {
          const splitSig = split(ref.targetSignature, ':');
          const userObj = this.accountsService.getAccount(+splitSig[1]);
          if (userObj.supervisorID) {
            assignedTo.users[0] = userObj.supervisorID;
          }
        }
        if (!intersection(opts.assigned, assignedTo.users).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedTeam && opts.assignedTeam.length) {
      if (assignedTo.teams && assignedTo.teams.length) {
        const assignedTeam = opts.assignedTeam.map(i => Number(i));
        if (!intersection(assignedTeam, assignedTo.teams).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedPerms && opts.assignedPerms.length) {
      if (assignedTo.permissions && assignedTo.permissions.length) {
        if (!intersection(opts.assignedPerms, assignedTo.permissions).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.permissions && opts.permissions.length) {
      if (owner) {
        // there is an owner, so look at that person
        if (!intersection(opts.permissions, Object.keys(ownerData.permissions)).length) {
          return false;
        }
      } else if (ref.assignedTo.permissions && ref.assignedTo.permissions.length) {
        // no owner - are there explicit assignment permissions we can test?
        if (!intersection(opts.permissions, ref.assignedTo.permissions).length) {
          return false;
        }
      }
    }

    if (opts.responders && opts.responders.length) {
      if (owner) {
        const responders = opts.responders.map(i => Number(i));
        if (!includes(responders, owner)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.groups && opts.groups.length) {
      if (ownerData && ownerData.groups && ownerData.groups.length) {
        if (!intersection(opts.groups, ownerData.groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.detailTags && opts.detailTags.length) {
      let detailTags = [];
      if (ref.questionsObjs && ref.questionsObjs.length) {
        forEach(ref.questionsObjs, (qObj, counter) => {
          if (qObj && qObj.closingTags && qObj.closingTags.length) {
            detailTags = [
              ...detailTags,
              qObj.closingTags
            ];
          }
        });
      }
      if (!intersection(opts.detailTags, detailTags).length) {
        return false;
      }
    }

    if (opts.questionCategory && opts.questionCategory.length) {
      const qCategory = [];
      if (ref.questionsObjs && ref.questionsObjs.length) {
        forEach(ref.questionsObjs, (qObj, counter) => {
          if (qObj && qObj.closingCategory) {
            qCategory.push(qObj.closingCategory);
          }
        });
      }
      if (!intersection(opts.questionCategory, qCategory).length) {
        return false;
      }
    }


    return true;
  }

  public async getResponses(filters: IObjectStringKeyMap<any>, useCache: boolean = false, applyFilters: boolean = false, returnResponses: boolean = true): Promise<IResponseCollection> {
    const e = clone(filters);
    e.cmd = e?.changesOnly ? 'getAvailableResponses' : 'getResponses';
    e.noCache = 1;
    e.trim = 1;
    e.sendTime = Date.now();
    if (!has(e, 'status')) {
      e.status = JSON.stringify(['available']);
    }
    if (e.startTime) {
      e.startTime = this.utils.toSeconds(e.startTime);
    }
    if (e.endTime) {
      e.endTime = this.utils.toSeconds(e.endTime);
    }
    if (!e?.changesOnly && useCache && e.startTime && (!this.responseCacheData.startTime || e.startTime < this.responseCacheData.startTime) ) {
      // see if there is anything local we can use
      const theResult = this.upToDate ? await this.restoreFromLocalDB(e.startTime) : await this.restoreAndUpdate(e.startTime);  // try to do a restore
      if (!theResult) {
        this.logger.debug(`nothing to restore from local storage`);
      } else {
        this.logger.debug('restored from local storage');
      }
    }
    if (!e?.changesOnly && useCache && this.responses.data) {
      // do we need to fetch stuff from the backend?
      if (!e.lastRequest && e.startTime && this.responseCacheData.startTime) {
        if (this.responseCacheData.startTime <= e.startTime) {
          // the data is already here
          this.logger.debug('using cached responses');
          if (!returnResponses) {
            return;
          } else {
            return this.buildClasses(this.responses.data, filters, applyFilters);
          }
        } else {
          // only retrieve up to here - we have the rest
          e.endTime = this.responseCacheData.startTime;
          this.logger.debug(`using startTime of ${e.startTime} and endTime of ${e.endTime}`);
        }
        //e.omit = JSON.stringify(rowList);
        // e.lastRequest = this.responses.lastRequest;
      }
    }
    // we got this far and are going to start pulling data
    let data;
    let err;
    if (useCache) {
      this.refreshing = true;
    }
    if (!has(e, 'lastRequest')) {
      // chunked load will deal with cachevalid
      [data, err] = await awaitHandler(this.chunkedLoad(e, useCache));
    } else {
      [data, err] = await awaitHandler(this.commsService.sendMessage(e));
      // roll this up into an array
      if (useCache && has(data.result, 'removed')) {
        if (has(data.result.removed, 'abandoned')) {
          each(data.result.removed.abandoned, (item) => {
            delete this.responses.data[item];
          });
          this.deleteFromLocalDB(data.result.removed.abandoned);
        }
        if (has(data.result.removed, 'canceled')) {
          each(data.result.removed.canceled, (item) => {
            delete this.responses.data[item];
          });
          this.deleteFromLocalDB(data.result.removed.canceled);
        }
      }
      if (useCache && data.result?.responses) {
        // save the changes
        this.updateLocalDB(data.result.responses, { newest: data.result.timestamp, oldest: e?.startTime}, true);
      }
    }
    if (data.reqStatus === 'OK') {
      // some data was loaded
      const rows: IObjectStringKeyMap<IResponse> = data.result.responses;
      if (useCache) {
        this.responses.lastRequest = data.result.timestamp;
        if (!this.responses.data) {
          this.responses.data = {};
        }
        // update the rows with the new data
        each(rows, item => {
          this.responses.data[item.responseID] = item;
        });
        if (e.startTime) {
          // the cache now goes back this far
          this.responseCacheData.startTime = this.utils.toSeconds(e.startTime);
          // was there an endTime?
          // ensure there is a timestamp update in the queue
          let endTime = e?.endTime;
          if (!endTime) {
            endTime = data.result.timestamp;
          } else if (endTime > data.result.timestamp) {
            endTime = data.result.timestamp;
          }

          this.updateLocalDB({}, { newest: endTime, oldest: e.startTime}, true);
        }
        this.events.publish('ccs:checkResponseUpdate', true);
        this.refreshing = false;
        if (returnResponses) {
          return this.buildClasses(this.responses.data, filters);
        } else {
          return;
        }
      } else {
        if (returnResponses) {
          return this.buildClasses(rows, filters);
        } else {
          return;
        }
      }
    } else if (useCache && data && data.reqStatus === 'CACHEVALID') {
      this.logger.debug('Response fetch returned CACHEVALID');
      this.refreshing = false;
      if (returnResponses) {
        return this.buildClasses(this.responses.data, filters);
      } else {
        return;
      }
    } else if (err) {
      this.logger.error(`getAvailableResponses request failed: ${data.reqStatus}`);
      this.refreshing = false;
      throw new Error(err);
    }
    return;
  }

  private buildClasses(rows: IObjectStringKeyMap<IResponse>, filters?: any, applyFilters: boolean = false): IResponseCollection {
    const collection: IResponseCollection = {};
    const depCache = {};
    const checkCache = {};

    // this.logger.debug(`starting to build Classes for ${Object.keys(rows).length} responses`);

      each(rows, (item, responseID) => {
        const deployment = depCache[item.deploymentID] ?? ( depCache[item.deploymentID] = this.deployment.getDeployment(item.deploymentID) );
        if (!deployment) {
          return;
        }
        const check = checkCache[deployment.checkID] ?? ( checkCache[deployment.checkID] = this.checkService.getCheck(deployment.checkID) || {} as ICheck );
        if (filters && applyFilters) {
          const r = new Response(item, check, deployment);
          if (this.testResponse(r, filters)) {
            collection[responseID] = r;
          }
        } else {
          collection[responseID] = new Response(item, check, deployment);
        }
      });
    // this.logger.debug(`Class build complete`);
    return collection;
  }

  private testResponse(ref: Response, opts: any): boolean {
    const owner = ref.owner;
    // ownerData is empty if there is no owner
    const ownerData: any = owner ? this.accountsService.getAccount(ref.owner) : {};

    if (opts.range || opts.startTime) {
      const importantTime = ref.completionTime || ref.expiresTime;
      if (importantTime < ( opts?.startTime ?? opts.range.startTime ) ) {
        return false;
      }
      if (opts.range.endTime || opts.endTime) {
        if (importantTime > ( opts?.endTime ?? opts.range.endTime)) {
          return false;
        }
      }
    }
    if (opts.status && opts.status.length) {
      if (!includes(opts.status, ref.status)) {
        return false;
      }
    }
    if (opts.results && opts.results.length) {
      if (!includes(opts.results, ref.result)) {
        return false;
      }
    }

    if (opts.checkType && opts.checkType.length) {
      if (has(ref, 'checkType')) {
        if (!includes(opts.checkType, ref.checkType.toString())) {
          return false;
        }
      }
    }

    if (opts.checkName && opts.checkName.length) {
      if (!includes(opts.checkName, ref.checkID)) {
        return false;
      }
    }

    if (opts.checkDetailName && opts.checkDetailName.length) {
      if (!includes(opts.checkDetailName, ref.checkID)) {
        return false;
      }
    }

    const target = ref.deployment.target;
    if (opts.deploymentType && opts.deploymentType.length) {
      if (!includes(opts.deploymentType, target.targetType)) {
        return false;
      }
    }

    if (opts.deploymentName && opts.deploymentName.length) {
      if (!includes(opts.deploymentName, ref.deploymentID)) {
        return false;
      }
    }

    if (opts.targetLocations && opts.targetLocations.length) {
      const targetLocations = opts.targetLocations.map(i => Number(i));
        if (!intersection(targetLocations, ref.targetLocations).length) {
          return false;
        }
    }

    if (opts.targetZones && opts.targetZones.length) {
      const targetZones = opts.targetZones.map(i => Number(i));
      if (!intersection(targetZones, ref.targetZones).length) {
        return false;
      }
    }

    if (opts.targetAssetType && opts.targetAssetType.length) {
      const assetTypes = opts.targetAssetType.map(i => Number(i));
      if (!intersection(assetTypes, target.assetTypes).length) {
        return false;
      }
    }

    if (opts.targetAssets && opts.targetAssets.length) {
      const assets = opts.targetAssets.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let assetsID = null;
      if (targetSignature) {
        const splitSig = split(targetSignature, ':');
        if (splitSig[0] == 'asset') {
          assetsID = +splitSig[1];
        }
      }
      if (assetsID) {
        if (!includes(assets, assetsID)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetTeams && opts.targetTeams.length) {
      const targetTeams = opts.targetTeams.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      let user = null;
      let groups = [];
      if (targetSignature) {
        const splitSig = split(targetSignature, ':');
        // eslint-disable-next-line eqeqeq
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
          if (userID) {
            user = this.accountsService.getByID(userID);
            groups = user ? user.groups : [];
          }
        }
      }
      if (userID && user && groups.length) {
        if (!intersection(targetTeams, groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetPermissions && opts.targetPermissions.length) {
      if (!intersection(opts.targetPermissions, target.permissions).length) {
        return false;
      }
    }

    if (opts.targetUsers && opts.targetUsers.length) {
      const targetUsers = opts.targetUsers.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      if (targetSignature) {
        const splitSig = split(targetSignature, ':');
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
        }
      }
      if (userID) {
        if (!includes(targetUsers, userID)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.users && opts.users.length && ref.assignedTo.users && ref.assignedTo.users.length) {
      if (!intersection(opts.users, ref.assignedTo.users).length) {
        return false;
      }
    }

    if (opts.assigned && opts.assigned.length) {
      if (ref.assignedTo.users && ref.assignedTo.users.length) {
        if (ref.assignedTo.users[0] === -1) {
          const splitSig = split(ref.targetSignature, ':');
          if (+splitSig[1]) {
            ref.assignedTo.users[0] = +splitSig[1];
          }
        } else if (ref.assignedTo.users[0] === -2) {
          const splitSig = split(ref.targetSignature, ':');
          const userObj = this.accountsService.getAccount(+splitSig[1]);
          if (userObj.supervisorID) {
            ref.assignedTo.users[0] = userObj.supervisorID;
          }
        }
        if (!intersection(opts.assigned, ref.assignedTo.users).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedTeam && opts.assignedTeam.length) {
      if (ref.assignedTo.teams && ref.assignedTo.teams.length) {
        const assignedTeam = opts.assignedTeam.map(i => Number(i));
        if (!intersection(assignedTeam, ref.assignedTo.teams).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedPerms && opts.assignedPerms.length) {
      if (ref.assignedTo.permissions && ref.assignedTo.permissions.length) {
        if (!intersection(opts.assignedPerms, ref.assignedTo.permissions).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.permissions && opts.permissions.length) {
      if (owner) {
        // there is an owner, so look at that person
        if (!intersection(opts.permissions, Object.keys(ownerData.permissions)).length) {
          return false;
        }
      } else if (ref.assignedTo.permissions && ref.assignedTo.permissions.length) {
        // no owner - are there explicit assignment permissions we can test?
        if (!intersection(opts.permissions, ref.assignedTo.permissions).length) {
          return false;
        }
      }
    }

    if (opts.groups && opts.groups.length) {
      if (ownerData && ownerData.groups && ownerData.groups.length) {
        if (!intersection(opts.groups, ownerData.groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  }

  getFlagIssue(flagIDArray: number[]): Promise<any> {
    const reqObj = {
      cmd: 'getResponseIssues',
      issues: JSON.stringify(flagIDArray)
    };
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(reqObj, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          resolve(data.result.issues);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getTargetZoneName(locId: number, zoneId: number): string {
    const zone = this.userService.findAnyZoneNoLoc(zoneId);

    const location = this.userService.getLocation(locId) || {
      name: this.translate.instant('SHARED.Unknown')
    };

    const zoneTitle = this.translate.instant('SHARED.Zone');

    const zoneWithLocation = toLower(location.name) + ' / ' + toLower(zone.name);

    return `${zoneTitle}: ${zoneWithLocation}`;
  }

  private async chunkedLoad(query: any, useCache: boolean = false) {
    this.refreshing = true;
    return new Promise(async (resolve, reject) => {
      let lastReq = null;
      let complete = false;
      let lastRow = null;
      const ret = {
        reqStatus: null,
        result: {
          timestamp: null,
          responses: null,
        }
      };

      while (!complete) {
        const e = clone(query);
        e.limit = 50000;
        if (lastRow) {
          e.startAfter = lastRow;
          this.logger.debug('Fetching another chunk of responses');
        } else {
          delete e.startAfter;
          this.logger.debug('Starting a response request');
        }
        const [data, err] = await awaitHandler(this.commsService.sendMessage(e, false, false));
        if (data && data.reqStatus === 'OK') {
          this.logger.debug(`Response fetch completed; retrieved ${data.result.numResponses} responses`);
          // only update the storage on the last fetch
          if (!has(data.result, 'numResponses') || data.result.numResponses === 0) {
            complete = true;
            ret.reqStatus = data.reqStatus;
            ret.result.responses = data.result.responses;
          } else {
            // we loaded some...
            if (data.result.numResponses < 50000) {
              // we ran out of items to fetch
              complete = true;
              this.initialCacheFill = false;
              // put the last request back to where we started so we can get anything that has been updated since then!
              if (lastReq) {
                ret.result.timestamp = lastReq;
              } else {
                ret.result.timestamp = data.result.timestamp;
              }
              // this.logger.log(`We have fetched all of the available responses; doing a final refresh to get anything changed since ${this.responses.lastRequest}`);
              // await this.refresh(undefined);
            } else {
              lastRow = data.result.lastResponse;
              if (!lastReq) {
                lastReq = data.result.timestamp;
              }
            }
            if (!ret.result.responses) {
              ret.result.responses = data.result.responses;
            } else {
              assign(ret.result.responses, data.result.responses);
            }
            if (useCache) {
              await this.updateLocalDB(data.result.responses);
            }
            if (!ret.reqStatus) {
              ret.reqStatus = data.reqStatus;
            }
          }
        } else if (data && data.reqStatus === 'CACHEVALID') {
          this.logger.debug('chunked request responded with CACHEVALID');
          ret.reqStatus = 'CACHEVALID';
        }
      }
      resolve(ret);
    });
  }

  // private fillResponse(item: Response): Response {
  //     const deployment = this.deployment.getDeployment(item.deploymentID);
  //     const check = this.checkService.getCheck(deployment.checkID) || {} as ICheck;

  //     item = defaults(item, deployment);
  //     item.checkID = check.checkID;
  //     item.checkType = check.checkType;
  //     item.deploymentAssignedTo = deployment.assignedTo;
  //     return item;
  // }

  // private fillResponses(responses: Response[]): Response[] {
  //   const depCache = {};
  //   const checkCache = {};
  //   each(responses, item => {
  //     const deployment = depCache[item.deploymentID] ?? ( depCache[item.deploymentID] = this.deployment.getDeployment(item.deploymentID) );
  //     const check = checkCache[deployment.checkID] ?? ( checkCache[deployment.checkID] = this.checkService.getCheck(deployment.checkID) || {} as ICheck );

  //     item = defaults(item, deployment);
  //     item.checkID = check.checkID;
  //     item.checkType = check.checkType;
  //     item.deploymentAssignedTo = deployment.assignedTo;
  //   });
  //   return responses;
  // }

  private findRespItemByObsID(oid: number) {
    let respItemRef = null;
    if (!isEmpty(this.responseItemCache[oid])) {
      return this.responseItemCache[oid];
    }
    each(this.responses.data, (ref: any) => {
      if (!isEmpty(ref.answers)) {
        const r: any = find(ref.answers, {observationID: oid});
        if (r) {
          r.responseID = ref.responseID;
          respItemRef = r;
          return false;
        }
      }
    });
    if (respItemRef) {
      this.responseItemCache[oid] = respItemRef;
    }
    return respItemRef;
  }

  private filterResponsesByDate(responses, startTime: number, endTime: number) {
    let filteredResponses;
    const s = this.utils.toSeconds(startTime);
    const e = this.utils.toSeconds(endTime);

    if (s && e) { // this means yesterday
      filteredResponses = filter(responses, (data: any) => {
        if (data.status === 'available' || data.status === 'inProgress' || data.status === 'pending') {
          return false;
        }
        //check if completed
        if (data.completionTime) {
          if (data.completionTime > s && data.completionTime < e) {
            return true;
          } // did it expire on its own?
        } else if (data.expiresTime > s && data.expiresTime < e) {
          return true;
        }
      });
    } else if (s && !e) {
      filteredResponses = filter(responses, (data: any) => {
        if (data.status === 'available') {
          return true;
        } else {
          if (data.completionTime > s) {
            return true;
          } else if (data.expiresTime > s) {
            return true;
          }
        }
      });
    } else {
      filteredResponses = responses;
    }

    return filteredResponses;
  }

  private _filterResponsesByVisibility(collection, teams) {
    const currentUser = this.accountsService.getAccount(this.userDataService.userID);
    let teamIds: number[];
    if (teams) {
      teamIds = teams.currentId === -1 ? teams.ids : [teams.currentId];
    }

    const getAccount = memoize(id => this.accountsService.getAccount(id));

    return filter(collection, (data: any) => {
      // 1. is the deploymentAssignedTo specific to a team?  If yes, then skip ones that are not assigned to my selected teams

      const intArry = map(data.deploymentAssignedTo.teams, Number);
      if (intArry.length && !intersection(intArry, teamIds).length) {
        return false;
      }

      // 2. is the deploymentAssignedTo specific to individual users (or to self, which means it is the same as the target worker)?  If yes, determine their primary teams and check the intersection of those teams with my selected teams.  Skip if the intersection set is empty (no overlapping teams)

      const t = split(data.targetSignature, ':');
      const targetUser = t[0] === 'worker' ? getAccount(+t[1]) : null;

      if (has(data.deploymentAssignedTo, 'users') && data.deploymentAssignedTo.users.length) {
        let match = 0;
        each(data.deploymentAssignedTo.users, user => {
          if (user && user > -2) {
            // user -1 means assignee is the target user
            const uObj = (user === -1) ? targetUser : getAccount(+user);
            if (+user === currentUser.userID) {
              match = 1;
            } else if (uObj && uObj.primaryGroup) {
              if (indexOf(teamIds, uObj.primaryGroup) > -1) {
                // this is assigned to someone on a team we are watching
                // am I an admin? If so then we should always show the info
                if (this.permissions.canView(Permission.Admin)) {
                  match = 1;
                // am I THEIR supervisor
                // 2.1. if it is an overlapping team, and I am a supervisor, am I the supervisor for any of the individual users?  If not, then skip.
                } else if (uObj && uObj.supervisorID) {
                  if (uObj.supervisorID === currentUser.userID) {
                    match = 1;
                  }
                } else {
                  // they dont have a supervisor and are on a team I am looking at
                  match = 1;
                }
              }
            } else if (uObj) {
              // they have no primary group; am I their supervisor?
              if (uObj && uObj.supervisorID) {
                if (uObj.supervisorID === currentUser.userID) {
                  match = 1;
                }
              }
            }
          } else if (user === -2) {
            // it is assigned to a supervisor.  is this person on a team we are watching?
            // 4. is the target a worker and does the deploymentAssignedTo users property include -2 (supervisor).  If so, and if I am not that user's supervisor, skip.
            if (targetUser && targetUser.primaryGroup && indexOf(teamIds, targetUser.primaryGroup) > -1) {
              if (targetUser.supervisorID && targetUser.supervisorID === currentUser.userID) {
                match = 1;
              }
            }
          }
        });

        if (!match) {
          return false;
        }
      }

      // 3. is the target a worker?   If so, then what is that worker's primary team?   Skip if that team is not one of my selected teams
      if (t[0] === 'worker' && targetUser && targetUser.primaryGroup && indexOf(teamIds, targetUser.primaryGroup) < 0) {
        return false;
      }
      // 5. If we get this far, this is one of my team's relevant available responses.  Show it.

      // if it is "All teams" then we should check "checks" that have empty deploymentAssignedTo
      /* if (teams.currentId === -1) {
        if (!intArry.length) {
          if (data.status === 'available') {
            return true;
          }
        }
      } */

      /* if (intArry.length) {
        const matchesTeam = intersection(teamIds, intArry).length
        if (matchesTeam) {
          // now check for status
          if (data.status === 'available') {
            return true;
          }
        }
      } */
      return true;
    });
  }

  private deleteFromLocalDB(items: string[]): Promise<void> {
    const tRef = this.localdb.table('responses');
    if (tRef) {
      this.logger.debug(`deleting ${items.length} records from responses`);
      return tRef.bulkDelete(items).then(async (res) => {
        const count = await tRef.count();
        this.logger.debug(`localdb responses now has ${count} records`);
      });
    } else {
      return;
    }
  }

  private updateCounter = 0;
  private isUpdating = false;
  private updateQueue = [];

  private async updateLocalDB(records: IObjectStringKeyMap<IResponse>, times?: { newest: number; oldest: number }, updateTimes?: boolean): Promise<boolean> {
    	if (!updateTimes && Object.keys(records).length === 0) {
        this.logger.debug('There are no records in the update');
        return false;
      }

      this.updateQueue.push({
        records, times, updateTimes
      });

      this.logger.debug(`added update to queue: ${this.updateQueue.length}`);
      if (this.isUpdating) {
        return true;
      }
      this.isUpdating = true;
      this.queueRunner();
      return true;
    }

  private async queueRunner(): Promise<void> {
    // iterate on the queue until it is complete
    while (this.updateQueue.length) {
      const job = this.updateQueue.shift();
      // use the web worker to do the writing
      const upArgs: IDBUpdate = {
        table: 'responses',
        data: job.records,
        dataIsArray: false
      };
      const times = job.times;
      if (times) {
        const [storedTimes, tErr] = await awaitHandler(this.localdb.table('updateTimes').get('responses'));

        if (storedTimes) {
          // there were already times in localstorage. is the range wider?
          if (!times?.oldest || ( has(storedTimes, 'oldest') && storedTimes.oldest < times.oldest) ) {
            times.oldest = storedTimes.oldest;
          }

          if (!times?.newest || ( has(storedTimes, 'newest') && storedTimes.newest > times.newest ) ) {
            times.newest = storedTimes.newest;
          }
        }
        if (times.newest && times.oldest) {
          upArgs.times = times;
          upArgs.updateTimes = true;
          this.logger.debug(`Times are ${times.oldest}, ${times.newest}`);
        }
      }
      const updateNum = ++this.updateCounter;
      this.logger.debug(`starting localdb responses update #${updateNum} with ${Object.keys(job.records).length} items`);
      const [result, err] = await awaitHandler(this.webworkers.updateDB(upArgs));
      if (err) {
        this.logger.error(`update #${updateNum} of localdb failed: ${err}`);
        this.updateQueue = [];
      } else {
        this.logger.debug(`finished localdb responses update #${updateNum}`);
      }
    }
    this.isUpdating = false;
    return;
  }


  private isRestoring = false;

  public async restoreAndUpdate(earliestTime?: number): Promise<boolean> {
    // load whatever we were asked to from cache; this should also reset the lastRequest time
    await this.restoreFromLocalDB(earliestTime);
    this.logger.debug('attempting to refresh from the backend');
    await this.refresh();
    this.upToDate = true;
    if (this.responses?.lastRequest) {
      return true;
    } else {
      return false;
    }
  }

  public async restoreFromLocalDB(earliestTime?: number): Promise<boolean> {
    if (this.isRestoring) {
      return false;
    }
    this.isRestoring = true;
    if (earliestTime) {
      earliestTime = this.utils.toSeconds(earliestTime);
      if (this.responseCacheData?.startTime && this.responseCacheData.startTime < earliestTime) {
        // the data from localcache is already loaded to here
        this.isRestoring = false;
        return true;
      }
    }

    // check if there is any data in there
    let times: IUpdateTime;
    let err = null;
    [times, err] = await awaitHandler( this.localdb.table('updateTimes').get('responses'));

    if (!times || ! times?.oldest) {
      // we don't seem to have any cached data
      this.isRestoring = false;
      return false;
    }

    // we have some cached data; does it go back further than the current request

    if (this.responseCacheData.startTime && times.oldest >= this.responseCacheData.startTime) {
      // we have already loaded everything cached locally
      this.isRestoring = false;
      return false;
    }

    if (times) {
      // okay - there was something
      this.logger.debug('starting restore from localdb');
      const tRef = this.localdb.table('responses');
      if (!this.responses?.data) {
        // ensure there is a bucket
        this.responses.data = {};
      }

      // is there a startTime
      let query;
      if (earliestTime) {
        // possibly amend earliest time so we are not confused about what is in RAM

        const eQuery = await tRef.where('updatedAt').aboveOrEqual(earliestTime).sortBy('availableTime');
        if (eQuery.length) {
          const ordered = sortBy(eQuery, 'availableTime');
          if (ordered[0].availableTime < earliestTime) {
            this.logger.debug(`changing earliestTime to ${ordered[0].availableTime}`);
            earliestTime = ordered[0].availableTime;
          }
        }

        if (this.responseCacheData.startTime) {
          // some are already loaded; we can load between or equal
          this.logger.debug(`loading between ${earliestTime} and ${this.responseCacheData.startTime}`);
          query = tRef.where('availableTime').between(earliestTime, this.responseCacheData.startTime, true, false);
        } else {
          // only fetch some
          this.logger.debug(`loading above or equal to ${earliestTime}`);
          query = tRef.where('availableTime').aboveOrEqual(earliestTime);
        }
      } else {
        this.logger.debug(`loading everything`);
        query = tRef;
      }
      // pull in everything
      let counter = 0;
      await query.toArray(async (theItems) => {
        this.logger.debug(`${theItems.length} items retrieved from localdb`);
        theItems.forEach((item) => {
          this.responses.data[item.responseID] = item;
          counter++;
        });
        return true;
      });

      // const theItems = await this.webworkers.restoreDB('responses');
      // we have either loaded a limited subset or everything
      const loadedTime = (earliestTime && times.oldest < earliestTime) ? earliestTime : times.oldest;
      this.responseCacheData.startTime = loadedTime;
      this.responseCacheData.endTime = times.newest;
      this.responses.lastRequest = times.newest;
      this.isRestoring = false;
      this.logger.debug(`restore of ${counter} responses from localdb complete`);
      this.logger.debug(`There are now ${Object.keys(this.responses.data).length} responses in RAM`);
      return true;
    } else {
      this.isRestoring = false;
      return false;
    }
  }

}
