<div class="details-block">
  <div class="title-block">
    <div class="title">{{ "DASHBOARD.CLOSE_POPUP_headline_medium" | translate }}</div>
    <ion-button [appPermissionCanView]="'supervisor'" (click)="editDetails()" class="page-button"
                color="secondary">{{ "SHARED.CLOSING_DETAILS_editDetails" | translate }}</ion-button>
  </div>

  <div class="content-block">
    <div class="left-block">
      <div *ngIf="closingData?.risk" class="info-block">
        <div class="info-title">{{ "DASHPAGES.Risk_Profile" | translate }}</div>
        <div class="info-block-content">
          <app-detail-risk-profile [config]="closingData.risk"></app-detail-risk-profile>
        </div>
      </div>

      <div class="info-block">
        <div class="info-title">{{ "SHARED.Category" | translate }}</div>
        <div class="info-block-content categories">
          <ul>
            <li *ngFor="let category of closingData.categories">{{category}}</li>
          </ul>
          <div *ngIf="closingData.categories.length === 0">{{ "SHARED.No_Data" | translate }}</div>
        </div>
      </div>

      <div *ngIf="closingData.type === 'condition' || closingData.type === 'quality' || closingData.type === 'ai'" class="info-block">
        <div class="info-title">{{ "SHARED.Impact" | translate }}</div>
        <div class="info-block-content default-value">
          {{closingData.impact === 1 ? "1 - " + ("SHARED.Low" | translate) : closingData.impact === 5 ? "5 - " + ("SHARED.High" | translate) : closingData.impact}}
        </div>
      </div>

      <div *ngIf="closingData.type === 'pi'" class="info-block">
        <div class="info-title">{{ "SHARED.CLOSE_POPUP_headline_small" | translate }}</div>
        <div class="info-block-content default-value">
          {{closingData.currency | currency: "USD"}}
        </div>
      </div>

      <div class="info-block">
        <div class="info-title">
          {{ "SHARED.Closed_By" | translate }}
          <span (click)="scrollTo()" class="link">{{ "SHARED.View_History" | translate }}</span>
        </div>
        <div class="info-block-content user-info">
          <img [src]="closingData.user.userImg" alt="User">
          <span>{{closingData.user.name}}</span>
        </div>
      </div>
    </div>

    <div class="right-block">
      <div class="info-block">
        <div class="info-title">{{ "SHARED.Tags" | translate }}</div>
        <div class="info-block-content observation-tags">
          <div *ngFor="let tag of closingData.tags" class="tag">{{tag}}</div>
          <div *ngIf="closingData.tags.length === 0">{{ "SHARED.No_Data" | translate }}</div>
        </div>
      </div>

      <div class="info-block">
        <div class="info-title note-title">
          <span>{{ "SHARED.Closing_Notes" | translate }}</span>
          <ion-button [appPermissionCanView]="'supervisor'" (click)="addNote()" class="page-button" color="secondary"
                      small="small">{{ "SHARED.Add_Note" | translate }}</ion-button>
        </div>
        <div class="info-block-content">
          <div *ngFor="let note of closingData.notes" class="note-content">
            <div class="note-title">
              <span class="note-author">{{note.name}}</span>
              <span class="note-time">{{(note.unixTime | amFromUnix) | amDateFormat:"MMM D HH:mm"}}</span>
            </div>
            <div [innerHTML]="note.data | markdown | async" class="note-text"></div>
          </div>
        </div>
        <div *ngIf="closingData.notes.length === 0">{{ "SHARED.No_Data" | translate }}</div>
      </div>
    </div>

  </div>
</div>
