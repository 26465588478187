import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IQuestion } from '@modules/management/pages/details/check/models';
import { FormBuilderService, TableService, UserdataService, UtilsService } from '@services';
import { QuestionService } from './../../services/question/question.service';

import { TranslateService } from '@ngx-translate/core';
import {
  each,
  find,
  first,
  get,
  identity,
  includes,
  isEmpty,
  last, map,
  pickBy,
  pullAt,
  set,
  some, sortBy,
  split,
  values,
  isEqual as _isEqual
} from 'lodash';

export interface IQuestionGroup {
  title: string;
  uuid: string;
  questions: any[];
}

export interface ISelectedQuestionsEvent {
  groupId: string;
  ids: string[];
}

export interface IOnNameChangeEvent {
  groupId: string;
  formData: any;
  isEqual: boolean;
}

@Component({
  selector: 'app-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss'],
})
export class QuestionGroupComponent implements OnInit {

  @ViewChild('nameForm', {static: true}) nameFormElement: ElementRef<HTMLElement>;
  @Input() groups: IQuestionGroup[];
  @Input() isGroupControlsVisible: boolean;
  @Input() checkId: string;
  @Output() onChecked: EventEmitter<ISelectedQuestionsEvent> = new EventEmitter<ISelectedQuestionsEvent>();
  @Output() onRowReorder: EventEmitter<string> = new EventEmitter<string>();
  @Output() onNameChange: EventEmitter<IOnNameChangeEvent> = new EventEmitter<IOnNameChangeEvent>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGroupRowReorder: EventEmitter<any> = new EventEmitter<any>();
  public tableId: string;
  public group: IQuestionGroup;
  private formConfig = {
    containers: true,
    prefix: 'questionGroup',
    fields: [
      {
        containerClass: 'group-name',
        name: 'title',
        title: this.translate.instant('MGMT_DETAILS.Group_Name'),
        type: 'text',
        maxlength: 180,
        size: 20
      },
      {
        containerClass: 'group-name',
        inputtype: 'verbatim',
        name: 'translation_title',
        title: this.translate.instant('MGMT_DETAILS.Group_Name_Translation'),
        type: 'textTranslations',
        fromID: 'translations',
        size: 20,
        maxlength: 180
      }
    ]
  };
  private tableAttr = {
    class: 'questionTable',
    checkbox: true,
    disableCheckboxSelectAll: true,
    rowprop: 'data-mid',
    rowvaluefrom: 'questionID',
    columns: [
      {
        id: 'orderNumber',
        title: this.translate.instant('MGMT_DETAILS.Question_Item'),
        headerClass: 'table-header-styled'
      },
      {
        id: 'type',
        title: this.translate.instant('MGMT_DETAILS.Format'),
        headerClass: 'table-header-styled',
        func: (type) => {
          if (type === 'passFail') {
            type = this.translate.instant('MGMT_DETAILS.Pass_Fail');
          } else if (includes(['multipleChoice', 'singleSelect', 'multiSelect'], type)) {
            type = this.translate.instant('MGMT_DETAILS.Multiple_Choice');
          } else if (type === 'textInput') {
            type = this.translate.instant('MGMT_DETAILS.Text_Input');
          } else if (type === 'incrementEntry') {
            type = this.translate.instant('MGMT_DETAILS.Increment_Entry');
          } else if (includes(['tolerance', 'actualMeasurement'], type)) {
            type = this.translate.instant('MGMT_DETAILS.Tolerance');
          } else if (type === 'dropdownSelection') {
            type = this.translate.instant('MGMT_DETAILS.Dropdown_Selection');
          }
          return type;
        }
      },
      {
        id: 'validation',
        title: this.translate.instant('MGMT_DETAILS.Validation'),
        headerClass: 'table-header-styled',
        func: (type, question) => {
          const translationMap = {
            no: this.translate.instant('SHARED.No'),
            photo: this.translate.instant('MGMT_DETAILS.Photo'),
            notes: this.translate.instant('MGMT_DETAILS.Notes'),
          };
          const options: any[] = values(this.questionService.getQuestionOptions(question.questionID));
          const validation = {
            photo: some(options, 'settings.usesPhoto'),
            notes: some(options, 'settings.usesNote')
          };
          let validationTitle: string = translationMap.no;

          if (validation.photo && validation.notes) {
            validationTitle = `${translationMap.photo}/${translationMap.notes}`;
          } else if (validation.photo) {
            validationTitle = translationMap.photo;
          } else if (validation.notes) {
            validationTitle = translationMap.notes;
          }

          return validationTitle;
        }
      },
      {
        id: 'importance',
        title: this.translate.instant('MGMT_DETAILS.Importance'),
        headerClass: 'table-header-styled'
      },
      {
        id: 'reorderIcon',
        title: '',
        headerClass: 'table-header-styled',
        func: () => '<div class="reorder-icon"></div>'
      }
    ],
    onClickRow: (messageID: string): any => this.openQuestion(messageID),
    onChecked: (tableId, id, selectedId, selectedIds) => {
      this.onChecked.emit({
        groupId: this.group.uuid,
        ids: selectedIds
      });
    }
  };
  private originalNameFormData: any = {};

  constructor(
    private formBuilder: FormBuilderService,
    private translate: TranslateService,
    private tableService: TableService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private questionService: QuestionService,
    private userDataService: UserdataService
  ) {
  }

  @Input() set groupItem(group: IQuestionGroup) {
    if (group) {
      this.group = group;
      this.renderGroupTable();
    }
  }

  ngOnInit() {
    this.renderNameForm();

    this.tableId = `groupTable_${this.group.uuid}`;
    setTimeout(() => {
      this.renderGroupTable();
    });
  }

  public resetName() {
    this.formBuilder.showForm($(this.nameFormElement.nativeElement), this.formConfig, this.originalNameFormData);
  }

  public defineOriginalNameFormData() {
    this.originalNameFormData = this.formBuilder.getFormData($(this.nameFormElement.nativeElement));
  }

  public renderGroupTable(refreshTitle: boolean = true): void {
    const tableData: any = this.group.questions;
    const tableSelector = `#${this.tableId}`;
    const tableElement = $(tableSelector);

    if (($.fn.dataTable.isDataTable(tableElement))) {
      tableElement.DataTable().clear().draw();
      tableElement.DataTable().destroy();
    }

    this.tableService.showTable(tableSelector, this.tableAttr, tableData);

    const tableInstance = $(tableSelector).DataTable(<any>{
      scrollCollapse: true,
      scrollX: true,
      paging: false,
      searching: false,
      info: false,
      retrieve: true,
      columnDefs: [
        {
          orderable: false,
          className: 'select-checkbox',
          targets: 0,
          width: '40px'
        },
        {
          targets: 1,
          orderable: false,
          type: 'num-html',
          render: (order: string, type, row, meta) => {
            const question = get(this.group.questions, `${meta.row}`);
            const currentLanguage: string = this.userDataService.getLanguage();
            this.utils.decodeTranslations(question, ['translatedTitle'], ['title']);

            return `${+order + 1}. ${get(question, `translatedTitle_${currentLanguage}`, question.title)}`;
          }
        },
        {targets: [2, 3, 4], orderable: false},
        {targets: 5, className: 'reorder', width: '60px'}
      ],
      select: {
        style: 'multi',
        selector: 'td:first-child'
      },
      rowReorder: {
        selector: '.reorder',
        dataSrc: 1
      },
      order: [[1, 'asc']],
      dom: 'frtip'
    });

    tableElement.width('100%');
    if (isEmpty(tableData)) {
      $((<any>tableInstance).table().container()).addClass('empty-table');
    }
    tableInstance.draw(false).columns.adjust();

    tableInstance.off('row-reorder').on('row-reorder', () => {
      setTimeout(() => {
        if (isEmpty(this.questionService.draggedRow)) {
          this.onRowReorderEvent(tableInstance.rows().nodes());
        }
        this.questionService.draggedRow = {};
      });
    });

    tableInstance.off('pre-row-reorder').on('pre-row-reorder', (event, rowElement) => {
      this.questionService.draggedRow = {
        id: tableElement.attr('id'),
        index: rowElement.index
      };
    });

    tableInstance.off('row-reordered').on('row-reordered', (event, diff, editData) => {
      const originalEvent = editData.originalEvent;
      const targetElement = document.elementFromPoint(originalEvent.clientX, originalEvent.clientY);
      const targetTable = $(targetElement).closest('table');
      const targetTableId: string = targetTable.attr('id');
      const draggedRow = this.questionService.draggedRow;
      const groupId: string = last(split(targetTableId, 'groupTable_'));

      if (targetTableId && targetTableId !== draggedRow.id) {
        const relocatedQuestion = first(pullAt(this.group.questions, draggedRow.index));
        const group: IQuestionGroup = find(this.groups, {uuid: groupId});
        const currentRowData = tableInstance.row(draggedRow.index).data();

        relocatedQuestion.checkGroup = groupId;
        relocatedQuestion.orderNumber = group.questions.length;
        currentRowData[1] = relocatedQuestion.orderNumber;

        tableInstance.row(draggedRow.index).remove();

        this.addRowIntoTable(targetTableId, currentRowData, relocatedQuestion);
        this.updateRowOrderNumbers(tableInstance);
      } else {
        this.questionService.draggedRow = {};
      }
    });

    if (refreshTitle) {
      this.renderNameForm();
      this.defineOriginalNameFormData();
    }
  }

  public openQuestion(id?: string): void {
    const params = pickBy({
      checkId: this.checkId,
      checkGroupUUID: this.group.uuid,
      id,
      skipChangeDetection: true
    }, identity);
    this.router.navigate(['/pages/management/checks/view-check/question', params]);
  }

  public removeGroup(): void {
    this.onRemove.emit(this.group.uuid);
  }

  public getGroupName(): string {
    return get(this.formBuilder.getFormData($(this.nameFormElement.nativeElement)), 'title');
  }

  private onNameFormInputHandler = () => this.onNameFormInput();

  private renderNameForm(): void {
    this.formConfig.prefix = `questionGroup_${this.group.uuid}`;
    this.formBuilder.showForm($(this.nameFormElement.nativeElement), this.formConfig, this.group);

    this.nameFormElement.nativeElement.removeEventListener('input', this.onNameFormInputHandler);
    this.nameFormElement.nativeElement.addEventListener('input', this.onNameFormInputHandler);
    this.defineOriginalNameFormData();
  }

  private onRowReorderEvent(diff): void {
    each(diff, (diffItem: any, index: number) => {
      const questionId: string = diffItem.getAttribute('data-mid');
      set(find(this.group.questions, {questionID: <any>questionId}), 'orderNumber', index);
    });
    this.onRowReorder.emit(this.group.uuid);
  }

  private onNameFormInput() {
    const formData = this.formBuilder.getFormData($(this.nameFormElement.nativeElement));
    const isEqual: boolean = _isEqual(formData, this.originalNameFormData);

    this.utils.encodeTranslations(formData, ['translation_title'], ['title']);
    this.onNameChange.emit({formData, isEqual, groupId: this.group.uuid});
  }

  private updateRowOrderNumbers(tableInstance: DataTables.Api) {
    const rowsData: any = tableInstance.rows().nodes().data();
    const sortedRowsMap = sortBy(map(rowsData, (row: string[], index: string) => ({
      rowNumber: index,
      orderNumber: row[1],
      questionID: this.group.questions[index].questionID
    })), 'orderNumber');

    each(sortedRowsMap, (sortedRow, index: number) => {
      sortedRow.orderNumber = index.toString();
    });

    each(rowsData, (rowData: string[], index: string) => {
      const sortedRow = find(sortedRowsMap, {rowNumber: index});
      rowData[1] = sortedRow.orderNumber;
    });

    const newNodes = tableInstance.clear().rows.add(rowsData).rows().nodes();
    each(newNodes, (node, index: string) => {
      const sortedRow = find(sortedRowsMap, {rowNumber: index});
      $(node).attr('data-mid', sortedRow.questionID);
    });
    tableInstance.columns.adjust().draw(true);
  }

  private addRowIntoTable(tableId: string, row: any, question: IQuestion) {
    const tableInstance = $(`#${tableId}`).DataTable();

    this.onGroupRowReorder.emit({tableId, rowData: question});
    const targetNode = (<any>tableInstance.row.add(row)).node();
    $(targetNode).attr('data-mid', question.questionID);
    tableInstance.draw(false);
  }

}
