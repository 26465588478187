<ion-header *ngIf="headerIsHidden" no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>

    <app-detail-controls [headerData]="headerData"></app-detail-controls>

    <app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes>

    <!-- IMAGES BLOCK-->
    <div class="detail-page-section">

      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col size="5">
              <div class="ui-bar">
                <div class="adjustment-grid-item-wrap">
                  <div class="descriptor">{{ "DASHPAGES.COACHING_DETAIL_ccsTableHeader" | translate }}</div>
                  <div class="adjustment-grid-item" id="behaviorList">
                    <div *ngFor="let behav of notes.behaviorNotes">
                      <img alt="User" class="adjustment-icon-tick" src="assets/images/check.svg">
                      <span class="adjustment-grid-value">{{ behav.data }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ion-col>
            <img alt="Arrow" src="assets/images/bigArrow.svg">
            <ion-col>
            </ion-col>
            <ion-col size="5">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "DASHPAGES.COACHING_DETAIL_MITIGATION" | translate }}</div>
                <div class="adjustment-grid-item" id="mitigationList">
                  <div *ngFor="let mit of notes.mitigationNotes">
                    <img alt="Check" class="adjustment-icon-tick" class="adjustment-icon-tick"
                         src="assets/images/check.svg">
                    <span class="adjustment-grid-value">{{ mit.data }}</span>
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <hr class="hr-dashed">

    </div>
    <!-- IMAGES BLOCK END-->


    <!-- NOTE BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium headline-medium-with-button">
        {{ "SHARED.Observation_Notes" | translate }}
        <span [appAccountCanViewExcept]="['viewer']">
          <ion-button (click)="addOpenNote()" class="page-button smaller"
                      color="secondary">{{ "SHARED.Add_Note" | translate }}</ion-button>
        </span>
      </div>

      <!--NEW OBSERVATION NOTES-->
      <div class="section-grid">
        <div class="section-grid-left">
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.openNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img">
                </div>
                <div class="notes-list-item-content">
                  <p><span class="body-copy-bold">{{note.name}}</span> | <span class="detail">{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <!-- NOTE BLOCK END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
