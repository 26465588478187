import { Component, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

import { LoadingService, ViewerService } from '@services';
import { NotePopupComponent } from '@modules/observation/components';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { AbstractObservationDetailPage } from '@modules/observation/pages/abstract-observation-detail-page/abstract-observation-detail-page';
import { Observation } from '@services/observations/observation.interfaces';

@Component({
  selector: 'app-process-detail',
  templateUrl: './process-detail.page.html',
  styleUrls: ['./process-detail.page.scss', './../../directives/mark-note.scss'],
})
export class ProcessDetailPage extends AbstractObservationDetailPage implements OnDestroy {

  // THESE DATA ARE DISPLAYED ON FRONT END
  public uuid: string = null;
  public oid: number = null; // oid
  public bottomComponentData: any; // bottom back/front nav, table nav
  public headerData: any = {}; // data for top component, loc, zone, user etc
  public closingData: any = {}; // data for closing component
  public images = []; // observation images
  public notes: any = {}; // observation notes, audio + test
  public footerButtons = []; // button displayed on footer
  public bottomNav = false;
  public footerData: any = {};
  public headerIsHidden: string;
  public observation: Observation;

  private isImageLoading = false;
  private hasOwner = false;  // to check if there is owner already or not for back nav.

  constructor(
    private observationDetailService: ObservationDetailService,
    private popover: PopoverController,
    private loadingService: LoadingService,
    private viewer: ViewerService,
    private router: Router,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteListener);
    this.events.unsubscribe('ccs:assignUpdate', this.assignListener);
  }

  ionViewDidEnter() {
    this.loadingService.disable();
  }

  ionViewWillEnter() {
    // get observations
    this.uuid = this.activatedRoute.snapshot.paramMap.get('id');
    this.headerIsHidden = this.activatedRoute.snapshot.queryParamMap.get('headerIsHidden');
    if (this.activatedRoute.snapshot.queryParamMap.get('tableNav') === 'true') {
      this.bottomNav = true;
    }

    this.events.subscribe('ccs:noteUpdate', this.noteListener);

    this.events.subscribe('ccs:assignUpdate', this.assignListener);

    this.events.subscribe('ccs:observationUpdate', this.updateObservationListener);

    // to make sure this observation actually exists, lets make a call for this observation.
    this.updateObservationListener();

  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:observationUpdate', this.updateObservationListener);
  }

  public addOpenNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'created'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addClosedNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'fixed'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addFixIdea() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'fixIdea'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addGeneralIdea() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'generalIdea'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private noteListener = (data) => {
    if (data) {
      this.notes = this.observationDetailService.getPiNotes(this.oid);
      this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
    }
  };

  private assignListener = (data) => {
    if (data) {
      // go back to main page after assigining.
      if (this.hasOwner) {
        this.headerData = this.observationDetailService.getTopComponentData(this.oid);
      } else {
        this.router.navigate(['pages/dashboard/process-table']);
      }
    }
  };
  private updateObservationListener = () => {
    this.observationService.getObservationByUUID(this.uuid).then(data => {
      if (data && data.observationID) {
        this.observation = data;
        this.oid = data.observationID;
        this.observationDetailService.checkObservationType(this.oid, 'pi');
        this.headerData = this.observationDetailService.getTopComponentData(this.oid);
        this.images = this.observationDetailService.getImages(this.oid);
        this.notes = this.observationDetailService.getPiNotes(this.oid);
        this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
        if (this.headerData.buttonPanel) {
          this.footerButtons = this.headerData.buttons;
        }

        if (data.ownerID) {
          this.hasOwner = true;
        }

        // if coming from table then only show prev-back nav.
        // blue nav bar depending whether its coming from the table list.
        if (this.bottomNav) {
          this.checkFooterData();
        }
      } else {
        this.observationDetailService.checkObservationType(null, 'pi');
      }
    }).catch(() => {
      this.observationDetailService.checkObservationType(null, 'pi');
    });
  };
}
