import { Component, Injector, Input, OnInit } from '@angular/core';

import { CheckResponseService, ObservationService } from '@services';
import { QuestionService } from '@modules/management/pages/details/check/services';
import { IQuestion, IResponse, IResponseAnswer, Response } from '@modules/management/pages/details/check/models';
import { checkDetailReport } from '@modules/reporting/models';
import { awaitHandler } from '@utils/awaitHandler';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { each, filter, find, get, includes, map, round, trim } from 'lodash';

@Component({
  selector: 'app-check-questions',
  templateUrl: './check-questions.component.html',
  styleUrls: ['./check-questions.component.scss'],
})
export class CheckQuestionsComponent implements OnInit {
  @Input() responseId: string;

  public duration: string;
  public isResponseAvailable: boolean;
  public response: Response;
  public questionCompletedPercent = 0;
  public dataTableOptions = {
    disableAutoResizeHandler: true,
    data: <IQuestion[]>[],
    paging: false,
    info: false,
    retrieve: true,
    stateSave: true,
    searching: false,
    autoWidth: false,
    disableScrolling: true,
    language: {},
    rowprop: 'data-mid',
    rowvaluefrom: 'questionID',
    enableSelectAll: false,
    sortClasses: false,
    ordering: false,
    columns: [],
    onRowClicked: (currentRow, event) => {
      const targetClass = trim(event.target.className);
      const answer = this.answers[currentRow.questionID];

      if (answer && targetClass === 'row-link') {
        this.checkDetailReport.openDetails(this.response, currentRow.questionID, {});
      }
    }
  };

  private columns = {
    title: {
      data: 'title',
      title: this.translate.instant('DASHPAGES.question-item'),
      render: (title: string, type, row, meta) => {
        return `${meta?.row + 1}. ${title}`;
      }
    },
    answer: {
      data: 'questionID',
      title: this.translate.instant('SHARED.Answer'),
      render: (id: string) => {
        const answerTitle = this.getAnswerTitle(id) || this.translate.instant('SHARED.NotCompleted');
        const answerStatus = this.getAnswerStatus(id);

        return `<span class="${answerStatus}">${answerTitle}</span>`;
      }
    },
    validation: {
      data: 'questionID',
      title: this.translate.instant('MGMT_DETAILS.Validation'),
      render: (id: string) => {
        const answer = this.answers[id];
        const title = this.getCellAnswerTitle(answer);
        let linkClass: string = '';

        if (this.observationService.getObservationById(answer?.observationID) || !answer?.observationID) {
          linkClass = 'row-link';
        }

        return title ? `<span class="${linkClass}">${this.translate.instant(title)}</span>` : null;
      }
    },
    duration: {
      data: 'questionID',
      title: this.translate.instant('SHARED.Duration'),
      render: (id: string) => {
        return moment.utc(this.answers[id]?.elapsedTime * 1000).format('mm:ss');
      }
    }
  };
  private columnsByStatus = {
    inProgress: [
      this.columns.title,
      this.columns.answer,
      this.columns.validation
    ],
    complete: [
      this.columns.title,
      this.columns.duration,
      this.columns.answer,
      this.columns.validation
    ]
  };
  private answers: { [answerId: string]: IResponseAnswer } = {};
  private questions: IQuestion[] = [];
  private questionLabelTypes = ['passFail', 'singleSelect', 'tolerance', 'actualMeasurement'];
  private checkDetailReport = new checkDetailReport(this.injector);

  constructor(
    private checkResponseService: CheckResponseService,
    private questionService: QuestionService,
    private translate: TranslateService,
    private injector: Injector,
    private observationService: ObservationService
  ) {}

  ngOnInit() {
    this.getQuestions();
  }

  private async getQuestions() {
    [this.response] = await awaitHandler(this.checkResponseService.getResponseAsync(this.responseId));
    this.isResponseAvailable = includes(['inProgress', 'complete'], this.response.status);
    this.dataTableOptions.columns = this.columnsByStatus[this.response.status];

    if (this.isResponseAvailable) {
      this.answers = this.response?.answers || {};
      this.questions = filter(map(this.response?.questions, (questionId) => {
        return this.questionService.getQuestion(questionId);
      }));
      this.dataTableOptions.data = this.questions;
      this.defineCompletedQuestions();
      this.defineDuration();
    }
  }

  private defineCompletedQuestions() {
    let answeredCount = 0;

    each(this.questions, (question) => {
      if (this.answers[question.questionID]) {
        answeredCount++;
      }
    });

    this.questionCompletedPercent = round(answeredCount / this.questions.length * 100) || 0;
  }

  private getAnswerTitle(questionId: string) {
    const question = this.questionService.getQuestion(questionId);
    const currentAnswer = this.answers[questionId] || {} as IResponseAnswer;
    const questionOptions = this.questionService.getQuestionOptions(question.questionID);
    let answerTitle = '';

    if (question) {
      if (includes(this.questionLabelTypes, question.type)) {
        const statusMap = {
          negative: this.translate.instant('MGMT_DETAILS.Fail'),
          neutral: this.translate.instant('SHARED.Neutral'),
          positive: this.translate.instant('MGMT_DETAILS.Pass')
        };

        answerTitle = statusMap[currentAnswer.result];
      } else if (question.type === 'multiSelect') {
        each(currentAnswer.response, (optionId: string) => {
          const currentOption = questionOptions[optionId];
          if (currentOption) {
            answerTitle += `<li>${currentOption?.label}</li>`;
          }
        });
      } else if (question.type === 'textInput' && currentAnswer?.responseValue) {
        each(currentAnswer?.responseValue, (option: { optionId: string; value: string }) => {
          answerTitle += `<li>${option?.value}</li>`;
        });
      } else if (question.type === 'dropdownSelection') {
        const currentOption = find(questionOptions, { optionID: currentAnswer.response });

        if (currentOption) {
          answerTitle = currentOption?.label;
        }
      } else if (question.type === 'incrementEntry') {
        const value = currentAnswer?.responseValue?.[0]?.value;

        if (value) {
          answerTitle = value;
        }
      }
    }

    return answerTitle;
  }

  private getAnswerStatus(questionId: string) {
    const question = this.questionService.getQuestion(questionId);
    let status = this.getAnswerTitle(questionId) ? '' : 'question-status';

    if (includes(this.questionLabelTypes, question?.type)) {
      status = `question-status ${this.answers[questionId]?.result}`;
    }

    return status;
  }

  private getCellAnswerTitle(answer: IResponseAnswer) {
    const observationId = get(answer, 'observationID');
    let linkName = '';

    if (observationId) {
      linkName = this.observationService.getObservationById(observationId) ? 'MGMT_DETAILS.View_Corrective_Action' : 'MGMT_DETAILS.Corrective_Action_Pending';
    } else if (get(answer, 'flaggedIssue')) {
      linkName = 'MGMT_DETAILS.View_Issue';
    } else if (get(answer, 'result') && (answer.notes?.length || answer.images?.length)) {
      linkName = 'MGMT_DETAILS.View_Validation';
    }

    return linkName;
  }

  private defineDuration() {
    if (this.response.completionTime) {
      const startTime = moment(this.response.claimTime);
      const completionTime = moment(this.response.completionTime);
      this.duration = moment.utc(completionTime.diff(startTime) * 1000).format('mm:ss');
    }
  }

}
