import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Storage } from '@ionic/storage-angular';

import { environment } from '@env';

import * as compareVersions from 'compare-versions';
import { first, get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private readonly frequency: number = 1000 * 60 * 10; // 10 minutes
  private isStarted = false;
  private intervalListener: any;
  private isVersionChecked: boolean;
  private checkHandler: Promise<boolean>;

  constructor(
    private swUpdate: SwUpdate,
    private storage: Storage
  ) {
  }

  public startListening(): Promise<boolean> {
    if (!this.isStarted) {
      this.isStarted = true;

      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.subscribe(() => {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        });
      }

      this.intervalListener = setInterval(() => {
        this.checkVersion();
      }, this.frequency);

      return this.checkVersion();
    } else if (this.isVersionChecked) {
      return Promise.resolve(true);
    } else {
      return this.checkHandler;
    }
  }

  private async checkVersion(): Promise<boolean> {
    if (environment.production && get(navigator.serviceWorker, 'controller.state') === 'activated') {
      this.checkHandler = this.swUpdate.checkForUpdate()
        .then(() => this.onFinish())
        .catch(() => this.onFinish());

      await this.checkMajorVersion();
    } else {
      this.checkHandler = Promise.resolve(this.onFinish());
    }

    return this.checkHandler;
  }

  private onFinish(): boolean {
    this.checkHandler = null;
    this.isVersionChecked = true;

    return true;
  }

  private async checkMajorVersion() {
    const isVersionDifferent = await this.isVersionDifferent();

    if (isVersionDifferent) {
      await this.storage.clear();
    } else {
      this.storage.set('version', environment.appVersion);
    }
  }

  private async isVersionDifferent(): Promise<boolean> {
    const getVersion = (versionValue) => versionValue && <string>first(versionValue.match(/\d+(\.\d+)/));
    const currentVersion = getVersion(environment.appVersion);
    const lastCachedVersion = getVersion(await this.storage.get('version'));

    return lastCachedVersion && compareVersions(currentVersion, lastCachedVersion) > 0;
  }
}
