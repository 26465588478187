import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { environment } from '@env';
import { CommsService } from '@services/comms/comms.service';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { Title } from '@angular/platform-browser';
import { Events } from '@services/events/events.service';
import { IFiscalYearPreference } from '@modules/subscriber/settings/components/date-select/date-select.component';
import moment from 'moment';

import { awaitHandler } from '@utils/awaitHandler';
import { PermissionsService } from '@services/permissions/permissions.service';
import { combineLatest, Observable } from 'rxjs';
import { IAppState } from '@store/IAppState';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ILocation, UserService } from '@services/user/user.service';
import { each, every, find, get, has, toInteger } from 'lodash';

export enum PluginName {
  TemperatureSensor
}

export enum Module {
  ACCESS_TOKENS = 'access_tokens',
  ACTIVE_LEARNING = 'active_learning',
  ASSETS = 'assets',
  ASSET_ISSUES = 'asset_issues',
  ASSET_TRACKING = 'asset_tracking',
  ASSET_STATUS = 'asset_status',
  AWARENESS_MESSAGES = 'awareness_messages',
  BEHAVIOR = 'behavior',
  CERTIFICATIONS = 'certifications',
  CHECKS = 'checks',
  COMPLIMENT = 'compliment',
  CONDITION = 'condition',
  CONSENT = 'consent',
  CONTENT = 'content',
  CULTURE = 'culture',
  DISCLAIMERS = 'disclaimers',
  EXPLORE = 'explore',
  EXTERNAL_DATA_MANAGEMENT = 'external_data_management',
  FEEDBACK = 'feedback',
  INQUIRIES = 'inquiries',
  LDAP = 'ldap',
  LEADERBOARDS = 'leaderboards',
  LEARNING_TEAMS = 'learning_teams',
  LOCATIONS = 'locations',
  LOCATION_HIERARCHY = 'location_hierarchy',
  LOGIN_FOOTER = 'login_footer',
  LOYALTY = 'loyalty_switch',
  MESSAGING = 'messaging',
  MOBILE_REPORTS = 'mobile_reports',
  NOTIFICATIONS = 'notifications',
  NO_LOCATION = 'no_location',
  OBSERVATIONS = 'observations',
  OBSERVATION_MAINTAINX = 'maintainx',
  OBSERVATION_WORKORDERS = 'workorder',
  OFFLINE_SUPPORT = 'offline_support',
  OPPORTUNITIES = 'opportunities',
  PDCA = 'pdca',
  PPE = 'ppe',
  PROCESS_IMPROVEMENT = 'pi',
  PROPERTIES = 'properties',
  PI_GENERAL = 'pi_general',
  PI_WAITING = 'pi_waiting',
  QUALITY = 'quality',
  QUALITY_PRODUCTION = 'quality_production',
  QUALITY_RECEIVING = 'quality_receiving',
  QUALITY_RMA = 'quality_rma',
  REPORTING = 'reporting',
  ROLES = 'roles',
  SITE_AWARENESS = 'site_awareness',
  SSO = 'sso',
  SUPERVISOR_DASHBOARD = 'supervisor_dashboard',
  TOPICS = 'topics',
  WORKER_PROXIMITY = 'worker_proximity',
  WORKER_STATUS = 'worker_status',
  WORK_NOTES = 'work_notes',
  WORK_TEMP = 'work_temp',
  WRISTBANDS = 'wristbands',
  CALERA_CORE = 'calera_core',
  RELAYS = 'relays',
  CRON = 'cron',
  CRON_NOTIFICATIONS = 'cron_notifications',
  CRON_DEPLOYMENTS = 'cron_deployments',
  CRON_WEATHER = 'cron_weather',
  CRON_SCORES = 'cron_scores',
  CRON_SENTIMENT = 'cron_sentiment',
  CRON_EXPIRE = 'cron_expire'
}

export enum Feature {
  OBSERVATIONS = 'observations',
  BEHAVIOR = 'behavior',
  COMPLIMENT = 'compliment',

  CONDITION = 'condition',
  OBSERVATION_WORKORDERS = 'workorder',
  OBSERVATION_MAINTAINX = 'maintainx',

  PROCESS_IMPROVEMENT = 'pi',
  PI_GENERAL = 'pi_general',
  PI_WAITING = 'pi_waiting',

  QUALITY = 'quality',
  QUALITY_RECEIVING = 'quality_receiving',
  QUALITY_RMA = 'quality_rma',
  QUALITY_PRODUCTION = 'quality_production',

  ASSET_ISSUES = 'asset_issues',
  ASSET_TRACKING = 'asset_tracking',
  ASSET_STATUS = 'asset_status',

  WORK_NOTES = 'work_notes',
  WN_SHIFT_NOTES = 'shift_notes',
  WN_SHIFT_NOTE_CREATORS = 'shift_note_creators',
  WN_SHIFT_NOTE_VIEWERS = 'shift_note_viewers',
  WN_PERSONAL_NOTES = 'personal_notes',

  USER_MESSAGING = 'userMessage',

  MESSAGE_DOWNLOAD = 'message_enabled_users',

  ALL_LOCATION_ACCESS = 'all_location_access',

  ARCHIVE_OBSERVATIONS = 'archive_observations',

  OBSERVATION_ACTIVITY = 'activity_dashboard',

  CHANGE_OBSERVATION_TYPE = 'change_observation_type',
  MOBILE_REPORTS = 'mobile_reports',
  LEADERBOARDS = 'leaderboards',
  SUPERVISOR_DASHBOARD = 'supervisor_dashboard',
  SITE_AWARENESS = 'site_awareness',
  WORKER_STATUS = 'worker_status',
  WORKER_PROXIMITY = 'worker_proximity',
  CULTURE_DASHBOARD = 'culture_dashboard',
  ASSETS = 'assets',
  WRISTBANDS = 'wristbands',
  CONSENT = 'consent',
  CONTENT = 'content',
  LDAP = 'ldap',
  AWARENESS_MESSAGES = 'awareness_messages',
  LOYALTY = 'loyalty_switch',
  CERTIFICATIONS = 'certifications',
  ROLES = 'roles',
  PPE = 'ppe',
  ZONESELECTION = 'zone_selection',
  FORCE_ZONESELECTION = 'force_zone_selection',
  PPE_AWARENESS_DASHBOARD = 'ppe_dashboard',

  FIRST_PASSWORD_UPDATE = 'first_password_update',
  FIRST_AVATAR = 'first_mobile_avatar',
  FORCE_NEW_PASSWORD = 'force_new_password',
  ID_TAG_REGISTRATION = 'id_tag_registration',
  SECURITY_QUESTION_RECOVERY = 'sq_password_recovery',
  Password_Policy = 'policy',
  PDCA = 'pdca',
  LOGIN_FOOTER = 'login_footer',
  ACCESS_TOKENS = 'access_tokens',
  OFFLINE_SUPPORT = 'offline_support',
  DISCLAIMERS = 'disclaimers',
  PROPERTIES = 'properties',
  RELAYS = 'relays'
}

export enum SubscriberUsageType {
  Module,
  Plugin,
  Feature
}

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  public carouselVersion = '2.21.1';
  public HEARTBEAT_TIME = 60; // this value is in seconds
  public appCurrentVersion: string = null;
  public languageList = [
    {
      id: 'en',
      description: 'English'
    },
    {
      id: 'fr',
      description: 'Français'
    },
    {
      id: 'pt',
      description: 'Português'
    },
    /*
    {
      id: 'fr',
      description: 'French'
    },
    {
      id: 'de',
      description: 'German'
    },
    */
    {
      id: 'es',
      description: 'Español'
    },
    // {
    //   id: 'ru',
    //   description: 'Pусский'
    // }
  ];
  public availableLocations: ILocation[] = [];
  public languageDefaults = {
    languages: ['en'],
    defaultLanguage: 'en'
  };
  public subInfo: any = {
    subscriberID: null,
    subscriberName: '',
    locationID: null,
    locationName: '',
    objectURL: null,
    extObjectURL: null,
    gear: [],
    certifications: [],
    modules: {},
    features: {},
    plugins: {},
    units: {},
    languages: {},
    preferences: {}
  };
  public lastRefresh = 0;
  public locationSelected: number = null;
  public subscriberSelected: number = null;
  public usingLDAP = false;
  public LDAPServers: Array<any> = [];
  public Auth0Config: any = {};
  private unitDefaults = {
    measurement: 'imperial',
    time: '12h',
  };
  private preferenceDefaults = {
    randomSafetyMessage: true,
    safetyMessageHeader: this.translate.instant('SHARED.SAFETY_FIRST'),
    safetyMessageHeaderTranslations: [],
    qualityReceivingFields: {
      addButton: false,
      data: [{
        type: 'barcode',
        required: false,
        activateNext: false,
        name: this.translate.instant('SUBSCRIBER_SERVICE.PO_Number'),
        value: null
      }]
    },
    qualityProductionFields: {
      addButton: true,
      data: [{
        type: 'barcode',
        required: true,
        activateNext: false,
        name: this.translate.instant('SHARED.Job_Number'),
        value: null,
        canAdd: false,
      },
        {
          type: 'barcode',
          required: false,
          activateNext: false,
          name: this.translate.instant('SHARED.Part_Number'),
          value: null,
          canAdd: true
        }]
    },
    qualityRMAFields: {
      addButton: true,
      data: [{
        type: 'barcode',
        required: true,
        activateNext: false,
        name: this.translate.instant('SHARED.Job_Number'),
        value: null,
        canAdd: false,
      },
        {
          type: 'barcode',
          required: false,
          activateNext: false,
          name: this.translate.instant('SHARED.Part_Number'),
          value: null,
          canAdd: true
        }]
    },
    loyaltyWeights: {
      condition: {
        logCondition: 1,
        fixCondition: 1
      },
      behavior: {
        logCoaching: 1,
        addCoachingNote: 1
      },
      quality: {
        logReceiving: 1,
        logRMA: 1,
        logProduction: 1
      },
      process: {
        waiting: 1,
        logProcessIdea: 1,
        logFixIdea: 1,
        implementImprovement: 1
      },
      compliment: {
        logCompliment: 1,
        addComplimentNote: 1,
        receiveCompliment: 1
      }
    }
  };
  private requestedScopes = 'openid profile email';
  private authHandle: auth0.WebAuth = null;

  private readonly checkRelatedFeaturesHandler = {
    [Feature.WORK_NOTES]: () => this.checkShiftNotesForLocations() || this.checkPersonalNotesForLocations(),
    [Feature.WN_SHIFT_NOTES]: () => this.checkShiftNotesForLocations(),
    [Feature.WN_PERSONAL_NOTES]: () => this.checkPersonalNotesForLocations()
  };

  public locations$: Observable<ILocation[]> = this.store.select(
    (state) => state.AppStateReducer.locations?.data
  ).pipe(filter(val => !!val));

  public userLocations$: Observable<number[]> = this.store.select(
    (state) => state.AppStateReducer.userInfo?.locations
  ).pipe(filter(val => !!val));

  constructor(
    protected translate: TranslateService,
    private store: Store<{AppStateReducer: IAppState}>,
    private permissionsService: PermissionsService,
    private logger: NGXLogger,
    private comms: CommsService,
    private events: Events,
    private titleService: Title
  ) {
    combineLatest([this.locations$, this.userLocations$]).subscribe(([locations, userLocations]) => {
      this.availableLocations = UserService.getUserLocationsStatic(locations, userLocations, true, true);
    });
  }

  /**
   * Determine if the subscriber preferences and features allow for anonymous data
   */
  public usesAnonymous(): boolean {
    if (this.usesModule(Module.INQUIRIES) || this.usesModule(Module.OPPORTUNITIES)) {
      return true;
    } else {
      return false;
    }
  }

  public getUnits(type: string = 'measurement'): string {
    const u = get(this.subInfo.units, type, get(this.unitDefaults, type));
    return u;
  }

  public baseURL(): string {
    let ret = window.location.origin;
    if (environment.baseHref) {
      ret += environment.baseHref;
    }
    return ret;
  }

  public getLanguages(omitDefault: boolean = false): string[] {
    if (!omitDefault) {
      return this.subInfo.languages.languages;
    } else {
      const r = [];
      each(this.subInfo.languages.languages, (lang) => {
        if (lang !== this.subInfo.languages.defaultLanguage) {
          r.push(lang);
        }
      });
      return r;
    }
  }

  public getLanguage(): string {
    let l = this.subInfo.languages.defaultLanguage;
    if (!l) {
      l = 'en';
    }
    return l;
  }

  public getDefaultLanguage(): string {
    let l = this.subInfo.languages.defaultLanguage;
    if (!l) {
      l = 'en';
    }
    return l;
  }

  public getLanguageName(lang: string): string {
    const l: any = find(this.languageList, ['id', lang]);
    if (!l) {
      return 'Unknown';
    } else {
      return l.description;
    }
  }

  clear() {
    this.subInfo = {
      subscriberID: null,
      subscriberName: '',
      locationID: null,
      locationName: '',
      objectURL: null,
      extObjectURL: null,
      gear: [],
      certifications: [],
      modules: {},
      features: {},
      plugins: {},
      units: {},
      preferences: {}
    };
    this.locationSelected = null;
    this.subscriberSelected = null;
  }

  public subscriberID(): number | null {
    if (this.subInfo.subscriberID) {
      return this.subInfo.subscriberID;
    } else {
      return null;
    }
  }

  public locationID(): number {
    let num = 0;
    if (this.subInfo.locationID) {
      num = this.subInfo.locationID;
    }
    return num;
  }

  public locationName(): string {
    let name = 'UNKNOWN';
    if (this.subInfo.locationID && this.subInfo.locationName) {
      name = this.subInfo.locationName;
    }
    return name;
  }

  public getFeature(name: string, def: any = ''): any {
    return get(this.subInfo.features, name, def);
  }

  public getPreference(name: string): any {
    return get(this.subInfo.preferences, name, get(this.preferenceDefaults, name, ''));
  }

  public preference(name: string, value?: any) {
    return new Promise((resolve, reject) => {
      const v = get(this.subInfo.preferences, name, get(this.preferenceDefaults, name, ''));
      if (value === undefined) {
        resolve(v);
      } else {
        this.subInfo.preferences[name] = value;
        const msg = {
          cmd: 'updateSubscriberInfo',
          preferences: JSON.stringify(this.subInfo.preferences)
        };
        this.comms.sendMessage(msg, false, false)
          .then((res) => {
            resolve(value);
          })
          .catch((err) => {
            this.logger.log('error updating preferences');
            reject(this.translate.instant('SUBSCRIBER_SERVICE.Subscriber_Failed'));
          });
      }
    });
  }

  public consentForm(content?: string, translations?: any) {
    if (content) {
      // we are updating the form
    }

  }

  public getPluginSettings(name: PluginName): any {
    let settings: any = {};

    if (name === PluginName.TemperatureSensor) {
      settings = {
        steps: [
          {
            title: this.translate.instant('PLUGGIN.Minimal'),
            value: {
              max: 26.111
            },
            points: [
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points0')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points1')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points2')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points3')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points4')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.if_necessary')}
              ]
            ]
          },
          {
            title: this.translate.instant('PLUGGIN.Lower'),
            value: {
              min: 26.111,
              max: 32.778
            },
            points: [
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points0')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points1')}
              ],
              [
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points5')},
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points6')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points7')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points8')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points9')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points10')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points11')}
              ]
            ]
          },
          {
            title: this.translate.instant('PLUGGIN.Moderate'),
            value: {
              min: 32.778,
              max: 39.444
            },
            points: [
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points0')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points1')}
              ],
              [
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points5')},
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points6')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points7')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points8')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points9')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points12')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points10')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points11')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points13')}
              ]
            ]
          },
          {
            title: this.translate.instant('SHARED.High'),
            value: {
              min: 39.444,
              max: 46.111
            },
            points: [
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points0')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points1')}
              ],
              [
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points5')},
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points6')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points7')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points8')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points9')}
              ],
              [
                {isBold: true, text: this.translate.instant('SUBSCRIBER_SERVICE.points10')},
                {text: this.translate.instant('SUBSCRIBER_SERVICE.points11')}
              ]
            ]
          },
          {
            title: this.translate.instant('PLUGGIN.Extreme'),
            value: {
              min: 46.111
            }
          }
        ]
      };
    }

    return settings;
  }

  public usesModule(module: string | Module, checkRelatedFeatures?: boolean) {
    if (get(this.subInfo, 'modules')) {
      if (get(this.subInfo.modules, module, 0)) {
        let isModuleValid = true;

        // If related features need to be checked, perform additional validation based on the module.
        if (checkRelatedFeatures) {
          if (module === Module.QUALITY) {
            isModuleValid = this.usesFeature(Feature.QUALITY_PRODUCTION) || this.usesFeature(Feature.QUALITY_RECEIVING) || this.usesFeature(Feature.QUALITY_RMA);
          } else if (module === Module.PROCESS_IMPROVEMENT) {
            isModuleValid = this.usesFeature(Feature.PI_GENERAL);
          }
        }

        if (isModuleValid) {
          // If the module is still valid, check its dependencies because any module might have a parent module.
          isModuleValid = this.checkModuleDependencies(module);
        }

        return isModuleValid;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public checkShiftNotesForLocations() {
    return !!find(this.availableLocations, (loc) => {

      const permissions = [];

      if (loc.features?.shift_note_creators?.length) {
        permissions.push(...loc.features.shift_note_creators);
      }

      if (loc.features?.shift_note_viewers?.length) {
        permissions.push(...loc.features.shift_note_viewers);
      }

      return !!find(permissions, perm => loc.features.shift_notes && this.permissionsService.canView(perm));
    });
  }

  public checkPersonalNotesForLocations() {
    return !!find(this.availableLocations, (loc) => loc.features.personal_notes);
  }

  public usesFeature(feature: Feature | string, checkRelatedFeatures?: boolean) {
    if (get(this.subInfo, 'features')) {
      if (get(this.subInfo.features, feature, 0)) {
        if (checkRelatedFeatures) {
          const checkHandler = this.checkRelatedFeaturesHandler[feature];

          if (checkHandler) {
            return checkHandler();
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public usesPlugin(plugin) {
    if (get(this.subInfo, 'plugins')) {
      if (get(this.subInfo.plugins, plugin, 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public uses(types: SubscriberUsageType[], alias: Module | Feature | PluginName): boolean {
    return every(types, (type: SubscriberUsageType) => {
      if (type === SubscriberUsageType.Module) {
        return this.usesModule(<Module>alias);
      } else if (type === SubscriberUsageType.Feature) {
        return this.usesFeature(<Feature>alias);
      } else {
        return this.usesPlugin(<PluginName>alias);
      }
    });
  }

  public refresh(): Promise<boolean> {
    this.comms.subscriberID = this.subInfo.subscriberID = environment.data.subscriberID;
    const eData: any = {
      cmd: 'getSubscriberInfo',
      version: this.appCurrentVersion,
      subscriber: environment.data.subscriberID
    };
    if (!this.comms.token) {
      eData.cmd = 'discover';
    }

    return new Promise((resolve, reject) => {
      this.comms.sendMessage(eData, false, false)
        .then(async (data: any) => {
          if (eData.cmd === 'discover') {
            this.logger.log('discovered subscriber basic configuration');
          } else {
            this.logger.log('got subscriber info');
          }
          // we got a response from getSubscriberInfo - remember it
          if (typeof data.result.subscribers[0] === 'object') {
            this.subInfo = data.result.subscribers[0];
            if (this.subInfo.hasOwnProperty('heartbeat')) {
              this.HEARTBEAT_TIME = this.subInfo.heartbeat;
              if (this.HEARTBEAT_TIME > 10000) {
                // tslint:disable-next-line:max-line-length
                this.HEARTBEAT_TIME = toInteger(this.HEARTBEAT_TIME / 1000);   // in case the backend is confused about seconds vs ms
              }
            }
            if (!this.subInfo.hasOwnProperty('features')) {
              this.subInfo.features = {};
            }
            if (!this.subInfo.hasOwnProperty('plugins')) {
              this.subInfo.plugins = {};
            }
            if (!this.subInfo.hasOwnProperty('units')) {
              this.subInfo.units = {};
            }
            if (!this.subInfo.hasOwnProperty('languages')) {
              this.subInfo.languages = {};
            }
            if (!this.subInfo.hasOwnProperty('LDAPServers')) {
              this.subInfo.LDAPServers = [];
              this.LDAPServers = [];
            } else {
              if (this.subInfo.LDAPServers.length) {
                this.usingLDAP = true;
                const ret = [];
                each(this.subInfo.LDAPServers, (ref: any) => {
                  const item = {
                    id: ref.serverNum,
                    description: ref.host
                  };
                  ret.push(item);
                });
                this.LDAPServers = ret;
              }
            }
            if (this.usesModule(Module.SSO)) {
              // this customer has SSO enabled = let's grab those settings
              this.Auth0Config = {
                auth0Connection: this.subInfo.modules.auth0Connection,
                auth0Domain: this.subInfo.modules.auth0Domain,
                auth0ClientID: this.subInfo.modules.auth0ClientID
              };
              this.auth0Setup().then(() => {
                this.logger.debug('Auth0 Setup Complete');
                resolve(true);
              });
            } else {
              resolve(true);
            }
            this.events.publish('ccs:subscriptionUpdate', true);
          }
          // this.logger.log('subscriber config: ' + JSON.stringify(this.subInfo));
        });
    });
  }

  public async updateCustomTerms(): Promise<void> {
    // this will also do interpolation from BASE_TERMS, so we need to
    // process English first

    const eData = {
      cmd: 'getTerms'
    };

    const [data, error] = await awaitHandler(this.comms.sendMessage(eData, false, false));

    const updates = (data && data.reqStatus === 'OK') ? this.flattenTerms(data.result.terms) : {};

    // English
    this.translate.resetLang('en');

    let englishBase = {};
    let loaded;
    let error2;

    const brandData = this.brandInfo();

    [loaded, error2] = await awaitHandler(this.translate.getTranslation('en').toPromise());

    if (loaded) {
      // we got the raw English translations
      // override with any Brand settings
      if (has(brandData, 'terms')) {
        const r = brandData.terms;
        each(r, (values, term) => {
          if (has(loaded, 'BASE_TERMS') && has(loaded.BASE_TERMS, term) && has(values, 'en')) {
            loaded.BASE_TERMS[term] = values['en'];
          }
        });
      }
      // fold in any subscriber updates
      if (has(updates, 'en')) {
        this.translate.setTranslation('en', updates.en, true);
        loaded = this.translate.translations.en;
      }
      englishBase = has(loaded, 'BASE_TERMS') ? loaded.BASE_TERMS : {};
      // merge in the base terms
      loaded = this.interpolateTranslations(loaded, loaded, englishBase, englishBase);
      // replace the translations!
      this.translate.setTranslation('en', loaded, false);
    }
    each(this.getLanguages(), async lang => {
      if (lang === 'en') {
        return;
      }
      this.translate.resetLang(lang);
      [loaded, error2] = await awaitHandler(this.translate.getTranslation(lang).toPromise());
      if (has(brandData, 'terms')) {
        const r = brandData.terms;
        each(r, (values, term) => {
          if (has(loaded, 'BASE_TERMS') && has(loaded.BASE_TERMS, term) && has(values, lang)) {
            loaded.BASE_TERMS[term] = values[lang];
          }
        });
      }
      if (has(updates, lang)) {
        this.translate.setTranslation(lang, updates[lang], true);
        loaded = this.translate.translations[lang];
      }
      const thisBase = has(loaded, 'BASE_TERMS') ? loaded.BASE_TERMS : {};
      loaded = this.interpolateTranslations(loaded, loaded, thisBase, englishBase);
      this.translate.setTranslation(lang, loaded, false);
    });
    return;
  }

  public brandInfo(): any {
    const rootElement: HTMLElement | any = document.documentElement;
    const theBrand = this.branding();

    let data: any = {
      brand: 'corvex',
      logo: '/assets/brand/corvex/logo.svg',
      headerLogo: '/assets/brand/corvex/header_logo.svg',
      loaderImg: '/assets/brand/corvex/loader_initialize_solid.gif',
      title: 'Corvex Connected Worker'
    };

    if (theBrand === 'hop') {
      data = {
        brand: 'hop',
        logo: '/assets/brand/hop/HOP-main.svg',
        headerLogo: '/assets/brand/hop/header_logo.svg',
        loaderImg: '/assets/brand/hop/gathering-data.gif',
        title: 'HOPtimize',
        backgrounds: ['HOP-slide-1.jpg', 'HOP-slide-2.jpg', 'HOP-slide-3.jpg', 'HOP-slide-4.jpg'],
        vars: {
          '--ccs-toolbar-background': '#414141',
          '--ccs-menu-background': '#343434',
          '--ccs-menu-child-background': '#232323'
        },
        terms: {
          Observation: {
            en: 'Submission',
            es: 'Presentación',
            fr: 'Soumission',
            pt: 'Submissão'
          },
          Observations: {
            en: 'Submissions',
            es: 'Presentaciones',
            fr: 'Soumissions',
            pt: 'Submissões'
          }
        }
      };
    }
    if (theBrand === 'inteliforz') {
      data = {
        brand: 'inteliforz',
        logo: '/assets/brand/inteliforz/inteliforz-full.svg',
        headerLogo: '/assets/brand/inteliforz/inteliforz.svg',
        loaderImg: '/assets/brand/inteliforz/gathering-data.gif',
        backgrounds: ['slide-1.jpg', 'slide-2.jpg', 'slide-3.jpg'],
        title: 'Inteliforz Zone Manager',
        vars: {
          '--ccs-toolbar-background': '#414141',
          '--ccs-menu-background': '#343434',
          '--ccs-menu-child-background': '#232323'
        }
      };
    }
    if (rootElement && has(data, 'vars')) {
      each(data.vars, (value, name) => {
        rootElement.style.setProperty(name, value);
      });
    }

    if (has(data, 'title')) {
      this.titleService.setTitle(data.title);
    }

    return data;
  }

  public branding(): string {
    if (has(window, 'corvexBrand')) {
      return get(window, 'corvexBrand') as string;
    } else {
      return get(environment.data, 'brand', 'corvex');
    }
  }

  public async searchLDAP(ldapServer: number, username: string): Promise<any> {
    const eData: any = {
      cmd: 'searchLDAP',
      subscriberID: environment.data.subscriberID,
      ldapServer,
      username
    };

    const data = await this.comms.sendMessage(eData, false, false);
    if (data && data.reqStatus === 'OK') {
      // we got something back
      return data.result.matches;
    } else {
      return [];
    }
  }

  public auth0(): auth0.WebAuth {
    return this.authHandle;
  }

  public auth0Setup(): Promise<any> {
    if (!this.usesModule(Module.SSO)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      // there is an auth0 config; set it up
      this.authHandle = new auth0.WebAuth({
        clientID: this.Auth0Config.auth0ClientID,
        domain: this.Auth0Config.auth0Domain,
        responseType: 'token id_token',
        audience: 'https://backend.corvexconnected.com/api',
        redirectUri: this.baseURL() + '/pages/login/sso',
        scope: this.requestedScopes
      });
      resolve(this.authHandle);
    });
  }

  public isFiscalPeriodEnabled(): boolean {
    const fiscalYear: IFiscalYearPreference = this.subInfo.preferences.fiscalYear;

    if (fiscalYear) {
      return fiscalYear.day !== 1 || fiscalYear.month !== moment.months()[0];
    }
    return false;
  }

  private flattenTerms(updates: any): any {
    const ret = {};
    each(updates, (groups, language) => {
      ret[language] = {};
      each(groups, (terms, key) => {
        // key could be a module name or an actual
        // translation key
        each(terms, (value, theTerm) => {
          if (theTerm.indexOf('.') > -1) {
            // this is scoped
            const [group, term] = theTerm.split('.');
            if (!has(ret[language], group)) {
              ret[language][group] = {};
            }
            ret[language][group][term] = value;
          } else {
            if (!has(ret[language], key)) {
              ret[language][key] = {};
            }
            ret[language][key][theTerm] = value;
          }
        });
      });
    });
    return ret;
  }

  private interpolateTranslations(currentTranslations: any, masterLanguageFile: any, thisBase: any, defaultBase: any): any {
    // {
    const pattern = new RegExp('@{([^}]+)}', 'g');
    // {
    const itemPattern = new RegExp('@{([^}]+)}');
    Object.keys(currentTranslations).forEach((key) => {
      if (key === 'BASE_TERMS') {
        // do NOT process the BASE_TERMS key. Avoids recursion
        return;
      }
      if (currentTranslations[key] !== null && typeof currentTranslations[key] === 'object') {
        this.interpolateTranslations(currentTranslations[key], masterLanguageFile, thisBase, defaultBase);
        return;
      }
      if (typeof currentTranslations[key] === 'string') {
        const m = currentTranslations[key].match(pattern);
        if (m && m.length) {
          each(m, item => {
            const i = item.match(itemPattern);

            if (thisBase && thisBase[i[1]]) {
              // this term is in the base terms
              currentTranslations[key] = currentTranslations[key].replace(i[0], thisBase[i[1]]);
            } else if (defaultBase && defaultBase[i[1]]) {
              currentTranslations[key] = currentTranslations[key].replace(i[0], defaultBase[i[1]]);
            } else {
              this.logger.error(`reference to undefined base term ${i[1]}`);
            }
          });
        }
      }
    });
    return currentTranslations;
  }

  private checkModuleDependencies(module: string | Module): boolean {
    if (module === Module.ASSET_ISSUES) {
      return !!get(this.subInfo.modules, Module.ASSETS, 0);
    }

    return true;
  }
}
