import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';
import {
  assign,
  cloneDeep,
  find,
  get,
  identity,
  includes,
  isEmpty,
  isEqual,
  map,
  omit,
  pick,
  pickBy,
  values
} from 'lodash';

export class AbstractFieldsModel {
  protected formGroupComponent: QuestionFormGroupListComponent;
  protected formGroupComponentRef: ComponentRef<QuestionFormGroupListComponent>;
  protected formConfig: any;
  private originalOrderOptionsIds: string[] = [];

  constructor(protected questionFormService: QuestionFormService) {
  }

  public markFieldAsChanged(formId: string, fieldName: string, value: any): void {
    if (formId) {
      const formGroup = find(get(this.formGroupComponent, 'groups'), (group) => includes(formId, get(group, 'formConfig.prefix')));

      if (formGroup) {
        this.questionFormService.markFieldAsChanged(fieldName, value, formGroup.formData);
      }
    }
  }

  public defineOptions(): void {
    const options: any[] = values(this.questionFormService.questionPage.questionOptions);

    if (!isEmpty(options)) {
      this.formGroupComponent.groups = map(options, (option, index: number) => {
        const title = `${this.formGroupComponent.title} ${index + 1}`;
        return {title, formConfig: cloneDeep(this.formConfig), formData: option};
      });
    }
  }

  public getFormData(settingsFields: string[], settingsHandler?: (settingsItem) => any) {
    return map(this.formGroupComponent.getFormListData(), (formData, index: number) => {
      (<any>this.questionFormService).utils.encodeTranslations(formData, ['translation_label'], ['label']);
      let settings = pick(formData, settingsFields);

      assign(settings, {
        usesNote: settings.notes_validation ? 2 : 0,
        usesPhoto: settings.photo_validation ? 2 : 0,
        verificationRequired: settings.required_validation_field
      });

      if (settings.required_validation_field) {
        settings.usesNote = 1;
        settings.usesPhoto = 1;
      }

      settings = omit(settings, [
        'notes_validation',
        'photo_validation',
        'required_validation_field'
      ]);

      if (settingsHandler) {
        settings = settingsHandler(settings);
      }

      return {
        optionID: formData.optionID,
        label: formData.label || `${index + 1} Field`,
        settings: JSON.stringify(pickBy(settings, identity)),
        translations: formData.translations
      };
    });
  }

  public onFormGroupComponentCreate(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    this.formGroupComponent = component;
    this.formGroupComponentRef = componentRef;

    this.formGroupComponent.formConfig = this.formConfig;
    this.defineOptions();

    const onGroupChange = () => {
      const orderOptionsIds: string[] = map(component.groups, 'formData.optionID');
      const isGroupsOrderChanged = !isEqual(this.originalOrderOptionsIds, orderOptionsIds);
      this.questionFormService.markFieldAsChanged('groupOrder', isGroupsOrderChanged || '', {});
    };

    if (this.syncRequiredFields) {
      component.onFinish.subscribe(() => {
        setTimeout(() => {
          this.syncRequiredFields();
        });
      });
    }

    this.originalOrderOptionsIds = map(component.groups, 'formData.optionID');
    component.onReorder.subscribe(() => onGroupChange());
    component.onItemRemoval.subscribe(() => onGroupChange());
    component.onNewGroup.subscribe(() => onGroupChange());
  }

  public isValid(): boolean {
    return this.questionFormService.validFormGroups(this.formGroupComponent.groups);
  }

  protected syncRequiredFields?();

}
