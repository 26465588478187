import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuController } from '@ionic/angular';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  RoutesRecognized
} from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {
  AuthService,
  HeartbeatService,
  MessagesService,
  MessageType,
  Module,
  PermissionsService,
  PopoverService,
  SubscriberService,
  UserdataService
} from '@services';
import { IPopoverConfig } from '@shared/components';
import { Events } from '@services/events/events.service';
import { MenuComponent } from '@modules/layout/components/menu/menu.component';
import { environment } from '@env';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { awaitHandler } from '@utils/awaitHandler';
import { get } from 'lodash';

export interface IHeaderConfig {
  isHidden?: boolean;
}

export interface IRecognizedHeaderConfig {
  config: IHeaderConfig;
  url: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({opacity: 1, height: '0px'})),
      transition(':enter', [
        style({opacity: 0, height: '0px'}),
        animate('1s ease-in-out')
      ]),
      transition(':leave',
        animate('0.3s ease-in-out', style({opacity: 0, height: '0px'})))
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MenuComponent) menuComponent: MenuComponent;

  public appVersion = `Version ${environment.appVersion}`;
  public logoSrc = '/assets/images/logo.svg';
  public brand = '';
  public isUserInfoAvailable: boolean;
  public carousel = false;
  public config: IHeaderConfig = {};
  public userName: string;
  public subscriberName: string;
  public isLoggedIn: boolean = this.authService.isUserLogged();
  public messages = 0;
  public notifications = 0;
  public workNote = 0;
  public isNoPermissionsUser: boolean = this.userData.isNoPermissionsUser();
  public isSupervisor: boolean = this.permissionsService.canView('supervisor') && !this.isNoPermissionsUser;
  public isMenuAvailable = true;
  public usesMessaging = true;
  public usesNotifications = true;
  public usesWorkerNotes = true;

  private isCommunicationSubscriberActive: boolean;
  private subscriptionDestroy: Subject<boolean> = new Subject<boolean>();


  constructor(
    private menu: MenuController,
    private authService: AuthService,
    private userData: UserdataService,
    private subscriber: SubscriberService,
    private popoverService: PopoverService,
    private messageService: MessagesService,
    public router: Router,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private events: Events,
    private heartbeat: HeartbeatService,
  ) {
  }

  ngOnInit() {
    this.getNames();
    this.config = this.getConfigFromRoute(this.route.snapshot.root);
    this.config.isHidden = this.route.snapshot.root.queryParams.headerIsHidden || this.config.isHidden;
    if (this.userData.showCarousel) {
      this.carousel = true;
      this.userData.showCarousel = false;
    }

    this.authService.onAuthEvent
      .pipe(takeUntil(this.subscriptionDestroy)).subscribe((isLoggedIn: boolean) => {
      this.isNoPermissionsUser = this.userData.isNoPermissionsUser();
      this.isSupervisor = this.permissionsService.canView('supervisor') && !this.isNoPermissionsUser;
      this.isLoggedIn = isLoggedIn;
      this.getNames();
      if (this.userData.showCarousel) {
        this.carousel = true;
        this.userData.showCarousel = false;
      }
      this.checkMenuAvailability();

      if (!this.isLoggedIn) {
        this.isUserInfoAvailable = false;
      }
    });

    this.checkMessages();
    this.events.subscribe('ccs:syncUpdate', this.onSyncUpdate);
    this.events.subscribe('ccs:updateHeaderParams', this.updateParams);
    this.events.subscribe('ccs:refreshHeaderNotifications', this.refreshCounts);

    if (this.heartbeat.isRunning()) {
      this.isUserInfoAvailable = true;
    }
    const d = this.subscriber.brandInfo();
    this.brand = d.brand;
    this.logoSrc = d.headerLogo;

    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      takeUntil(this.subscriptionDestroy),
      map((event: RoutesRecognized) => {
        const config: IHeaderConfig = this.getConfigFromRoute(event.state.root);
        config.isHidden = event.state.root.queryParams.headerIsHidden || config.isHidden;

        return {
          config,
          url: event.url
        };
      })
    ).subscribe((headerConfig: IRecognizedHeaderConfig) => {
      this.config = headerConfig.config;
      if (!this.config.isHidden) {
        this.initCommunicationSubscribers();
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.subscriptionDestroy)
    ).subscribe(() => {
      setTimeout(() => {
        this.checkMenuAvailability();
      });
      this.checkMessages();
    });

    if (!this.config.isHidden) {
      this.initCommunicationSubscribers();
    }
  }

  ngAfterViewInit(): void {
    this.checkMenuAvailability();
  }

  ngOnDestroy() {
    this.destroySubscribers();
  }

  public async toggleMenu() {
    const menuId = 'sideMenu';
    const [isEnabled] = await awaitHandler(this.menu.isEnabled(menuId));

    if (!isEnabled) {
      const [menuElement] = await awaitHandler(this.menu.enable(true, menuId));

      if (!menuElement) {
        console.error('the menu is not available');
      }
    }

    this.menu.toggle(menuId);
  }

  public logout(): void {
    const params: IPopoverConfig = {
      title: this.translate.instant('LAYOUT.title'),
      description: this.translate.instant('LAYOUT.description'),
      onSave: () => this.authService.handleSignout()
    };
    this.popoverService.show(params);
  }

  public goToHomePage(): void {
    if (this.isUserInfoAvailable) {
      this.authService.navigateToHomePage();
    }
  }

  public showCarousel(): void {
    this.carousel = false;
    this.router.navigate(['/pages/dashboard/app-carousel']);
  }

  public navWorkNotes(): void {
    this.messageService.showUnreadNotes = true;
    this.router.navigate(['/pages/work-notes']);
  }

  public clickCloseCarousel(event): void {
    this.carousel = false;
  }

  public isMessageBlockAvailable(): boolean {
    return !this.isNoPermissionsUser && (this.isMessagesAvailable() || this.isNotificationsAvailable() || this.isWorkNotesAvailable());
  }

  public isMessagesAvailable(): boolean {
    return this.messages && this.usesMessaging;
  }

  public isNotificationsAvailable(): boolean {
    return this.notifications && this.usesNotifications;
  }

  public isWorkNotesAvailable(): boolean {
    return this.workNote && this.usesWorkerNotes;
  }


  private getNames(): void {
    this.userName = this.userData.shortName;
    this.subscriberName = this.subscriber.subInfo.subscriberName;
  }

  private initCommunicationSubscribers(): void {
    if (!this.isCommunicationSubscriberActive) {
      this.isCommunicationSubscriberActive = true;
      this.refreshCounts();

      this.messageService.unread(MessageType.MESSAGES).pipe(takeUntil(this.subscriptionDestroy)).subscribe((count: number) => {
        this.messages = count;
      });

      this.messageService.unread(MessageType.NOTIFICATIONS).pipe(takeUntil(this.subscriptionDestroy)).subscribe((count: number) => {
        this.notifications = count;
      });

      this.messageService.unread(MessageType.SHIFTNOTES).pipe(takeUntil(this.subscriptionDestroy)).subscribe((count: number) => {
        this.workNote = count;
      });
    }
  }

  private destroySubscribers(): void {
    this.events.unsubscribe('ccs:syncUpdate', this.onSyncUpdate);
    this.events.unsubscribe('ccs:updateHeaderParams', this.updateParams);
    this.events.unsubscribe('ccs:refreshHeaderNotifications', this.refreshCounts);

    this.subscriptionDestroy.next(true);
    this.subscriptionDestroy.unsubscribe();
  }

  private getConfigFromRoute(snapshot: ActivatedRouteSnapshot): IHeaderConfig {
    const config: IHeaderConfig = {};
    let firstChild: ActivatedRouteSnapshot | any = snapshot.firstChild;


    while (firstChild) {
      if (firstChild.data && firstChild.data.headerConfig) {
        Object.assign(config, firstChild.data.headerConfig);
      }
      firstChild = firstChild.firstChild || null;
    }

    return config;
  }

  private onSyncUpdate = () => {
    this.isUserInfoAvailable = true;
  };

  private updateParams = (routerStateSnapshot?: RouterStateSnapshot) => {
    this.config = this.getConfigFromRoute(get(routerStateSnapshot, '_root.value') || this.route.snapshot.root);
  };

  private refreshCounts = () => {
    this.messages = this.messageService.getCachedUnreadCount(MessageType.MESSAGES);
    this.notifications = this.messageService.getCachedUnreadCount(MessageType.NOTIFICATIONS);
    this.workNote = this.messageService.getCachedUnreadCount(MessageType.SHIFTNOTES);
  };

  private checkMenuAvailability(): void {
    this.isMenuAvailable = this.menuComponent && this.menuComponent.isAvailable();
  }

  private checkMessages(): void {
    this.usesMessaging = this.subscriber.usesModule(Module.MESSAGING);
    this.usesNotifications = this.subscriber.usesModule(Module.NOTIFICATIONS);
    this.usesWorkerNotes = this.subscriber.usesModule(Module.WORK_NOTES);
  }
}
