import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { BaseService } from './../abstract-base-service/abstract-base-service.service';
import { Asset, AssetFilterParameters } from './asset.interfaces';

import { TranslateService } from '@ngx-translate/core';
import { FoldersDataService } from '@services/folders/folders-data.service';
import { cloneDeep, each, filter, find, get, includes, isArray, isUndefined, keyBy, some, sortBy, union } from 'lodash';
import { LoadingService } from '@services/loading/loading.service';
import { awaitHandler } from '@utils/awaitHandler';
import { DataTableOptions } from '@services/table/table.service';

export enum AssetState {
  Up = 2,
  Strained = 1,
  Down = 0,
  NoState = -1
}

@Injectable({
  providedIn: 'root'
})
export class AssetsService extends BaseService {

  public filterObject: any = {};

  asset: {
    data: Asset[];
    lastRequest: number | null;
  } = {
    data: [],
    lastRequest: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private events: Events,
    private translate: TranslateService,
    private folderService: FoldersDataService,
    private loadingService: LoadingService
  ) {
    super(commsService);
  }

  public refresh(updated: number = 0) {
    if (updated && updated < this.asset.lastRequest) {
      this.logger.log(`local assets cache already up to date: ${updated}, ${this.asset.lastRequest}`);
      return Promise.resolve(this.asset.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getAssets',
          includeDisabled: 1,
          lastRequest: this.asset.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
          }
          resolve(sortBy(this.asset.data, 'assetID'));
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public getAssetEvents(assetId: number): Promise<Asset> {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.commsService.sendMessage({
        cmd: 'getAssets',
        includeDisabled: 1,
        includeEvents: 1,
        assets: [assetId],
        sendTime: when
      }, false, false).then(data => {
        resolve(data?.result?.assets?.[0] || {});
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public updateCache(data) {
    this.asset.lastRequest = data.result.timestamp;
    this.asset.data = data.result.assets;
    this.events.publish('ccs:assetsUpdate');
  }

  public clearCache() {
    this.asset.lastRequest = null;
    this.asset.data = null;
  }

  public handleAddAsset(fData) {
    fData.cmd = 'addAsset';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateAsset(fData) {
    fData.cmd = 'updateAssets';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteAsset(fData) {
    fData.assets = JSON.stringify(fData);
    fData.cmd = 'deleteAssets';
    return this.handleRequest(fData);
  }

  public removeAssets(assetList: string[]): Promise<any> {
    const cmd = {
      cmd: 'deleteAssets',
      assets: JSON.stringify(assetList)
    };

    return this.handleRequest(cmd);
  }


  public fullPath(asset) {
    let ret = this.folderPath(asset);
    if (ret !== '') {
      ret += '/';
    }
    ret += asset.name;
    return ret;
  }

  public folderPath(asset) {
    let ret = '';
    if (asset.folderID) {
      const path = this.folderService.getFolderPath(asset.folderID);
      each(path, f => {
        ret = '/' + f + ret;
      });
    }
    return ret;
  }

  public getEnabledAssets(): Asset[] {
    return filter(this.asset.data, { disabledAt: 0 });
  }

  public getActiveAssets(): Asset[] {
    return filter(this.asset.data, { disabledAt: 0, active: 1 });
  }

  public getAssetById(id: number): Asset[] {
    return filter(this.asset.data, ['assetID', +id]);
  }

  public getAssetsByType(assetTypeIDs?: any) {
    if (assetTypeIDs && isArray(assetTypeIDs)) {
      // find all of these types and return them
      let l = [];
      each(assetTypeIDs, id => {
        const list = filter(this.asset.data, {checkType: +id});
        l = union(l, list);
      });
      // now we have an ARRAY of matching assets; convert it to an object
      return keyBy(l, 'assetID');
    } else {
      return this.asset.data;
    }
  }

  public getAssetsByProperty(propertyID: number) {
    if (propertyID) {
      // find all of these types and return them
      const list = filter(this.asset.data, (item: any) => {
        if (item?.properties) {
          return some(item.properties, { propertyID } );
        }
        return false;
      });
      // now we have an ARRAY of matching assets; convert it to an object
      return keyBy(list, 'assetID');
    } else {
      return this.asset.data;
    }
  }

  public getAssetLocation(assetID: number): number {
    return this.getAssetById(assetID)?.[0]?.location || null;
  }

  public getAssetsByLocations(locationIds: number[] = []) {
    const assets = this.getActiveAssets();

    if (locationIds.length) {
      return filter(assets, (assetItem) => includes(locationIds, assetItem.location));
    } else {
      return assets;
    }
  }

  public getAssetsByZoneId(zoneId) {
    const assets = this.getActiveAssets();
    return filter(assets, { zone: zoneId });
  }

  public getAssetsByName(name: string) {
    const assets = this.getActiveAssets();
    return filter(assets, { name });
  }

  public getAssetsByFolder(folderID: number) {
    const assets = this.getActiveAssets();
    return filter(assets, { folderID });
  }

  public assetState(health: number): string {
    const states = [
      'PROPERTY.State_Down',
      'PROPERTY.State_Strained',
      'PROPERTY.State_Up'
    ];

    if (health >= 0 && health <=2) {
      return this.translate.instant(states[health]);
    } else {
      return this.translate.instant('SHARED.Unknown');
    }
  }


  public getLocalAssets(activeOnly: boolean = true): Asset[] {
    if (activeOnly) {
      return cloneDeep(this.getActiveAssets());
    } else {
      return cloneDeep(this.getEnabledAssets());
    }
  }

  public isAssetNoState(asset: Asset): boolean {
    return asset.monitorHealth === 0;
  }

  public async getFilters(filterID?: number): Promise<any> {
    const params: any = {cmd: 'getFilters', types: [ 'assetDashboard' ]};
    if (filterID) {
      params.filters = [ filterID ];
    }
    await this.loadingService.enable(null, null);

    const [ response, err] = await awaitHandler (this.commsService.sendMessage(params, false, false));
    this.loadingService.disable();
    if (err) {
      return;
    }
    if (response && response?.reqStatus === 'OK') {
      const filters = get(response, 'result.filters', []);
      if (!filters) {
        return;
      }
      if (filterID) {
        const ref = find(filters, { filterID });
        if (ref) {
          return ref;
        } else {
          return;
        }
      } else {
        return filters;
      }
    }
  }

  public async createFilter(selectors: AssetFilterParameters, theTable?: DataTables.Api, tableOptions?: DataTableOptions): Promise<number> {
    const theColumns = [];
    const sortBy = [];

    if (!isUndefined(theTable) && !isUndefined(tableOptions)) {
      const visibility = <unknown>theTable.columns().visible() as DataTables.Api;
      each(visibility.toArray(), (state: boolean, column: number) => {
        if (!state) {
          return;
        }
        const colName = this.getColumnName(tableOptions, column);
        if (colName) {
          theColumns.push(colName);
        }
      });

      const theOrder = theTable.order();
      if (theOrder) {
        // there is some ordering; grab the column names
        each(theOrder, item => {
          const colName = this.getColumnName(tableOptions, +item[0]);
          if (colName) {
            sortBy.push({ id: colName, dir: item[1] });
          }
        });
      }
    }

    const req = {
      cmd: 'addFilter',
      type: 'assetDashboard',
      sortBy,
      columns: theColumns,
      selectors
    };

    // use the new filterID in the URL

    const [res, err] = await awaitHandler(this.commsService.sendMessage(req));
    if (err || res.reqStatus !== 'OK') {
      // creating the filter failed... what do we do?
      return;
    } else {
      return +res.result.filterID;
    }
  }

  private getColumnName(tableOptions: DataTableOptions, col: number) {
    const ref = tableOptions.columns[col];
    if (ref) {
      return ref?.name ?? ref.data;
    } else {
      return null;
    }
  }
}
