import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { CheckResponseService } from '@services';
import { DeepLinkGuard } from '@services/deep-link/deep-link.service';

import { Observable } from 'rxjs';
import { get, split } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CheckDetailLinkGuard implements CanActivate, DeepLinkGuard {

  constructor(
    private checkResponseService: CheckResponseService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id: string = next.paramMap.get('id');
    return this.navigateToResponsePage(id);
  }

  public async navigateToResponsePage(responseId: string) {
    await this.checkResponseService.getResponseAsync(responseId);
    return this.router.navigate([`/pages/dashboard/check-detail/${responseId}`, { backToMain: true }]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public parseUrlId(url: string): string {
    return get(split(url, '/pages/dashboard/check-detail/'), '[1]');
  }

  private navigateToMainPage(): boolean {
    this.router.navigate(['pages/dashboard/main']);
    return false;
  }

}
