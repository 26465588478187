import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SubscriberService } from '@services';
import { each, find, isArray } from 'lodash';


@Component({
  selector: 'app-form-translation-field',
  templateUrl: './form-translation-field.component.html',
  styleUrls: ['./form-translation-field.component.scss'],
})
export class FormTranslationFieldComponent {

  @Input() fields: { language: string; value: string }[] = [];
  @Input() title = 'SHARED.Translation';
  @Input() name: string;

  @Output() onChange: EventEmitter<void> = new EventEmitter();

  public languages: any[] = this.subscriberService.getLanguages(true);
  public isActive: boolean;
  public translationMap: { [key: string]: string } = {};

  constructor(private subscriberService: SubscriberService) {
  }

  ngOnInit() {
    if (!isArray(this.fields)) {
      this.fields = [];
    }

    each(this.languages, (language) => {
      this.translationMap[language] = this.subscriberService.getLanguageName(language);

      if (!find(this.fields, {language})) {
        this.fields.push({language, value: ''});
      }
    });
  }

  public emitInputChange() {
    this.onChange.emit();
  }

}
