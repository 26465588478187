import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';
import { awaitHandler } from '@utils/awaitHandler';
import { Location } from '@angular/common';
import { CollectionItemType, ICollectionItemData } from '@services/collections/collections.service';
import { CommsService } from '@services/comms/comms.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { LoadingService } from '@services/loading/loading.service';
import { Events } from '@services/events/events.service';
import { TranslateService } from '@ngx-translate/core';

import * as Mark from 'mark.js';
import { each, filter, find, forEach, has, isInteger } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {

  public feedbacks = {
    lastRequest: null,
    data: <any>{}
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private userDataService: UserdataService,
    private loaderService: LoadingService,
    private location: Location,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  clearCache(): void {
    this.feedbacks = {
      lastRequest: null,
      data: {}
    };
  }

  public refresh() {
    const when = Date.now();

    return this.commsService.sendMessage({
      cmd: 'getFeedbackRequests',
      includeReplies: 1,
      includeHidden: 1,
      includeHistory: 1,
      sendTime: when
    }, false, false).then((data) => {
      const result = {};

      if (data && data.reqStatus === 'OK') {
        this.feedbacks.data = data.result.requests;
        this.events.publish('ccs:feedbackUpdate', true);
      }
      return result;
    });
  }

  public getFeedbackRequest(reqID: number) {
    if (has(this.feedbacks.data, reqID)) {
      return this.feedbacks.data[reqID];
    } else {
      return;
    }
  }

  public getFeedbackReply(reqID: number, replyID: number) {
    const request = this.getFeedbackRequest(reqID);
    let ret;
    if (request) {
      const replies = request.replies;
      ret = find(request.replies, {replyID});
    }
    return ret;
  }

  public addFeedbackRequest(parameters: any, showLoading: boolean = true): any {
    const requestParameters = {
      cmd: 'addFeedbackRequest',
      creator: this.userDataService.userID,
      ...parameters
    };

    return this.commsService.sendMessage(requestParameters, false, showLoading);
  }

  public async toggleState(id: number, isAddressed: boolean = true, disableBackNavigation?: boolean) {
    const state = isAddressed ? 'addressed' : 'published';
    await this.loaderService.enable();
    const [response] = await awaitHandler(this.commsService.sendMessage({
      cmd: 'updateFeedbackRequest',
      requestID: id,
      state
    }, false, false));
    this.loaderService.disable();

    if (response.reqStatus === 'OK' && !disableBackNavigation) {
      this.location.back();
    }
  }

  public async markAsArchived(fId) {
    await this.loaderService.enable();
    const [response] = await awaitHandler(this.commsService.sendMessage({
      cmd: 'updateFeedbackRequest',
      requestID: fId,
      state: 'archived'
    }, false, false));
    this.loaderService.disable();

    if (response.reqStatus === 'OK') {
      this.location.back();
    }
  }

  public getRequestCollectionData(id: number | number[]): ICollectionItemData[] {
    if (isInteger(id)) {
      id = [id as number];
    }

    const ret: ICollectionItemData[] = [];

    each(id as number[], theItem => {
      const ref = this.getFeedbackRequest(theItem);
      if (ref) {
        ret.push({
          source: CollectionItemType.Feedback,
          item: `${theItem}`,
          type: this.translate.instant('SHARED.Feedback'),
          state: ref.state,
          zone: 0,
          locationID: 0,
          notes: ref.question,
          groupID: 0,
          addedAt: ref.created,
          addedBy: ref.creator,
          lastUpdate: ref.lastUpdate,
          created: ref.created,
          tagIDs: ref.tagIDs,
          attachments: [],
          id: CollectionItemType.Feedback + ':' + theItem,
        });
      }
    });
    return ret;
  }

  public getReplyCollectionData(id: number | number[]): ICollectionItemData[] {
    if (isInteger(id)) {
      id = [id as number];
    }

    const ret: ICollectionItemData[] = [];

    each(id as number[], theItem => {
      const ref = this.getFeedbackReply(null, theItem);
      if (ref) {
        ret.push({
          source: CollectionItemType.Reply,
          type: this.translate.instant('SHARED.Feedback_Reply'),
          state: ref.state,
          zone: ref.zoneID,
          item: `${theItem}`,
          locationID: ref.locationID,
          notes: ref.question,
          groupID: ref.responderGroupID,
          addedAt: ref.respondedAt,
          created: ref.respondedAt,
          addedBy: ref.responder,
          lastUpdate: ref.lastUpdate,
          tagIDs: ref.tagIDs,
          attachments: ref.attachments,
          id: CollectionItemType.Reply + ':' + theItem,
        });
      }
    });
    return ret;
  }

  public addFeedbackReply(parameters) {
    const requestParameters = {
      cmd: 'addFeedbackReply',
      userID: this.userDataService.userID,
      ...parameters
    };

    return this.commsService.sendMessage(requestParameters);
  }

  public async handleTagRequests(requests: number[], tagIDs: number[]): Promise<void> {
    return this.updateFeedback({requests, tagIDs});
  }

  public updateFeedbackReplyState(rId, rState) {
    this.commsService.sendMessage({
      cmd: 'updateFeedbackReply',
      replyID: rId,
      state: rState
    }, false, false).then((res) => {
      if (res.reqStatus === 'OK' && res.result) {

      }
    });
  }

  public async updateFeedback(fData) {
    fData.cmd = 'updateFeedbackRequest';
    return this.handleRequest(fData);
  }

  public getFeedbackById(fid): { [key: string]: any } {
    const when = Date.now();
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage({
        cmd: 'getFeedbackRequests',
        requests: [fid],
        includeReplies: 1,
        sendTime: when
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          resolve(data.result.requests[fid]);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getFeedbackByParentId(id: number, type: string) {
    return filter(this.feedbacks.data, (item) => {
      if (item.parentType === type && item.parentID === id.toString()) {
        return true;
      }
    });
  }

  public getLocalFeedbackById(id) {
    return find(this.feedbacks.data, {requestID: +id});
  }

  public highlightSearchResult(selector: string, value: string): void {
    setTimeout(() => {
      const searchString: string = value;
      forEach(document.querySelectorAll(`${selector}`), (el) => {
        const instance = new Mark(el);
        if (instance) {
          instance.unmark();
          if (searchString) {
            instance.mark(searchString, {element: 'span', className: 'highlight'});
          }
        }
      });
    });
  }

}
