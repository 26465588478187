/* eslint-disable @typescript-eslint/quotes */
import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import {
  HistoryItemRelatedType,
  IObservationHistoryItem,
  ObservationDetailService
} from '@services/observationDetail/observation-detail.service';
import { MessagePopupComponent } from './../message-popup/message-popup.component';
import { PdcaService } from '@services/pdca/pdca.service';
import { AssetsService, ObservationService, PropertyService, SubscriberService, UserService } from '@services';

import { TranslateService } from '@ngx-translate/core';
import { each, find, isArray, join, isObject, filter } from 'lodash';
import { Observation, ObservationTypes } from '@services/observations/observation.interfaces';
import { CustomFieldsService } from '@modules/customFields/services';
import { CustomFieldType } from '@modules/customFields/custom-fields.interfaces';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PropertyCategory } from '@services/property/property-model.interfaces';

@Component({
  selector: 'app-observation-history',
  templateUrl: './observation-history.component.html',
  styleUrls: ['./observation-history.component.scss'],
})
export class ObservationHistoryComponent {
  @Input() title = 'SHARED.History';
  @Input() set observationId(id: number) {
    if (id) {
      this.init(id);
    }
  }

  public history: IObservationHistoryItem[] = [];
  private theObservation: Observation;
  private theFields: FormlyFieldConfig[];

  constructor(
    private popover: PopoverController,
    private router: Router,
    private pdcaService: PdcaService,
    private userService: UserService,
    private translateService: TranslateService,
    private observations: ObservationService,
    private observationDetailService: ObservationDetailService,
    private subscriber: SubscriberService,
    private customFields: CustomFieldsService,
    private propertyService: PropertyService,
    private assetsService: AssetsService
  ) {
  }

  public init(id: number): void {
    this.theFields = null;
    this.theObservation = this.observations.getObservationById(id) as Observation;
    const theType = this.observations.getProperty(this.theObservation, 'type');
    if (theType) {
      if (theType === ObservationTypes.Quality) {
        // this one may have custom fields
        const obsSubtype = this.observations.getProperty(this.theObservation, 'subtype');

        const subtypeMap = {
          production: 'qualityProductionFields',
          receiving: 'qualityReceivingFields',
          rma: 'qualityRMAFields'
        };

        const fieldType = subtypeMap[obsSubtype] ?? 'unknown';

        // get form settings
        const res = this.subscriber.getPreference(fieldType);
        if (res) {
          this.theFields = this.customFields.buildFields(res.data);
        }
      }
    }

    const history = this.observationDetailService.getHistory(id);
    this.history = this.setRelatedData(history);
  }

  public setRelatedData(history: IObservationHistoryItem[]): IObservationHistoryItem[] {
    const fieldInfo = {
      zoneID: {
        label: this.translateService.instant('SHARED.Zone'),
        value: (data) => {
          const location = this.userService.getLocationByID(this.theObservation.locationID);
          const oldValue = this.userService.findAnyZone(location, data?.old ?? 0);
          const newValue = this.userService.findAnyZone(location, data?.new ?? 0);

          return this.translateService.instant('SHARED.Changed', { value1: oldValue.name, value2: newValue.name });
        }
      },
      ownerID: {
        label: this.translateService.instant('SHARED.Owner'),
        value: (data) => {
          const oldOwner = data?.old ? +data.old : 0;
          const newOwner = data?.new ? + data.new : 0;

          const oldValue = !oldOwner ? this.translateService.instant('SHARED.Unassigned') : this.userService.getFullname(oldOwner);
          const newValue = !newOwner ? this.translateService.instant('SHARED.Unassigned') : this.userService.getFullname(newOwner);

          return this.translateService.instant('SHARED.Changed', { value1: oldValue, value2: newValue });
        }
      },
      custom: {
        label: (data) => {
          // determine the label for this custom field
          if (data?.uuid) {
            const field = this.findField(data.uuid);
            if (field) {
              return field?.props?.label ? this.translateService.instant('SHARED.Field_Name_Value', { value: field.props.label }) : this.translateService.instant('SHARED.Unknown_Field');
            }
          }
          return this.translateService.instant('SHARED.Unknown_Field');
        },
        value: (data) => {
          const field = this.findField(data.uuid);
          const fieldType = field.props.attributes.originalType as CustomFieldType;

          let ret = '';

          let oldValue = this.customFields.parseFieldValue(data?.old ?? '', fieldType, '', field);
          let newValue = this.customFields.parseFieldValue(data?.new ?? '', fieldType, '', field);
          if (isArray(oldValue)) {
            oldValue = join(oldValue, "', '");
          }
          if (isArray(newValue)) {
            newValue = join(newValue, "', '");
          }

          if (newValue !== '' && oldValue === '') {
            ret = this.translateService.instant('SHARED.Added', { value: newValue });
          } else if (oldValue !== '' && newValue === '') {
            ret = this.translateService.instant('SHARED.Removed', { value: oldValue });
          } else {
            ret = this.translateService.instant('SHARED.Changed', { value1: oldValue, value2: newValue });
          }
          return ret;
        }
      }
    };

    each(history, (historyItem) => {
      if (this.isPdca(historyItem)) {
        const pdca = this.pdcaService.getById(+historyItem.relatedItem);
        if (pdca) {
          historyItem.linkTitle = this.userService.translateItem(pdca, 'title');
          historyItem.linkRoute = '/pages/dashboard/pdca-list/pdca-detail/' + historyItem.relatedItem;
        } else {
          historyItem.linkTitle = `(${this.translateService.instant('SHARED.Deleted')})`;
        }
      }
      if (historyItem.activity === 'Updated' && historyItem.relatedData?.field) {
        // this was a field change.
        const ref = historyItem.relatedData;
        if (fieldInfo[ref.field]) {
          const fRef = fieldInfo[ref.field];
          if (fRef?.label) {
            if (typeof(fRef.label) === 'function') {
              ref.label = fRef.label(ref);
            } else {
              ref.label = this.translateService.instant(fRef.label);
            }
          } else {
            ref.label = '';
          }
          if (fRef?.value) {
            if (typeof(fRef.value) === 'function') {
              ref.value = fRef.value(ref);
            } else {
              ref.value = this.translateService.instant('SHARED.Unknown');
            }
          } else {
            ref.value = '';
          }
        }
      } else if (historyItem.activity === 'Event' && historyItem.relatedType === HistoryItemRelatedType.property && historyItem.relatedData) {
        const property = this.propertyService.getPropertyById(+historyItem.relatedItem);
        const category = historyItem?.relatedData?.category || property?.category;

        if (isObject(historyItem.relatedData.value)) {
          historyItem.relatedData.value = this.propertyService.getManualEntryPropertyValue(historyItem.relatedData.value);
        } else if (category === PropertyCategory.InService || !category) {
          historyItem.relatedData.value = this.assetsService.assetState(+historyItem.relatedData.value);
        }

        historyItem.relatedData.label = this.translateService.instant('PROPERTY.Manual_Entry');
        historyItem.linkTitle = `${this.translateService.instant('SHARED.Property')}: ${property?.title || historyItem.relatedItem}`;

        if (property?.propertyID) {
          historyItem.linkRoute = `/pages/management/properties/property`;
          historyItem.linkRouteParameters = { messageID: historyItem.relatedItem };
        }
      }
    });

    return history;
  }

  public sendMessageTo(historyItem: IObservationHistoryItem) {
    this.popover.create(<any>{
      component: MessagePopupComponent,
      animated: false,
      componentProps: {
        userObject: historyItem,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public isPdca(historyItem: IObservationHistoryItem): boolean {
    return historyItem.relatedType === HistoryItemRelatedType.pdca;
  }

  public navigateToLink(historyItem: IObservationHistoryItem): void {
    if (historyItem.linkRoute) {
      this.router.navigate(filter([historyItem.linkRoute, historyItem.linkRouteParameters]));
    }
  }

  private findField(uuid: string): FormlyFieldConfig {
    if (this.theFields) {
      return find(this.theFields, { key: uuid });
    }
  }
}
