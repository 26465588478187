import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { each } from 'lodash';

// const dataSheets = {
//   ['Sheet 1']: [
//     ['firstName*', 'lastName*', 'desired-UserName*', 'password*', 'phone', 'email', 'certification', 'roles', 'permissions*', 'supervisor', 'primaryTeam*', 'teams', 'license*', 'locations'],
//     ['john', 'doe', 'jdog', 'tempPass', 123445, 'a@b.com', 'Site Permit', 'role 1', 'Helper', 'John', 'primaryTeam', 'Welding, Plumbing', 'Dedicated', 'Site A, Site B']
//   ],
//   ['Sheet 2']: [
//     ['firstName*', 'lastName*', 'desired-UserName*', 'password*', 'phone', 'email', 'certification', 'roles', 'permissions*', 'supervisor', 'primaryTeam*', 'teams', 'license*', 'locations'],
//     ['john', 'doe', 'jdog', 'tempPass', 123445, 'a@b.com', 'Site Permit', 'role 1', 'Helper', 'John', 'primaryTeam', 'Welding, Plumbing', 'Dedicated', 'Site A, Site B']
//   ]
// };
// this.exportExcelService.export(dataSheets, 'Corvex Report');

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  public export(dataSheets: { [tabName: string]: (string | number)[][] }, fileName = 'Corvex Export') {
    const workBook = XLSX.utils.book_new();

    each(dataSheets, (dataSheet, tabName) => {
      const workSheet = XLSX.utils.aoa_to_sheet(dataSheet);
      XLSX.utils.book_append_sheet(workBook, workSheet, tabName);
    });

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  }

}
