import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { each, filter, find, includes, indexOf, map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  public shifts = {
    lastRequest: 0,
    data: []
  };

  constructor(private comms: CommsService, private events: Events) {
  }

  public get(uuid: string) {
    return find(this.shifts.data, ['uuid', uuid]);
  }

  public getByLocationIds(ids: number[] = []): any[] {
    return ids.length ? filter(this.shifts.data, (shift: any) => includes(ids, shift.locationID)) : this.shifts.data;
  }

  public name(uuid: string) {
    const s = this.get(uuid);
    if (s) {
      return s.name;
    } else {
      return 'Unassigned';
    }
  }

  public uuid(name: string, locationID?: number): string | null {
    const item = find(this.shifts.data, (o) => {
      if (locationID && o.locationID !== locationID) {
        return false;
      }
      if (o.name === name) {
        return true;
      }
      return false;
    });
    if (item) {
      return item.uuid;
    } else {
      return;
    }
  }

  /**
   *
   * @param filters array of functions that are called with the user recored as a parameter.  The function returns true if the item should be filtered out of the user list.
   */
  public filterShiftList(filters: any) {
    const r = [];
    each(this.shifts.data, (ref) => {
      let matched = true;
      if (Array.isArray(filters)) {
        each(filters, (test) => {
          if (test(ref)) {
            matched = false;
          }
        });
      }
      if (matched) {
        r.push(ref);
      }
    });
    return r;
  }

  public refresh(locations: number[] = []) {
    return new Promise((resolve, reject) => {
      const when: number = Date.now();
      this.comms.sendMessage({
        cmd: 'getShifts',
        sendTime: when,
        includeDisabled: 1
      }, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
          }
          if (locations.length) {
            const r = [];
            each(this.shifts.data, (ref) => {
              if (indexOf(locations, ref.locationID) > -1) {
                r.push(ref);
              }
            });
            return r;
          } else {
            resolve(this.shifts.data);
          }
        }).catch((err) => reject(err));
    });
  }

  public clearCache() {
    this.shifts.lastRequest = null;
    this.shifts.data = null;
  }

  public updateCache(data) {
    this.shifts.lastRequest = data.result.timestamp;
    this.shifts.data = data.result.shifts;
    this.events.publish('ccs:shiftsUpdate');
  }

  public getSelectMenuObjectsByLocations(locations) {
    const shiftsByLocation: { [locationName: string]: any[] } = {};
    const locationsIds: number[] = map(locations, 'locationID');

    each(this.getByLocationIds(locationsIds), (shift: any) => {
      const location: any = find(locations, {locationID: shift.locationID});

      if (location) {
        if (!shiftsByLocation[location.name]) {
          shiftsByLocation[location.name] = [];
        }

        shiftsByLocation[location.name].push({
          id: shift.uuid,
          text: shift.name,
          locationId: shift.locationID
        });
      }
    });

    return map(shiftsByLocation, (shifts: any[], locationName) => ({
      text: locationName,
      id: locationName,
      children: shifts
    }));
  }

}
