import { Injectable } from '@angular/core';

import { CommsService, LoadingService } from '@services';
import { identity, isEmpty, pickBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ScoresService {

  public scores: any = {
    data: [],
    lastRequest: null
  };

  constructor(
    private commsService: CommsService,
    private loadingService: LoadingService
  ) {
  }

  public calculateScores(filter: any, cached?: boolean, enableLoading?: boolean): Promise<any> {
    if (cached) {
      return isEmpty(this.scores.data) ? this.getScores(filter, enableLoading) : Promise.resolve(this.scores.data);
    } else {
      return this.getScores(filter, enableLoading);
    }
  }

  public getScores(filter?: any, enableLoading?: boolean): Promise<any> {
    const params: any = {
      cmd: 'getScores',
      lastRequest: this.scores.lastRequest,
      sendTime: Date.now(),
      ...pickBy(filter, identity)
    };

    this.setLoadingState(true, enableLoading);
    return this.commsService.sendMessage(params, false, false).then((response: any) => {
      this.setLoadingState(false, enableLoading);

      if (response && response.reqStatus === 'OK') {
        this.scores.lastRequest = response.result.timestamp;
        this.scores.data = response.result;
        return response.result;
      }
      return null;
    }).catch(() => this.setLoadingState(false, enableLoading));
  }

  private setLoadingState(state: boolean, enableLoading?: boolean): void {
    if (enableLoading) {
      if (state) {
        this.loadingService.enable(null, null);
      } else {
        this.loadingService.disable();
      }
    }
  }

}
