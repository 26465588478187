import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { UserdataService } from '@services';
import { DatePickerPopupComponent } from '@shared/components';

import { TranslateService } from '@ngx-translate/core';
import { find, last } from 'lodash';

export enum DateRangeType {
  Today = 0,
  PastDay = 1,
  PastWeek = 2,
  Past2Week = 3,
  PastMonth = 4,
  Custom = 5
}

interface IDateType {
  title: string;
  dateType: DateRangeType;
}

export interface IDateModel {
  dateType: DateRangeType;
  range?: {
    startTime: number;
    endTime: number;
  };
}

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {

  @Output() onSelectDate: EventEmitter<IDateModel> = new EventEmitter();

  public dateTypes: IDateType[] = [
    {title: this.translate.instant('SHARED.Today'), dateType: DateRangeType.Today},
    {title: this.translate.instant('SHARED.Yesterday'), dateType: DateRangeType.PastDay},
    {title: this.translate.instant('SHARED.7_Days'), dateType: DateRangeType.PastWeek},
    {title: this.translate.instant('SHARED.14_Days'), dateType: DateRangeType.Past2Week},
    {title: this.translate.instant('SHARED.30_Days'), dateType: DateRangeType.PastMonth},
    {title: this.translate.instant('SHARED.Custom'), dateType: DateRangeType.Custom}
  ];
  public currentDateType: IDateType = this.dateTypes[0];

  private storeDataKey = 'dashboardHome';
  private filterObject: any = {};

  constructor(
    private popoverController: PopoverController,
    private userDataService: UserdataService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.onSelectDate.emit(this.getSelectedDate());
  }

  public getSelectedDate(): IDateModel {
    const storedDate: any = this.getStoredDataByKey('date');
    let range: any;

    if (storedDate) {
      this.currentDateType = <any>find(this.dateTypes, {dateType: storedDate.type});

      if (storedDate.range) {
        this.filterObject = storedDate.range;

        range = {
          startTime: this.filterObject.startTime,
          endTime: this.filterObject.endTime
        };
      }
    }

    if (!range) {
      range = this.getDateRangeByDateType(+this.currentDateType.dateType);
    }

    return {dateType: this.currentDateType.dateType, range};
  }

  public onSelect(type: IDateType): void {
    if (type.dateType === DateRangeType.Custom) {
      this.popoverController.create(<any>{
        component: DatePickerPopupComponent,
        animated: false,
        componentProps: {
          filterObject: this.filterObject,
        }
      }).then((element: HTMLIonPopoverElement) => {
        element.present();

        element.onDidDismiss().then(() => {
          if (this.filterObject.startTime && this.filterObject.endTime) {
            this.currentDateType = last(this.dateTypes);
            this.setStoredDataByKey('date', {
              type: type.dateType,
              range: {
                startTime: this.filterObject.startTime,
                endTime: this.filterObject.endTime
              }
            });
            this.onSelectDate.emit({
              dateType: this.currentDateType.dateType,
              range: {
                startTime: this.filterObject.startTime,
                endTime: this.filterObject.endTime
              }
            });
          }
        });
      });
    } else {
      if (this.currentDateType.dateType !== type.dateType) {
        this.currentDateType = type;
        this.onSelectDate.emit({
          dateType: this.currentDateType.dateType,
          range: this.getDateRangeByDateType(type.dateType)
        });
        this.setStoredDataByKey('date', {
          type: type.dateType
        });
      }
    }
  }

  private getDateRangeByDateType(type: DateRangeType) {
    const d: Date = new Date();
    let startTime: number = null;
    let endTime: number = null;

    if (type === DateRangeType.Today) {
      d.setHours(0, 0, 0, 0);
      startTime = d.getTime();
      endTime = Date.now();
    } else if (type === DateRangeType.PastDay) {
      d.setHours(0, 0, 0, 0);
      endTime = d.getTime();
      d.setDate(d.getDate() - 1);
      startTime = d.getTime();
    } else if (type === DateRangeType.PastWeek) {
      d.setDate(d.getDate() - 7);
      d.setHours(0, 0, 0, 0);
      startTime = d.getTime();
      endTime = Date.now();
    } else if (type === DateRangeType.Past2Week) {
      d.setDate(d.getDate() - 14);
      d.setHours(0, 0, 0, 0);
      startTime = d.getTime();
      endTime = Date.now();
    } else if (type === DateRangeType.PastMonth) {
      d.setDate(d.getDate() - 30);
      d.setHours(0, 0, 0, 0);
      startTime = d.getTime();
      endTime = Date.now();
    }

    return {startTime, endTime};
  }

  private getStoredDataByKey(key: string): any {
    return this.userDataService.getStoreDataByKey(this.storeDataKey, key);
  }

  private setStoredDataByKey(key: string, value: any): any {
    return this.userDataService.setStoreDataByKey(this.storeDataKey, key, value);
  }

}
