<div>

  <ion-searchbar (ionInput)="changeSearchModel($event)" *ngIf="isSearchable"
                 [value]="searchModel" placeholder="{{'SHARED.Search' | translate}}"></ion-searchbar>
  <ion-list #list (deferLoad)="init()" lines="full">
    <ion-item *ngIf="isMultiple && isSelectAllEnabled"
               (click)="selectAll()" [ngClass]="{'selected': selectAllEnabled}">
      <ion-checkbox [checked]="selectAllEnabled"></ion-checkbox>
      <div class="info">
        <div [ngClass]="selectAllEnabled ? 'list-label-selected': 'list-label'" class="item-info name">
          {{'SHARED.Select_All' | translate}}
        </div>
      </div>
    </ion-item>
    <ion-item (click)="selectItem(item)" *ngFor="let item of viewItems | orderBy: 'name'"
              [hidden]="item.hidden" [ngClass]="{'selected': item.checked}" [disabled]="item.isDisabled">
      <ion-checkbox *ngIf="isMultiple" [checked]="item.checked" label-placement="end" slot="start">
        <div class="info">
          <div [ngClass]="item.checked ? 'list-label-selected': 'list-label'" class="item-info name">{{item.name}}</div>
          <div *ngIf="item.description !== ''" class="item-info description">{{item.description}}</div>
        </div>
      </ion-checkbox>
      <ion-avatar *ngIf="item.avatarUrl">
        <img [src]="item.avatarUrl">
      </ion-avatar>
    </ion-item>

    <div (scrolled)="loadNextItems()" *ngIf="isInfinityScrollEnabled" [fromRoot]="true" [infiniteScrollContainer]="list"
         [infiniteScrollThrottle]="50" infiniteScroll></div>
  </ion-list>
</div>
