import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/core';

import { MenuFilterPipe } from '@shared/pipes';
import { cloneDeep, includes, intersectionBy, map, reject } from 'lodash';

@Component({
  selector: 'app-item-list-drag-and-drop',
  templateUrl: './item-list-drag-and-drop.component.html',
  styleUrls: ['./item-list-drag-and-drop.component.scss'],
  providers: [MenuFilterPipe]
})
export class ItemListDragAndDropComponent {

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  public viewItems: any[] = [];

  @Input() set items(items) {
    if (this.viewItems && this.viewItems.length) {
      const currentItems = intersectionBy(this.viewItems, items, 'id');
      const currentItemIds = map(currentItems, 'id');

      this.viewItems = [
        ...currentItems,
        ...reject(items, (item) => includes(currentItemIds, item.id))
      ];
    } else {
      this.viewItems = cloneDeep(items);
    }
  }

  public trackBy(index, item) {
    return item.id;
  }

  public doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete(this.viewItems);
  }

}
