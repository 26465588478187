import { ElementRef, Injectable } from '@angular/core';

import { UtilsService } from '@services/utils/utils.service';

import * as Chart from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { get, merge } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardChartServiceService {

  public charts: any = {};

  constructor(private utils: UtilsService, protected translate: TranslateService) {
    Chart.pluginService.register({
      afterUpdate(chart: any) {
        if (chart.config.options.elements.center) {
          const helpers = Chart.helpers;
          const centerConfig = chart.config.options.elements.center;
          const globalConfig = Chart.defaults.global;
          const ctx = chart.chart.ctx;

          const fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
          const fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

          let fontSize = centerConfig.fontSize;
          if (!centerConfig.fontSize) {
            ctx.save();
            fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
            const maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
            const maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

            while (true || ctx.restore()) {
              ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
              const textWidth = ctx.measureText(maxText).width;

              // check if it fits, is within configured limits and that we are not simply toggling back and forth
              if (textWidth + 16 < chart.innerRadius * 2 && fontSize < maxFontSize) {
                fontSize += 1;
              } else {
                // reverse last step
                fontSize -= 1;
                break;
              }
            }
          }

          // save properties
          chart.center = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
          };
        }
      },
      afterDraw: (chart: any) => {
        const ctx = chart.chart.ctx;

        ctx.save();
        if (chart.center) {
          const centerConfig = chart.config.options.elements.center;
          ctx.font = chart.center.font;
          ctx.fillStyle = chart.center.fillStyle;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillText(centerConfig.text, centerX, centerY);
        }
        if (chart.data.datasets[0] && chart.data.datasets[0].data.length === 0) {
          const width = chart.chart.width;
          const height = chart.chart.height;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#F58020';
          ctx.fillText(this.translate.instant('COMMONT_SERVICE.No_data'), width / 2, height / 2);
        }
        ctx.restore();
      }
    });
  }

  /**
   * @param target - viewChild ref of target
   * @param title - title of the chart
   * @param data - data set per chart js
   * @param clickHandler - function to handle clicks on the charts
   * @param legendTarget - deprecated- where legends are drawn
   */
  public pieChart(target: any, title: string, data: any, clickHandler: () => {}, legendTarget: ElementRef) {
    let c = null;
    const showLegend = false;
    if (this.charts[target.nativeElement.id]) {
      this.charts[target.nativeElement.id].destroy();
    }
    {
      const ctx = target.nativeElement;
      ctx.height = 350;
      //      var h = (clickHandler === undefined ? null : clickHandler);
      c = new Chart(ctx, {
        type: 'pie',
        options: {
          onClick: null,  // legend click fuction should be somwhere else
          responsive: true,
          maintainAspectRatio: false,
          title: {display: true, fontSize: 16, fontFamily: 'WorkSans, SourceSansPro', text: title},

          legend: {
            display: showLegend,
            position: 'right',
            labels: {
              fontFamily: 'WorkSans, SourceSansPro',
              fontSize: 14,
              boxWidth: 20,
              padding: 20
            }
          },
        },
        data
      });
      this.charts[target.nativeElement.id] = c;
    }
    return c;
  }

  public barChart(target, title, data) {
    let c = null;
    const showLegend = false;

    if (this.charts[target.nativeElement.id]) {
      // exists - just update it
      this.charts[target.nativeElement.id].destroy();
    }
    {
      const ctx = target.nativeElement;
      ctx.height = 200;
      c = new Chart(ctx, {
        type: 'horizontalBar',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {display: false},
          scales: {
            yAxes: [<any>{
              categoryPercentage: 1,
              barPercentage: 0.5,
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              stacked: true,
              scaleLabel: {
                display: false,
                labelString: 'Percentage'
              },
              ticks: {
                beginAtZero: true,
                display: false
              },
              gridLines: {
                display: false
              }
            }]
          }
        },
        data
      });
      this.charts[target.nativeElement.id] = c;
      return c;

    }
  }

  public lineChart(target: ElementRef, data: any, options?: any) {
    const ctx = target.nativeElement;

    ctx.height = 320;
    if (this.charts[target.nativeElement.id]) {
      // exists - just update it
      this.charts[target.nativeElement.id].destroy();
    }

    options = merge({
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          display: true
        }
      },
      title: {
        display: true,
        lineHeight: 1
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
        }],
        yAxes: [{
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false
          }
        }]
      },
      elements: {
        line: {
          tension: 0.1, // disables bezier curves
        }
      }
    }, options);

    const chart = new Chart(ctx, {type: 'line', data, options});
    this.charts[target.nativeElement.id] = chart;

    return chart;
  }

  public arc(target, title, data, annotation) {
    let c = null;
    if (this.charts[target]) {
      delete this.charts[target];
    }
    if (this.charts[target]) {
      c = this.charts[target];
      c.data.datasets[0].data = data.datasets[0].data;
      c.options.elements.center = {
        text: annotation,
        maxText: '100%',
        fontFamily: 'WorkSans, SourceSansPro'
      };
      c.update();
    } else {
      const ctx: any = $(target);
      ctx.height = 300;
      c = new Chart(ctx, {
        type: 'doughnut',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {display: true, fontSize: 16, fontFamily: 'WorkSans, SourceSansPro', text: title},
          cutoutPercentage: 66,
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontFamily: 'WorkSans, SourceSansPro',
              fontSize: 10
            }
          },
          elements: <any>{
            center: {
              text: annotation,
              maxText: '100%',
              fontFamily: 'WorkSans, SourceSansPro'
            }
          },
          tooltips: {
            callbacks: {
              label: (item, data) => this.utils.secsToDuration(data.datasets[0].data[item.index]),
            },
          },
        },
        data
      });
      this.charts[target] = c;
    }
    return c;
  }

  horizontalBar(target, title, data, clickHandler) {
    let c;
    if (this.charts[target]) {
      // exists - just update it
      this.charts[target].destroy();
    }
    const ctx = $(target);
    const h = (clickHandler === undefined ? null : clickHandler);
    c = new Chart(ctx, {
      type: 'horizontalBar',
      options: {
        tooltips: {
          callbacks: {
            label: (item: any) => {
              const v = parseInt(<any>(item.xLabel * 100)) / 100;
              return v + '%';
            },
          },
        },
        onClick: h,
        responsive: true,
        maintainAspectRatio: true,
        title: {display: true, fontSize: 16, fontFamily: 'WorkSans, SourceSansPro', text: title},
        legend: {display: false},
        scales: {
          yAxes: [<any>{stacked: true, scale: true}],
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: false,
              labelString: 'Percentage'
            },
            ticks: {
              beginAtZero: true,
              callback: (v, i, vs) => null
            },
            gridLines: {
              display: false
            }

          }]
        }
      },
      data
    });
    this.charts[target] = c;
    return c;
  }

  public showBar(target: ElementRef, data, options?: any, clickHandler = null): Chart {
    const ctx = $(target.nativeElement);

    options = merge({
      tooltips: {
        displayColors: true,
        callbacks: {
          mode: 'x'
        }
      },
      onClick: clickHandler,
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
          type: 'linear'
        }]
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          display: true
        }
      }
    }, options);

    if (this.charts[target.nativeElement.id]) {
      // exists - just update it
      this.charts[target.nativeElement.id].destroy();
    }
    const chartInstance = new Chart(ctx, {type: 'bar', options, data});

    if (target.nativeElement.id) {
      this.charts[target.nativeElement.id] = chartInstance;
    }

    return chartInstance;
  }

  public prepareCustomTooltip(tooltipEvent, tooltipElement: HTMLElement | any, chartInstance): { index: number; datasetIndex: number } {
    if (tooltipEvent.opacity === 0) {
      tooltipElement.style.opacity = 0;
      return;
    }

    tooltipElement.classList.remove('above', 'below', 'no-transform');
    if (tooltipEvent.xAlign) {
      tooltipElement.classList.add(tooltipEvent.xAlign);
    } else {
      tooltipElement.classList.add('no-transform');
    }

    const positionY = chartInstance.canvas.offsetTop;
    const positionX = chartInstance.canvas.offsetLeft;

    tooltipElement.style.opacity = .95;
    tooltipElement.style.left = positionX + tooltipEvent.caretX + 'px';
    tooltipElement.style.top = positionY + tooltipEvent.caretY + (tooltipEvent.yPadding * 1.5) + 'px';

    return get(tooltipEvent, 'dataPoints[0]');
  }

}
