import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot } from '@angular/router';

import { AccountsService, AccountTypes, AuthService, UserdataService } from '@services';


import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { get, includes, isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {

  constructor(
    private router: Router,
    private accountsService: AccountsService,
    private userDataService: UserdataService,
    private authService: AuthService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isUserLogged()) {
      if (this.accountsService.accounts.data.length) {
        return Promise.resolve(this.checkAccountType(next.data));
      } else {
        return this.authService.onAuthEvent.pipe(
          map(() => this.checkAccountType(next.data),
            take(1)
          ));
      }
    } else {
      return true;
    }
  }

  private checkAccountType(routeData: Data): boolean {
    const userType: AccountTypes = this.userDataService.type;
    const unavailableUserTypes: string[] = get(routeData, 'canViewExcept');
    const isModuleGuard = !isEmpty(get(routeData, 'moduleAccess'));
    if (isModuleGuard) {
      return true;
    }

    if (userType && unavailableUserTypes && includes(unavailableUserTypes, userType)) {
      if (userType === AccountTypes.Reporting) {
        this.router.navigate(['pages/reporting']);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
