import { Component, Input } from '@angular/core';

import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { TranslateService } from '@ngx-translate/core';
import { RiskWidget } from './../risk-widget/risk-widget.component';
import { first, isArray, last } from 'lodash';


@Component({
  selector: 'app-risk-profile',
  templateUrl: './risk-profile.component.html',
  styleUrls: ['./risk-profile.component.scss'],
})
export class RiskProfileComponent extends CustomFormComponent {

  @Input() values: string[];

  public riskAssessmentConfig: RiskWidget = {
    title: this.translate.instant('DASHPAGES.Your_Risk_Assessment'),
    value: null
  };

  public remainingRiskConfig: RiskWidget = {
    title: this.translate.instant('DASHPAGES.Remaining_Risk'),
    value: null
  };

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    if (isArray(this.values)) {
      this.riskAssessmentConfig.value = first(this.values);
      this.remainingRiskConfig.value = last(this.values);
    }
  }

  public onSelect() {
    if (this.riskAssessmentConfig.value && this.remainingRiskConfig.value) {
      this.formValue = [this.riskAssessmentConfig.value, this.remainingRiskConfig.value];
    }
  }

}
