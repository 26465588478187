<ion-header no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="content">
    <div class="title-block">
      <div class="value">{{ "DASHPAGES.CHANGING_TYPE_title" | translate }}</div>
      <div class="sign">#</div>
      <div class="id">{{observationId}}</div>
    </div>

    <div class="select-type">
      <div class="type-block">
        <div class="title">{{ "DASHPAGES.CHANGING_TYPE_typeTitle" | translate }}</div>
        <div class="value">{{typeTitle}}</div>
      </div>

      <div class="type-arrow"></div>

      <div class="type-selection">
        <div class="title">{{ "DASHPAGES.CHANGING_TYPE_selection" | translate }}</div>
        <select2 (valueChanged)="onTypeChange($event)" [data]="viewRangeOptions" [options]="options"
                 class="table-content"></select2>
      </div>

    </div>

    <hr class="hr-dashed">

    <div *ngIf="currentType" class="form">
      <div *ngIf="currentType === 'condition'">
        <div class="title">{{ "DASHPAGES.CHANGING_TYPE_currentType" | translate }}</div>
        <div>
          <app-range (onChangedValue)="onRangeValueChanged($event, 'severity')"
                     [config]="severityRangeConfig"></app-range>
          <app-range (onChangedValue)="onRangeValueChanged($event, 'liklihood')"
                     [config]="liklihoodRangeConfig"></app-range>
        </div>
      </div>

      <div *ngIf="currentType === 'quality'">
        <div class="title">{{ "DASHPAGES.CHANGING_TYPE_quality" | translate }}</div>

        <ion-radio-group (ionChange)="onIssueChange($event)">
          <ion-item *ngFor="let qualityIssue of qualityIssues" lines="none">
            <ion-radio [value]="qualityIssue.key" label-placement="end">{{qualityIssue.value}}</ion-radio>
          </ion-item>
        </ion-radio-group>

        <ng-container *ngIf="subType">
          <div *ngIf="subType === 'production' && observation.state === 'fixed'; else elseBlock">
            <div class="title">{{ "DASHPAGES.CHANGING_TYPE_fixed" | translate }}</div>

            <ion-radio-group (ionChange)="onFixTypeChange($event)">
              <ion-item *ngFor="let fixTypeOption of fixTypeOptions" lines="none">
                <ion-radio [value]="fixTypeOption.key" label-placement="end">{{fixTypeOption.value}}</ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <ng-template #elseBlock>
            <app-quality-fields [observationType]="subType"></app-quality-fields>
          </ng-template>
        </ng-container>
      </div>

      <div class="title">{{ "DASHPAGES.CHANGING_TYPE_note" | translate }}</div>
      <ion-textarea [(ngModel)]="note"></ion-textarea>
    </div>

    <div class="buttons button-block">
      <ion-button (click)="back()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
      <ion-button (click)="save()" [disabled]="isFormInvalid()" class="page-button"
                  color="secondary">{{ "DASHPAGES.CHANGING_TYPE_secondary" | translate }}</ion-button>
    </div>
  </div>
</ion-content>
