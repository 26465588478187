import { Component, Injector, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { LoadingService, PopupObservationService, ViewerService } from '@services';
import { NotePopupComponent } from '@modules/observation/components';
import {
  ObservationDetailService
} from '@services/observationDetail/observation-detail.service';
import { AbstractObservationDetailPage } from '@modules/observation/pages/abstract-observation-detail-page/abstract-observation-detail-page';
import { Observation } from '@services/observations/observation.interfaces';

@Component({
  selector: 'app-condition-detail',
  templateUrl: './condition-detail.page.html',
  styleUrls: ['./condition-detail.page.scss', './../../directives/mark-note.scss'],
})
export class ConditionDetailPage extends AbstractObservationDetailPage implements OnDestroy {

  // THESE DATA ARE DISPLAYED ON FRONT END
  public uuid: string = null;
  public oid: number = null; // oid
  public bottomComponentData: any; // bottom back/front nav, table nav
  public headerData: any = {}; // data for top component, loc, zone, user etc
  public closingData: any = {}; // data for closing component
  public images = []; // observation images
  public notes: any = {}; // observation notes, audio + test
  public likelihood = 50; // lik sev for unsafe
  public severity = 50;
  public originalLikelihood = null;
  public originalSeverity = null;
  public footerButtons = []; // button displayed on footer
  public footerData: any = {};
  public bottomNav = false;
  public headerIsHidden: string;
  public observation: Observation;

  private isImageLoading = false;

  constructor(
    private observationDetailService: ObservationDetailService,
    private popover: PopoverController,
    private popOps: PopupObservationService,
    private viewer: ViewerService,
    private loadingService: LoadingService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteListener);
    this.events.unsubscribe('ccs:assignUpdate', this.assignListener);
  }

  ionViewWillEnter() {
    // get observations
    this.uuid = this.activatedRoute.snapshot.paramMap.get('id');
    this.headerIsHidden = this.activatedRoute.snapshot.queryParamMap.get('headerIsHidden');
    // if coming from table then only show prev-back nav.
    if (this.activatedRoute.snapshot.queryParamMap.get('tableNav') === 'true') {
      this.bottomNav = true;
    }

    this.events.subscribe('ccs:noteUpdate', this.noteListener);
    this.events.subscribe('ccs:assignUpdate', this.assignListener);
    this.events.subscribe('ccs:observationUpdate', this.updateObservationListener);

    // to make sure this observation actually exists, lets make a call for this observation.
    this.updateObservationListener();
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:observationUpdate', this.updateObservationListener);
  }

  ionViewDidEnter() {
    this.loadingService.disable();
  }

  public addOpenNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'created'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addClosedNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'fixed'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private noteListener = (data) => {
    if (data) {
      this.notes = this.observationDetailService.getNotes(this.oid);
      this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
    }
  };

  private assignListener = (data) => {
    if (data) {
      this.headerData = this.observationDetailService.getTopComponentData(this.oid);
    }
  };

  private updateObservationListener = () => {
    this.observationService.getObservationByUUID(this.uuid).then(data => {
      if (data && data.observationID) {
        this.observation = data;
        this.oid = data.observationID;
        this.observationDetailService.checkObservationType(this.oid, 'condition');
        this.headerData = this.observationDetailService.getTopComponentData(this.oid);
        this.images = this.observationDetailService.getImages(this.oid);
        this.notes = this.observationDetailService.getNotes(this.oid);
        this.likelihood = this.observationDetailService.getLikelihood(this.oid);
        this.severity = this.observationDetailService.getSeverity(this.oid);
        this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
        this.originalSeverity = data.severity;
        this.originalLikelihood = data.likelihood;

        // find sust
        // grab buttons if available to be put at the bottom
        if (this.headerData.buttonPanel) {
          this.footerButtons = this.headerData.buttons;
        }

        // blue nav bar depending whether its coming from the table list.
        if (this.bottomNav) {
          this.checkFooterData();
        }
      } else {
        this.observationDetailService.checkObservationType(null, 'condition');
      }
    });
  };

}
