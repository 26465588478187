/* eslint-disable @typescript-eslint/member-ordering */
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';

import { UtilsService } from '@services/utils/utils.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { TeamsService } from '@services/teams/teams.service';
import { SettingsService } from '@services/settings/settings.service';
import { UserService } from '@services/user/user.service';
import { ObjectsService } from '@services/objects/objects.service';
import { PermissionsService } from '@services/permissions/permissions.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { LoadingService } from '@services/loading/loading.service';
import { CollectionItemType, CollectionsService } from '@services/collections/collections.service';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';

import { TranslateService } from '@ngx-translate/core';
import { FeedbackService } from '@services';
import { each, has } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDetailService {

  public dataSet: any = [];
  public resolveFromSupervisor = false;

  public singleOption = {
    width: '100%',
  };

  private headerMap = {
    feedback: {
      name: this.translate.instant('SHARED.Feedback_Detail'),
      data: ['requested', 'requestedBy', 'lastActivity', 'recipients'],
      buttonPanel: true,
    },
  };

  private buttonMap = {
    addTags: {
      name: 'TOPICS.Add_Tag',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => this.router.navigate([`/pages/management/topics/add-tags/${oid}`], {queryParams: {type: 'feedback'}})
    },
    addTopic: {
      name: 'TOPICS.Create_Add_Topic',
      color: 'secondary',
      class: 'page-button',
      click: (id: number) => {
        this.collectionsService.itemCollection = [{
          itemID: id,
          type: CollectionItemType.Feedback,
          item: id.toString()
        }];
        this.router.navigate(['pages/management/add-topic']);
      }
    },
    moveToAddressed: {
      name: 'MGMT_DETAILS.Move_To_Addressed',
      color: 'secondary',
      class: 'page-button move-to-addressed',
      click: (id) => this.showAddressPopup(id)
    },
    archive: {
      name: 'ODetail.Archive',
      color: 'light',
      class: 'page-button button-right',
      click: (id) => this.showArchivedPopup(id)
    },
  };

  private functionMap = {
    requested: (data) => this.requested(data),
    requestedBy: (data) => this.requestedBy(data),
    lastActivity: (data) => this.lastActivity(data),
    recipients: (data) => this.recipients(data),
  };

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private utils: UtilsService,
    private accountService: AccountsService,
    private teamService: TeamsService,
    private settingsService: SettingsService,
    private userService: UserService,
    private objectService: ObjectsService,
    private popover: PopoverController,
    private permissionsService: PermissionsService,
    private subscriber: SubscriberService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private alertController: AlertController,
    private popupObservationService: PopupObservationService,
    private collectionsService: CollectionsService,
    private feedbackService: FeedbackService
  ) {
  }

  public getTopComponentData(fid) {

    const returnData: any = {
      buttonPanel: this.headerMap.feedback.buttonPanel,
      title: this.headerMap.feedback.name,
      type: 'feedback',
      oid: fid,
      uuid: fid,
    };
    returnData.oid = fid;
    const feedback = this.feedbackService.feedbacks.data[fid];
    const headerArray = this.headerMap.feedback.data;

    returnData.headerElements = [];
    each(headerArray, elem => {
      if (this.functionMap[elem]) {
        returnData.headerElements.push(this.functionMap[elem](feedback));
      }
    });

    returnData.buttons = this.figureOutButtons(feedback);

    return returnData;
  }

  public figureOutButtons(feedback: any) {
    const retArray: any = [];
    const buttsArray: any = feedback.state === 'addressed' ? ['addTags', 'addTopic', 'archive'] : ['addTags', 'addTopic', 'moveToAddressed', 'archive'];

    each(buttsArray, butts => {
      const r = this.buttonMap[butts];
      if (!has(r, 'feature') || this.subscriber.usesFeature(r.feature)) {
        retArray.push(this.buttonMap[butts]);
      }
    });

    return retArray;
  }

  public getRecipients(users) {
    if (users) {
      return users.length > 1 ? users.length + ' ' + 'Recipients' : '1 Recipient';
    } else {
      return '0 Recipients';
    }
  }

  public requested(data) {
    return {
      name: 'SHARED.Requested',
      data: this.utils.dateTimeFormat(data.created, null, true)
    };
  }

  public lastActivity(data) {
    return {
      name: 'MGMT_LIST.LAST_ACTIVITY',
      data: this.utils.dateTimeFormat(data.lastUpdate, null, true)
    };
  }

  public requestedBy(data) {
    return {
      name: 'SHARED.Requested_By',
      data: this.accountService.fullname(data.creator),
      message: true,
      userID: data.creator
    };
  }

  public recipients(data) {
    return {
      name: 'SHARED.Recipients',
      data: this.getRecipients(data.recipients),
      isLink: data.recipients ? true : false,
      link: '/pages/management/recipients',
      queryParams: {feedbackID: data.requestID}
    };
  }

  private showAddressPopup(feedbackId: number) {
    const params: any = {
      header: this.translate.instant('SHARED.Are_You_Sure'),
      message: this.translate.instant('SHARED.Change_Feedback_Status'),
      cssClass: 'custom-alert',
      buttons: [
        {text: this.translate.instant('SHARED.Cancel')},
        {
          text: this.translate.instant('SHARED.Yes_Move_It'),
          handler: () => this.feedbackService.toggleState(feedbackId)
        }
      ]
    };

    this.alertController.create(params).then((alert) => {
      alert.present();
    });
  }

  private showArchivedPopup(feedbackId: number) {
    const params: any = {
      header: this.translate.instant('SHARED.Are_You_Sure'),
      message: this.translate.instant('SHARED.Change_Feedback_Status_Archived'),
      cssClass: 'custom-alert',
      buttons: [
        {text: this.translate.instant('SHARED.Cancel')},
        {
          text: this.translate.instant('SHARED.Yes_Archive_It'),
          handler: () => this.feedbackService.markAsArchived(feedbackId)
        }
      ]
    };

    this.alertController.create(params).then((alert) => {
      alert.present();
    });
  }

}
