import { Pipe, PipeTransform } from '@angular/core';
import { filter, get, includes, lowerCase, replace } from 'lodash';


@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  transform(items: any, searchModel: string, type: string): any {
    let filteredItems: any = items;

    if (searchModel) {
      filteredItems = filter(filteredItems, (item: any) => {
        if (type === 'team') {
          return this.isTeamValid(item, searchModel);
        } else if (type === 'user') {
          return this.isUserValid(item, searchModel);
        }
      });
    }

    return filteredItems;
  }

  private isUserValid(user: any, searchModel: string): boolean {
    const firstName: string = replace(lowerCase(get(user, 'firstname')), / /g, '');
    const lastName: string = replace(lowerCase(get(user, 'lastname')), / /g, '');
    searchModel = replace(lowerCase(searchModel), / /g, '');

    return includes(firstName, searchModel) ||
      includes(lastName, searchModel) ||
      includes(firstName + lastName, searchModel) ||
      includes(lastName + firstName, searchModel);
  }

  private isTeamValid(team: any, searchModel: string): boolean {
    const name: string = replace(lowerCase(get(team, 'name')), / /g, '');
    searchModel = replace(lowerCase(searchModel), / /g, '');

    return includes(name, searchModel);
  }

}
