import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ObservationService } from '@services';
import { Events } from '@services/events/events.service';
import { Observation } from '@services/observations/observation.interfaces';
import { NoteListConfig } from '@modules/observation/pages/insights/pages/detail/components';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { findLastIndex, pullAt } from 'lodash';

@Component({
  selector: 'app-observation-notes',
  templateUrl: './observation-notes.component.html',
  styleUrls: ['./observation-notes.component.scss'],
})
export class ObservationNotesComponent implements OnInit, OnDestroy {

  public notes: { open: NoteListConfig; closed: NoteListConfig } = {
    open: {
      title: 'SHARED.Submitted',
      titleColorClass: 'subhead-opportunity',
      notes: [],
      observationId: null,
      newNoteType: 'created',
      showEmptyMessage: true
    },
    closed: {
      title: 'SHARED.Addressed',
      titleColorClass: 'subhead-fixed',
      notes: [],
      observationId: null,
      newNoteType: 'fixed'
    }
  };
  public isClosedNotesAvailable: boolean;

  private observationColorMap = {
    ai: 'subhead-asset-open',
    si: 'subhead-opportunity',
    condition: 'subhead-open',
    quality: 'subhead-quality',
    behavior: 'subhead-opportunity'
  };

  private id: number;
  private onNoteUpdate = () => {
    if (this.id) {
      this.init(this.id);
    }
  }

  constructor(
    private observationDetailService: ObservationDetailService,
    private observationService: ObservationService,
    private events: Events
  ) {}

  @Input() set observationId(id: number) {
    if (id) {
      this.init(id);
    }
  }

  ngOnInit() {
    this.events.subscribe('ccs:noteUpdate', this.onNoteUpdate);
    this.events.subscribe('ccs:observationUpdate', this.onNoteUpdate);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.onNoteUpdate);
    this.events.unsubscribe('ccs:observationUpdate', this.onNoteUpdate);
  }

  public init(id: number): void {
    const observation = this.observationService.getObservationById(id) as Observation;
    const notes = this.observationDetailService.getNotes(id);

    if (observation.type === 'si') {
      pullAt(notes.openNotes, findLastIndex(notes.openNotes, {type: 'text'}));
    }

    this.notes.open.titleColorClass = this.observationColorMap[observation?.type] || 'subhead-opportunity';
    this.notes.open.notes = notes.openNotes;
    this.notes.open.observationId = id;
    this.notes.closed.notes = notes.closedNotes;
    this.notes.closed.observationId = id;

    this.isClosedNotesAvailable = notes.displayClosedNotes;
    this.id = id;
  }
}
