import { Component, Input, OnInit } from '@angular/core';
import { includes } from 'lodash';
import { PermissionsService, UserdataService } from '@services';

@Component({
  selector: 'app-account-can-view-except',
  templateUrl: './account-can-view-except.component.html',
  styleUrls: ['./account-can-view-except.component.scss'],
})
export class AccountCanViewExceptComponent implements OnInit {

  @Input() canViewExcept: string[] = [];
  @Input() canViewPermission: string = '';

  public isCanView: boolean;

  constructor(private userDataService: UserdataService,
    private permissionService: PermissionsService
  ) {
  }

  ngOnInit(): void {
    this.isCanView = !includes(this.canViewExcept, this.userDataService.type);
    if (this.isCanView && this.canViewPermission !== '') {
      this.isCanView = this.permissionService.canView(this.canViewPermission);
    }
  }

}
