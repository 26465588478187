import { Injectable } from '@angular/core';

import { Feature, Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { AccountTypes } from '@services';
import { UserdataService } from '@services/userdata/userdata.service';
import { get, includes, isEmpty, some, has, each } from 'lodash';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';

export interface IModuleAccess {
  module: Module;
  feature?: Feature;
  checkRelatedFeatures?: boolean;
  subscriberAccess?: IModuleSubscriberAccess;
}

export interface IModuleSubscriberAccess {
  user?: string;
  account?: string;
  permission?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModuleAccessService {

  public static readonly allowedAccounts: AccountTypes[] = [AccountTypes.Dedicated, AccountTypes.Shared];

  constructor(
    private subscriberService: SubscriberService,
    private userDataService: UserdataService,
    private permissions: PermissionsService,
  ) {
  }

  public isModuleEnabled(moduleAlias: Module, checkRelatedFeatures?: boolean): boolean {
    return moduleAlias ? this.subscriberService.usesModule(moduleAlias.toString(), checkRelatedFeatures) : true;
  }

  public isFeatureEnabled(featureAlias: Feature, checkRelatedFeatures?: boolean): boolean {
    return featureAlias ? this.subscriberService.usesFeature(featureAlias, checkRelatedFeatures) : true;
  }

  public hasSubscriberAccess(subscriberAccess: IModuleSubscriberAccess, isNoPermissionsUser: boolean = false): boolean {
    if (isEmpty(subscriberAccess)) {
      // when it is empty, there are no restrictions
      return true;
    }

    let isAllowed = true;

    const usersIds: string[] = get(this.subscriberService.subInfo, `features.${subscriberAccess.user}`, []);
    if (usersIds.length) {
      if (!includes(usersIds, this.userDataService.userID)) {
        isAllowed = false;
      }
    }

    if (isAllowed && subscriberAccess.account) {
      const limit = get(this.subscriberService.subInfo, `features.${subscriberAccess.account}`, []);
      if (limit.length || isNoPermissionsUser) {
        // we are limiting by account type
        const accountAccess: AccountTypes[] = [
          ...limit,
          ...ModuleAccessService.allowedAccounts
        ];
        isAllowed = includes(accountAccess, this.userDataService.type);
      }
    }

    if (isAllowed && !isNoPermissionsUser && subscriberAccess.permission) {
      const limit = get(this.subscriberService.subInfo, `features.${subscriberAccess.permission}`, []);
        // we are limiting by account type
        let permissionAccess: Permission[] = [
          Permission.SuperAdmin,
          Permission.Admin,
          Permission.Corvex
        ];
      if (limit.length) {
        permissionAccess = [
          ...limit,
          Permission.SuperAdmin,
          Permission.Admin,
          Permission.Corvex
        ];
      }
        isAllowed = false;
        each(permissionAccess, perm => {
          if (has(this.userDataService.Permissions, perm) && this.userDataService.Permissions[perm]) {
            isAllowed = true;
          }
        });
    }

    return isAllowed;
  }

  public isAllowedAccount(): boolean {
    return includes(ModuleAccessService.allowedAccounts, this.userDataService.type);
  }

  public isAccountPermitted(subscriberAccess: IModuleSubscriberAccess) {
    const permissions = get(this.subscriberService.subInfo, `features.${subscriberAccess.account}`, []);
    return some(permissions, (permission) => this.permissions.canView(permission));
  }
}
