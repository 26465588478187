import { Injectable } from '@angular/core';
import { DeploymentService } from '@modules/management/pages/details/check/services';
import {
  AccountsService,
  AssetsService,
  CheckResponseService,
  ChecksService,
  ObjectsService,
  ObservationService,
  SettingsService,
  TeamsService,
  UserService,
  UtilsService
} from '@services';
import * as moment from 'moment';
import { AssignPopupComponent } from '@modules/observation/components';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { QuestionService } from '../../modules/management/pages/details/check/services/question/question.service';
import { ICopyConfig } from '@shared/modules/copy-link/components/copy-link/copy-link.component';
import {
  cloneDeep,
  each,
  filter,
  find,
  findIndex,
  includes,
  isArray,
  isEmpty,
  map,
  maxBy,
  orderBy,
  sortBy,
  split,
  sumBy
} from 'lodash';
import { Response } from '@modules/management/pages/details/check/models';

@Injectable({
  providedIn: 'root'
})
export class CheckDetailService {

  // see getIssueByIssueId()
  public issueCaTableData: any;
  // check-result-table from check-type-table, we have to pre-calculate what targets are available //one page ahead.
  public checkResultTableTargetItems: any;

  // this holds the table data for logged & Ca in check result table once the table is rendered.
  // when we go to detail, we can pick the issueDetails from this table item without further computation
  // This is read and cleared out at unable-to-perform page.
  public unableCheckResponseArr: any;

  // this holds the targetSignature required for the drop down while going into
  // used for navigating back and forth with the detail
  public currentTableData: any;


  // This holds the array of avilable-response Rid from supervisor-dashboard-component which are
  // bulk selected for unableToPerform Operation.
  // on upper level
  public statusPageTimeRange: any = {
    currentFilter: {},
    id: null
  };
  // Column Builder Param and Map for check result table Start
  targetName = {
    title: this.translate.instant('OTable.TARGET') + '<span class="sort-icon"></span>',
    data: (data: any) => {
      let nameStr = '';
      if (data.targetSignature) {
        const splitSig = split(data.targetSignature, ':');
        // Translate these stuff
        if (splitSig[0] == 'loc') {
          nameStr = this.getTargetZoneName(+splitSig[1], +splitSig[2]);
        } else if (splitSig[0] == 'asset') {
          const targObj = this.assetsService.getAssetById(+splitSig[1]);
          const location = this.userService.getLocation(targObj[0].location) || {
            name: this.translate.instant('SHARED.Unknown')
          };
          nameStr = location.name + '; ' + 'Asset' + ': ' + targObj[0].name;
        } else if (splitSig[0] == 'worker') {
          nameStr = 'Worker' + ': ' + this.accountsService.fullname(+splitSig[1]);
        }
        return nameStr;
      } else {
        return '';
      }
    },
  };

  // this is the drop down time holder for check status component in supervisor taskboard, this will be used in deeper pages to reflect the selected time range
  questionItem = {
    title: this.translate.instant('DASHPAGES.question-item') + '<span class="sort-icon"></span>',
    data: (data: any) => {
      if (data.questionID) {
        const questionObj = this.questionService.getQuestion(data.questionID);
        if (questionObj) {
          return questionObj.title;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  };
  submittedTime = {
    title: this.translate.instant('SHARED.SUBMITTED') + '<span class="sort-icon"></span>',
    data: data => data,
    render: (data, type, row, meta) => {

      if (type === 'display' || type === 'filter' || type === 'export') {
        return this.utils.dateTimeFormat(data.startTime, null, true);
      } else {
        return data.startTime;
      }
    }
  };
  missedTimeColumn = {
    title: this.translate.instant('SHARED.Missed') + '<span class="sort-icon"></span>',
    data: data => data,
    render: (data, type, row, meta) => {

      if (type === 'display' || type === 'filter' || type === 'export') {
        return this.utils.dateTimeFormat(data.expiresTime, null, false);
      } else {
        return data.expiresTime;
      }
    }
  };
  observationID = {
    title: this.translate.instant('SHARED.Response_ID') + '<span class="sort-icon"></span>',
    data: 'observationID'
  };
  checkNameColumn = {
    title: this.translate.instant('OTable.CHECK_NAME') + '<span class="sort-icon"></span>',
    data: (data: any) => {
      // get check object
      const check = this.checksService.getCheck(data.checkID);
      if (check) {
        let groupName = '';
        if (data.isGrouped) {
          each(check.checkGroups, (checkGroup) => {
            if (checkGroup.groupID === data.checkGroup) {
              groupName = checkGroup.title;
            }
          });
        }
        if (groupName) {
          return check.title + ': ' + groupName;
        } else {
          return check.title;
        }
      } else {
        return '';
      }
    }
  };
  submittedByColumn = {
    title: this.translate.instant('SHARED.SUBMITTED_BY') + '<span class="sort-icon"></span>',
    data: (data) => this.accountsService.fullname(data.responder)
  };
  obsStateColumn = {
    title: this.translate.instant('SHARED.State') + '<span class="sort-icon"></span>',
    data: (data) => {
      if (data.state !== 'resolved') {
        return this.translate.instant('SHARED.Open');
      } else {
        return this.translate.instant('DASHPAGES.resolved');
      }
    }
  };
  resolvedDateColumn = {
    title: this.translate.instant('DASHPAGES.resolved') + '<span class="sort-icon"></span>',
    data: (data) => data,
    render: (data, type, row, meta) => {
      let fixedTime: any = {};
      fixedTime = find(data.history, ['activity', 'resolved']);
      if (type === 'display' || type === 'filter') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, true);
        } else {
          return '';
        }
      } else if (type === 'sort') {
        return fixedTime ? fixedTime.time : 0;
      } else if (type === 'export') {
        return this.utils.dateTimeFormat(fixedTime.time, null, false);
      } else {
        return data;
      }
    }
  };
  submittedBySkippedColumn = {
    title: this.translate.instant('SHARED.SUBMITTED_BY') + '<span class="sort-icon"></span>',
    data: (data) => this.accountsService.fullname(data.owner)
  };
  ownerColumn = {
    title: this.translate.instant('SHARED.OWNER') + '<span class="sort-icon"></span>',
    data: (data) => this.accountsService.fullname(data.owner)
  };
  assignedToColumn = {
    title: this.translate.instant('SHARED.Assigned_To') + '<span class="sort-icon"></span>',
    data: (data) => {
      const assignedTo = data.assignedTo;
      let assigned = '';
      let separate = false;
      if (assignedTo.teams && assignedTo.teams.length) {
        const teams: string[] = [];
        assignedTo.teams.forEach((teamID) => {
          teams.push(this.teamsService.teamNameByID(teamID));
        });
        if (assignedTo.teams.length === 1) {
          assigned += this.translate.instant('SHARED.Team') + ': ' + map(teams).join(', ');
        } else {
          assigned += this.translate.instant('SHARED.TEAMS') + ': ' + map(teams).join(', ');
        }
        separate = true;
      } else if (0) {
        assigned += separate ? '<hr>' : '';
        assigned += this.translate.instant('SHARED.TEAMS') + ': ' + this.translate.instant('SHARED.Any_Team');
        separate = true;
      }
      if (assignedTo.users && assignedTo.users.length) {
        const users: string[] = [];
        assignedTo.users.forEach((userID) => {
          if (userID === -1) {
            users.push(this.translate.instant('MGMT_DETAILS.Self_Check'));
          } else if (userID === -2) {
            users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
          } else {
            users.push(this.userService.getFullname(userID));
          }
        });
        assigned += separate ? '<hr>' : '';
        if (users.length === 1) {
          assigned += this.translate.instant('SHARED.USER') + ': ' + map(users).join(', ');
        } else {
          assigned += this.translate.instant('SHARED.USERS') + ': ' + map(users).join(', ');
        }
        separate = true;
      } else if (0) {
        assigned += separate ? '<hr>' : '';
        assigned += this.translate.instant('SHARED.USERS') + ': ' + this.translate.instant('SHARED.Any_User');
        separate = true;
      }
      return assigned || this.translate.instant('SHARED.None');
    }
  };
  notesColumn = {
    title: this.translate.instant('OTable.NOTES') + '<span class="sort-icon"></span>',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      each(data.notes, nObj => {
        if (nObj.type === 'text') {
          noteStr += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };
  skipNotes = {
    title: this.translate.instant('OTable.NOTES') + '<span class="sort-icon"></span>',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      each(data.skipNotes, nObj => {
        if (nObj && nObj.type === 'text') {
          noteStr += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };
  reasonColumn = {
    title: this.translate.instant('SHARED.Reason') + '<span class="sort-icon"></span>',
    data: (data) => {
      const msg = this.settingsService.getItem('checkReason', data.skipReason, false, true);
      if (msg) {
        return msg.messageTitle;
      } else {
        return '';
      }
    }
  };
  deploymentNameColumn = {
    title: this.translate.instant('SHARED.Deployment_Name') + '<span class="sort-icon"></span>',
    data: (data) => {
      const depObj = this.deployment.getDeployment(data.deploymentID);
      if (depObj) {
        return depObj.title;
      } else {
        return '';
      }
    }
  };
  complianceCheck = {
    title: this.translate.instant('DASHPAGES.Complaince_Check') + '<span class="sort-icon"></span>',
    data: (data) => {
      const checkObj: any = this.checksService.getCheck(data.checkID);
      if (checkObj && checkObj.isCompliance) {
        return this.translate.instant('SHARED.Yes');
      } else {
        return this.translate.instant('SHARED.No');
      }
    }
  };
  completedTime = {
    title: this.translate.instant('SHARED.Completed') + '<span class="sort-icon"></span>',
    data: data => data,
    render: (data, type, row, meta) => {
      if (type === 'display' || type === 'filter' || type === 'export') {
        return this.utils.dateTimeFormat(data.completionTime, null, true);
      } else {
        return data.completionTime;
      }
    }
  };
  completedByColumn = {
    title: this.translate.instant('SHARED.Completed_By') + '<span class="sort-icon"></span>',
    data: (data) => this.accountsService.fullname(data.owner)
  };

  completedLoggedCommentsColumn = {
    title: this.translate.instant('OTable.COMMENTS') + '<span class="sort-icon"></span>',
    data: (data) => {
      const flaggedIssues = filter(data.answers, 'flaggedIssue').length;
      const cellClass: string = flaggedIssues ? 'risk-index-red-small' : 'table-large';

      return `<span class="${cellClass}">${flaggedIssues}</span>`;
    }
  };

  completedCAColumn = {
    title: this.translate.instant('SHARED.Corrective_Actions') + '<span class="sort-icon"></span>',
    data: (data) => {
      const loggedCAs = map(filter(data.answers, 'observationID'), 'observationID');
      const textClass = loggedCAs.length ? 'risk-index-red-small' : 'table-large';
      let openCAs = 0;

      each(loggedCAs, (id) => {
        const observation = this.observationService.observations.data[id] || {};
        if (includes(['new', 'escalated', 'workorder'], observation.state)) {
          openCAs++;
        }
      });
      const openText = openCAs ? `(${openCAs} ${this.translate.instant('SHARED.Open')})` : '';

      return `
        <span class=${textClass}>${loggedCAs.length}</span>
        <span class="open-spacer">${openText}</span>
      `;
    }
  };

  tableMap = {
    complete: [this.completedTime, this.completedByColumn, this.checkNameColumn, this.deploymentNameColumn, this.completedLoggedCommentsColumn, this.completedCAColumn],
    logged: [this.deploymentNameColumn, this.questionItem, this.submittedTime, this.submittedByColumn, this.notesColumn],
    corrective: [this.observationID, this.deploymentNameColumn, this.obsStateColumn, this.submittedTime, this.submittedByColumn, this.ownerColumn, this.resolvedDateColumn],
    skipped: [this.deploymentNameColumn, this.submittedTime, this.submittedBySkippedColumn, this.reasonColumn, this.skipNotes],
    missed: [this.deploymentNameColumn, this.checkNameColumn, this.complianceCheck, this.assignedToColumn, this.missedTimeColumn] //this.checkNameColumn has full check name with group, add that if asked for.
  };

  checkResultColumn = {
    completed: {
      title: 'SHARED.Completed',
      data: 'completed',
    },
    checkType: {
      title: 'SHARED.Check_Type',
      data: 'name',
      width: '20%'
    },
    targetType: {
      title: 'OTable.TARGET',
      data: this.targetName.data,
      width: '20%'
    },
    issue: {
      title: 'MGMT_DETAILS.Issues_Action',
      data: 'issues',
      width: '20%',
      render: (data, type, row, meta) => {
        const textClass = data ? 'risk-index-red-small' : 'table-large';
        return `<div class=${textClass}>${data}</div>`;
      }
    },
    issuesOnly: {
      title: 'OTable.COMMENTS',
      data: 'issuesOnly',
      width: '10%',
      render: (data, type, row, meta) => {
        const textClass = data ? 'risk-index-red-small' : 'table-large';
        return `<div class=${textClass}>${data}</div>`;
      }
    },
    casOnly: {
      title: 'SHARED.Corrective_Actions',
      data: 'actionsOnly',
      width: '10%',
      render: (data, type, row, meta) => {
        // first figure out the count
        const lengthCa = data.length;
        let openCas = 0;

        each(data, i => {
          const obsObj = this.observationService.observations.data[i] || {};
          if (obsObj.state === 'new' || obsObj.state === 'escalated' || obsObj.state === 'workorder') {
            openCas++;
          }
        });
        const textClass = lengthCa ? 'risk-index-red-small' : 'table-large';
        const openText = openCas ? `(${openCas} open)` : ' ';

        if (type === 'display') {
          return `<span class=${textClass}>${lengthCa}</span><span class="table-body open-spacer">${openText}</span>`;
        } else if (type === 'sort' || type === 'type') {
          return lengthCa;
        }
      }
    },
    missed: {
      title: 'SHARED.Missed',
      data: 'missedOnly',
      width: '10%',
      // {name: 'MGMT_DETAILS.Unable_To_Perform', class: 'light-shade-block' },
      // {name: 'SHARED.Missed', class: 'dark-shade-block' }
    },
    skipped: {
      title: 'MGMT_DETAILS.Unable_To_Perform',
      data: 'skippedOnly',
      width: '10%',
    },
    getProgressColumn: (innerCheckType: boolean = false) => ({
      title: 'SHARED.Results',
      data: 'colorMap',
      width: '60%',
      render: (data, type, row, meta) => {
        let colorString = '';
        // let's figure out the completed percentage.
        const doneSum = sumBy(data, (obj: any) => {
          if (obj.color !== 'white') {
            return obj.data;
          }
        });
        const percentage = +(doneSum / 12 * 100).toFixed(0);
        let classString = 'newClass';
        if (innerCheckType) {
          classString = 'newInnerClass';
        }
        each(data, color => {
          colorString += `<ion-col data-length=${color.length} data-percentage=${color.data} data-color=${color.color} size="${color.colLength}" class="${color.color} ${classString}"></ion-col>`;
        });

        if (type === 'sort') {
          const colObj: any = find(data, {color: 'success'});
          const negObj: any = find(data, {color: 'danger'});
          if (colObj) {
            return colObj.data;
          } else if (negObj) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return `<ion-grid><ion-row class='row-class'>`
            + colorString +
            `</ion-row></ion-grid>`;

        }
        // <span class="span-class table-body-bold">${percentage ? percentage : 0}%</span>`
      }
    })
  }

  private destination: string;

  private headerMap = {
    available: {
      name: 'MGMT_DETAILS.Available_Check_Details',
      data: ['deploymentName', 'checkName', 'targetFull', 'assignedTo'],
      buttonPanel: true,
      buttsArray: ['assignCheck', 'unableToPerform']
    },
    missed: {
      name: 'MGMT_DETAILS.Missed_Check_Details',
      data: ['deploymentName', 'checkName', 'targetFull', 'owner'],
      buttonPanel: false,
    },
    logged: {
      name: 'MGMT_DETAILS.Issue_Details',
      data: ['submitted', 'submittedBy'],
      buttonPanel: false,
    },
    ca: {
      name: 'DASHPAGES.corrective-action-detail',
      data: ['submitted', 'submittedBy', 'owner', 'type', 'target'],
      buttonPanel: true,
    },
    unable: {
      name: 'MGMT_DETAILS.Unable_To_Perform_Details',
      data: ['deploymentName', 'targetFull', 'submitted', 'submittedBy'],
      buttonPanel: false,
    },
    complete: {
      name: 'SHARED.Completed_Check',
      data: ['completed', 'checkName', 'deploymentName', 'targetFull', 'completedBy'],
      buttonPanel: false,
    },
    validation: {
      name: 'SHARED.Validation_Details',
      data: ['submitted', 'submittedBy', 'type', 'target'],
      buttonPanel: false,
    },
    incomplete: {
      name: 'SHARED.Incomplete_Check',
      data: ['deploymentName', 'checkName', 'targetFull', 'owner'],
      buttonPanel: false,
    },
    notCompleted: {
      name: 'SHARED.Incomplete_Check',
      data: ['deploymentName', 'checkName', 'targetFull', 'owner'],
      buttonPanel: false,
    },
    onDemand: {
      name: 'MGMT_DETAILS.On_Demand_Check_Details',
      data: ['deploymentName', 'checkName', 'targetFull', 'assignedTo'],
      buttonPanel: true,
      buttsArray: ['assignCheck']
    },
    inProgress: {
      name: 'MGMT_DETAILS.In_Progress_Check_Details',
      data: ['deploymentName', 'checkName', 'targetFull', 'claimedBy', 'expiringTime'],
      buttonPanel: true,
      buttsArray: ['redeployCheck', 'unableToPerform']
    }
  };
  private functionMap = {
    deploymentName: (data) => this.deploymentName(data),
    checkName: (data) => this.checkName(data),
    target: (data) => this.target(data),
    owner: (data) => this.owner(data),
    submitted: (data) => this.submitted(data),
    submittedBy: (data) => this.submittedBy(data),
    type: (data) => this.type(data),
    targetFull: (data) => this.targetFullColumn(data),
    assignedTo: (data) => this.assignedTo(data),
    claimedBy: (data) => this.claimedBy(data),
    expiringTime: (data) => this.expiringTime(data),
    completed: (data) => this.completed(data),
    completedBy: (data) => this.completedBy(data)
  };

  // Column Builder Param and Map for check result table End
  private buttonMap = {
    assignCheck: {
      name: 'MGMT_DETAILS.Assign_Check',
      color: 'secondary',
      class: 'page-button',
      click: (rid) => {
        this.assignObservation(rid);
      }
    },
    unableToPerform: {
      name: 'MGMT_DETAILS.Unable_To_Perform',
      color: 'secondary',
      class: 'page-button',
      click: (rid) => {
        this.unableToPerform(rid, this.destination);
      }
    },
    redeployCheck: {
      name: 'MGMT_DETAILS.Restart_Check',
      color: 'secondary',
      class: 'page-button',
      click: (rid) => {
        this.router.navigate([`pages/dashboard/check-detail/${rid}/restart`]);
      }
    }
  };
  private colorOrder = ['success', 'danger', 'light-shade', 'dark-shade', 'white'];

  constructor(
    private deployment: DeploymentService,
    private checksService: ChecksService,
    private assetsService: AssetsService,
    private accountsService: AccountsService,
    private checkResponseService: CheckResponseService,
    private translate: TranslateService,
    private popover: PopoverController,
    private router: Router,
    private settingsService: SettingsService,
    private questionService: QuestionService,
    private utils: UtilsService,
    private objectService: ObjectsService,
    private userService: UserService,
    private teamsService: TeamsService,
    private observationService: ObservationService
  ) {
  }

  public dataNavMap(data, type) {
    if (type === 'logged') {
      return `pages/dashboard/issue-detail/${data.responseID}/${data.flaggedIssue}`;
    } else {
      return `pages/dashboard/check-detail/${data.responseID}`;
    }
  }

  public unableToPerform(rid, destination = '/pages/dashboard/supervisor-dashboard') {
    if (isArray(rid)) {
      this.unableCheckResponseArr = rid;
      this.router.navigate([`pages/dashboard/unable-to-perform/${rid}`],
        {queryParams: {bulkReq: true, destination}});
    } else {
      this.router.navigate([`pages/dashboard/unable-to-perform/${rid}`], {queryParams: {destination}});
    }
  }

  public assignObservation(rid) {
    // check role

    const componentProps: any = {
      participants: []
    };
    if (isArray(rid)) {
      componentProps.responseIDArr = rid;
    } else {
      componentProps.responseID = rid;
    }
    this.popover.create(<any>{
      component: AssignPopupComponent,
      animated: false,
      componentProps
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public isUUID(uuid) {
    let s: any = '' + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  /**
   * This method figures out the top component data for issue/ca/check details
   * We have to work with response object or answer object to build the relevant pages.
   * Incase of logged and Ca we work with answer object for which the parameters are passed in as optional from detail page.
   *
   * @param rid - responseID of the response obejct
   * @param issueType - optional parameter for logged or ca type
   * @param flagID flagId or observationID
   */

  public async getTopComponentData(rid, issueType?: string, flagID?: number, destination?: string) {
    const returnData: any = {};
    this.destination = destination;
    let responseObject: any = await this.checkResponseService.getResponseAsync(rid);
    let type = this.typeMap(responseObject);
    if (issueType) {
      type = issueType;
      if (flagID) {
        responseObject = this.getIssueByIssueId(flagID);
      }
    }
    const headerArray = this.headerMap[type].data;
    returnData.title = this.translate.instant(this.headerMap[type].name);
    returnData.type = type;
    returnData.headerElements = [];
    returnData.buttonPanel = this.headerMap[type].buttonPanel;
    returnData.rid = rid;
    each(headerArray, elem => {
      if (this.functionMap[elem]) {
        returnData.headerElements.push(this.functionMap[elem](responseObject));
      }
    });

    if (this.headerMap[type].buttonPanel) {
      returnData.buttonPanel = this.headerMap[type].buttonPanel;
      const btn: any = [];
      const btnArr = this.headerMap[type].buttsArray;
      each(btnArr, data => {
        if (this.buttonMap[data]) {
          btn.push(this.buttonMap[data]);
        }
      });
      returnData.buttons = btn;
    }

    if (type === 'unable') {
      const bodyDetail: any = {};
      const text = find(responseObject.skipNotes, <any>{type: 'text'});
      if (text) {
        bodyDetail.note = text;
      } else {
        bodyDetail.note = '';
      }
      const audio = find(responseObject.skipNotes, <any>{type: 'audio'});
      if (audio) {
        bodyDetail.audio = audio;
      } else {
        bodyDetail.audio = null;
      }

      const reasonObj = this.settingsService.getItem('checkReason', responseObject.skipReason);
      if (reasonObj) {
        bodyDetail.reason = reasonObj.messageTitle;
      } else {
        bodyDetail.reason = '';
      }

      returnData.bodyDetail = bodyDetail;
    }

    if (responseObject.status === 'complete' && !flagID && !issueType) {
      returnData.exportPDF = true;
    }

    returnData.copyLink = {
      url: `pages/dashboard/check-detail/${rid}`,
      description: 'MGMT_DETAILS.Copy_Link_Response'
    } as ICopyConfig;

    return returnData;
  }

  public async getFooterComponent(rid, issueType?: string, flagID?: number) {
    // let's collect the ids and navs
    const returnData: any = {};
    const response: any = await this.checkResponseService.getResponseAsync(rid);

    if (this.currentTableData && this.currentTableData.length === 0) { // no observation to go back and forth?
      return returnData; // bail out
    }
    let currentPosition: number = null;
    if (issueType === 'logged') {
      currentPosition = findIndex(this.currentTableData, ['flaggedIssue', flagID]);
      returnData.name = 'ODetail.All_Comments';
      returnData.activeTab = 'logged';
    }

    if (issueType === 'unable') {
      currentPosition = findIndex(this.currentTableData, ['responseID', rid]);
      returnData.activeTab = 'skipped';
      returnData.name = 'ODetail.All_Unable_To_Perform';
    }


    if (currentPosition > 0) {
      const previousObservation = this.currentTableData[currentPosition - 1];
      returnData.prev = {
        disabled: false,
        data: this.dataNavMap(previousObservation, issueType)
      };
    } else {
      returnData.prev = {
        disabled: true
      };
    }

    // last element of the table
    if (!this.currentTableData || currentPosition + 1 === this.currentTableData.length) {
      returnData.next = {
        disabled: true
      };
    } else {
      const nextObservation = this.currentTableData[currentPosition + 1];
      returnData.next = {
        disabled: false,
        data: this.dataNavMap(nextObservation, issueType)
      };
    }
    returnData.nav = `pages/dashboard/check-result-table/${response.checkType}/${response.targetSignature}`;
    return returnData;
  }

  /**
   *
   * @param currentFilter - time range for data
   * @param teamFilter - selected teams for data
   * @param checkTypeID - specific checkTypeID
   * @param filteredResponses - provided filtered responses
   * This method populates check-type-table page, it fetches info about specific check and display the progress over various targets
   */
  public async getCheckTypeProgress(currentFilter, teamFilter, checkTypeID, filteredResponses?) {
    let rawResponses = filteredResponses;
    if (!rawResponses) {
      rawResponses = await this.checkResponseService.getAllCheckResponses(currentFilter, teamFilter);
    }
    // let's filter it by checkTypeID
    const responses = filter(rawResponses, ['checkType', checkTypeID]);
    const targetTypeObj = {};
    // now we have to figure out progress made on each target types for colors and all
    each(responses, (respObj: any) => {
      if (targetTypeObj[respObj.targetSignature]) {
        targetTypeObj[respObj.targetSignature].push(respObj);
      } else {
        targetTypeObj[respObj.targetSignature] = [respObj];
      }
    });
    // figure out color, names
    const returnObj: any = {};
    each(targetTypeObj, (objs: any, key) => {
      returnObj[key] = this.figureOutColorPercentage(objs, key);
    });

    // craft ret Object compatible for table.
    const retArr: any = [];
    each(returnObj, (data, key) => {
      const retObj = {
        targetSignature: key,
        colorMap: data.colorMap,
        issues: data.issues,
        id: checkTypeID,
        actionsOnly: data.actionsOnly,
        issuesOnly: data.issuesOnly,
        missedOnly: data.missedOnly,
        skippedOnly: data.skippedOnly
      };
      retArr.push(retObj);
    });
    const sortedArr = sortBy(retArr, ['name'], ['desc']);
    return sortedArr;

  }

  /**
   *
   * @param currentFilter - time to  filter
   * @param teamFilter - team to filter
   * @param filteredResponses - provided filtered responses
   * This method populates the color grid for check progresses for various check types in main-supervisor taskboard
   */
  public async getAllCheckTypeProgress(currentFilter, teamFilter, filteredResponses?) {
    let responses = filteredResponses;
    if (!responses) {
      responses = await this.checkResponseService.getAllCheckResponses(currentFilter, teamFilter);
    }
    // responses by check types
    const checkTypeObj = {};
    each(responses, (respObj: any) => {
      if (checkTypeObj[respObj.checkType]) {
        checkTypeObj[respObj.checkType].push(respObj);
      } else {
        checkTypeObj[respObj.checkType] = [respObj];
      }
    });

    // map name, color types for each row
    const returnObj: any = {};
    each(checkTypeObj, (objs: any, key) => {
      //got check type name
      const checkType = this.settingsService.getItem('checkType', key);
      // let's iterate over arrays to figure out colors
      let checkName = '';
      if (checkType) {
        checkName = checkType.messageTitle;
      } else {
        checkName = 'Site-Wide';
      }
      returnObj[checkName] = this.figureOutColorPercentage(objs, key);
    });

    // craft ret Object compatible for table.
    const retArr: any = [];
    each(returnObj, (data, key) => {
      const retObj = {
        name: key,
        colorMap: data.colorMap,
        issues: data.issues,
        id: data.id,
        actionsOnly: data.actionsOnly,
        issuesOnly: data.issuesOnly,
        missedOnly: data.missedOnly,
        skippedOnly: data.skippedOnly,
        completed: data.completed
      };
      if (data.colorMap.length) { // if there is no data available to show in grid, skip those data
        retArr.push(retObj);
      } else if (data.missedOnly || data.skippedOnly) // okay, no color, but are there any missed or skipped then?
      {
        retArr.push(retObj);
      }

    });
    const sortedArr = sortBy(retArr, ['name'], ['desc']);
    return sortedArr;
  }

  /**
   * Returns the list of check types. It is filtered over provided time/team and returns check types that have responses in them.
   *  It discard check types with empty responses. It is used to build drop-down-menu for check-type-table page.
   */
  public async notEmptyCheckDropDown(range, teams, filteredResponses?) {
    const preFiltered = await this.getAllCheckTypeProgress(range, teams, filteredResponses);
    const mappedDropDown = map(preFiltered, data => ({
      id: +data.id,
      text: data.name
    }));

    // returnt the ID of checks
    return mappedDropDown;

  }

  /**
   *
   * @param innerCheckType to fetch inner check-type-details
   * Builds column element for check status grid for supervisor taskboard and inner individual check-typees for check-type-table page
   */
  public buildColumnCheckResult(innerCheckType?: boolean) {
    if (innerCheckType) {
      return cloneDeep([
        this.checkResultColumn.targetType,
        this.checkResultColumn.getProgressColumn(innerCheckType),
        this.checkResultColumn.issuesOnly,
        this.checkResultColumn.casOnly,
        this.checkResultColumn.missed,
        this.checkResultColumn.skipped
      ]);
    } else {
      return cloneDeep([
        this.checkResultColumn.checkType,
        this.checkResultColumn.getProgressColumn(innerCheckType),
        this.checkResultColumn.issuesOnly,
        this.checkResultColumn.casOnly,
        this.checkResultColumn.missed,
        this.checkResultColumn.skipped
      ]);
    }
  }

  public deploymentName(data) {
    const depObj = this.deployment.getDeployment(data.deploymentID);
    const retObj = {
      name: 'SHARED.Deployment_Name',
      data: ''
    };
    if (depObj) {
      retObj.data = depObj.title;
    }
    return retObj;
  }

  public checkName(data) {
    const check = this.checksService.getCheck(data.checkID);
    const retObj = {
      name: 'OTable.CHECK_NAME',
      data: ''
    };
    if (check) {
      let groupName = '';
      if (data.isGrouped) {
        each(check.checkGroups, (checkGroup) => {
          if (checkGroup.groupID === data.checkGroup) {
            groupName = checkGroup.title;
          }
        });
      }
      if (groupName) {
        retObj.data = check.title + ': ' + groupName;
      } else {
        retObj.data = check.title;
      }
    }
    return retObj;
  }

  public type(data) {
    // const checkObj = this.checksService.getCheck(data.checkID);
    const checkTypeID = data.checkType || data;
    const checkType = this.settingsService.getItem('checkType', checkTypeID);
    const retObj = {
      name: 'SHARED.TYPE',
      data: ''
    };
    if (checkType) {
      retObj.data = checkType.messageTitle;
    } else {
      retObj.data = '';
    }
    return retObj;
  }

  public assignedTo(data) {
    const nameStr = [];
    const retObj: any = {
      name: 'SHARED.ASSIGNED_TO'
    };
    if (data.assignedTo.users) {
      if (data.assignedTo.users.length === 1) {
        const user = data.assignedTo.users[0];
        if (user > 0) {
          retObj.message = true;
          retObj.data = this.accountsService.getCompactName(user),
            retObj.userID = user,
            retObj.userImg = this.accountsService.avatar(user, 64, false, true),
            retObj.fullname = this.accountsService.fullname(user);
        } else if (user === -1) {
          const splitSig = split(data.targetSignature, ':');
          const name = this.accountsService.getCompactName(+splitSig[1]);
          retObj.message = true;
          retObj.data = name,
            retObj.userID = +splitSig[1],
            retObj.userImg = this.accountsService.avatar(+splitSig[1], 64, false, true),
            retObj.fullname = this.accountsService.fullname(+splitSig[1]);
        } else if (user === -2) {
          const splitSig = split(data.targetSignature, ':');
          const userObj = this.accountsService.getAccount(+splitSig[1]);
          if (userObj.supervisorID) {
            const supName = this.accountsService.getCompactName(userObj.supervisorID);
            retObj.message = true;
            retObj.data = supName,
              retObj.userID = +splitSig[1],
              retObj.userImg = this.accountsService.avatar(+splitSig[1], 64, false, true),
              retObj.fullname = this.accountsService.fullname(userObj.supervisorID);
          }
        }
      } else {
        each(data.assignedTo.users, user => {
          if (user > 0) {
            const finalD = {
              data: this.accountsService.getCompactName(user),
              userID: user,
              userImg: this.accountsService.avatar(user, 64, false, true),
              fullname: this.accountsService.fullname(user)
            };
            nameStr.push(finalD);

          } else if (user === -1) {
            const splitSig = split(data.targetSignature, ':');
            const name = this.accountsService.getCompactName(+splitSig[1]);
            const finalD = {
              data: name,
              userID: +splitSig[1],
              userImg: this.accountsService.avatar(+splitSig[1], 64, false, true),
              fullname: this.accountsService.fullname(+splitSig[1])
            };
            nameStr.push(finalD);
          } else if (user === -2) {
            const splitSig = split(data.targetSignature, ':');
            const userObj = this.accountsService.getAccount(+splitSig[1]);
            if (userObj.supervisorID) {
              const supName = this.accountsService.getCompactName(userObj.supervisorID);
              const finalD = {
                data: supName,
                userID: +splitSig[1],
                userImg: this.accountsService.avatar(+splitSig[1], 64, false, true),
                fullname: this.accountsService.fullname(userObj.supervisorID)
              };
              nameStr.push(finalD);
            }
          }
        });
        retObj.data = nameStr;
        retObj.recipients = true;
      }
    } else {
      retObj.data = this.translate.instant('SHARED.Unassigned');
    }
    return retObj;
  }

  private claimedBy(data) {
    const result = {
      name: 'SHARED.Claimed_By',
      message: true,
      userID: data.ownerID,
      data: this.accountsService.getCompactName(data.owner)
    }

    if (!data.owner) {
      result.message = false;
      result.data = this.translate.instant('SHARED.Unassigned');
    }

    return result;
  }

  private expiringTime(data) {
    return {
      name: 'MGMT_DETAILS.Remind_Expiring',
      data: moment(data.expiresTime * 1000).fromNow(true)
    }
  }

  public targetFull(data) {
    const retObj = {
      name: 'OTable.TARGET',
      data: data.targetSignature
    };
    return retObj;
  }

  public targetFullColumn(data) {
    const retObj = {
      name: 'OTable.TARGET',
      data: ''
    };
    let nameStr = '';
    if (data.targetSignature) {
      const splitSig = split(data.targetSignature, ':');
      // Translate these stuff
      if (splitSig[0] == 'loc') {
        nameStr = this.getTargetZoneName(+splitSig[1], +splitSig[2]);
      } else if (splitSig[0] == 'asset') {
        const targObj = this.assetsService.getAssetById(+splitSig[1]);
        nameStr = 'Asset' + ': ' + targObj[0].name;
      } else if (splitSig[0] == 'worker') {
        nameStr = 'Worker' + ': ' + this.accountsService.getCompactName(+splitSig[1]);
      }
      retObj.data = nameStr;
    }
    return retObj;
  }

  public resolvedDuration(data) {
    const retObj = {
      name: 'OTable.DURATION',
      data: ''
    };
    const fixed = find(data, ['activity', 'resolved']);
    const created = find(data, ['activity', 'created']);
    let tDiff = 0;
    if (fixed && created) {
      tDiff = fixed.time - created.time;
      retObj.data = moment.duration(tDiff * 1000).humanize();
    }
    return retObj;
  }

  public target(data) {
    const splitSig = split(data.targetSignature, ':');
    const retObj = {
      name: 'OTable.TARGET',
      data: ''
    };
    // Translate these stuff
    if (splitSig[0] == 'loc') {
      retObj.data = this.getTargetZoneName(+splitSig[1], +splitSig[2]);
    } else if (splitSig[0] == 'asset') {
      const targObj = this.assetsService.getAssetById(+splitSig[1]);
      retObj.data = targObj[0].name;
    } else if (splitSig[0] == 'worker') {
      retObj.data = this.accountsService.getCompactName(+splitSig[1]);
    }
    return retObj;
  }

  public async prepareUnableMissed(status, currentFilter, checkID, teamFilter, targetSignature, targetResponses?: Response[]) {
    const table = await this.checkResponseService.getCheckResponseByCheckIDStatus(checkID, currentFilter, teamFilter, targetSignature, status, targetResponses);
    this.issueCaTableData = table;
    return table;
  }

  public async prepareIssueCaTable(id: string, responses: Response[]) {
    const mappedAnswers = [];
    each(responses, (respObj: any) => {
      if (!isEmpty(respObj.answers)) {
        each(respObj.answers, (data, key) => {
          data.checkID = respObj.checkID;
          data.owner = respObj.owner;
          data.targetSignature = respObj.targetSignature;
          data.checkType = respObj.checkType;
          data.deploymentID = respObj.deploymentID;
          data.checkGroup = respObj.checkGroup;
          data.isGrouped = respObj.isGrouped;
          data.responseID = respObj.responseID;
          mappedAnswers.push(data);
        });
      }
    });
    const retData = filter(mappedAnswers, (respObj: any) => {
      if (id === 'logged') {
        if (respObj.flaggedIssue) {
          return true;
        }
      } else {
        if (respObj.observationID) {
          // need to inject some info from observation, then do here
          // assiging real ownerID,
          const obs = this.observationService.observations.data[respObj.observationID];
          if (obs) {
            respObj.owner = obs.ownerID;
            respObj.state = obs.state;
            respObj.history = obs.history;
          }
          return true;
        }
      }
    });
    this.issueCaTableData = retData;
    return retData;
  }

  public getIssueByIssueId(id) {
    return find(this.issueCaTableData, {flaggedIssue: id});
  }

  public getIssueByCaId(id) {
    return find(this.issueCaTableData, {observationID: id});
  }

  public translateData(data: any) {
    if (!data) {
      return '';
    }
    const currentLanguage: string = this.translate.getDefaultLang();
    if (currentLanguage) {
      const translatedObject: any = find(data.translations, {language: currentLanguage});
      if (translatedObject) {
        data.title = translatedObject.value;
      }
    }
    if (data.title) {
      return data.title;
    } else {
      return data.label;
    }
  }

  public getImages(response) {
    const retArry: any = [];
    each(response.images, img => {
      const imgObj = {
        imgSrc: this.objectService.URI(img, true),
        class: `obs-detail-image ${response.result === 'positive' ? 'green-border' : 'red-border'}`,
        time: this.utils.dateTimeFormat(response.created, '', true),
        name: this.accountsService.getCompactName(response.creator)
      };
      retArry.push(imgObj);
    });
    return retArry;
  }

  public getNotes(response) {
    let retArry: any = [];
    each(response.notes, note => {
      if (note.type === 'audio') {
        const noteObj = {
          data: this.objectService.URI(note.value, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.createdAt),
          name: this.accountsService.getCompactName(note.userID),
          unixTime: response.startTime,
          userImg: this.accountsService.avatar(note.userID, 64, false, true)
        };
        retArry.push(noteObj);
      } else if (note.type === 'text') {
        const noteObj = {
          data: note.value,
          type: 'text',
          time: this.utils.dateTimeFormat(note.createdAt),
          name: this.accountsService.getCompactName(note.userID),
          unixTime: response.startTime,
          userImg: this.accountsService.avatar(note.userID, 64, false, true)
        };
        retArry.push(noteObj);
      }
    });
    retArry = sortBy(retArry, 'unixTime').reverse();

    return retArry;
  }

  public buildTargetMenu() {
    const tgtMenu = [];
    each(this.checkResultTableTargetItems, tgt => {
      const e: any = {
        targetSignature: tgt
      };
      const tObj = this.target(e);
      tgtMenu.push({id: tgt, text: tObj.data});
    });
    return tgtMenu;
  }

  public getTargetZoneName(locId: number, zoneId: number): string {
    const zone = this.userService.findAnyZoneNoLoc(zoneId);

    const location = this.userService.getLocation(locId) || {
      name: this.translate.instant('SHARED.Unknown')
    };

    const locationTitle = this.translate.instant('SHARED.Location');
    const zoneTitle = this.translate.instant('SHARED.Zone');

    // return `${locationTitle}: ${location.name}; ${zoneTitle}: ${zone.name}`;
    return `${location.name}; ${zoneTitle}: ${zone.name}`;
  }

  private figureOutColorPercentage(checkObjArry, key) {
    // get the length
    const retArry: any = {};
    let length = 0;
    let flaggedIssues = 0;
    let issuesOnly = 0;
    let missedOnly = 0;
    let skippedOnly = 0;
    let completed = 0;
    const actionsOnly = [];
    each(checkObjArry, (obj: any) => {

      // const retColor = this.statusColorMap(obj.status);
      // if (retColor) {
      //   length++;
      //   if (retArry[retColor]) {
      //     retArry[retColor] += 1;
      //   } else {
      //     retArry[retColor] = 1;
      //   }
      // }


      // get counts of missed and skipped checks
      if (obj.status === 'skipped') {
        skippedOnly++;
      }

      if (obj.status === 'missed') {
        missedOnly++;
      }

      if (includes(['groupsCompleted', 'complete'], obj.status)) {
        completed++;
      }

      each(obj.answers, ans => {
        const ansColor = this.answerColorMap(ans);
        if (ansColor) {
          length++;
          if (retArry[ansColor]) {
            retArry[ansColor] += 1;
          } else {
            retArry[ansColor] = 1;
          }
        }
        if (ans.flaggedIssue || ans.observationID) {
          flaggedIssues++;
        }
        if (ans.flaggedIssue) {
          issuesOnly++;
        }
        if (ans.observationID) {
          actionsOnly.push(ans.observationID);
        }
      });
    });

    const colorMap = map(retArry, (data, key) => {
      let percentage = +(data / length * 100);
      let colLength = +(data / length * 12).toFixed(0);
      if (percentage && !colLength) {
        colLength = 1; // for cases where the number is very low, but still needs to be in chart
      }
      if (!percentage) {
        percentage = 1;
      }
      return {
        data: percentage,
        color: key,
        length: data,
        colLength
      };
    });

    // now we need to make sure the sum of percentage is not more than 12 for nice UI view.
    const totalSumPerct = sumBy(colorMap, obj => obj.colLength);
    if (totalSumPerct > 12) {
      const greaterBy = totalSumPerct - 12;
      const maxElem = maxBy(colorMap, obj => obj.colLength);
      maxElem.colLength = maxElem.colLength - greaterBy;
    }


    const sortedMap = orderBy(colorMap, [cData => this.colorOrder.indexOf(cData.color), 'data']);

    return {
      colorMap: sortedMap,
      issues: flaggedIssues,
      id: key,
      issuesOnly,
      actionsOnly,
      skippedOnly,
      missedOnly,
      completed
    };
  }

  private statusColorMap(status) {
    if (status === 'skipped') {
      return 'light-shade';
    }
    if (includes(['notCompleted', 'missed', 'canceled'], status)) {
      return 'dark-shade';
    }
    // if (includes(['pending', 'available'], status)) {
    //   return "white";
    // }
  }

  private answerColorMap(ans) {
    // if answer has observation id or flagID red
    if (ans.result === 'negative') {
      return 'danger';
    }
    if (ans.result === 'positive') {
      return 'success';
    }
  }

  private typeMap(resObj) {
    let retStr = '';
    switch (resObj.status) {
      case 'available': {
        retStr = 'available';
        break;
      }
      case 'skipped': {
        retStr = 'unable';
        break;
      }
      case 'missed': {
        retStr = 'missed';
        break;
      }
      case 'complete': {
        retStr = 'complete';
        break;
      }
      case 'validation': {
        retStr = 'validation';
        break;
      }
      case 'incomplete': {
        retStr = 'incomplete';
        break;
      }
      case 'notCompleted': {
        retStr = 'notCompleted';
        break;
      }
      case 'onDemand': {
        retStr = 'onDemand';
        break;
      }
      case 'inProgress': {
        retStr = 'inProgress';
        break;
      }
    }
    return retStr;
  }

  private submitted(data) {
    const retObj = {
      name: 'SHARED.SUBMITTED',
      data: ''
    };
    if (data.startTime) {
      retObj.data = this.utils.dateTimeFormat(data.startTime, null, true);
    }
    return retObj;
  }

  private completed(data) {
    const retObj = {
      name: 'SHARED.Completed',
      data: ''
    };
    if (data.completionTime) {
      retObj.data = this.utils.dateTimeFormat(data.completionTime, null, true);
    }
    return retObj;
  }

  private submittedBy(data) {
    const retObj: any = {
      name: 'SHARED.SUBMITTED_BY',
      data: ''
    };
    if (data.responder) {
      // this is answer
      retObj.data = this.accountsService.fullname(data.responder);
      if (data.responder) {
        retObj.message = true;
        retObj.userID = data.responder;
        retObj.userImg = this.accountsService.avatar(data.responder, 64, false, true);
      }
    } else {
      retObj.data = this.accountsService.fullname(data.owner);
      if (data.owner) {
        retObj.message = true;
        retObj.userID = data.owner;
        retObj.userImg = this.accountsService.avatar(data.owner, 64, false, true);
      }
    }
    return retObj;
  }

  private completedBy(data) {
    return {
      name: 'SHARED.Completed_By',
      data: this.accountsService.fullname(data.owner),
      message: true,
      userID: data.owner,
      userImg: this.accountsService.avatar(data.owner, 64, false, true)
    };
  }

  private owner(data) {
    const account = this.accountsService.getCompactName(data.owner);
    const retObj = {
      name: 'SHARED.OWNER',
      data: ''
    };
    if (account) {
      retObj.data = account;
    }
    return retObj;
  }
}
