import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { BaseService } from './../abstract-base-service/abstract-base-service.service';
import { forEach, includes, intersection, isBoolean, isNumber, keys, map, without } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class GearService extends BaseService {

  public filterObject: any = {};

  gear: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private userDataService: UserdataService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.gear.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.gear.lastRequest}`);
      return Promise.resolve(this.gear.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getGear',
          includeDisabled: 1,
          lastHash: this.gear.lastHash,
          lastRequest: this.gear.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.gear.lastHash = data.result.datahash;
          }
          resolve(this.gear.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public getGearData(params) {
    const when = Date.now();
    return this.commsService.sendMessage({
      cmd: 'getGearData',
      sendTime: when,
      ...params
    }, false, false).then((data) => {
      let result = {};
      if (data && data.reqStatus === 'OK') {
        result = data.result;
      }
      return result;
    });
  }

  public clearCache() {
    this.gear.lastHash = null;
    this.gear.lastRequest = null;
    this.gear.data = null;
  }

  public updateCache(data) {
    this.gear.lastRequest = data.result.timestamp;
    this.gear.data = data.result.gear;
    this.events.publish('ccs:gearUpdated');
  }

  public handleAddGear(fData) {
    fData.cmd = 'addGear';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateGear(fData) {
    if (!fData.hasOwnProperty('usesBeacon')) {
      fData.usesBeacon = 0;
    }
    if (fData.gearImageID) {
      fData.imageID = fData.gearImageID;
    }
    fData.cmd = 'updateGear';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteGear(fData) {
    fData.cmd = 'deleteGear';
    return this.handleRequest(fData);
  }

  public getGearByID(theID) {
    let ret = null;
    $.each(this.gear.data, (i, gear) => {
      if (gear.gearID === parseInt(theID)) {
        ret = gear;
        return false;
      }
    });
    return ret;
  }

  public getGearByNFC(signature: string) {
    let ret = null;
    if (signature.match(/.*:.*:/)) {
      // there is a serial number - strip it
      signature = signature.replace(/:[^:]*$/, '');
    }
    // now loop over all the gear we know about - is there one like this
    $.each(this.gear.data, (i, ppeType) => {
      if (ppeType.corvexID === signature) {
        ret = ppeType;
        return false;
      }
    });
    return ret;
  }

  public gearNameFromType(type) {
    let gRef = null;
    $.each(this.gear.data, (i, ref) => {
      if (ref.gearID === type) {
        gRef = ref;
        return false;
      }
    });

    if (gRef) {
      return this.gearName(gRef);
    } else {
      return `Unknown (" ${type} ")`;
    }
  }

  public gearName(gearItem) {
    let ret = gearItem.name || '';
    if (ret === '') {
      ret = gearItem.description || '';
    }
    if (gearItem.subtype) {
      ret += ' - ' + gearItem.subtype;
    }
    return ret;
  }

  /**
   * forget - remove a piece of gear from the cache
   *
   * @param theID - the id to forget
   *
   * Finds the bucket in which the gear exists.  If there is one, deletes the
   * item from the various lists and then from the bucket and the master list.
   *
   */
  public forget(beaconID: any): void {
    this.userDataService.gear = <any[]>without(this.userDataService.gear, beaconID);
  }

  public checkGear(ref, opts) {
    let matched = true;

    const type: string = this.getProperty(ref, 'type');
    const name: string = this.getProperty(ref, 'name');
    const locsIDs: number[] = map(keys(this.getProperty(ref, 'locations')), (id) => +id);
    const groupsIDs: number[] = map(keys(this.getProperty(ref, 'groups')), (id) => +id);

    if (!opts.includingAll) {
      matched = false;
      const extraCols = this.getProperty(opts, 'extraColumns');
      forEach(extraCols, (col) => {
        col = col.replace('average_', '');
        if (this.getProperty(ref, col) && !matched) {
          matched = true;
        }
      });
    }

    if (opts.gearTypes && opts.gearTypes.length) {
      if (!includes(opts.gearTypes, type)) {
        matched = false;
      }
    }

    if (opts.gearNames && opts.gearNames.length) {
      if (!includes(opts.gearNames, ref.gearID)) {
        matched = false;
      }
    }

    if (opts.locations && opts.locations.length) {
      if (!intersection(opts.locations, locsIDs).length) {
        matched = false;
      }
    }

    if (opts.groups && opts.groups.length) {
      if (!intersection(opts.groups, groupsIDs).length) {
        matched = false;
      }
    }

    return matched;
  }

  public getProperty(obsref, prop) {
    const newprop = prop + 'Override';

    if (obsref.hasOwnProperty(newprop) && (obsref[newprop] || isNumber(obsref[newprop]) || isBoolean(obsref[newprop]))) {
      return obsref[newprop];
    } else {
      return obsref[prop];
    }
  }

}
