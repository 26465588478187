<div [appAccountCanViewExcept]="['viewer']" class="adjustment-grid">
  <div class="ui-block-a">
    <div class="ui-bar">
      <div class="table-header">
        <div *ngIf="closingData.type !== 'pi'" class="headline-wrap">
          <span class="headline-small">{{ "SHARED.Category" | translate }}   </span>
          <span (click)="closingCategory()" class="body-link"
                id="editCategoriesList">{{ "SHARED.Edit" | translate }}</span>
        </div>
        <ul class="body-copy">
          <li *ngFor="let cat of closingData.categories" class="li-styled">{{cat}}</li>
        </ul>
        <div *ngIf="closingData.savBool" class="closing-block">
          <div class="headline-small">{{ "SHARED.CLOSE_POPUP_headline_small" | translate }}</div>
          <p *ngIf="closingData.saving"
             class="closing-block-text body-copy-bold">{{closingData.saving | currency: "USD" }}</p>
          <p *ngIf="!closingData.saving" class="closing-block-text body-copy-bold">--</p>
        </div>
        <div class="closing-block">
          <div class="headline-wrap">
            <span class="headline-small">{{ "SHARED.Closed_By" | translate }} </span>
            <span (click)="scroll('target')" class="body-link pointer" data-scroll="condition">
              {{ "SHARED.View_History" | translate }}
            </span>
          </div>
          <div class="closing-block-user" id="closedBy">
            <img [src]="closingData.user.userImg" alt="User" class="user-img">
            <span class="body-copy-bold">{{closingData.user.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-block-c">
    <div class="ui-bar">
      <div class="table-header">
      </div>
    </div>
  </div>
  <div class="ui-block-b">
    <div class="ui-bar">
      <div class="table-header">
        <div class="headline-wrap-with-button">
          <div class="headline-small">{{ "SHARED.Closing_Notes" | translate }}</div>
          <ion-button (click)="closingNote()" class="page-button"
                      color="secondary">{{ "SHARED.Add_Note" | translate }}</ion-button>
        </div>
        <div id="closingNoteDetails">
          <div *ngFor="let note of closingData.notes" class="closing-notes">
            <div><span class="body-copy-bold">{{note.name}}</span><span class="detail"> | {{note.time}}</span>
            </div>
            <p [innerHTML]="note.data | markdown | async" class="body-copy"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <hr class="hr-dashed" id="secondhr">
</div>
