import { Injectable } from '@angular/core';

import { lowerCase, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class Select2Service {

  constructor() {
  }

  public init(): void {
    $.fn.select2.defaults.set('sorter', (data) => sortBy(data, (sortItem: any) => {
      if (sortItem.children) {
        sortItem.children = sortBy(sortItem.children, [child => lowerCase(child.text)]);
      }
      return lowerCase(sortItem.text);
    }));
  }
}
