import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'selectLangPipe'
})
export class SelectLangPipe implements PipeTransform {
  constructor(protected translate: TranslateService) {

  }

  transform(viewRangeOptions) {
    const options = cloneDeep(viewRangeOptions);
    for (const item of options) {
      item.text = this.translate.instant(item.text);
    }
    return options;
  }
}
