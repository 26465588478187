import { ActivatedRoute } from '@angular/router';
import { Injector } from '@angular/core';

import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { CollectionsService, ObservationService } from '@services';
import { Events } from '@services/events/events.service';
import { keys } from 'lodash';



export class AbstractObservationDetailPage {

  public footerData: any;
  public oid: any;
  public backNavigateUrl: string;

  protected collectionsService: CollectionsService = this.injector.get(CollectionsService);
  protected events: Events = this.injector.get(Events);
  protected observationService: ObservationService = this.injector.get(ObservationService);
  protected observationTableService: ObservationTableService = this.injector.get(ObservationTableService);
  protected activatedRoute: ActivatedRoute = this.injector.get(ActivatedRoute);

  constructor(protected injector: Injector) {
  }

  protected async updateFooterData() {
    const collectionId = this.activatedRoute.snapshot.queryParamMap.get('collectionId') || 0;
    const collectionItemType = this.activatedRoute.snapshot.queryParamMap.get('collectionItemType');

    if (collectionId || collectionItemType) {
      this.footerData = await this.collectionsService.getFooterData(this.oid, +collectionId, collectionItemType);
      this.backNavigateUrl = this.footerData?.nav;
    } else {
      this.footerData = this.observationTableService.getFooterData(this.oid);
    }
  }

  protected checkFooterData(): void {
    this.updateFooterData();

    if (keys(this.observationService.observations.data).length === 1) {
      const listener = () => {
        this.updateFooterData();
        this.events.unsubscribe('ccs:observationUpdate', listener);
      };
      this.events.subscribe('ccs:observationUpdate', listener);
    }
  }

}
