import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UtilsService } from '@services/utils/utils.service';
import { Events } from '@services/events/events.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

import { TranslateService } from '@ngx-translate/core';
import { each, find, has, includes, intersection, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends BaseService {

  public filterObject: any = {};

  teams: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.teams.lastRequest) {
      this.logger.log(`local team cache already up to date: ${updated}, ${this.teams.lastRequest}`);
      return Promise.resolve(this.teams.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getGroups',
          includeDisabled: 1,
          lastRequest: this.teams.lastRequest,
          lastHash: this.teams.lastHash,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.teams.lastHash = data.result.datahash;
          }
          resolve(this.teams.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.teams.lastHash = null;
    this.teams.lastRequest = null;
    this.teams.data = null;
  }

  public updateCache(data) {
    this.teams.lastRequest = data.result.timestamp;
    this.teams.data = data.result.groups;
    this.events.publish('ccs:teamsUpdate');
  }

  public get(teamID: number, active = true): any {
    return find(this.teams.data, (team) => {
      const isActive = active && team.disabledAt === 0 || !active;
      return team.groupID === teamID && isActive;
    }) || null;
  }

  public teamNameByID(teamID) {
    let ret = null;
    if (+teamID === 0) {
      return this.translate.instant('SHARED.no_team');
    } else {
      each(this.teams.data, (team) => {
        if (team.groupID === +teamID) {
          ret = team;
          return false;
        }
      });
      return this.teamName(ret);
    }
  }

  public teamName(teamItem) {
    if (teamItem) {
      return teamItem.disabledAt ? teamItem.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : teamItem.name;
    } else {
      return this.translate.instant('SHARED.Unassigned');
    }
  }

  handleAddTeam(fData) {
    fData.cmd = 'addGroup';
    fData.members = JSON.stringify(this.utilsService.makeArray(fData.members, parseInt));
    if (fData.hasOwnProperty('locations')) {
      fData.locations = JSON.stringify(this.utilsService.makeArray(fData.locations, parseInt));
    } else {
      fData.locations = '[]';
    }
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleUpdateTeam(fData) {
    fData.cmd = 'updateGroup';
    if (fData.hasOwnProperty('locations')) {
      fData.locations = JSON.stringify(this.utilsService.makeArray(fData.locations, parseInt));
    } else {
      fData.locations = '[]';
    }
    if (has(fData, 'members')) {
      fData.members = JSON.stringify(this.utilsService.makeArray(fData.members, parseInt));
    }
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteTeam(fData) {
    fData.cmd = 'deleteGroup';
    return this.handleRequest(fData);
  }

  /**
   *
   * @param filters array of functions that are called with the user recored as a parameter.  The function returns true if the item should be filtered out of the user list.
   */
  public filterTeamList(filters: any) {
    const r = [];
    each(this.teams.data, (ref) => {
      let matched = true;
      if (Array.isArray(filters)) {
        each(filters, (test) => {
          if (test(ref)) {
            matched = false;
          }
        });
      }
      if (matched) {
        r.push(ref);
      }
    });
    return r;
  }

  public getTeamList(locations: number[] = [], sort: boolean = false) {
    let r = this.filterTeamList([
      (team) => {
        // if there are no locations in the list, then this user has access to all locations
        if (team.locations.length === 0 || locations.length === 0) {
          return false;
        }
        const overlap = intersection(locations, team.locations);
        return !overlap.length;
      }
    ]);
    if (sort) {
      r = sortBy(r, ['name']);
    }
    return r;
  }

  public buildTeamMenu(locations: number[] = [], includeDisabled: boolean = true, ids: number[] = []) {
    const teamArray = [];
    const theData = locations.length ? this.getTeamList(locations) : this.teams.data;

    each(theData, data => {
      if (!includeDisabled && data.disabledAt) {
        // skip this one
      } else {
        if (ids && ids.length) {
          if (includes(ids, data.groupID)) {
            teamArray.push({
              id: data.groupID,
              text: data.disabledAt ? data.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.name
            });
          }
        } else {
          teamArray.push({
            id: data.groupID,
            text: data.disabledAt ? data.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.name
          });
        }
      }
    });

    return {
      name: 'Team',
      dropDownOptions: teamArray,
    };
  }

  public byLocation(locList: Array<number>, includeDisabled: boolean = false): any {
    const ret = {} as any;
    each(locList, loc => {
      ret[loc] = {
        locationID: loc,
        teams: []
      };
    });

    each(this.teams.data, team => {
      if ((includeDisabled || team.disabledAt === 0) && has(team, 'locations')) {
        if (team.locations.length === 0) {
          each(ret, (ref: any) => {
            ref.teams.push(team.teamID);
          });
        } else {
          each(team.locations, locID => {
            if (has(ret, locID)) {
              ret[locID].teams.push(team.teamID);
            }
          });
        }
      }
    });

    return ret;
  }


  public getTeamByName(name: string) {
    return find(this.teams.data, ['name', name]) || null;
  }

}
