import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { Events } from '@services/events/events.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

import { TranslateService } from '@ngx-translate/core';
import { each, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class TierService extends BaseService {

  tier: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };
  public userLevel: any = {};

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private subscriberService: SubscriberService,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public async getTiers() {
    return await this.tier.data;
  }

  public isLoyaltyActive() {
    return (this.subscriberService.getFeature('loyalty_switch') === 1);
  }

  public async calcTierProp(pointVal: number) {
    const retObj: any = {};
    let userLevel: any = {};
    let nextLevel: any = {};
    let histTiers: any = {};
    let imgSrc = '';
    let finalLevel: any;
    if (this.tier.data.length === 0) {
      await this.refresh();
    }
    // order tier by point values ascending
    const localTier = sortBy(this.tier.data, 'threshold');
    each(localTier, (tdata, i) => {
      if (tdata.threshold >= pointVal) {

        if (i - 1 >= 0) {
          userLevel = localTier[i - 1]; // figures out level

          /**
           If user level reaches the max point in a tier, user has actually achieved next tier
           **/
          if (tdata.threshold === pointVal) {
            if (localTier[i]) {
              userLevel = localTier[i];
            }
          }
          imgSrc = this.resolveImg(userLevel.objectID);
          histTiers = localTier.slice(0, i);
          userLevel.starterFlag = false;
        } else {
          if (i === 0 && tdata.threshold === pointVal) {
            userLevel = localTier[i];
            imgSrc = this.resolveImg(userLevel.objectID);
            histTiers = [];
            userLevel.starterFlag = false;
          } else {
            userLevel = {
              threshold: 0,
              name: this.translate.instant('MGMT_DETAILS.Powering_Up'),
              starterFlag: true,
            };
            imgSrc = 'assets/images/loyaltyTiers/tier0.svg';
            histTiers = [];
          }
        }

        if (tdata.threshold === pointVal) {
          if (localTier[i + 1]) {
            nextLevel = localTier[i + 1];
          } else {
            nextLevel = tdata;
          }
        } else {
          nextLevel = tdata;
        }
        return false;
      }
      finalLevel = tdata;
    });
    if (!imgSrc && !userLevel.starterFlag) {
      // we've reached max level
      imgSrc = this.resolveImg(finalLevel.objectID);
      userLevel = finalLevel;
      nextLevel = finalLevel;
      histTiers = localTier;
    }

    // historic tiers, resolve img
    each(histTiers, elem => {
      elem.imgSrc = this.resolveImg(elem.objectID);
    });


    retObj.imgSrc = imgSrc;
    retObj.currentLevel = userLevel;
    retObj.nextLevel = nextLevel;
    retObj.histTiers = histTiers;
    return retObj;
  }

  public resolveImg(oid) {
    let imgSrc = '';
    if (+oid <= 12) {
      imgSrc = `assets/images/loyaltyTiers/tier${oid}.svg`;
    } else {
      imgSrc = this.commsService.objectURI(+oid, false);
    }
    return imgSrc;
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.tier.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.tier.lastRequest}`);
      return Promise.resolve(this.tier.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getTiers',
          includeDisabled: 0,
          lastRequest: this.tier.lastRequest,
          lastHash: this.tier.lastHash,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.tier.lastHash = data.result.datahash;
          }
          resolve(this.tier.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.tier.lastHash = null;
    this.tier.lastRequest = null;
    this.tier.data = null;
  }

  public updateCache(data) {
    this.tier.lastRequest = data.result.timestamp;
    this.tier.data = data.result.tiers;
    this.events.publish('ccs:tierUpdate');
  }

  public handleAddTier(fData) {
    fData.cmd = 'addTier';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateTier(fData) {
    if (fData.tierImageID) {
      fData.imageID = fData.tierImageID;
    }
    fData.cmd = 'updateTier';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteTier(fData) {
    fData.cmd = 'deleteTier';
    return this.handleRequest(fData);
  }

  // public refreshHandler() {
  //   return this.refresh();
  // }

  public getTierByID(theID) {
    let ret = null;
    $.each(this.tier.data, (i, tier) => {
      if (tier.tierID === Number(theID)) {
        ret = tier;
        return false;
      }
    });
    return ret;
  }

  public tierNameFromType(type) {
    let gRef = null;
    $.each(this.tier.data, (i, ref) => {
      if (ref.tierID === type) {
        gRef = ref;
        return false;
      }
    });

    if (gRef) {
      return this.tierName(gRef);
    } else {
      return `Unknown (" ${type} ")`;
    }
  }

  public tierName(tierItem) {
    let ret = tierItem.name || '';
    if (ret === '') {
      ret = tierItem.description || '';
    }
    if (tierItem.subtype) {
      ret += ' - ' + tierItem.subtype;
    }
    return ret;
  }

}
