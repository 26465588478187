import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
  AccountsService,
  AssetsService,
  FoldersDataService,
  PermissionsService,
  SettingsService,
  TableService,
  TeamsService,
  UserService
} from '@services';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';

import { TranslateService } from '@ngx-translate/core';
import { filter, find, get, join, map } from 'lodash';

@Component({
  selector: 'app-deployment-table',
  templateUrl: './deployment-table.component.html',
  styleUrls: ['./deployment-table.component.scss'],
})
export class DeploymentTableComponent implements OnInit {

  @ViewChild('deploymentTable') deploymentTable: ElementRef<HTMLTableElement>;

  @Input() deployments: any;
  @Input() type: string;
  @Input() checkId: string;

  @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();

  public tableId: string;
  public title: string;
  public isTableRendered: boolean;
  private titleMap = {
    daily: 'SHARED.Daily',
    weekly: 'SHARED.Weekly',
    monthly: 'SHARED.Monthly',
    quarterly: 'SHARED.Quarterly',
    yearly: 'SHARED.Yearly',
    onDemand: 'MGMT_DETAILS.On_Demand',
    once: 'SHARED.Once'
  };
  private isTableReady: boolean;
  private tableAttributes: any = {
    checkbox: true,
    disableCheckboxSelectAll: true,
    rowprop: 'data-mid',
    rowvaluefrom: 'deploymentID',
    columns: [
      {
        headerClass: 'table-header',
        id: 'title',
        title: this.translate.instant('MGMT_DETAILS.Title')
      },
      {
        headerClass: 'table-header',
        id: 'target',
        title: this.translate.instant('SHARED.Target'),
        width: '250px',
        func: (target, row) => {
          const separateString = `<br><hr>`;
          const targetTitleMap = {
            assets: this.translate.instant('SHARED.ASSETS'),
            zones: this.translate.instant('SHARED.Zones'),
            workers: this.translate.instant('SHARED.Workers')
          };
          const allTargetTitleMap = {
            assets: `
              ${this.translate.instant('SHARED.All_Locations')} ${separateString}
              ${this.translate.instant('SHARED.All_Zones')} ${separateString}
              ${this.translate.instant('MGMT_LIST.All_Asset_Types')} ${separateString}
              ${this.translate.instant('MGMT_LIST.All_Assets')} ${separateString}
            `,
            workers: `
              ${this.translate.instant('SHARED.All_Locations')} ${separateString}
              ${this.translate.instant('SHARED.All_Teams')} ${separateString}
              ${this.translate.instant('SHARED.All_Permissions')} ${separateString}
              ${this.translate.instant('MGMT_LIST.All_Workers')} ${separateString}
            `
          };
          const targetType = `${this.translate.instant('SHARED.Type')}: ${targetTitleMap[target.targetType]} <hr>`;
          let separate = false;
          let targetStr = '';

          if (target.locations && target.locations.length) {
            const locations: string[] = [];
            target.locations.forEach((locID) => {
              locations.push(this.userService.getLocation(parseInt(locID)).name);
            });
            if (target.locations.length === 1) {
              targetStr += this.translate.instant('SHARED.Location');
            } else {
              targetStr += this.translate.instant('SHARED.Locations');
            }
            targetStr += ': ' + map(locations).join(', ');
            separate = true;
          } else if (0) {
            targetStr += separate ? '<hr>' : '';
            targetStr += this.translate.instant('SHARED.Locations') + ': ' + this.translate.instant('SHARED.Any_Location');
            separate = true;
          }
          if (target.zones && target.zones.length) {
            const zones: string[] = [];
            target.zones.forEach((zoneID) => {
              const zoneStr = '' + zoneID;
              if (zoneStr.match(/:/)) {
                const z = zoneStr.split(/:/);
                const loc = this.userService.getLocation(+z[0]);
                const zone = this.userService.findAnyZone(loc, +z[1], false, true);
                zones.push(loc.name + ' - ' + zone.name);
              } else {
                if (zoneID == -1) {
                  zones.push(this.translate.instant('SHARED.All_Zones'));
                } else {
                  const zone = this.userService.findAnyZoneNoLoc(+zoneID, true);
                  const loc = this.userService.findLocation(zone.locationID);

                  if (zone && loc) {
                    zones.push(loc.name + ' - ' + zone.name);
                  }
                }
              }
            });
            targetStr += separate ? '<hr>' : '';
            if (target.zones.length === 1) {
              targetStr += this.translate.instant('SHARED.Zone') + ': ' + map(zones).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.Zones') + ': ' + map(zones).join(', ');
            }
            separate = true;
          }
          if (target.assetTypes && target.assetTypes.length) {
            const assetTypes: string[] = [];
            target.assetTypes.forEach((assetTypeID) => {
              assetTypeID = parseInt(assetTypeID);
              const assetType = find(this.settingsService.assetType.data, {messageID: assetTypeID});
              assetTypes.push(assetType.messageTitle);
            });
            targetStr += separate ? '<hr>' : '';
            if (target.assetTypes.length === 1) {
              targetStr += this.translate.instant('MGMT_LIST.Asset_Type') + ': ' + map(assetTypes).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.Asset_Types') + ': ' + map(assetTypes).join(', ');
            }
            separate = true;
          }
          if (target.assets && target.assets.length) {
            const assets: string[] = [];
            target.assets.forEach((assetID) => {
              assetID = parseInt(assetID);
              const asset = find(this.assetsService.getAssetById(assetID));
              assets.push(asset.name);
            });
            targetStr += separate ? '<hr>' : '';
            if (target.assets.length === 1) {
              targetStr += this.translate.instant('MGMT_LIST.Asset') + ': ' + map(assets).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.Assets_1') + ': ' + map(assets).join(', ');
            }
            separate = true;
          }
          if (target?.folders?.length) {
            const folders: string[] = filter(map(target.folders, (folderId: number) => {
              if (this.foldersDataService.isActive(folderId)) {
                return join(this.foldersDataService.getFolderPath(folderId, true), '/ ');
              }
            }));

            targetStr += separate ? '<hr>' : '';

            if (folders.length === 1) {
              targetStr += `${this.translate.instant('SHARED.Folder')}: <div class="folder-name">${folders[0]}</div>`;
            } else if (folders.length > 1) {
              targetStr += `${this.translate.instant('SHARED.Folders')}: ${map(folders, (folder) => `<div class="folder-name">${folder}</div>`).join(' ')}`;
            }
            separate = true;
          }
          if (target.teams && target.teams.length) {
            const teams: string[] = [];
            target.teams.forEach((teamID) => {
              teams.push(this.teamsService.teamNameByID(teamID));
            });
            targetStr += separate ? '<hr>' : '';
            if (target.teams.length === 1) {
              targetStr += this.translate.instant('SHARED.TEAM') + ': ' + map(teams).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.TEAMS') + ': ' + map(teams).join(', ');
            }
            separate = true;
          } else if (0) {
            targetStr += separate ? '<hr>' : '';
            targetStr += this.translate.instant('SHARED.TEAMS') + ': ' + this.translate.instant('SHARED.Any_Team');
            separate = true;
          }
          if (target.users && target.users.length) {
            const users: string[] = [];
            target.users.forEach((userID) => {
              if (userID === -1) {
                users.push(this.translate.instant('MGMT_DETAILS.Self_Check'));
              } else if (userID === -2) {
                users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
              } else {
                users.push(this.userService.getFullname(userID));
              }
            });
            targetStr += separate ? '<hr>' : '';
            if (users.length === 1) {
              targetStr += this.translate.instant('SHARED.USER') + ': ' + map(users).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.USERS') + ': ' + map(users).join(', ');
            }
            separate = true;
          } else if (0) {
            targetStr += separate ? '<hr>' : '';
            targetStr += this.translate.instant('SHARED.USERS') + ': ' + this.translate.instant('SHARED.Any_User');
            separate = true;
          }
          if (target.permissions && target.permissions.length) {
            const permissions: string[] = [];
            target.permissions.forEach((permID) => {
              const perm: any = find(this.permissions.permissions.data, {id: permID});
              permissions.push(this.translate.instant(perm.description));
            });
            targetStr += separate ? '<hr>' : '';
            if (target.permissions.length === 1) {
              targetStr += this.translate.instant('MGMT_LIST.Permission') + ': ' + map(permissions).join(', ');
            } else {
              targetStr += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + map(permissions).join(', ');
            }
          } else if (0) {
            targetStr += separate ? '<hr>' : '';
            targetStr += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + this.translate.instant('SHARED.Any_Permissions');
          }
          if (row.isLayerOf) {
            targetStr += separate ? '<hr>' : '';
            const deploy = this.deploymentService.getDeployment(row.isLayerOf);
            const deployName = get(deploy, 'title');
            targetStr += this.translate.instant('MGMT_LIST.Is_Layer_Of') + ': ' + deployName;
          }

          return `${targetType} ${targetStr || allTargetTitleMap[target.targetType]}`;
        }
      },
      {
        id: 'assignedTo',
        title: this.translate.instant('MGMT_LIST.ASSIGNMENT'),
        width: '250px',
        headerClass: 'table-header',
        func: (assignedTo, row) => {
          let assigned = '';
          let separate = false;

          if (get(assignedTo, 'teams.length')) {
            const teams: string[] = [];
            assignedTo.teams.forEach((teamID) => {
              if (teamID === -1) {
                teams.push(this.translate.instant('MGMT_DETAILS.Workers_Primary_Team'));
              } else {
                // is the team active?
                const tRef = this.teamsService.get(teamID);
                if (tRef && tRef?.disabledAt === 0) {
                  teams.push(this.teamsService.teamNameByID(teamID));
                }
              }
            });
            if (assignedTo.teams.length === 1) {
              assigned += this.translate.instant('SHARED.TEAM') + ': ' + map(teams).join(', ');
            } else {
              assigned += this.translate.instant('SHARED.TEAMS') + ': ' + map(teams).join(', ');
            }
            separate = true;
          } else if (assignedTo.teams || row.target.targetType === 'workers') {
            assigned += separate ? '<hr>' : '';
            assigned += this.translate.instant('SHARED.TEAMS') + ': ' + this.translate.instant('SHARED.Any_Team');
            separate = true;
          }

          if (assignedTo.users && assignedTo.users.length) {
            const users: string[] = [];
            assignedTo.users.forEach((userID) => {
              if (userID === -1) {
                users.push(this.translate.instant('MGMT_DETAILS.Self_Check'));
              } else if (userID === -2) {
                users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
              } else {
                const uRef = this.accountsService.getAccount(userID);
                if (uRef && uRef?.active && uRef?.disabledAt === 0) {
                  users.push(this.userService.getFullname(userID));
                }
              }
            });
            if (users.length) {
              assigned += separate ? '<hr>' : '';
              if (users.length === 1) {
                assigned += this.translate.instant('SHARED.USER') + ': ' + map(users).join(', ');
              } else {
                assigned += this.translate.instant('SHARED.USERS') + ': ' + map(users).join(', ');
              }
              separate = true;
            }
          } else if (0) {
            assigned += separate ? '<hr>' : '';
            assigned += this.translate.instant('SHARED.USERS') + ': ' + this.translate.instant('SHARED.Any_User');
            separate = true;
          }

          if (get(assignedTo, 'permissions.length')) {
            const permissions: string[] = [];
            assignedTo.permissions.forEach((permID) => {
              const perm: any = find(this.permissions.permissions.data, {id: permID});
              permissions.push(this.translate.instant(perm.description));
            });
            assigned += separate ? '<hr>' : '';
            if (assignedTo.permissions.length === 1) {
              assigned += this.translate.instant('MGMT_LIST.Permission') + ': ' + map(permissions).join(', ');
            } else {
              assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + map(permissions).join(', ');
            }
          } else if (assignedTo.permissions || row.target.targetType === 'workers') {
            assigned += separate ? '<hr>' : '';
            assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + this.translate.instant('SHARED.Any_Permissions');
          }
          return assigned || this.translate.instant('SHARED.None');
        }
      },
      {
        id: 'powerpoints',
        title: this.translate.instant('MGMT_DETAILS.Points'),
        headerClass: 'table-header'
      },
      {
        id: 'active',
        title: this.translate.instant('MGMT_LIST.ACTIVE'),
        headerClass: 'table-header',
        func: (isActive: number) => isActive ? this.translate.instant('SHARED.Active') : this.translate.instant('SHARED.Inactive')
      }
    ],
    onClickRow: (id): void => {
      this.router.navigate(['/pages/management/checks/view-check/deployment', {checkId: this.checkId, id}]);
    },
    onChecked: (tableId, id, selectedId, selectedIds) => {
      this.onChecked.emit({tableId, ids: selectedIds});
    }
  };
  private dataTableOptions: any = {
    paging: false,
    searching: false,
    info: false,
    retrieve: true,
    stateSave: true,
    select: {
      style: 'multi',
      selector: 'td:first-child'
    },
    columnDefs: [
      {
        orderable: false,
        className: 'select-checkbox',
        targets: 0,
        width: '40px'
      },
      {
        orderable: false,
        targets: [1, 2, 3],
        className: 'deployment-field',
      },
      {
        orderable: false,
        className: 'active-switcher',
        targets: 4
      },
      {targets: 1, width: '35%'}
    ],
    order: [[1, 'asc']],
    dom: 'frtip',
    scrollCollapse: true,
    scrollX: true
  };

  constructor(
    private tableService: TableService,
    private translate: TranslateService,
    private deploymentService: DeploymentService,
    private router: Router,
    private userService: UserService,
    private teamsService: TeamsService,
    private permissions: PermissionsService,
    private settingsService: SettingsService,
    private assetsService: AssetsService,
    private accountsService: AccountsService,
    private foldersDataService: FoldersDataService
  ) {
  }

  ngOnInit() {
    this.tableId = `deploymentTable${this.type}`;
    this.title = this.titleMap[this.type] || this.type;
  }

  public showTable() {
    this.isTableRendered = true;
    this.isTableReady = false;
    const tableElement = $(this.deploymentTable.nativeElement);

    if ($.fn.dataTable.isDataTable(this.deploymentTable.nativeElement)) {
      tableElement.DataTable().clear().draw();
      tableElement.DataTable().destroy();
    }

    this.tableService.showTable(`#${this.tableId}`, this.tableAttributes, this.deployments);
    const tableInstance = $(this.deploymentTable.nativeElement).DataTable(this.dataTableOptions).draw();

    tableElement.width('100%');

    setTimeout(() => {
      this.isTableReady = true;
      tableInstance.draw(false).columns.adjust();
    });
  }

}
