<div class="header">
  <button (click)="close()" class="popup-close-button"></button>
  <div class="title-block">
    <h2 class="title">{{ 'CONTENT.Content_Library' | translate }}</h2>
    <ion-button (click)="uploadContent()" [appPermissionCanView]="'admin'" class="page-button"
                color="secondary">{{ 'MGMT_DETAILS.Upload_Content' | translate }}</ion-button>
  </div>
</div>

<ion-searchbar (ionInput)="onSearch($event)" [value]="searchModel" class="search-bar"
               placeholder="{{'SHARED.Search' | translate}}"></ion-searchbar>

<ion-list *ngIf="contentViewItems.length; else emptyMessage">
  <ion-item-group *ngFor="let group of $any(contentViewItems | groupBy: 'contentCategoryName' | keyvalue | orderBy: 'key')"
                  [class.collapsed-item]="collapseItems[group.key]">
    <ion-item-divider (click)="toggleItemGroup(group.key)">
      <ion-label>{{ group.key }}</ion-label>
    </ion-item-divider>

    <ion-item *ngFor="let contentItem of group.value | orderBy: ['mediaType', 'description']" lines="full">
      <ion-checkbox (ionChange)="onCheck()" [(ngModel)]="contentItem.selected" slot="start"></ion-checkbox>
      <img [src]="contentItem.iconName">
      <div class="description">{{ contentItem | translateBy: 'description' }}</div>
    </ion-item>
  </ion-item-group>
</ion-list>

<ng-template #emptyMessage>
  <div class="empty-list-message">{{ 'SHARED.No_Data' | translate }}</div>
</ng-template>

<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ 'SHARED.Cancel' | translate }}</ion-button>
  <ion-button (click)="save()" [disabled]="selectedItems.length === 0" class="page-button"
              color="secondary">{{ 'SHARED.Add' | translate }}</ion-button>
</div>
