import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { ObservationService, UserdataService } from '@services';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { DeepLinkGuard } from '@services/deep-link/deep-link.service';

import { Observable } from 'rxjs';
import { get, includes, map, sortBy, split } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ObservationLinkGuard implements CanActivate, DeepLinkGuard {

  constructor(
    private observationService: ObservationService,
    private observationTableService: ObservationTableService,
    private observationDetailService: ObservationDetailService,
    private router: Router,
    private userDataService: UserdataService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id: string = next.paramMap.get('id');
    return this.navigateToObservationPage(id);
  }

  public navigateToObservationPage(id): Promise<boolean> {
    return this.observationService.getObservationByUUID(id).then((observation: any) => {
      const observations: any = this.observationTableService.getNearestObservationsById(+observation.observationID);
      const observationsIds: string[] = map(observations, 'observationID');
      const tableNavIsEnabled = !includes(['viewer'], this.userDataService.type);

      this.observationDetailService.navigateToDetail(observation, sortBy(observationsIds), true, tableNavIsEnabled);
      return false;
    }).catch(() => this.navigateToUnknownPage(id));
  }

  public parseUrlId(url: string): string {
    return get(split(url, '/observation/'), '[1]');
  }

  private navigateToUnknownPage(id: string): boolean {
    this.router.navigate(['pages/dashboard/unknown-observation', {id}]);
    return false;
  }

}
