import { PopoverController } from '@ionic/angular';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { environment } from '@env';
import { last } from 'lodash';

@Component({
  selector: 'app-copy-modal-popup',
  templateUrl: './copy-modal-popup.component.html',
  styleUrls: ['./copy-modal-popup.component.scss'],
})
export class CopyModalPopupComponent implements OnInit {
  @ViewChild('textRef') textRef: ElementRef<HTMLInputElement>;

  public url: string;
  public description: string;

  constructor(private popOver: PopoverController) {}

  ngOnInit() {
    this.prepareUrl();
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public copyLink(): void {
    this.textRef.nativeElement.select();
    document.execCommand('Copy');
    this.close();
  }

  private prepareUrl() {
    let baseHref: string = environment.baseHref;

    if (last(baseHref) !== '/') {
      baseHref += '/';
    }

    this.url = `${document.location.origin}${baseHref}${this.url}`;
  }
}
