import { Component, EventEmitter, Input, Output } from '@angular/core';
import { find } from 'lodash';


export enum ILeaderboardViewType {
  User,
  Group
}

export interface IViewSwitcherIcon {
  activeIconName: string;
  inactiveIconName: string;
  class?: string;
}

export interface IViewSwitcherType {
  icon: IViewSwitcherIcon;
  key: ILeaderboardViewType | any;
}

export interface IViewSwitcherConfig {
  viewTypes: [IViewSwitcherType, IViewSwitcherType];
  selectedType: ILeaderboardViewType | any;
}

@Component({
  selector: 'app-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss'],
})
export class ViewSwitcherComponent {

  @Input() config: IViewSwitcherConfig = <IViewSwitcherConfig>{};
  @Output() onSelect: EventEmitter<ILeaderboardViewType> = new EventEmitter();

  public onViewChange(event: CustomEvent): void {
    const type = <IViewSwitcherType>find(this.config.viewTypes, (viewType) => viewType.key.toString() === event.detail.value);
    this.onSelect.emit(type.key);
    this.config.selectedType = type.key;
  }

}
