import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { UtilsService } from '@services/utils/utils.service';
import { UserService } from '@services/user/user.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, each, find } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  public consent = {
    lastRequest: 0,
    data: {}
  };
  private lastRequest: 0;

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private utils: UtilsService,
    private userService: UserService,
    private userdata: UserdataService,
    private subscriber: SubscriberService,
    protected translate: TranslateService
  ) {
  }

  public refresh() {
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage({
        cmd: 'getSubscriberSettings',
        name: 'consentForm',
        lastRequest: this.lastRequest,
        sendTime: Date.now()
      }, false, false)
        .then((data) => {
          if (data) {
            if (data.reqStatus === 'OK') {
              const ref = data.result.settings[0];
              this.consent.data = ref;
              this.consent.lastRequest = data.result.timestamp;
            } else {
              this.logger.log('subscriber settings has no consentForm');
              this.consent.data = {
                value: '',
                translations: []
              };
            }
          }
          resolve(true);
        });
    });
  }

  public get(translate: boolean = true, mapTranslations: boolean = false) {
    let r: any = this.consent.data;
    if (r && r.hasOwnProperty('translations')) {
      r = cloneDeep(r);
      if (translate) {
        const l = this.userdata.getLanguage();
        if (l !== this.subscriber.getDefaultLanguage()) {
          const t = find(r.translations, ['language', l]);
          if (t) {
            r.value = t.value;
          }
        }
      }
      if (mapTranslations) {
        each(r.translations, (ref) => {
          r[`translations_${ref.language}`] = ref.value;
        });
      }
    }
    return r;
  }

  public update(fData, updateHandler): Promise<any> {
    const cmdOpts = cloneDeep(fData);

    cmdOpts.sendTime = Date.now();
    'use strict';

    const CRLF = '\r\n';

    fData.value.replace(new RegExp(CRLF, 'g'), '\n');

    // roll up any translations
    const l = this.subscriber.getLanguages(true);
    if (l && l.length) {
      const t = [];
      each(l, (lang: string) => {
        const n = `translations_${lang}`;
        if (cmdOpts.hasOwnProperty(n)) {
          const v = cmdOpts[n];
          v.replace(new RegExp(CRLF, 'g'), '\n');
          t.push({language: lang, value: v});
        }
      });
      if (t.length) {
        cmdOpts.translations = JSON.stringify(t);
      } else {
        delete cmdOpts.translations;
      }
    }

    cmdOpts.cmd = 'updateSubscriberSetting';
    cmdOpts.name = 'consentForm';

    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.refresh()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }


}
