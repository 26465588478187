import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FormBuilderService } from '@services';

import { ActivatedRoute } from '@angular/router';
import { each, get, includes } from 'lodash';

export interface IQuestionFormGroup {
  title?: string;
  formConfig: any;
  formData: any;
  finished?: boolean;
}

@Component({
  selector: 'app-question-form-group',
  templateUrl: './question-form-group.component.html',
  styleUrls: ['./question-form-group.component.scss'],
})
export class QuestionFormGroupComponent implements OnInit {

  @ViewChild('form', {static: true}) formElement: ElementRef<HTMLFormElement>;

  @Input() group: IQuestionFormGroup;
  @Input() isReorderAvailable: boolean;
  @Input() isRemovalAvailable: boolean;
  @Input() index: number;

  @Output() onFinish: EventEmitter<void> = new EventEmitter();
  @Output() onRemoval: EventEmitter<void> = new EventEmitter();

  public formId: string;

  constructor(
    private formBuilderService: FormBuilderService,
    private elementRef: ElementRef,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    if (!includes(this.group.formConfig.prefix, '_group_')) {
      this.group.formConfig.prefix += `_group_${this.index}`;
    }
    this.formId = `${this.group.formConfig.prefix}Form`;
    this.formBuilderService.showForm(this.formElement.nativeElement, this.group.formConfig, this.decodeFormSettings(this.group.formData));
    this.initChangeDetection();
    this.onFinishEvent();
  }

  public onRemove() {
    this.onRemoval.emit();
  }

  private decodeFormSettings(formData = <any>{}) {
    each(get(formData, 'settings'), (value, key) => {
      formData[key] = isNaN(+value) ? value : +value;
    });
    return formData;
  }

  private initChangeDetection() {
    if (this.route.snapshot.paramMap.get('id')) {
      $(this.formElement.nativeElement).off('input').on('input', (event: any) => {
        const fieldName: string = get(event, 'target.attributes.name.nodeValue');

        if (fieldName) {
          const originalValue: any = this.group.formData[fieldName] || '';

          this.formBuilderService.markFieldAsChanged(`#questionEditForm`, fieldName, originalValue, event.target.value);
        }
      });
    }
  }

  private onFinishEvent() {
    setTimeout(() => {
      this.onFinish.emit();
    });
  }

}
