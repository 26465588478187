import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';
import { TranslateService } from '@ngx-translate/core';
import { clone, cloneDeep, concat, each, find } from 'lodash';

export interface HFolderChildren {
  isOpened: boolean;
  active: number;
  children?: HFolderChildren[];
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  folderID: number;
  lastUpdate: number;
  parentID: number;
  title: string;
  translations: [];
  locations: [];
  type: string;

}

export interface HFolder {
  isOpened: boolean;
  active: number;
  children?: HFolderChildren[];
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  folderID: number;
  lastUpdate: number;
  parentID: number;
  locations: [];
  title: string;
  translations: [];
  type: string;

}

@Injectable({
  providedIn: 'root'
})
export class HierarchyGroupingService extends BaseService {
  folder: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };
  public userLevel: any = {};
  public locationHierarchyComponent: any = {};
  public parentHierarchy: any = {};
  public secondParentHierarchy: any = {};

  constructor(
    protected commsService: CommsService,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public getFolders() {
    return cloneDeep(this.folder.data);
  }

  public handleAddFolder(fData) {
    fData.type = 'location';
    fData.active = 1;
    fData.cmd = 'addFolder';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateFolder(fData) {
    fData.cmd = 'updateFolder';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteFolder(fData) {
    fData.cmd = 'deleteFolder';
    return this.handleRequest(fData);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.folder.lastRequest) {
      console.log(`local folder cache already up to date: ${updated}, ${this.folder.lastRequest}`);
      return Promise.resolve(this.folder.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getFolderTree',
          includeDisabled: 0,
          lastRequest: this.folder.lastRequest,
          lastHash: this.folder.lastHash,
          type: 'location',
          includeLocations: 1,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.folder.lastHash = data.result.datahash;
          }
          resolve(this.folder.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.folder.lastHash = null;
    this.folder.lastRequest = null;
    this.folder.data = null;
    this.folder.tree = null;
  }

  public updateCache(data) {
    this.folder.lastRequest = data.result.timestamp;
    this.folder.data = data.result.tree.children;
    this.folder.tree = data.result.tree;
    this.events.publish('ccs:folderUpdate');
  }

  translateData(data: any): void {
    const currentLanguage: string = this.translate.getDefaultLang();
    if (currentLanguage) {
      each(data, (dataItem: any) => {
        const translatedObject: any = find(dataItem.translations, {language: currentLanguage});
        this.translateData(dataItem.children);
        if (translatedObject) {
          dataItem.title = translatedObject.value;
        }
      });
    }
  }

  getObjectByKeys(obj, key) {
    this.translateData(obj);
    return new Promise((resolve, reject) => {
      each(obj, (dataItem: any) => {
        if (dataItem.folderID === key) {
          resolve(dataItem);
        }
        resolve(this.getObjectByKeys(dataItem.children, key));
      });
    });
  }

  getFolderObjectByName(title): HFolder {
    return find(this.getFlatFolder(), (loc) => loc.title === title);
  }


  /**
   *
   * @returns flatArray - flattens out all the children into an array
   */
  getFlatFolder() {
    const flatArray = [];
    each(this.folder.data, data => {
      this.getChildren(data, flatArray);
    });
    return flatArray;
  }


  /**
   *
   * @param dObj - node objects
   * @param arrList - array of the flat list
   */
  getChildren(dObj, arrList) {
    arrList.push(dObj);
    each(dObj.children, child => {
      arrList.push(child);
      if (child.children.length) {
        this.getChildren(child, arrList);
      }
    });
  }

  getFolderByID(folderID: string | number): HFolder {
    // accept either node:NN or n
    let f = folderID;
    if (typeof (folderID) === 'string') {
      f = folderID.replace(/^node:/, '');
    }

    // recursively walk the tree and find the required node
    const search = (node: HFolder): HFolder => {
      let localRet;
      // eslint-disable-next-line eqeqeq
      if (node.folderID == f) {
        localRet = node;
      } else {
        // search the children
        each(node.children, child => {
          const v = search(child);
          if (v) {
            localRet = v;
            return false;
          }
        });
      }
      return localRet;
    };
    return search(this.folder.tree);
  }

  /**
   *
   * @param folderID - a string or numeric folderID
   * @param includeLocations - whether to include the child locationIDs in the list.  Defaults to true.
   * @param includeFolders - whether to include the child nodeIDs in the list (in the form node:NN).  Defaults to false.
   * @returns a list of child locationIDs, nodeIDs, or both.
   */
  getChildIDsAsList(folderID: string | number, includeLocations: boolean = true, includeFolders: boolean = false): Array<string | number> {
    let ret = [];
    const recurse = (node: HFolder) => {
      // start with the local locations (if requested)
      let localRet = includeLocations ? clone(node.locations) : [];
      each(node.children, child => {
        if (includeFolders) {
          // we want the names of the nodes in this node
          localRet.push(`node:${child.folderID}`);
        }
        // add in everything below this child
        localRet = concat(localRet, recurse(child));
      });
      return localRet;
    };

    const theFolder = this.getFolderByID(folderID);
    if (theFolder) {
      ret = recurse(theFolder);
    }

    return ret;
  }

  getSelectedFolder(obj, id) {
    const arr: any = Object.values(obj);
    for (let i = 0; i < arr.length; i++) {
      const children: any = Object.values(arr[i].children);
      if (arr[i].folderID === id) {
        return ([arr[i].title]);
      } else if (children && children.length) {
        const t = this.getSelectedFolder(children, id);
        if (t !== false) {
          t.push(arr[i].title);
          return t;
        }
      }
    }
    return false;
  }

}
