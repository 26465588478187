<ion-header *ngIf="headerIsHidden" no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>

    <app-detail-controls [headerData]="headerData"></app-detail-controls>
    <app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes>

    <!-- IMAGES BLOCK-->
    <div class="detail-page-section">

      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col>
              <div class="descriptor">{{ "DASHPAGES.COMPLIMENT_DETAIL_ccsTableHeader" | translate }}</div>
              <div class="adjustment-grid-inline" id="behaviorList">
                <span *ngFor="let comp of notes.complimentNotes" class="adjustment-grid-inline-item">
                  <img alt="Check" class="adjustment-icon-tick" src="assets/images/check.svg">
                  <span class="adjustment-grid-value">{{ comp.data }}</span>
                </span>
              </div>
            </ion-col>
            <ion-col class="adjustment-grid-inline ion-align-items-end ion-justify-content-end" size="2">
              <img alt="Check" src="assets/images/thumbsUp.svg">
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <hr class="hr-dashed">
    </div>
    <!-- IMAGES BLOCK END-->

    <!-- NOTE BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium headline-medium-with-button">
        {{ "SHARED.Observation_Notes" | translate }}
        <ion-button (click)="addOpenNote()" [appAccountCanViewExcept]="['viewer']" class="page-button"
                    color="secondary">
          {{ "SHARED.Add_Note" | translate }}
        </ion-button>
      </div>

      <div class="section-grid">
        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.openNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img">
                </div>
                <div class="notes-list-item-content">
                  <p><span class="observation-u-name">{{note.name}}</span> | <span>{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>

        </div>

      </div>

    </div>
    <!-- NOTE BLOCK END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->

  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
