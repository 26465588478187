import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { MessagePopupComponent } from '@modules/observation/components/message-popup/message-popup.component';
import { AccountsService, UtilsService } from '@services';
import { cloneDeep, findLast, includes } from 'lodash';


interface HistoryItem {
  activity: string;
  time: number;
  userID: number;
}

@Component({
  selector: 'app-detail-address-controls',
  templateUrl: './detail-address-controls.component.html',
  styleUrls: ['./detail-address-controls.component.scss'],
})
export class DetailAddressControlsComponent {

  @Input() scrollTarget: string;
  public history: {
    activity: string;
    name: string;
    userID: number;
    time: string;
    userImg: string;
  };

  constructor(
    private popover: PopoverController,
    private accountService: AccountsService,
    private utils: UtilsService
  ) {
  }

  @Input() set addressData(data: HistoryItem[]) {
    if (data) {
      this.init(data);
    }
  }

  public messageUser(uObj) {
    const tempUobj = cloneDeep(uObj);
    this.popover.create(<any>{
      component: MessagePopupComponent,
      animated: false,
      componentProps: {
        userObject: tempUobj,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public scrollTo(): void {
    const targetElement: HTMLElement = document.getElementById(this.scrollTarget);
    targetElement.scrollIntoView({behavior: 'smooth'});
  }

  private init(historyItems: HistoryItem[]) {
    const addressedItem: HistoryItem = findLast(historyItems, (historyItem) => includes(['fixed', 'addressed'], historyItem.activity));

    if (addressedItem) {
      this.history = {
        activity: addressedItem.activity,
        name: this.accountService.getCompactName(addressedItem.userID),
        userID: addressedItem.userID,
        time: this.utils.dateTimeFormat(addressedItem.time, '', true),
        userImg: this.accountService.avatar(addressedItem.userID, 64, false, true)
      };
    }
  }
}

