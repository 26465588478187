import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { SubscriberService } from '@services';

import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { includes } from 'lodash';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent extends CustomFormComponent implements AfterViewInit, OnInit {
  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('startDate', {static: false}) startDateElement: ElementRef<HTMLInputElement>;
  @ViewChild('endDate', {static: false}) endDateElement: ElementRef<HTMLInputElement>;

  @Input() startLabel = 'SHARED.Start';
  @Input() startPlaceholder = '';
  @Input() endLabel = 'SHARED.Until';
  @Input() endPlaceholder = '';
  @Input() isTimePicker: boolean;
  @Input() autoApply: boolean = true;
  @Input() dateRange: { startDate: number; endDate: number } = {
    startDate: 0,
    endDate: 0
  };
  @Input() useUTC = false;
  @Input() singleDatePicker = false;
  @Input() showDropdowns = false;
  @Input() emptyDefaultValue = false;
  @Input() canSaveWithSingleDate = false;
  @Input() noLabel = false;

  private readonly dateFormat: string = 'MM/DD/YYYY';
  private readonly time24Format: string = 'HH:mm';
  private readonly time12Format: string = 'hh:mm A';
  private startDate: moment.Moment;
  private endDate: moment.Moment;
  private datePickerConfig = {
    singleDatePicker: true,
    timePicker24Hour: this.subscriberService.getUnits('time') === '24h',
    autoUpdateInput: false,
    drops: 'auto',
    locale: <{ format: string; cancelLabel: string; monthNames?: string[]; daysOfWeek?: string[] }>{
      format: this.dateFormat,
      monthNames: moment.monthsShort(),
      daysOfWeek: moment.weekdaysShort()
    }
  };

  constructor(
    private subscriberService: SubscriberService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.emptyDefaultValue && !this.dateRange.startDate) {
      this.dateRange.startDate = moment().valueOf();
    }
  }

  ngAfterViewInit() {
    if (this.useUTC) {
      this.startDate = this.dateRange.startDate ? moment.utc(+this.dateRange.startDate) : moment.utc();
      this.endDate = this.dateRange.endDate ? moment.utc(+this.dateRange.endDate) : null;
    } else {
      this.startDate = this.dateRange.startDate ? moment(+this.dateRange.startDate) : moment();
      this.endDate = this.dateRange.endDate ? moment(+this.dateRange.endDate) : null;
    }

    if (this.singleDatePicker && this.formValue) {
      this.startDate = this.useUTC ? moment.utc(+this.formValue) : moment(+this.formValue);
      this.dateRange.startDate = this.startDate.valueOf();
    }

    this.initDatePicker();
  }

  public emitChanges(startDate: moment.Moment = this.startDate, endDate: moment.Moment = this.endDate): void {
    const date: { startDate: number; endDate?: number } = {
      startDate: this.useUTC ? startDate.utc(true).valueOf() : startDate.valueOf()
    };

    if (this.singleDatePicker) {
      this.formValue = date.startDate;
    } else {
      if (endDate) {
        if (this.useUTC) {
          date.endDate = this.isTimePicker ? endDate.utc(true).valueOf() : endDate.utc(true).endOf('day').valueOf();
        } else {
          date.endDate = this.isTimePicker ? endDate.valueOf() : endDate.endOf('day').valueOf();
        }
      }

      if (this.canSaveWithSingleDate || endDate) {
        this.formValue = date;
      }
    }
  }

  public onChangedValue() {
    const format = this.datePickerConfig.locale.format;
    const startDate = moment(this.startDateElement.nativeElement.value, format, true);

    if (this.singleDatePicker) {
      if (startDate.isValid()) {
        this.emitChanges(startDate);
      } else {
        this.formValue = this.getDefaultValue();
      }
    } else {
      const endDate = moment(this.endDateElement.nativeElement.value, format, true);

      if (startDate.isValid() && endDate.isValid()) {
        this.emitChanges(startDate, endDate);
      } else {
        this.formValue = this.getDefaultValue();
      }
    }
  }

  private initDatePicker(): void {
    const startDateConfig: { [key: string]: moment.Moment } = {startDate: this.startDate};
    const endDateConfig: { [key: string]: moment.Moment } = {minDate: this.startDate};
    const timeFormat = this.datePickerConfig.timePicker24Hour ? this.time24Format : this.time12Format;

    if (this.endDate) {
      startDateConfig.maxDate = moment(this.endDate).startOf('day');
      endDateConfig.startDate = this.endDate;
    }

    this.datePickerConfig.locale.format = this.isTimePicker ? `${this.dateFormat} ${timeFormat}` : this.dateFormat;
    this.initDatePickerBy(this.startDateElement, startDateConfig, (startDate) => this.onStartDateChange(startDate));

    if (!this.singleDatePicker) {
      this.initDatePickerBy(this.endDateElement, endDateConfig, (endDate) => this.onEndDateChange(endDate));
    }

    const format = this.datePickerConfig.locale.format;

    if (this.useUTC) {
      this.startDateElement.nativeElement.value = moment.utc(this.startDate).format(format);
      if (this.endDate) {
        this.endDateElement.nativeElement.value = moment.utc(this.endDate).format(format);
      }
    } else {
      this.startDateElement.nativeElement.value = moment(this.startDate).format(format);
      if (this.endDate) {
        this.endDateElement.nativeElement.value = moment(this.endDate).format(format);
      }
    }

    if (this.emptyDefaultValue && !this.dateRange.startDate) {
      this.startDateElement.nativeElement.value = '';
    }
  }

  private initDatePickerBy(element: ElementRef<HTMLInputElement>, config: { [key: string]: any } = {}, onSelect: (data: moment.Moment) => void) {
    const targetElement: any = $(element.nativeElement);
    const dateRangeConfig: { [key: string]: any } = {
      ...this.datePickerConfig,
      ...config,
      timePicker: this.isTimePicker,
      showDropdowns: this.showDropdowns
    };

    if (this.autoApply) {
      dateRangeConfig.autoApply = true;
    } else {
      dateRangeConfig.locale.cancelLabel = this.translate.instant('SHARED.Clear');

      targetElement.on('cancel.daterangepicker', (event) => {
        this.onCancel(targetElement, event);
      });
    }

    targetElement.daterangepicker(dateRangeConfig).on('apply.daterangepicker', (event, picker) => {
      onSelect(config.startDate ? picker.startDate : picker.endDate);
      this.emitChanges();
    });

    targetElement.on('hide.daterangepicker', (event) => {
      setTimeout(() => {
        this.onHideDatePicker(event);
      });
    });
  }

  private onStartDateChange(startDate) {
    this.startDate = startDate;
    this.startDateElement.nativeElement.value = startDate.format(this.datePickerConfig.locale.format);

    if (!this.singleDatePicker) {
      $(this.endDateElement.nativeElement).data('daterangepicker').minDate = moment(startDate);

      if (!this.endDate && $(this.endDateElement.nativeElement).data('daterangepicker').startDate.isBefore(this.startDate)) {
        $(this.endDateElement.nativeElement).data('daterangepicker').startDate = startDate;
        $(this.endDateElement.nativeElement).data('daterangepicker').endDate = startDate;
      }
    }
  }

  private onEndDateChange(endDate) {
    this.endDate = endDate;
    this.endDateElement.nativeElement.value = endDate.format(this.datePickerConfig.locale.format);
    $(this.startDateElement.nativeElement).data('daterangepicker').maxDate = moment(endDate).startOf('day');

    if (endDate.isBefore(this.startDate)) {
      $(this.startDateElement.nativeElement).data('daterangepicker').startDate = endDate;
      this.onStartDateChange(endDate);
    }
  }

  private onCancel(targetElement, event) {
    targetElement.val('');

    if (!this.singleDatePicker) {
      const dateOutput: { startDate: number; endDate?: number } = {
        startDate: 0,
        endDate: 0
      };

      if (this.startDateElement.nativeElement.value) {
        dateOutput.startDate = this.useUTC ? this.startDate.utc(true).valueOf() : this.startDate.valueOf();
      }

      if (this.endDateElement.nativeElement.value) {
        dateOutput.endDate = this.useUTC ? this.endDate.utc(true).valueOf() : this.endDate.valueOf();
      }

      if (includes(event.target.className, 'start-date')) {
        $(this.endDateElement.nativeElement).data('daterangepicker').minDate = null;
      } else {
        $(this.startDateElement.nativeElement).data('daterangepicker').maxDate = null;
      }


      if (this.required && !(dateOutput.endDate && dateOutput.startDate)) {
        this.formValue = this.getDefaultValue();
      } else {
        this.formValue = dateOutput;
      }
    } else {
      this.formValue = this.getDefaultValue();
    }
  }

  private onHideDatePicker(event) {
    const date = moment(event.target.value, this.datePickerConfig.locale.format, true);

    if (event.target.value && !date.isValid()) {
      if (includes(event.target.className, 'start-date')) {
        this.startDateElement.nativeElement.value = this.startDate.format(this.datePickerConfig.locale.format);
      } else {
        this.endDateElement.nativeElement.value = this.endDate ? this.endDate.format(this.datePickerConfig.locale.format) : '';
      }
    }
  }

  private getDefaultValue() {
    return this.required ? undefined : 0
  }

}
