import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';

import { ListPage, MessageThreadPage } from './pages';
import {
  MessagesComponent,
  NotificationsComponent,
  NewMessageMenuComponent,
  ChatMenuComponent,
  RemovalPanelComponent,
  ParticipantViewComponent,
  NotificationMenuComponent,
} from './components';
import { CommunicationsRoutingModule } from './communications-routing.module';
import { SharedModule } from './../shared/shared.module';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';

const PAGES = [
  ListPage,
  MessageThreadPage
];

const COMPONENTS = [
  MessagesComponent,
  NotificationsComponent,
  NewMessageMenuComponent,
  ChatMenuComponent,
  RemovalPanelComponent,
  ParticipantViewComponent,
  NotificationMenuComponent
];

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    CommunicationsRoutingModule,
    IonicModule.forRoot(),
    FormsModule,
    SharedModule,
    MomentModule,
    MarkdownModule.forChild(),
    TranslateModule.forChild(),
    NgPipesModule
  ]
})
export class CommunicationsModule {
}
