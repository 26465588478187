import { Component, Input } from '@angular/core';

import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { findLast } from 'lodash';

interface SubmissionNote {
  avatarUrl: string;
  name: string;
  time: string;
  text: string;
}

@Component({
  selector: 'app-submission-note',
  templateUrl: './submission-note.component.html',
  styleUrls: ['./submission-note.component.scss'],
})
export class SubmissionNoteComponent {

  public submissionNote: SubmissionNote;

  constructor(private observationDetailService: ObservationDetailService) {
  }

  @Input() set observationId(id: number) {
    if (id) {
      const note = this.getFirstNote(id);
      this.defineSubmissionNote(note);
    }
  }

  private getFirstNote(observationId: number): { [key: string]: any } {
    return findLast(this.observationDetailService.getNotes(observationId).openNotes, {type: 'text'});
  }

  private defineSubmissionNote(note: { [key: string]: any }): void {
    if (note?.type === 'text') {
      this.submissionNote = {
        avatarUrl: note.userImg,
        name: note.name,
        time: note.time,
        text: note.data
      };
    }
  }

}
