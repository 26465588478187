<ion-header *ngIf="headerIsHidden" no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>
    <app-detail-controls [headerData]="headerData"></app-detail-controls>

    <!-- <app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes> -->
    <app-closing-details [scrollRef]="historyRef" *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-details>

    <!-- IMAGES BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium">{{ "DASHPAGES.CONDITION_DETAIL_headline_medium" | translate }}</div>
      <image-gallery-modal [inputImages]="images"></image-gallery-modal>
      <app-asset-status-panel [observation]="observation"></app-asset-status-panel>
      <hr class="hr-dashed">
    </div>
    <!-- IMAGES BLOCK END-->

    <!-- NOTE BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium">{{ "SHARED.Observation_Notes" | translate }}</div>

      <div class="section-grid">
        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-open" id="noteColorCode">
            {{ "SHARED.Open" | translate }}
            </span>
            <ion-button (click)="addOpenNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.openNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img">
                </div>
                <div class="notes-list-item-content">
                  <p>
                    <span [title]="note.fullName" class="body-copy-bold">{{note.name}}</span> | <span
                    class="detail">{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!--FIXED OBSERVATION NOTES-->
        <div *ngIf="notes.displayClosedNotes" class="section-grid-right">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-fixed">Fixed</span>
            <ion-button (click)="addClosedNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.closedNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p>
                    <span [title]="note.fullName" class="body-copy-bold">{{note.name}}</span> | <span
                    class="detail">{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <hr class="hr-dashed">

    </div>
    <!-- NOTE BLOCK END-->

    <!--OBSERTVATION HISTORY-->
    <div #historyRef>
      <app-observation-history [observationId]="oid" [title]="'SHARED.Observation_History'"></app-observation-history>
    </div>

    <!--OBSERTVATION HISTORY END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
