import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { ICheck, IQuestion, IQuestionOption } from '@modules/management/pages/details/check/models';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { assign, cloneDeep, each, filter, get, has, isArray, isEmpty, keyBy, map, union } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ChecksService {

  public filterObject: any = {};

  public checks: { data: { [id: string]: ICheck }; lastRequest: number } = {
    data: {},
    lastRequest: null
  };

  public questions: IObjectStringKeyMap<IQuestion> = {};
  public questionOptions: IObjectStringKeyMap<IObjectStringKeyMap<IQuestionOption>> = {};

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private events: Events
  ) {
  }

  public refresh(updated: number = 0) {
    if (updated && updated <= this.checks.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.checks.lastRequest}`);
      return Promise.resolve(this.checks.data);
    } else {
      return new Promise((resolve, reject) => {
        this.commsService.sendMessage({
          cmd: 'getChecks',
          lastRequest: this.checks.lastRequest,
          sendTime: Date.now(),
          includeQuestions: true,
          includeDisabled: 1
        }, false, false).then((data: any) => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
          }
          resolve(this.checks.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public updateCache(data) {
    const dataMap = {
      checks: this.checks.data,
      questions: this.questions,
      questionOptions: this.questionOptions
    };

    this.checks.lastRequest = data.result.timestamp;
    each(dataMap, (dataReference, key: string) => {
      if (has(data.result, key)) {
        const items = get(data, `result.${key}`, {});
        if (isEmpty(dataReference)) {
          assign(dataReference, items);
        } else {
          each(items, (item, id: string) => {
            dataReference[id] = item;
          });
        }
      }
    });

    this.events.publish('ccs:checksUpdate');
  }

  public clearCache() {
    this.checks.lastRequest = null;
    this.checks.data = {};
    this.questions = {};
    this.questionOptions = {};
  }

  public getCheck(id: string): ICheck {
    const r = this.checks.data[id];
    return r ? cloneDeep(r) : null;
  }

  public addCheck(formData: any): Promise<any> {
    const params: any = {
      cmd: 'addCheck',
      ...formData
    };

    this.encodeFormLocations(params);

    return this.commsService.sendMessage(params, false, false);
  }

  public removeCheck(ids: string[]): Promise<any> {
    const params: any = {
      cmd: 'deleteCheck',
      checks: JSON.stringify(ids)
    };

    return this.commsService.sendMessage(params, false, false);
  }

  public updateCheck(data): Promise<any> {
    const params: any = {
      cmd: 'updateCheck',
      ...data
    };

    this.encodeFormLocations(params);
    return this.commsService.sendMessage(params, false, false);
  }

  public duplicateChecks(ids: string[]): Promise<any> {
    const params = {
      cmd: 'duplicateChecks',
      checks: JSON.stringify(ids)
    };

    return this.commsService.sendMessage(params, false, false);
  }

  public getAllChecks() {
    return this.checks.data;
  }

  public getChecksByType(checkTypeIDs?: any) {
    if (checkTypeIDs && isArray(checkTypeIDs)) {
      // find all of these types and return them
      let l = [];
      each(checkTypeIDs, id => {
        const list = filter(this.checks.data, {checkType: +id});
        l = union(l, list);
      });
      // now we have an ARRAY of matching checks; convert it to an object
      return keyBy(l, 'checkID');
    } else {
      return this.checks.data;
    }
  }

  private encodeFormLocations(data) {
    if (isArray(data.locations)) {
      data.locations = JSON.stringify(map(data.locations, Number));
    }
  }

}
