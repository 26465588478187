import { Injectable } from '@angular/core';

import { ChecksService, CommsService } from '@services';
import { ICheck, ICheckGroup, IQuestion, IQuestionOption } from '@modules/management/pages/details/check/models';
import { IObjectStringKeyMap } from '@shared/models';
import { difference, each, filter, find, flatten, get, groupBy, includes, isEmpty, keys, map, values } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  public draggedRow: any = {};

  private temporaryGroupTitles: { [id: string]: string } = {};

  constructor(
    private commsService: CommsService,
    private checksService: ChecksService
  ) {
  }

  public async addQuestion(formData): Promise<any> {
    formData.cmd = 'addQuestion';
    formData.sendTime = Date.now();

    const questionOptionsData = formData.questionOptions;
    delete formData.questionOptions;

    const response = await this.commsService.sendMessage(formData);
    const question: IQuestion = get(response, 'result.question');
    if (question) {
      await Promise.all(map(questionOptionsData, (questionOption) => {
        questionOption.questionID = question.questionID;
        return this.addQuestionOption(questionOption).then((response: any) => {
          questionOption.optionID = get(response, 'result.option.optionID');
          return response;
        });
      }));

      const optionsIds: string[] = filter(map(questionOptionsData, 'optionID'));
      if (optionsIds && optionsIds.length) {
        question.options = optionsIds;
        const update = {
          questionID: question.questionID,
          options: optionsIds
        };
        await this.updateQuestion(update);
      }
      return this.updateCheckByNewQuestion(question, formData.checkID);
    }
    return response;
  }

  public async updateQuestion(formData): Promise<any> {
    const questionOptionsData = formData.questionOptions;
    delete formData.questionOptions;

    formData.cmd = 'updateQuestion';
    formData.sendTime = Date.now();

    await Promise.all(map(questionOptionsData, (questionOption) => {
      questionOption.questionID = formData.questionID;

      if (questionOption.optionID) {
        return this.updateQuestionOption(questionOption);
      } else {
        return this.addQuestionOption(questionOption).then((response) => {
          const optionID: string = get(response, 'result.option.optionID');
          if (optionID) {
            questionOption.optionID = optionID;
          }
        });
      }
    }));

    const optionsIds: string[] = map(questionOptionsData, 'optionID');
    if (optionsIds && optionsIds.length) {
      formData.options = filter(optionsIds);
    }
    formData.options = JSON.stringify(formData.options || []);
    return this.commsService.sendMessage(formData);
  }

  public async duplicateQuestions(checkId: string, selectedGroupedQuestions: IObjectStringKeyMap<string[]>): Promise<any> {
    const questionsIds: string[] = flatten(values(selectedGroupedQuestions));
    const formData = {cmd: 'duplicateQuestion', questions: JSON.stringify(questionsIds)};
    const duplicateQuestionsResponses = await this.commsService.sendMessage(formData);
    const duplicateQuestions: IObjectStringKeyMap<IQuestion[]> = groupBy(values(get(duplicateQuestionsResponses, 'result.questions')), 'checkGroup') || {};

    if (!isEmpty(duplicateQuestions)) {
      const check: ICheck = this.checksService.getCheck(checkId);
      each(check.checkGroups, (checkGroup: ICheckGroup, index: number) => {
        const selectedQuestionsIds: string[] = map(values(duplicateQuestions[checkGroup.groupID]), 'questionID');

        if (selectedQuestionsIds && selectedQuestionsIds.length) {
          check.checkGroups[index].questions = [
            ...check.checkGroups[index].questions,
            ...selectedQuestionsIds
          ];
        }
      });

      this.applyTemporaryGroups(check);
      const requestParams = {checkID: checkId, checkGroups: JSON.stringify(check.checkGroups)};
      return this.checksService.updateCheck(requestParams);
    } else {
      return duplicateQuestionsResponses;
    }
  }

  public addQuestionOption(formData): Promise<any> {
    formData.cmd = 'addQuestionOption';
    formData.sendTime = Date.now();

    return this.commsService.sendMessage(formData);
  }

  public updateQuestionOption(formData): Promise<any> {
    formData.cmd = 'updateQuestionOption';
    return this.commsService.sendMessage(formData);
  }

  public getQuestionOptions(questionID): IObjectStringKeyMap<IQuestionOption> {
    return this.checksService.questionOptions[questionID] || {};
  }

  public getQuestionsByCheckId(checkId: string): IQuestion[] {
    return filter(this.checksService.questions, {checkID: checkId});
  }

  public getQuestionsByIds(questionIds: string[]): IQuestion[] {
    return filter(this.checksService.questions, (question: IQuestion) => includes(questionIds, question.questionID));
  }

  public getQuestion(id: string): IQuestion {
    return this.checksService.questions[id];
  }

  public async removeQuestions(checkId: string, selectedQuestions: IObjectStringKeyMap<string[]>): Promise<any> {
    const ids: string[] = flatten(values(selectedQuestions));
    const requests: Promise<any>[] = map(ids, (id: string) => {
      const formData = {
        cmd: 'deleteQuestion',
        questionID: id
      };

      return this.commsService.sendMessage(formData);
    });

    const check = this.checksService.getCheck(checkId);
    each(check.checkGroups, (checkGroup: ICheckGroup, index: number) => {
      const questionGroup = selectedQuestions[checkGroup.groupID];

      if (questionGroup) {
        check.checkGroups[index].questions = difference(checkGroup.questions, questionGroup);

        if (check.checkGroups[index].questions.length === 0) {
          delete check.checkGroups[index];
        }
      }
    });

    const checkGroups: ICheckGroup[] = filter(check.checkGroups);
    const requestParams = {checkID: checkId, checkGroups: JSON.stringify(checkGroups)};

    await Promise.all(requests);
    this.clearTemporaryGroups();
    return this.checksService.updateCheck(requestParams);
  }

  public setTemporaryGroup(id: string, title: string): void {
    this.temporaryGroupTitles[id] = title;
  }

  public clearTemporaryGroups(): void {
    this.temporaryGroupTitles = {};
  }

  public getTemporaryGroupCount(): number {
    return keys(this.temporaryGroupTitles).length;
  }

  private updateCheckByNewQuestion(question: IQuestion, checkID: string): Promise<any> {
    const checkGroupId: string = question.checkGroup;
    const questionId: string = question.questionID;

    if (questionId && checkGroupId) {
      const check: ICheck = this.checksService.getCheck(checkID) || <ICheck>{};
      const checkGroups: ICheckGroup[] = check.checkGroups || [];
      const checkGroup: ICheckGroup = find(checkGroups, {groupID: checkGroupId});
      const requestParams = {checkID, checkGroups: null};

      if (checkGroup) {
        checkGroup.questions.push(questionId);
        requestParams.checkGroups = checkGroups;
      } else {
        requestParams.checkGroups = [...checkGroups, {groupID: checkGroupId, questions: [questionId]}];
      }

      this.applyTemporaryGroups(requestParams);
      requestParams.checkGroups = JSON.stringify(requestParams.checkGroups);

      this.clearTemporaryGroups();
      return this.checksService.updateCheck(requestParams);
    } else {
      return Promise.resolve();
    }
  }

  private applyTemporaryGroups(requestParams: { checkID: string; checkGroups: ICheckGroup[] }): void {
    const temporaryGroupTitles: ICheckGroup[] = [];

    each(this.temporaryGroupTitles, (title: string, id: string) => {
      const group: ICheckGroup = find(requestParams.checkGroups, {groupID: id});

      if (group) {
        group.title = title;
      } else {
        temporaryGroupTitles.push({groupID: id, title, questions: []});
      }
    });

    requestParams.checkGroups = [
      ...requestParams.checkGroups,
      ...temporaryGroupTitles
    ];
  }

}
