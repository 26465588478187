<ion-menu (ionDidClose)="onClose()" (ionDidOpen)="startListScrollListening()" (ionWillClose)="stopListScrollListening()" class="custom"
          contentId="main-content" menuId="chat" side="end" swipeGesture="false" type="overlay">
  <div class="user-block">
    <div class="user-info">
      <ion-avatar>
        <img *ngIf="avatarUrl" [src]="avatarUrl" alt="User">
      </ion-avatar>
      <div class="user-data">
        <div class="date">{{date}}</div>
        <div class="user-name">{{fullName}}</div>
        <div (click)="viewParticipants()" *ngIf="isViewAvailable" class="link">{{"SHARED.View" | translate}}</div>
      </div>
      <div (click)="close()" class="close-icon"></div>
    </div>
    <ion-content #content class="message-list">
      <ion-list *ngIf="viewItems.length" id="message-list" lines="none">
        <ion-item *ngFor="let message of viewItems" [ngClass]="{'right': !message.userName}">
          <div class="message-item">
            <ion-avatar *ngIf="message.userName && (isTeamChat || isMultipleUsersChat)">
              <img [src]="message.userAvatarUrl" alt="User">
            </ion-avatar>
            <div class="message-block">
              <div class="message-info">
                <div *ngIf="isTeamChat || isMultipleUsersChat" class="name">{{message.userName}}</div>
                <div class="time">{{message.time | amTimeAgo}}</div>
              </div>
              <div [innerHTML]="message.text | markdown | async" class="message-text">
              </div>
              <div *ngIf="message.object && message.object.objectURL" class="message-image">
                <app-media-file [mediaType]="message.object.mediaType" [url]="message.object.objectURL"
                                class="message-image"></app-media-file>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </ion-content>
    <div class="message-input">
      <textarea #newMessageTextArea (keydown)="submitOnEnter($event)" (keyup)="calcHeight()" [(ngModel)]="newMessage"
                [maxlength]="messageLength" placeholder="{{'COMMUNICATIONS.Add_message_here' | translate}}"></textarea>
      <div (click)="sendMessage()" [class.empty]="newMessage.trim().length === 0" class="message-action"></div>
    </div>
  </div>
</ion-menu>
