import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { ILocation, IZone, UserService } from '@services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { each, includes, isArray, isNull, isNumber, isObject, isUndefined, lowerCase, map, orderBy, sortBy, toString as _toString } from 'lodash';

export interface IListItem {
  id: string;
  text: string;
}

export interface ILocationZonesItem {
  locationID: number;
  text: string;
  disabled: boolean;
  children: IListItem[];
}

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  public siteManagementZoneComponent: any = {}; // this value holds the current zone/location of the open accordions which is accessed later to maintain the state going back to site management page.

  constructor(private userService: UserService, protected translate: TranslateService,
              private logger: NGXLogger) {
  }

  public buildZoneList(locationID, current, includeDisabled) {
    const currentList = [];
    if (current === undefined || current === null) {
      //  currentList.push(0);
    } else if (typeof current === 'object' && Array.isArray(current)) {
      $.each(current, (idx, val) => {
        currentList.push(val);
      });
    } else {
      currentList.push(current);
    }

    if (includeDisabled === undefined) {
      includeDisabled = false;
    }

    const ret = [];
    let locationList = [];

    if (isUndefined(locationID) || (isNumber(locationID) && !locationID) || isNull(locationID) || (isObject(locationID) && isArray(locationID) && locationID.length === 0)) {
      $.each(this.userService.locations.data, (idx, ref) => {
        if (!includeDisabled && ref.disabledAt) {
          return true;
        }
        locationList.push(ref.locationID);
      });
    } else if (typeof locationID === 'object' && Array.isArray(locationID)) {
      locationList = locationID;
    } else if (typeof locationID === 'number') {
      locationList.push(locationID);
    } else {
      this.logger.log('Invalid locationID parameter');
      return null;
    }

    $.each(locationList, (idx, locationId) => {
      const locationRef = this.userService.findLocation(locationId);
      if (!locationRef) {
        // can't find a location - skip it
        this.logger.log(`Can't find location ${locationId}`);
        return true;
      }
      let name = '';
      let value = '';
      if (locationList.length > 1) {
        name += locationRef.name + ' - ';
      }
      name += 'Site-wide';
      value = locationId + ':0';
      ret.push({id: value, description: name});

      if (locationRef.zones && locationRef.zones.length) {
        $.each(locationRef.zones, (index, ref) => {
          const listItem = {id: ref.zoneID, description: ''};

          if (!includeDisabled && ref.disabledAt) {
            return true;
          }
          name = '';
          value = '';
          if (locationList.length > 1) {
            name += locationRef.name + ' - ';
          }
          name += this.userService.translateItem(ref, 'name');
          listItem.description = name;
          ret.push(listItem);

          if (ref.zones && ref.zones.length) {
            ref.zones.forEach(zone => this.findZonesRecursive(zone, ret, includeDisabled, listItem));
          }
        });
      }
    });

    return orderBy(ret, [zone => zone.description.toLowerCase()], ['asc']);
  }

  public findZoneRecursive(zones, zoneID) {
    if (!zones) {
      return;
    }

    for (const item of zones) {
      if (item.zoneID === zoneID) {
        return item;
      }
      const child = this.findZoneRecursive(item.zones, zoneID);
      if (child) {
        return child;
      }
    }
  }

  public getGroupedZonesByLocations(locationIds: number[] = [], displayParentZone = true): any {
    let zonesGroupedByLocations: ILocationZonesItem[] = [];

    each(this.userService.locations.data, (location: ILocation) => {
      if (includes(locationIds, location.locationID) || locationIds.length === 0) {
        const zones: IZone[] = [];
        location.zones?.forEach(zone => this.getZonesRecursive(zone, zones));

        const locationListItem: ILocationZonesItem = {
          locationID: location.locationID,
          text: location.name,
          disabled: location.disabledAt > 0,
          children: map(zones, (zone) =>  {
            let route = '';
            let parent = zone.parent;

            if (displayParentZone) {
              while (parent) {
                route = this.userService.translateItem(parent, 'name') + ' / ' + route;
                parent = parent.parent;
              }
            }

            return ({
              id: _toString(zone.zoneID),
              text: route + this.userService.translateItem(zone, 'name')
            });
          })
        };

        locationListItem.children.unshift({
          id: `${location.locationID}:0`,
          text: this.translate.instant('SHARED.Site-wide')
        });

        zonesGroupedByLocations.push(locationListItem);
      }
    });

    zonesGroupedByLocations = sortBy(zonesGroupedByLocations, (sortItem: any) => {
      if (sortItem.children) {
        sortItem.children = sortBy(sortItem.children, (child) => child.text === 'Site-wide' || lowerCase(child.text));
      }
      return lowerCase(sortItem.text);
    });

    return zonesGroupedByLocations;
  }

  // Find zones for buildZoneList and put id/name for select to 'zonesList'
  private findZonesRecursive(zone: IZone, zonesList: { id: string | number; description: string }[], includeDisabled: boolean, parentListItem: { id: string | number; description: string }) {
    if (!includeDisabled && zone.disabledAt) {
      return;
    }
    const description = `${parentListItem.description} - ${this.userService.translateItem(zone, 'name')}`;
    const currentListItem = {id: zone.zoneID, description};

    if (zone.zones?.length > 0) {
      zone.zones.forEach((item) => this.findZonesRecursive(item, zonesList, includeDisabled, currentListItem));
    }
    zonesList.push(currentListItem);
  }

  // Find all children zones of a zone and put to 'zones' array
  private getZonesRecursive(zone: IZone, zones: IZone[], parent?: IZone) {
    if (!zone.disabledAt) {
      if (zone.zones?.length > 0) {
        zone.zones.forEach((item) => this.getZonesRecursive(item, zones, zone));
      }
      if (parent) {
        zone.parent = parent;
      }
      zones.push(zone);
    }
  }
}
