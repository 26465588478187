<ion-header *ngIf="headerIsHidden || navFromSuperBoard" no-border>
  <app-close-button (onClicked)="superTaskBack()" *ngIf="headerIsHidden && navFromSuperBoard"></app-close-button>
  <app-close-button *ngIf="headerIsHidden && !navFromSuperBoard"></app-close-button>
</ion-header>

<ion-content>
  <div class="wide bottom-footer-space">
    <app-detail-header [headerData]="headerData"></app-detail-header>

    <app-detail-controls [headerData]="headerData"></app-detail-controls>
    <!--<app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes>-->
    <!-- <app-closing-details *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-details> -->

    <app-observation-tag-list #tagListRef [ids]="tags"></app-observation-tag-list>
    <hr *ngIf="tagListRef.tags.length" class="hr-dashed">

    <!-- Check Detail Block -->
    <div class="detail-page-section">
      <div class="headline-medium">{{ "DASHPAGES.check-issue" | translate }}</div>
      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col size="3">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "SHARED.Deployment_Name" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="adjustment-grid-value">{{caDetail.deployment}}</div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "DASHPAGES.question-item" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="adjustment-grid-value">
                    {{caDetail.question}}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left" size="3">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "DASHPAGES.check-answer" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div [ngClass]="'red-block'">
                    {{ caDetail.answer }}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "SHARED.Category" | translate }}</div>
                <div *ngIf="categoryObjs" class="adjustment-grid-item tag-item">
                  <div class="tag-value">
                    {{ categoryObjs }}
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <!-- Check Detail Block End-->


    <!-- IMAGES BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium">{{ "DASHPAGES.issue-input" | translate }}</div>
      <image-gallery-modal [inputImages]="images"></image-gallery-modal>
      <app-asset-status-panel [observation]="observationObject"></app-asset-status-panel>
      <hr class="hr-dashed">
    </div>
    <!-- IMAGES BLOCK END-->


    <!-- DOUBLE NOTE-->
    <div *ngIf="notes.createdNotes" class="detail-page-section">

      <div class="headline-medium">{{ "SHARED.Observation_Notes" | translate }}</div>

      <div class="section-grid">
        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-logged" id="noteColorCode">
              {{ "DASHPAGES.escalated" | translate }}
            </span>
            <ion-button (click)="addOpenNote()" [appAccountCanViewExcept]="['viewer']"
                        class="page-button smaller border-margin" color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>

          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.createdNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p><span class="observation-u-name">{{note.name}}</span> | <span>{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async" class="notes-list-item-text">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="state==='resolved'" class="section-grid-right">
          <!--FIXED OBSERVATION NOTES-->
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-fixed">
              {{ "DASHPAGES.resolved" | translate }}
            </span>
            <ion-button (click)="addClosedNote()" [appAccountCanViewExcept]="['viewer']"
                        class="page-button smaller border-margin" color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.closingNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p><span class="observation-u-name">{{note.name}}</span> | <span>{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown | async" class="notes-list-item-text">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="hr-dashed">
    </div>
    <!-- DOUBLE NOTE END-->

    <!--OBSERTVATION HISTORY-->
    <app-observation-history [observationId]="oid" [title]="'SHARED.Observation_History'"></app-observation-history>

    <!--OBSERTVATION HISTORY END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
