import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { ObservationService } from '@services/observations/observation.service';
import { ILocation, IZone, UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils.service';
import { SettingsService } from '@services/settings/settings.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { LoadingService } from '@services/loading/loading.service';
import { Feature, Module, SubscriberService, SubscriberUsageType } from '@services/subscriber/subscriber.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { IModuleSubscriberAccess, ModuleAccessService } from '@services/moduleAccess/module-access.service';
import { EventHandler } from '@services/events/events.service';
import { MenuService } from '@services/menu/menu.service';
import { Observation } from '@services/observations/observation.interfaces';
import { AssetsService } from '@services/assets/assets.service';

import * as moment from 'moment';
import {
  cloneDeep,
  concat,
  constant,
  difference,
  each,
  filter,
  find,
  forEach,
  includes,
  indexOf,
  map,
  mean,
  meanBy,
  merge,
  orderBy, sortBy,
  sumBy, times,
  upperFirst
} from 'lodash';

export interface IHeaderSetting {
  title: string;
  icon?: DashboardHeaderIcon[];
  filter?: any;
  selectDropDown?: {
    data?: any[];
    option?: any;
    defaultVal?: any;
    dataNavMenuKey?: Module;
  };
  showSearchField?: boolean;
  disableSearchField?: boolean;
  showBreadCrumb?: boolean;
  breadCrumbObject?: IBreadCrumbObject;
  customButton?: ICustomButtonObject;
  hideSelectionArrow?: boolean;
  type?: any;
}

export interface ICustomButtonObject {
  title: string;
  class: string;
  module?: string;
  handler: EventHandler;
}

export interface IBreadCrumbObject {
  pageTitle: string; // the title of the page
  backNavTitle: string; // display name for back navigation
  backNavUrl: string; // url of intentded navigation
}

export interface IMenuIcon {
  src: string;
  selected: boolean;
  name: string;
  disabledSrc: string;
}

export interface IHeaderIcon {
  filter?: any;
  activeSrc: string;
  inactiveSrc: string;
  src?: string;
  plugin?: string;
  feature?: string;
  routeUrl: string;
  type: DashboardHeaderIcon;
  label?: string;

  isAvailable?(): boolean;
}

export interface ObservationIcon {
  disabled?: boolean;
  routeUrl?: string;
  id: string;
  label?: string;
}

export enum DashboardHeaderIcon {
  SiteAwareness,
  Observations,
  SupervisorDashboard,
  Leaderboards,
  WorkStatus,
  WorkZone,
  AssetStatus,
  FrontlineCulture
}

export interface ILocationHealth {
  name?: string;
  locationID?: number;
  openCount?: number;
  avgTime?: string;
  avgTimeSeconds?: number;
  riskIndex?: string;
  riskClass?: string;
  qualityCount?: number;
  waitingTime?: number;
}

export interface ISubscriberRisk {
  safetyIndex?: string;
  class?: string;
}

export interface IDashboardHomeData {
  locationHealth?: ILocationHealth[];
  subscriberRisk?: ISubscriberRisk;
}

export interface IMenuNavItem {
  id: string;
  text: string;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ObservationDashboardUIService {

  public locationDashboardFilter: any = {}; // this is the filter object for location dashboard
  public observationHomePreference: any = {};
  public lineChartIndices: any = {};
  public pieChartIndices: any = {};

  private observationType = ['condition', 'behavior', 'quality', 'pi', 'ca', 'compliment', 'si', 'ai'];
  private orderOfElements = ['All', 'condition', 'behavior', 'quality', 'pi', 'ai', 'ca', 'compliment', 'si'];
  private dashboardHomeData: IDashboardHomeData = {};
  private headerIcons: IHeaderIcon[] = [
    {
      activeSrc: 'icon_site_aware_blue.svg',
      inactiveSrc: 'icon_site_aware_grey.svg',
      routeUrl: '/pages/dashboard/home',
      type: DashboardHeaderIcon.SiteAwareness,
      label: 'SHARED.PPE_Awareness',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/home';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'icon_obs_blue.svg',
      inactiveSrc: 'icon_obs_grey.svg',
      routeUrl: '/pages/dashboard/main',
      type: DashboardHeaderIcon.Observations,
      label: 'LAYOUT.Observation_Activity',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/main';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'observations_blue.svg',
      inactiveSrc: 'observations_grey.svg',
      routeUrl: '/pages/dashboard/supervisor-dashboard',
      type: DashboardHeaderIcon.SupervisorDashboard,
      label: 'LAYOUT.Supervisor_Taskboard',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/supervisor-dashboard';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'icon_leaderboard_blue.svg',
      inactiveSrc: 'icon_leaderboard_grey.svg',
      routeUrl: `/pages/dashboard/leaderboards`,
      type: DashboardHeaderIcon.Leaderboards,
      label: 'SHARED.Leaderboards',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/leaderboards';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'icon_user_blue.svg',
      inactiveSrc: 'icon_user_grey.svg',
      routeUrl: '/pages/dashboard/worker',
      type: DashboardHeaderIcon.WorkStatus,
      label: 'LAYOUT.Worker_Status',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/worker';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'workerZoneBlue.svg',
      inactiveSrc: 'workerZoneGrey.svg',
      routeUrl: '/pages/dashboard/worker-zone',
      type: DashboardHeaderIcon.WorkZone,
      label: 'LAYOUT.Worker_Zone_Status',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/worker-zone';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'workerZoneBlue.svg',
      inactiveSrc: 'workerZoneGrey.svg',
      routeUrl: '/pages/dashboard/asset',
      type: DashboardHeaderIcon.AssetStatus,
      label: 'LAYOUT.Asset_Status',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/asset';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    },
    {
      activeSrc: 'workerZoneBlue.svg',
      inactiveSrc: 'workerZoneGrey.svg',
      routeUrl: '/pages/dashboard/frontline-culture',
      type: DashboardHeaderIcon.FrontlineCulture,
      label: 'SHARED.Frontline_Culture',
      isAvailable: () => {
        const menuItemUrl = '/pages/dashboard/frontline-culture';

        return this.menuService.checkMenuItemByUrl(menuItemUrl);
      }
    }
  ];
  private colorMap = {
    behavior: '#988000',
    compliment: '#279F3E',
    condition: '#FAD400',
    quality: '#8800FF',
    ca: '#D0021B',
    pi: '#460085',
    si: '#33CEB0',
    ai: '#1AA0A4'
  };
  private brand = 'corvex';
  private location: ILocation;

  constructor(
    private observationService: ObservationService,
    private userdataService: UserdataService,
    private userService: UserService,
    private utils: UtilsService,
    private settingsService: SettingsService,
    private accountService: AccountsService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private subscriberService: SubscriberService,
    private permissions: PermissionsService,
    private moduleAccessService: ModuleAccessService,
    private menuService: MenuService,
    private router: Router,
    private assetService: AssetsService
  ) {
  }

  public initDataTable(idReference, noSort: boolean = false) {
    $(idReference).show();
    if ($(idReference + ' thead').length) {
      const opts = {
        paging: false,
        searching: false, // enables the searching over table
        info: false,
        order: [],
        ordering: !noSort,
        language: {
          emptyTable: this.translate.instant('SHARED.No_Activity'),
          sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
        },
      };
      const t: any = $(idReference).DataTable(opts);
      // $(location + ' td').css({ 'text-align': 'left !important', 'padding-left': '0px !important'});

    }
  }

  /**
   * prepares the locationHealth data and subscriber risk info for main observation dashboard.
   */
  public prepareDashboardHomeDataSet(): IDashboardHomeData {
    // 1. get risk score for whole subscriber
    this.dashboardHomeData.subscriberRisk = this.getSubscriberRisk();

    // 2. get site health
    this.dashboardHomeData.locationHealth = this.getLocationHealthData();
    return this.dashboardHomeData;
  }

  /**
   * Prepares location(s) detail data, prepares the raw data as per selected filter object.
   * Then passes the data to other data prunning functions according to the nature of data.
   * Current filters available Location, Team and Time-range
   */
  public prepareLocationDetailDataSet(): any {
    this.loadingService.enable();

    if (this.locationDashboardFilter.locations?.length === 1) {
      this.location = this.userService.getLocation(this.locationDashboardFilter.locations[0]);
    } else {
      this.location = undefined;
    }

    const locationData: any = {};

    // As a first step, we filter out the observations information with the current -locationDashboardFilter object.
    // no we have to apply multiple filters to extract data to our likings.

    // 1. get Count for open unsafe and quality observations AND coaching and thumbs up.
    locationData.counts = this.getLocationCountData();

    // 2. get data for Zone health for quality, Condition, Coaching and PI
    locationData.zoneHealth = this.getZoneHealthData();

    // 3. Get line chart data for given interval of time and other filters
    locationData.lineData = this.getLocationLineData();
    this.loadingService.disable();
    return locationData;
  }

  public getIconByType(type: DashboardHeaderIcon): IHeaderIcon {
    return find(cloneDeep(this.headerIcons), {type});
  }

  /**
   * @param zoneInfoObj- takes the zone info and build custom elements and click handles for various types of observations
   * @returns tableAttribute that is fed into showTables method in table Service
   */
  public buildZoneTableAttrs(zoneInfoObj) {
    const tableAttr: any = {};
    let columns: any = [];

    // let's grab zone-site name, that's common to all
    const siteZoneName = {
      id: 'name',
      title: this.translate.instant('SHARED.HOME_TS_ZONE'),
      headerClass: 'zone-table-header no-vis',
    };
    tableAttr.rowprop = 'data-lid';
    tableAttr.rowvaluefrom = 'locationZone';

    // 1. zone health elements => location-zone name, obs logged, avg time open, risk index
    if (zoneInfoObj.id === 'zoneCondition') {
      tableAttr.class = 'condition-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header no-vis',
          class: 'zone-health-number'
        },
        {
          id: 'avgTime',
          title: this.translate.instant('SHARED.LOCATION_descriptor1'),
          headerClass: 'zone-table-header no-vis'
        },
        {
          id: 'riskIndex',
          title: this.translate.instant('SHARED.SITE_HEALTH_siteLabel3'),
          headerClass: 'zone-table-header-selected no-vis',
          fromID: 'riskIndex',
          func: (val) => {
            const className = this.findSiteHealthColor(val);

            return `<div class="${className} zone-health-number">${val}</div>`;
          },
        }
      ];
    }

    // 2. zone coaching elements => zone, obs logged, most-frequent
    if (zoneInfoObj.id === 'zoneCoaching') {
      tableAttr.class = 'coaching-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header-selected no-vis'
        },
        {
          id: 'message',
          title: this.translate.instant('ODetail.MOST_FREQUENT'),
          headerClass: 'zone-table-header no-vis'
        }
      ];
    }

    // 3. zone quality elements => zone obs logged, avg time open
    if (zoneInfoObj.id === 'zoneQuality') {
      tableAttr.class = 'quality-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header-selected no-vis'
        },
        {
          id: 'avgTime',
          title: this.translate.instant('SHARED.LOCATION_descriptor1'),
          headerClass: 'zone-table-header no-vis'
        }
      ];
    }
    // 4. zone process elements -> zone-name, obs logged, avg waiting time
    if (zoneInfoObj.id === 'zonePI') {
      tableAttr.class = 'process-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header no-vis'
        },
        {
          id: 'waiting',
          title: this.translate.instant('ODetail.WAITING_TIME'),
          headerClass: 'zone-table-header-selected no-vis'
        }
      ];
    }

    // 5. zone compliment elements => zone, obs logged, most-frequent
    if (zoneInfoObj.id === 'zoneCompliment') {
      tableAttr.class = 'compliment-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header-selected no-vis'
        },
        {
          id: 'message',
          title: this.translate.instant('ODetail.MOST_FREQUENT'),
          headerClass: 'zone-table-header no-vis'
        }
      ];
    }

    // 6. zone CA elements => zone, obs logged, most-frequent
    if (zoneInfoObj.id === 'zoneCA') {
      tableAttr.class = 'ca-table';
      columns = [
        siteZoneName,
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header-selected no-vis',
          class: 'zone-health-number'
        },
        {
          id: 'avgTime',
          title: this.translate.instant('SHARED.LOCATION_descriptor1'),
          headerClass: 'zone-table-header no-vis'
        },
      ];
    }

    if (zoneInfoObj.id === 'zoneAI') {
      tableAttr.class = 'assets';
      columns = [
        {
          id: 'name',
          title: `${this.translate.instant('SHARED.Zone')} / ${this.translate.instant('SHARED.Asset')}`,
          headerClass: 'table-header zone-table-header no-vis'
        },
        {
          id: 'count',
          title: this.translate.instant('ODetail.OBS_LOGGED'),
          headerClass: 'zone-table-header-selected no-vis',
          class: 'zone-health-number'
        },
        {
          id: 'status',
          title: this.translate.instant('SHARED.Asset_Status'),
          headerClass: 'table-header zone-table-header no-vis',
          func: () => {
            return `<img class="image-spacer obs-dot" src="assets/images/highestDot.svg">`;
          }
        }
      ];
    }
    tableAttr.columns = columns;
    return tableAttr;
  }

  /**
   * This method returns the label object that can be placed according to eric's design EX(pie and line chart on observation dashboard)
   *
   * @param chart ChartObject created by chart.js
   */
  public generateLabel(chart: any): any {
    const retObject: any = [];
    const dataSet = chart.data.datasets;
    const labels = chart.data.labels;

    if (chart.config.type === 'pie') {
      dataSet[0].data.forEach((element, index) => {
        const labelObject: any = {};
        let liClass = null;
        if (index % 2 !== 0) {
          liClass = 'pie-label-border';
        }
        labelObject.index = index;
        labelObject.class = liClass;
        // place to change the name of label if we decide to move to other
        // nomenclature
        if (labels[index] === 'Conditions') {
          labelObject.label = this.translate.instant('SHARED.Unsafe_Conditions');
        } else {
          labelObject.label = labels[index];
        }
        labelObject.borderColor = dataSet[0].backgroundColor[index];
        labelObject.backgroundColor = dataSet[0].backgroundColor[index];
        retObject.push(labelObject);
      });
    } else if (chart.config.type === 'line') {
      dataSet.forEach((label, index) => {
        const labelObject: any = {};
        let liClass = null;
        if (index % 2 !== 0) {
          liClass = 'pie-label-border';
        }
        labelObject.index = index;
        labelObject.class = liClass;
        labelObject.borderColor = label.borderColor;
        labelObject.backgroundColor = label.backgroundColor;
        if (label.label === 'pi') {
          labelObject.label = this.translate.instant('SHARED.Process');
          label.label = this.translate.instant('SHARED.Process');
        } else if (label.label === 'ai') {
          labelObject.label = this.translate.instant('SHARED.Asset_Issues');
          label.label = this.translate.instant('SHARED.Asset_Issues');
        } else if (label.label === 'behavior') {
          labelObject.label = this.translate.instant('SHARED.Coaching');
          label.label = this.translate.instant('SHARED.Coaching');
        } else if (label.label === 'compliment') {
          labelObject.label = this.translate.instant('SHARED.Thumbs-Up');
          label.label = this.translate.instant('SHARED.Thumbs-Up');
        } else if (label.label === 'si') {
          labelObject.label = this.translate.instant('SHARED.Opportunities');
          label.label = this.translate.instant('SHARED.Opportunities');
        } else {
          if (label.label === 'All') {
            labelObject.label = this.translate.instant('SHARED.All');
            label.label = this.translate.instant('SHARED.All');
          } else if (label.label === 'quality') {
            labelObject.label = this.translate.instant('SHARED.Quality');
            label.label = this.translate.instant('SHARED.Quality');
          } else if (label.label === 'condition') {
            labelObject.label = this.translate.instant('SHARED.Conditions');
            label.label = this.translate.instant('SHARED.Conditions');
          } else if (label.label === 'ca') {
            labelObject.label = this.translate.instant('SHARED.Corrective_Actions');
            label.label = this.translate.instant('SHARED.Corrective_Actions');
          } else {
            labelObject.label = upperFirst(label.label);
            label.label = upperFirst(label.label);
          }

        }
        retObject.push(labelObject);
      });

    }


    return retObject;
  }

  /** Filters the pie and line chart by selected span from dropdown.
   *
   * @param timespan - timespan on format accepted by filter method. accepted formats ['7days', '30days', '90days', '180days', '365days']
   * @returns  Pie and line chart data filtered by selected time span.
   */
  public filterChartData(timeInterval: string): any {
    const timespan = this.utils.timespan(timeInterval);
    const chartData: any = {};
    let timePeriod = 'days';
    if (timeInterval === 'today') {
      timePeriod = 'hours';
    } else if (timeInterval === '90days') {
      timePeriod = 'weeks';
    } else if (timeInterval === '365days') {
      timePeriod = 'months';
    }

    const opts: any = {
      startTime: timespan.startTime,
      endTime: timespan.endTime,
      obstype: ['pi', 'ai', 'condition', 'behavior', 'quality', 'ca', 'compliment', 'si'],
      period: timePeriod,
      timespan: timeInterval
    };
    const pGroup = {
      fieldName: 'type'
    };
    const filteredData = this.observationService.findInIntervals(opts, pGroup);
    chartData.pie = this.preparePieChartdata(filteredData);
    chartData.line = this.prepareLineChartData(filteredData, timeInterval);
    return chartData;
  }

  public getMenuNavItems(key: Module): IMenuNavItem[] {
    const observationNavItem = this.menuService.getMenuItemByKey(key);
    const observationNavItems = filter(observationNavItem?.children, 'isAvailable');

    return map(observationNavItems, (navItem) => ({
      text: this.translate.instant(navItem.title),
      id: navItem.url,
      disabled: includes(this.router.url, navItem.url)
    }));
  }

  private checkHeaderIcon(alias: Module | Feature, permission: Permission, types: SubscriberUsageType[] = [SubscriberUsageType.Module], subscriberAccess?: IModuleSubscriberAccess): boolean {
    const isModuleAvailable: boolean = this.subscriberService.uses(types, alias);
    let isSubscriberAccessAllowed = true;
    let isPermissionAccessAllowed: boolean = this.permissions.canView(permission.toString());

    if (subscriberAccess) {
      if (this.moduleAccessService.isAllowedAccount()) {
        isPermissionAccessAllowed = isPermissionAccessAllowed || this.moduleAccessService.isAccountPermitted(subscriberAccess);
      } else {
        isSubscriberAccessAllowed = this.moduleAccessService.hasSubscriberAccess(subscriberAccess);

        if (isSubscriberAccessAllowed) {
          isPermissionAccessAllowed = true;
        }
      }
    }

    return isModuleAvailable && isSubscriberAccessAllowed && isPermissionAccessAllowed;
  }

  /**
   * returns the line chart data based on selection of filter and locations
   */
  private getLocationLineData() {
    const lineFilter: any = cloneDeep(this.locationDashboardFilter);
    if (!lineFilter.timespan || lineFilter.timespan === 'all') {
      lineFilter.timespan = '365days';
    }
    const timespan = this.utils.timespan(lineFilter.timespan);
    const chartData: any = {};
    let timePeriod = 'days';
    if (lineFilter.timespan === 'today') {
      timePeriod = 'hours';
    } else if (lineFilter.timespan === '90days') {
      timePeriod = 'weeks';
    } else if (lineFilter.timespan === '365days') {
      timePeriod = 'months';
    }

    // adding additional param to filterObject
    lineFilter.obstype = ['pi', 'ai', 'condition', 'behavior', 'quality', 'ca', 'compliment'];
    lineFilter.startTime = timespan.startTime;
    lineFilter.endTime = timespan.endTime;
    lineFilter.period = timePeriod;
    const pGroup = {
      fieldName: 'type'
    };
    const filteredData = this.observationService.findInIntervals(lineFilter, pGroup);
    const lineData = this.prepareLineChartData(filteredData, lineFilter.timespan);
    return lineData;
  }

  private getZoneHealthData() {
    const returnObj: any = {};
    const pGroup = {
      fieldName: 'zoneID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + ref.zoneID;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, ref.zoneID);
        if (zoneRef) {
          return [zsig, locRef.name + ' / ' + zoneRef.name];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    };
    const sGroup = {
      fieldName: 'type'
    };

    // 1 get unsafe zone health data
    const tempConditionFitler = cloneDeep(this.locationDashboardFilter);
    tempConditionFitler.states = ['open'];
    tempConditionFitler.obstype = ['condition'];
    const tempArray = this.getHealthData(tempConditionFitler, pGroup);
    const orderedZoneData = orderBy(tempArray, ['riskIndex'], ['desc']);
    returnObj.zoneCondition = orderedZoneData;

    // 2 get coaching zone health data
    const tempCoachingFilter = cloneDeep(this.locationDashboardFilter);
    tempCoachingFilter.obstype = ['behavior'];
    returnObj.zoneCoaching = this.getHealthData(tempCoachingFilter, pGroup);

    // 3 get quality zone health data
    const tempQualityFilter = cloneDeep(this.locationDashboardFilter);
    tempQualityFilter.obstype = ['quality'];
    tempQualityFilter.states = ['open'];
    returnObj.zoneQuality = this.getHealthData(tempQualityFilter, pGroup);

    // 4 get compliment zone health data
    const tempComplimentFilter = cloneDeep(this.locationDashboardFilter);
    tempComplimentFilter.obstype = ['compliment'];
    returnObj.zoneCompliment = this.getHealthData(tempComplimentFilter, pGroup);

    // 5 get PI zone health data
    const tempPIFilter = cloneDeep(this.locationDashboardFilter);
    tempPIFilter.obstype = ['pi'];
    returnObj.zonePI = this.getHealthData(tempPIFilter, pGroup);

    // 6 get CA zone health data
    const tempCAFilter = cloneDeep(this.locationDashboardFilter);
    tempCAFilter.obstype = ['ca'];
    tempCAFilter.states = ['open'];
    returnObj.zoneCA = this.getHealthData(tempCAFilter, pGroup);

    // 7 get coaching zone health data
    const tempAIFilter = cloneDeep(this.locationDashboardFilter);
    tempAIFilter.obstype = ['ai'];
    tempAIFilter.states = ['open'];
    pGroup.fieldFunc = (val, observation: Observation) => {
      const zoneId = `${observation.locationID}:${observation.zoneID}`;
      const location: ILocation = this.userService.findLocation(observation.locationID);
      const zone: IZone = this.userService.findAnyZone(location, observation.zoneID);
      const asset = this.assetService.getAssetById(observation.subjectID)?.[0];
      if (!asset) {
        return;
      }
      const defaultZone: string = this.translate.instant('SHARED.Site-wide');
      const tableRowId: string = `${zoneId}:${asset.assetID}`;
      let label: string = defaultZone;

      if (location) {
        const zoneName = zone?.name || defaultZone;
        label = `${location.name} / ${zoneName}`;
      }

      if (asset?.name) {
        label += ` / ${asset.name}`;
      }

      return [tableRowId, label];
    };
    returnObj.zoneAI = this.getHealthData(tempAIFilter, pGroup);

    return returnObj;
  }

  private getHealthData(tempConditionFitler, pGroup): any {
    const dataRaw = this.observationService.findInIntervals(tempConditionFitler, pGroup);

    // if condition, need to consider work order as well
    if (tempConditionFitler.obstype[0] === 'condition' || tempConditionFitler.obstype[0] === 'ca') {
      delete tempConditionFitler.states;
      tempConditionFitler.hasWorkorder = 1;
      const workRaw = this.observationService.findInIntervals(tempConditionFitler, pGroup);
      each(workRaw.rows, (wData, key) => {
        if (dataRaw.rows[key]) {
          dataRaw.rows[key].items = concat(dataRaw.rows[key].items, wData.items);
        } else {
          dataRaw.rows[key] = wData;
        }

      });
    }
    const zoneData: any = [];
    forEach(dataRaw.rows, (obj, key) => {
      const tempObj: any = {};
      const avgUTime = meanBy(obj.items, 'created');
      tempObj.avgTime = moment(avgUTime * 1000).fromNow(true);
      tempObj.count = obj.items.length;
      tempObj.name = obj.label;
      // tempObj.items = obj.items;
      if (tempConditionFitler.obstype && tempConditionFitler.obstype[0] === 'condition') {
        tempObj.riskIndex = meanBy(obj.items, 'safetyIndex').toFixed(0);
      }
      if (tempConditionFitler.obstype && tempConditionFitler.obstype[0] === 'behavior') {
        const repeatedMsg = this.findCountOfCategories(obj.items, 'behaviors');
        if (repeatedMsg.length > 0) {
          tempObj.message = repeatedMsg[0].message;
        } else {
          tempObj.message = null;
        }
      }
      if (tempConditionFitler.obstype && tempConditionFitler.obstype[0] === 'pi') {
        const waiting = sumBy(obj.items, (testObj: any) => {
          if (testObj.subtype === 'waiting') {
            return Number(testObj.value);
          }
        });
        tempObj.waiting = waiting;
      }
      if (tempConditionFitler.obstype && tempConditionFitler.obstype[0] === 'compliment') {
        const repeatedMsg = this.findCountOfCategories(obj.items, 'compliments');
        if (repeatedMsg.length > 0) {
          tempObj.message = repeatedMsg[0].message;
        } else {
          tempObj.message = null;
        }
      }
      tempObj.locationZone = key;
      zoneData.push(tempObj);
    });
    // lets sort this out by the count.
    const orderedZoneData = orderBy(zoneData, ['count'], ['desc']);
    return orderedZoneData;
  }

  /**
   * @param data - array of behaviors for top behaviors
   */
  private prepareBarChartData(data) {
    if (data.length > 5) {
      data = data.splice(0, 5);
    }
    const value = [];
    const label = [];
    forEach(data, (elem) => {
      value.push(elem.count);
      if (elem.message.length > 35) {
        const trimMsg = elem.message.substring(0, 34) + '..';
        label.push(trimMsg);
      } else {
        label.push(elem.message);
      }

    });
    const dataBehaviorObject = [{
      data: value,
      backgroundColor: '#D8B600',
      borderColor: '#D8B600'
    }];

    return {
      labels: label,
      datasets: dataBehaviorObject
    };
  }

  /**
   * @returns openUnsafeCount , openQualityCount, coachingCount, ThumbsupCount, TopCoaching, TopThumbsup
   */
  private getLocationCountData(): any {
    const returnObj: any = {};
    // locations, groups
    const tempFilter = cloneDeep(this.locationDashboardFilter);
    tempFilter.states = ['open'];
    const pGroup = {
      fieldName: 'type'
    };

    let conditionCount = [];
    let workOrderCount = [];
    let totalCount = [];

    // ***  1. first get count data for condition and quality, this will be used for zone health and quality health  ***  //

    // 1.a get open count and avg time.
    const openQualityConditionRaw = this.observationService.findInIntervals(tempFilter, pGroup);

    // 1.b get count of workorder
    delete tempFilter.states;
    tempFilter.hasWorkorder = 1;
    tempFilter.obstype = ['condition'];
    const obsWorkorderCount = this.observationService.findInIntervals(tempFilter, pGroup);

    // now delete hasworkorder status for rest filters
    delete tempFilter.hasWorkorder;

    if (openQualityConditionRaw.rows.condition) {
      conditionCount = openQualityConditionRaw.rows.condition.items;
    }

    if (obsWorkorderCount.rows.condition) {
      workOrderCount = obsWorkorderCount.rows.condition.items;
      totalCount = concat(conditionCount, workOrderCount);
    } else {
      totalCount = conditionCount;
    }


    if (totalCount.length) {
      const openCount = totalCount.length;
      const avgUTime = meanBy(totalCount, 'created');
      const avgTime = moment(avgUTime * 1000).fromNow(true);
      returnObj.unsafe = {
        count: openCount,
        timeOpen: avgTime
      };
    } else {
      returnObj.unsafe = {
        count: 0,
        timeOpen: null
      };
    }

    // 1.c get quality count and avg time
    if (openQualityConditionRaw.rows.quality) {
      const qualityCount = openQualityConditionRaw.rows.quality.items.length;
      const avgUTime = meanBy(openQualityConditionRaw.rows.quality.items, 'created');
      const avgTime = moment(avgUTime * 1000).fromNow(true);
      returnObj.quality = {
        count: qualityCount,
        timeOpen: avgTime
      };
    } else {
      returnObj.quality = {
        count: 0,
        timeOpen: null
      };
    }

    // ** 2. find count for coaching, thumbsup, top coaching and thumbsup leader board. ** //
    tempFilter.states = ['closed'];
    tempFilter.obstype = ['behavior', 'compliment', 'pi'];
    const closedObsRaw = this.observationService.findInIntervals(tempFilter, pGroup);

    // 2a get coaching count and top coaching activities
    if (closedObsRaw.rows.behavior) {
      // 2.a.i get the count of behavior
      const behaviorCount = closedObsRaw.rows.behavior.items.length;

      // 2.a.ii find top coaching behaviors that fits chart js
      // find the Ids of behaviors through the system
      const behavMap = this.findCountOfCategories(closedObsRaw.rows.behavior.items, 'behaviors');
      const barChartData = this.prepareBarChartData(behavMap);
      returnObj.coaching = {
        count: behaviorCount,
        topObject: barChartData
      };
    } else {
      returnObj.coaching = {
        count: 0,
        topObject: null
      };
    }

    // 2b get thumbs up count
    if (closedObsRaw.rows.compliment) {
      // 2 b.i get count of the compliments
      const complimentCount = closedObsRaw.rows.compliment.items.length;

      // 2.b.ii get leader board data
      const thumbsMap = [];
      each(closedObsRaw.rows.compliment.items, (val) => {
        each(val.recipients, (cat) => {

            const tempVar = find(this.accountService.accounts.data, ['userID', cat]);
            const mappedUser = find(thumbsMap, ['userID', cat]);
            if (tempVar) { // the user actually needs to exist, might have been deleted on the way
              // lets inject the picture as well
              tempVar.imgSrc = this.userService.accountAvatar(cat, 100, null, false);
              tempVar.class = 'compliment-border';
              tempVar.compactName = this.accountService.getCompactName(cat);
              if (mappedUser) {
                mappedUser.count++;
              } else {
                tempVar.count = 1;
                thumbsMap.push(tempVar);
              }
            }

          }
        );
      });
      let complimentOrdered = orderBy(thumbsMap, ['count'], ['desc']);
      if (complimentOrdered.length > 5) {
        complimentOrdered = complimentOrdered.splice(0, 5);
      }
      returnObj.compliment = {
        count: complimentCount,
        topCompliment: complimentOrdered
      };
    } else {
      returnObj.compliment = {
        count: 0,
        topObject: null
      };
    }
    return returnObj;
  }

  /**
   * @param dataArray - collection of observations
   // tslint:disable-next-line:max-line-length
   * @param type - could be one of the category type ex, behaviors, compliments, groups etc,
   check settingsService for what types are available.
   * @return max repeated items
   */
  private findCountOfCategories(dataArray, type) {
    const behaviorIds = map(this.settingsService[type].data, 'messageID');
    const behavMap: any = [];
    each(dataArray, (val) => {
      each(val.categories, (cat) => {
        if (includes(behaviorIds, cat)) {
          const tempVar = find(behavMap, ['Id', cat]);
          if (tempVar) {
            tempVar.count++;
          } else {
            // let's get what message this is as well since we're inserting
            const behavObj = find(this.settingsService[type].data, ['messageID', cat]);
            behavMap.push({
              Id: cat,
              count: 1,
              message: behavObj.messageTitle
            });
          }
        }
      });
    });
    const sortedVal = orderBy(behavMap, ['count'], ['desc']);
    return sortedVal;
  }

  /**
   * gets the location vital stats for main dashboard
   */
  private getLocationHealthData(): ILocationHealth[] {
    const siteHealthData: ILocationHealth[] = [];
    const locationInfo = this.userService.getUserLocations(this.userdataService.locations, false);
    const timespan = this.utils.timespan('');
    const opts: any = {
      startTime: timespan.startTime,
      endTime: timespan.endTime,
      states: ['open'],
      period: 'none',
      timespan: '30days'
    };
    const pGroup = {
      fieldName: 'locationID',
    };
    const sGroup = {
      fieldName: 'type'
    };
    const obsQualityCondition = this.observationService.findInIntervals(opts, pGroup, sGroup);
    // now let's use the same query to get info about process waiting
    opts.obstype = ['pi'];
    opts.states = ['closed'];
    const obsProcessImp = this.observationService.findInIntervals(opts, pGroup, sGroup);
    // let get workorder count as well.
    delete opts.states;
    opts.hasWorkorder = 1;
    opts.obstype = ['condition'];
    const obsWorkorderCount = this.observationService.findInIntervals(opts, pGroup);
    // const obsWorkorderCount = {rows: {}};
    // prepare data set for display
    each(locationInfo, (val) => {
      const locationObject: any = {};
      let openCount = [];
      let workCount = [];
      let totalCount = [];
      // get location name and ID
      locationObject.name = val.name;
      locationObject.locationID = val.locationID;
      if (obsQualityCondition.rows[val.locationID] && obsQualityCondition.rows[val.locationID].secondary.condition) {
        openCount = obsQualityCondition.rows[val.locationID].secondary.condition.items;
      }
      if (obsWorkorderCount.rows[val.locationID]) {
        workCount = obsWorkorderCount.rows[val.locationID].items;
        totalCount = concat(openCount, workCount);
      } else {
        totalCount = openCount;
      }

      // now let's get open count, risk score and other interesting data
      if (totalCount.length) {
        locationObject.openCount = totalCount.length;

        if (totalCount) {
          const avgUTime = meanBy(totalCount, 'created');
          locationObject.avgTime = moment(avgUTime * 1000).fromNow(true);
          const seconds = moment(avgUTime * 1000);
          locationObject.avgTimeSeconds = moment().diff(seconds, 'seconds');
          locationObject.riskIndex = meanBy(totalCount, 'safetyIndex').toFixed(0);
          locationObject.riskClass = this.findSiteHealthColor(locationObject.riskIndex);
        } else {
          locationObject.avgTime = 0;
          locationObject.avgTimeSeconds = 0;
          locationObject.riskIndex = 0;
        }
      } else {
        locationObject.openCount = 0;
        locationObject.avgTime = 0;
        locationObject.avgTimeSeconds = 0;
        locationObject.riskIndex = 0;
      }
      // get quality count
      if (obsQualityCondition.rows[val.locationID] && obsQualityCondition.rows[val.locationID].secondary.quality) {
        locationObject.qualityCount = obsQualityCondition.rows[val.locationID].secondary.quality.items.length;
      } else {
        locationObject.qualityCount = 0;
      }
      // get waiting time count
      if (obsProcessImp.rows[val.locationID]) {
        locationObject.waitingTime = sumBy(obsProcessImp.rows[val.locationID].secondary.pi.items, (obj: any) => {
          if (obj.subtype === 'waiting') {
            return Number(obj.value);
          } else {
            return 0;
          }
        });
      } else {
        locationObject.waitingTime = 0;
      }
      siteHealthData.push(locationObject);
    });
    return siteHealthData;
  }

  /**
   * averages the safety index from open unsafe conditions throughout the system
   */
  private getSubscriberRisk(): ISubscriberRisk {
    // let's filter out observations with invalid location first
    const locs = map(
      this.userService.getUserLocations(this.userdataService.locations, false),
      (ref) => ref.locationID
    );
    // merge open and workoder
    const sourceDSet = merge(this.observationService.observations.open, this.observationService.observations.workorder);
    const filteredObs = filter(sourceDSet, (ref: any) =>
      indexOf(locs, ref.locationID) > -1 && this.userService.findLocation(ref.locationID)
    );
    const riskCollection = map(filteredObs, 'safetyIndex');
    let subscriberRisk = mean(riskCollection);
    if (!subscriberRisk) {
      subscriberRisk = 0;
    }
    return {
      safetyIndex: subscriberRisk.toFixed(0),
      class: this.findSiteHealthColor(subscriberRisk)
    };
  }

  /**
   * returns a coloring class for the numerals
   *
   * @param riskScore riskIndex of the observation
   */
  private findSiteHealthColor(riskScore: number): string {
    let riskScoreColor = '';
    if (riskScore >= 60) {
      riskScoreColor = 'danger-safety-index';
    } else if (riskScore < 60 && riskScore >= 30) {
      riskScoreColor = 'medium-safety-index';
    } else if (riskScore > 0 && riskScore < 30) {
      riskScoreColor = 'low-safety-index';
    }
    return riskScoreColor;
  }

  /**
   *
   * @param timeInterval - string time format
   */
  private filterLabelName(timeStamp, timeInterval) {
    let label = timeStamp;
    if (timeInterval === '7days' || timeInterval === '14days') {
      label = moment(timeStamp, 'LL').format('ddd');
      label = label.toUpperCase();
    } else if (timeInterval === 'today') {
      label = moment(timeStamp, 'MMM DD hh:mm').format('hh A');
    } else if (timeInterval === '30days') {
      label = moment(timeStamp, 'MMM D').format('MMM D');
    }
    return label;
  }

  private checkFeature(feature: Feature) {
    let locationFeature = true;
    if (this.location) {
      locationFeature = this.location.features[feature];
    }
    return this.subscriberService.usesFeature(feature) && locationFeature;
  }

  private prepareLineChartData(filteredData, timeInterval) {
    const labelCollection = [];

    forEach(filteredData.intervals, (ref) => {
      const labelFormat = this.filterLabelName(ref.label, timeInterval);
      labelCollection.push(labelFormat);
    });

    const rowRefs = [];
    // first, order the primary labels
    const dataSet = [];
    const availableObservationTypes = map(filteredData.rows, 'label');

    console.log('location', this.location);

    each(filteredData.rows, (item, pds) => {
      if ((item.label === 'si'
        && this.subscriberService.usesModule(Module.OPPORTUNITIES))
        || (item.label === 'condition' && this.checkFeature(Feature.CONDITION))
        || (item.label === 'quality' && this.subscriberService.usesModule(Feature.QUALITY, true))
        || (item.label === 'behavior' && this.checkFeature(Feature.BEHAVIOR))
        || (item.label === 'pi' && this.subscriberService.usesFeature(Feature.PROCESS_IMPROVEMENT))
        || (item.label === 'compliment' && this.checkFeature(Feature.COMPLIMENT))
        || (item.label === 'ca' && this.subscriberService.usesModule(Module.CHECKS))
        || (item.label === 'ai' && this.subscriberService.usesModule(Module.ASSET_ISSUES))
      ) {
        const dataElem: any = {};
        dataElem.label = item.label;
        dataElem.fill = false;
        dataElem.backgroundColor = this.colorMap[item.label];
        dataElem.borderColor = this.colorMap[item.label];
        dataElem.data = [];
        each(item.intervals, (iref, name) => {
          dataElem.data.push(iref.items.length);
        });
        dataSet.push(dataElem);
      }

    });

    // that's collection of data coming from backend,
    // now zero fill the observationtypes that were not included in filter returned
    const missingObsType = difference(this.observationType, availableObservationTypes);
    forEach(missingObsType, (label) => {
      if ((label === 'si' && this.subscriberService.usesModule(Module.OPPORTUNITIES))
        || (label === 'condition' && this.checkFeature(Feature.CONDITION))
        || (label === 'quality' && this.subscriberService.usesModule(Feature.QUALITY, true))
        || (label === 'behavior' && this.checkFeature(Feature.BEHAVIOR))
        || (label === 'pi' && this.subscriberService.usesFeature(Feature.PROCESS_IMPROVEMENT))
        || (label === 'compliment' && this.checkFeature(Feature.COMPLIMENT))
        || (label === 'ca' && this.subscriberService.usesModule(Module.CHECKS))
        || (label === 'ai' && this.subscriberService.usesModule(Module.ASSET_ISSUES))
      ) {
        const dataElem: any = {};
        dataElem.label = label;
        dataElem.fill = false;
        dataElem.backgroundColor = this.colorMap[label];
        dataElem.borderColor = this.colorMap[label];
        dataElem.data = times(filteredData.intervals.length, constant(0));
        dataSet.push(dataElem);
      }
    });

    if (dataSet.length > 1) {

      // fill overall counts
      const allCount = times(filteredData.intervals.length, constant(0));
      each(dataSet, elem => {
        each(elem.data, (obj, ind) => {
          allCount[ind] += obj;
        });
      });
      const allElem: any = {};
      allElem.label = this.translate.instant('SHARED.All');
      allElem.fill = false;
      allElem.backgroundColor = '#153B4F';
      allElem.borderColor = '#153B4F';
      allElem.data = allCount;
      dataSet.unshift(allElem);
    }
    // set observation order as designed by Eric
    const sortedData = sortBy(dataSet, (item) => this.orderOfElements.indexOf(item.label));

    return {
      labels: labelCollection,
      datasets: sortedData
    };
  }

  private preparePieChartdata(filteredData) {
    // might have to filter here, put data for lineChart and pieChart
    const observationBreakDown: any = [];
    const dataCount = [];
    const backgroundColor = [];
    const borderColor = [];
    const label = [];
    // we have to be careful on this one, missed data should be 0.
    if (this.subscriberService.usesFeature(Feature.CONDITION)) {
      if (filteredData.rows.condition) {
        dataCount.push(filteredData.rows.condition.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#FAD400');
      borderColor.push('#FAD400');
      label.push(this.translate.instant('SHARED.Conditions'));
    }
    if (this.subscriberService.usesFeature(Feature.BEHAVIOR)) {
      if (filteredData.rows.behavior) {
        dataCount.push(filteredData.rows.behavior.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#988000');
      borderColor.push('#988000');
      label.push(this.translate.instant('SHARED.COACHING_OPPORTUNITIES'));
    }
    if (this.subscriberService.usesFeature(Feature.QUALITY)) {
      if (filteredData.rows.quality) {
        dataCount.push(filteredData.rows.quality.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#8800FF');
      borderColor.push('#8800FF');
      label.push(this.translate.instant('SHARED.Quality_Issues'));
    }
    if (this.subscriberService.usesFeature(Feature.PROCESS_IMPROVEMENT)) {
      if (filteredData.rows.pi) {
        dataCount.push(filteredData.rows.pi.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#460085');
      borderColor.push('#460085');
      label.push(this.translate.instant('SHARED.Process_Improvements'));
    }
    if (this.subscriberService.usesModule(Module.ASSET_ISSUES)) {
      if (filteredData.rows.ai) {
        dataCount.push(filteredData.rows.ai.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#1AA0A4');
      borderColor.push('#1AA0A4');
      label.push(this.translate.instant('SHARED.Asset_Issues'));
    }
    if (this.subscriberService.usesModule(Module.CHECKS)) {
      if (filteredData.rows.ca) {
        dataCount.push(filteredData.rows.ca.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#D0021B');
      borderColor.push('#D0021B');
      label.push(this.translate.instant('SHARED.Corrective_Actions'));
    }
    if (this.subscriberService.usesFeature(Feature.COMPLIMENT)) {
      if (filteredData.rows.compliment) {
        dataCount.push(filteredData.rows.compliment.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#279F3E');
      borderColor.push('#279F3E');
      label.push(this.translate.instant('SHARED.Thumbs-Up'));
    }
    if (this.subscriberService.usesModule(Module.OPPORTUNITIES)) {
      if (filteredData.rows.si) {
        dataCount.push(filteredData.rows.si.items.length);
      } else {
        dataCount.push(0);
      }
      backgroundColor.push('#33CEB0');
      borderColor.push('#33CEB0');
      label.push(this.translate.instant('SHARED.Opportunities'));
    }
    const conditionObject: any = {
      backgroundColor,
      borderColor,
      data: dataCount
    };
    observationBreakDown.push(conditionObject);
    return {
      labels: label,
      datasets: observationBreakDown
    };
  }

}
