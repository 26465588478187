import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

import { LoadingService, ObjectsService, SettingsService } from '@services';
import { ContentListItem } from '@modules/management/pages/details/content/components';
import { ContentPage } from '@modules/management/pages/details/content/content.page';

import { awaitHandler } from '@utils/awaitHandler';
import { each, filter, find, get, includes, map, some, trim } from 'lodash';

@Component({
  selector: 'app-content-picker',
  templateUrl: './content-picker.component.html',
  styleUrls: ['./content-picker.component.scss'],
})
export class ContentPickerComponent implements OnInit {

  public contentViewItems: ContentListItem[] = [];
  public selectedItems: ContentListItem[] = [];
  public rejectedIds: number[] = [];
  public searchModel: string;
  public collapseItems: { [key: string]: boolean } = {};

  private contentItems: ContentListItem[] = [];

  constructor(
    private objectsService: ObjectsService,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
    private loadingService: LoadingService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    if (!this.settingsService.customTags.data.length) {
      await this.loadingService.enable();
      await awaitHandler(this.settingsService.getCustomTags());
      this.loadingService.disable();
    }

    this.defineItems();
  }

  public onCheck(): void {
    this.selectedItems = filter(this.contentViewItems, 'selected');
  }

  public close() {
    this.popoverController.dismiss();
  }

  public save() {
    const ids = map(filter(this.contentItems, 'selected'), 'objectID');
    this.popoverController.dismiss({ids});
  }

  public onSearch(event) {
    this.contentViewItems = filter(this.contentItems, (content) => {
      const searchModel = trim(event.detail.value.toLowerCase());
      const isDescriptionValid: boolean = this.isSearchDescriptionValid(content, searchModel);
      const isSearchTagsValid: boolean = this.isSearchTagsValid(content, searchModel);

      return isDescriptionValid || isSearchTagsValid;
    });
    this.onCheck();
  }

  public toggleItemGroup(key: string) {
    this.collapseItems[key] = !this.collapseItems[key];
  }

  public async uploadContent() {
    this.modalController.create({
      component: ContentPage,
      cssClass: 'fullscreen-modal',
      componentProps: {
        disableBackNavigation: true,
        cancel: () => this.modalController.dismiss(),
        onComplete: async () => {
          await this.modalController.dismiss();
          await this.loadingService.enable();
          await this.objectsService.getObjectList(['content'], false);
          this.loadingService.disable();
          this.defineItems();
        }
      }
    }).then((element: HTMLIonModalElement) => {
      element.present();
    });
  }

  private isSearchDescriptionValid(content: ContentListItem, searchModel: string): boolean {
    const description: string = trim(get(content, 'description').toLowerCase());
    return includes(description, searchModel);
  }

  private isSearchTagsValid(content: ContentListItem, searchModel: string): boolean {
    const customTags: any = this.settingsService.customTags.data;
    const tags: string[] = filter(map(content.tags, (tagID) => get(find(customTags, {tagID}), 'tag')));

    return some(tags, (tag) => includes(tag.toLowerCase(), searchModel));
  }

  private async defineItems() {
    let objectItems = this.objectsService.getCachedObjectByAlias('content');

    if (!objectItems?.length) {
      await this.loadingService.enable();
      await this.objectsService.getObjectList(['content'], false);
      this.loadingService.disable();
      objectItems = this.objectsService.getCachedObjectByAlias('content');
    }

    this.contentItems = filter(objectItems, (object) => {
      const contentCategory = this.settingsService.getItem('contentCategory', get(object, 'contentCategory'));
      const isActive = object.state === 'active';
      const isObjectIdValid = !includes(this.rejectedIds, object.objectID);

      return contentCategory && isObjectIdValid && isActive;
    });

    each(this.contentItems, (item) => {
      const contentCategory = this.settingsService.getItem('contentCategory', item.contentCategory);

      item.iconName = this.objectsService.getIconByType(item.mediaType);
      item.contentCategoryName = contentCategory.messageTitle;
    });

    this.contentViewItems = this.contentItems;
  }

}
