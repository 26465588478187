import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { ViewerService } from '@services/viewer/viewer.service';
import { ObjectsService } from '@services/objects/objects.service';
import { LoadingService } from '@services/loading/loading.service';

import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
import { filter, get, includes, isUndefined, join, last, map, merge, set, split, uniq } from 'lodash';

export interface FileUploadOptions {
  formData?: {
    token?: string;
    subscriberID?: string;
    type?: string;
    subtype?: string;
    isPublic?: number;
    mediaType?: string;
    originalName?: string;
  };
  url?: string;
  acceptFiles?: string;
  allowedTypes?: string;
  dragDrop?: boolean;
  fileName?: string;
  multiple?: boolean;
  autoSubmit?: boolean;
  returnType?: string;
  showCancel?: boolean;
  showAbort?: boolean;
  showDone?: boolean;
  showDownload?: boolean;
  showStatusAfterSuccess?: boolean;
  showError?: boolean;
  showFileSize?: boolean;
  dragDropStr?: string;
  uploadStr?: string;
  maxFileCount?: number;
  onSuccess?: (files: any[], data: any) => void;
  onSelect?: () => void;
  onError?: (files: any[], status: any, errMsg: any) => void;
  hideRemoveButton?: boolean;
}

export interface FileUploadCallbacks {
  onSelect?: (id: string) => void;
  onSuccess?: (response) => void;
  onSubmit?: () => void;
  onError?: () => void;
  onCancel?: () => void;
  onRemove?: (imageInstance) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ImageUploaderService {

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private userDataService: UserdataService,
    private subscriberService: SubscriberService,
    private translate: TranslateService,
    private viewerService: ViewerService,
    private objectsService: ObjectsService,
    private loadingService: LoadingService
  ) {
  }

  public upload(options): Promise<any> {
    return new Promise((resolve, reject) => {
      const config = merge({
        ...this.getBaseOptions(),
        multiple: false,
        autoSubmit: true,
        returnType: 'json',
        maxFileCount: 1,
        onSuccess: (files: any[], data: any) => {
          resolve(data);
          imageContainer.innerHTML = '';
          this.loadingService.disable();
        },
        onSelect: (files) => {
          const objectType = get(files, '[0].type');

          if (objectType) {
            set(config, 'formData.mediaType', objectType);
          }
          let name = get(files, '[0].name]');
          if (name) {
            set(config, 'formData.originalName', name);
          }
        },
        onSubmit: () => this.loadingService.enable(),
        onError: (files: any[], status: any, errMsg: any) => {
          reject(errMsg);
          this.loadingService.disable();
        }
      }, options);

      this.addAllowedTypes(config);

      const imageContainer: HTMLElement = document.createElement('div');
      (<any>$(imageContainer)).uploadFile(config);
      imageContainer.querySelector('input').dispatchEvent(new MouseEvent('click'));
    });
  }

  public init(selector: string, options?: FileUploadOptions, mediaType?: string, callbacks: FileUploadCallbacks = {}) {
    const objectType = this.objectsService.getObjectType(mediaType);
    const source: string = this.getSourceByType(objectType, selector);

    if (source) {
      if (!options.hideRemoveButton) {
        this.initRemoveButton(selector, options, objectType, callbacks);
      }
    } else {
      const config = merge({
        ...this.getBaseOptions(),
        dragDrop: true,
        multiple: false,
        autoSubmit: true,
        returnType: 'json',
        showCancel: false,
        showAbort: false,
        showDone: false,
        showDownload: false,
        showStatusAfterSuccess: false,
        showError: true,
        showFileSize: false,
        dragDropStr: `<span>${this.translate.instant('WORK_NOTES.DD')}</span>`,
        uploadStr: this.translate.instant('WORK_NOTES.Upload'),
        maxFileCount: 1,
        onSelect: () => {
          const itemUUID = uuid.v4();
          callbacks?.onSelect && callbacks.onSelect(itemUUID);
          this.setImageValue(selector, itemUUID);
        },
        onSuccess: (files: any[], data: any) => {
          if (data?.status === 'ERROR') {
            config.onError(files, data.status, data.statusText);
            uploadFileInstance.reset();
          } else {
            callbacks?.onSuccess && callbacks.onSuccess(data);
            this.onSuccess(data, selector);
            $(`${selector}-container`).html('');

            this.initRemoveButton(selector, options, this.objectsService.getObjectType(data.mediaType), callbacks);
          }
        },
        onSubmit: () => {
          $(`${selector}-container`).hide();
          callbacks?.onSubmit && callbacks.onSubmit();
        },
        onError: (files: any[], status: any, errMsg: any) => {
          this.logger.log(errMsg);
          $(`${selector}-container`).show();
          $('.ajax-file-upload-statusbar').remove();
          callbacks?.onError && callbacks.onError();
        },
        onCancel: () => {
          callbacks?.onCancel && callbacks.onCancel();
          this.setImageValue(selector);
        }
      }, options);

      this.addAllowedTypes(config);

      const uploadFileInstance = (<any>$(`${selector}-container`)).uploadFile(config);
      return uploadFileInstance;
    }
  }

  public convertTypesToString(formats: { [formatTypeKey: string]: string[] }): string {
    return join(map(formats, (extensions: string[], format: string) => join(map(extensions, (extension) => `${format}/${extension}`))));
  }

  private initRemoveButton(selector: string, options?: any, objectType?: any, callbacks: FileUploadCallbacks = {}): void {
    const buttonName: string = objectType === 'image' ? 'SHARED.Remove_Image' : 'SHARED.Remove';
    const removeButton: any = $(`<ion-button class="page-button" color="light">${this.translate.instant(buttonName)}</ion-button>`);

    removeButton.on('click', () => {
      removeButton.off('click');
      $(`${selector}_container .image-block ion-button`).remove();
      $(`${selector}-container`).show();

      if (objectType === 'image') {
        $(`${selector}-thumbnail`).attr('src', null).hide();
      } else if (includes(['audio', 'video'], objectType)) {
        $(`${selector}-${objectType}`).hide().find('source').attr('src', null);
      } else {
        $(`${selector}-${objectType}`).attr('href', null).hide();
      }


      const instance = this.init(selector, options, objectType, callbacks);
      callbacks?.onRemove && callbacks.onRemove(instance);

      this.setImageValue(selector);
    });

    $(`${selector}-thumbnail`).after(removeButton);
  }

  private onSuccess(data, selector) {
    const objectType = this.objectsService.getObjectType(data.mediaType);

    this.setImageValue(selector, data.objectID);

    if (objectType === 'image') {
      const imageElement = $(`${selector}-thumbnail`);
      let src = this.commsService.objectURI(data.objectID, true, true);

      imageElement.attr('src', src).show();
      if (src) {
        src = src.replace(/\/thumb/, '');
      }
      imageElement.off('click').on('click', () => this.viewerService.showByUrl(src));
    } else if (includes(['audio', 'video'], objectType)) {
      const element = $(`${selector}-${objectType}`);
      const url = this.commsService.objectURI(data.objectID, false, true);

      element.show().find('source').attr('src', url);
      (<any>element[0]).load();
    } else {
      // the other objectTypes are sort of generic
      const theElement = $(`${selector}-${objectType}`);
      const url = this.commsService.objectURI(data.objectID, false, true);
      theElement?.attr('href', url).show();
    }
  }

  private getSourceByType(objectType: string, selector): string {
    let src: string;

    if (objectType === 'pdf') {
      src = $(`${selector}-object`).attr('href');
    } else if (includes(['audio', 'video'], objectType)) {
      src = $(`${selector}-${objectType}`).find('source').attr('src');
    } else if (objectType === 'image' || !objectType) {
      const imageElement = $(`${selector}-thumbnail`);

      imageElement.off('click');
      src = imageElement.attr('src');
      if (src) {
        src = src.replace(/\/thumb/, '');
      }
      imageElement.on('click', () => this.viewerService.showByUrl(src));
    }

    return src;
  }

  private addAllowedTypes(config: FileUploadOptions): void {
    if (isUndefined(config.allowedTypes)) {
      const acceptFiles: string[] = split(config.acceptFiles, ',');
      const subtypes: string[] = map(acceptFiles, (acceptFile) => {
        const subtype: string = last(split(acceptFile, '/'));
        return subtype === '*' ? '' : subtype;
      });
      const allowedTypes = join(uniq(filter(subtypes)), ',');

      if (allowedTypes) {
        config.allowedTypes = allowedTypes;
      }
    }
  }

  private setImageValue(selector: string, value = ''): void {
    const imageHandlerElement = $(`${selector}-image-handler`);

    if (imageHandlerElement) {
      imageHandlerElement.val(value).trigger('blur');
    }
  }

  private getBaseOptions() {
    return {
      formData: {
        token: this.userDataService.Token,
        subscriberID: this.subscriberService.subInfo.subscriberID,
        type: 'message',
        subtype: 'image'
      },
      url: this.commsService.backendHost + this.commsService.uploadPath + '?ngsw-bypass',
      acceptFiles: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      fileName: 'file'
    };
  }
}
