import { ElementRef } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { PopoverService } from '@services';
import { IPopoverConfig } from '@shared/components';

import { TranslateService } from '@ngx-translate/core';
import { get, groupBy, includes, intersection, mapValues, omit } from 'lodash';

export class CustomValidate {

  constructor(
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    private popoverService?: PopoverService
  ) {
  }

  public animateActivityFun(formConfig) {
    if (this.removeIn(formConfig)) {
      const startSlideErrorElement = this.elementRef.nativeElement.querySelector(`#startSlideError`);
      if (startSlideErrorElement && startSlideErrorElement.classList.contains('in')) {
        startSlideErrorElement.classList.remove('in');
      }
    } else {
      const startSlideErrorElement = this.elementRef.nativeElement.querySelector(`#startSlideError`);
      if (startSlideErrorElement && !startSlideErrorElement.classList.contains('in')) {
        startSlideErrorElement.classList.add('in');
      }
    }
  }

  public scrollByError() {
    const firstElementWithError = document.querySelector('.error');
    if (firstElementWithError !== null) {
      const firstFormControlId = document.querySelector('form .ui-field-contain label[id]').id;
      const stringFirstFormControlId = firstFormControlId.replace('_label', '');
      const errorControlId = document.querySelector('.error').id;
      if (stringFirstFormControlId === errorControlId) {
        firstElementWithError.scrollIntoView(false);
      } else {
        firstElementWithError.scrollIntoView();
      }
    }
  }

  public validateFunction(formConfig) {
    if (this.elementRef.nativeElement.querySelector(`#startSlideError`) === null) {
      const errorMessage = '<div id="startSlideError" class="toast-error">' + this.translate.instant('SHARED.Error_Missing') + '</div>';
      this.elementRef.nativeElement.querySelector(`ion-content`).insertAdjacentHTML('beforebegin', errorMessage);
    }

    formConfig.fields.filter(function (obj) {
      return obj.required || obj.equalTo || obj.unique;
    }).map(function (obj) {
      if (includes(['number', 'customElement'], obj.type)) {
        const selector = `input[name="${obj.name}"]`;
        const fieldElement: HTMLElement = this.elementRef.nativeElement.querySelector(selector);
        let isFieldValid: boolean = (<any>$(fieldElement)).valid();

        if (!isFieldValid) {
          fieldElement.closest('.ui-field-contain').classList.add('custom-error');
          $(fieldElement).on('keyup change', (event: any) => {
            const targetElement: any = event.currentTarget.closest('.ui-field-contain');
            isFieldValid = (<any>$(fieldElement)).valid();

            if (isFieldValid) {
              targetElement.classList.remove('custom-error');
            } else {
              targetElement.classList.add('custom-error');
            }

            this.animateActivityFun(formConfig);
          });
        }
      }

      if ((obj.type === 'password' || obj.type === 'text' || obj.type === 'timepicker') && this.isFieldContainerVisible(obj.name)) {
        const currentValue: any = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value;

        if ((obj.required && currentValue === '') || (obj.equalTo && currentValue !== this.elementRef.nativeElement.querySelector(`input[name=${obj.equalTo}]`).value) || (obj.maxlength !== undefined && obj.maxlength < currentValue.length)) {
          this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.add('custom-error');

          if (obj.equalTo) {
            this.elementRef.nativeElement.querySelector(`input[name="${obj.equalTo}"]`).addEventListener('blur', (e) => {
              const currentValue: any = this.elementRef.nativeElement.querySelector(`input[name=${obj.name}]`).value;

              if (currentValue) {
                if (e.target.value !== currentValue) {
                  this.elementRef.nativeElement.querySelector(`input[name=${obj.name}]`).closest('.ui-field-contain').classList.add('custom-error');
                } else {
                  this.elementRef.nativeElement.querySelector(`input[name=${obj.name}]`).closest('.ui-field-contain').classList.remove('custom-error');
                }

                this.animateActivityFun(formConfig);
              }
            });
          }

          this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).addEventListener('keyup', (e) => {
            if (e.target.value === '' || (obj.equalTo && e.target.value !== this.elementRef.nativeElement.querySelector(`input[name=${obj.equalTo}]`).value) || !(<any>$(`input[name="` + obj.name + `"]`)).valid()) {
              e.currentTarget.closest('.ui-field-contain').classList.add('custom-error');
            } else {
              e.currentTarget.closest('.ui-field-contain').classList.remove('custom-error');
            }

            this.animateActivityFun(formConfig);
          });

          if (obj.type === 'timepicker') {
            const errorId = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).id;
            const errorIdLabel = errorId + '-error';
            this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``).remove();

            if (!this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``)) {
              if (obj.name === 'username' && this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value) {
                this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('fieldset').insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.User_exists') + '</label>');
              } else {
                this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('fieldset').insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.requiredError') + '</label>');
              }
            }
          }

        }
      } else if (obj.type === 'textarea' && this.isFieldContainerVisible(obj.name, 'textarea')) {
        if (this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).value === '' || (obj.maxlength !== undefined && obj.maxlength < this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).value.length)) {
          this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).closest('.ui-field-contain, .ui-field-contain-break').classList.add('custom-error');
          this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).addEventListener('keyup', (e) => {
            if (e.target.value === '') {
              e.currentTarget.closest('.ui-field-contain, .ui-field-contain-break').classList.add('custom-error');
            } else {
              e.currentTarget.closest('.ui-field-contain, .ui-field-contain-break').classList.remove('custom-error');
            }
            this.animateActivityFun(formConfig);
          });
        }
      } else if (obj.type === 'selectmenu' && this.isFieldContainerVisible(obj.name, 'select')) {
        if (this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).value === '') {
          this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.add('custom-error');
        }
        const that = this;
        $('#' + this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).id).on('select2:select', function (e) {
          if ($(this).val() !== null) {
            if ($(this).closest('.ui-field-contain').closest('.ui-field-contain').hasClass('custom-error')) {
              $(this).closest('.ui-field-contain').closest('.ui-field-contain').removeClass('custom-error');
              if ($(this).next().hasClass('error')) {
                $(this).next().html('');
              }
            }
          }
          that.animateActivityFun(formConfig);
        });
      } else if (obj.type === 'checkbox') {
        const inputField = this.elementRef.nativeElement.querySelector(`input[id="${obj.name}-checkbox"]`);
        const fieldContain = inputField.closest('.ui-field-contain');

        if (!inputField.value && fieldContain) {
          fieldContain.classList.add('custom-error');
        }

        $(`input[id="` + obj.name + `-checkbox"]`).on('blur', (e: any) => {
          const fieldContain = e.currentTarget.closest('.ui-field-contain');

          if (fieldContain) {
            if (e.target.value === '') {
              fieldContain.classList.add('custom-error');
            } else {
              fieldContain.classList.remove('custom-error');
            }
          }
          this.animateActivityFun(formConfig);
        });
      } else if (obj.type === 'customElement') {
        const inputField = this.elementRef.nativeElement.querySelector(`input[name="${obj.name}"]`);
        const fieldContain = inputField.closest('.ui-field-contain');

        if (!inputField.value && fieldContain) {
          fieldContain.classList.add('custom-error');
        }

        $(inputField).on('change', (e: any) => {
          const fieldContain = e.currentTarget.closest('.ui-field-contain');

          if (fieldContain) {
            if (e.target.value) {
              fieldContain.classList.remove('custom-error');
            } else {
              fieldContain.classList.add('custom-error');
            }
          }
          this.animateActivityFun(formConfig);
        });
      }

      setTimeout(() => {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
      }, 100);
    }.bind(this));

    this.scrollByError();
  }

  public validateUniqueFields(formConfig) {
    if (this.elementRef.nativeElement.querySelector(`#startSlideError`) === null) {
      const errorMessage = '<div id="startSlideError" class="toast-error">' + this.translate.instant('SHARED.Error_Missing') + '</div>';
      this.elementRef.nativeElement.querySelector(`ion-content`).insertAdjacentHTML('beforebegin', errorMessage);
    }

    let formValid = true;
    const uniqueFields = formConfig.fields.filter(function (obj) {
      return obj.unique;
    });
    mapValues(mapValues(groupBy(uniqueFields, 'unique'), c => c.map(f => omit(f, 'unique'))), (obj) => {
      if (this.elementRef.nativeElement.querySelector(`select[name="` + obj[0].name + `"]`).value !== '' && this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).value !== '') {
        const errorId = this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).id;
        const errorIdLabel = this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).id + '-error';
        const errorLabel = '<label class="unique-error" id="' + errorIdLabel + '" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.duplicateError') + '</label>';
        if (intersection($('#' + this.elementRef.nativeElement.querySelector('select[name="' + obj[0].name + '"]').id).val().toString().split(','),
          $('#' + this.elementRef.nativeElement.querySelector('select[name="' + obj[1].name + '"]').id).val().toString().split(',')).length) {
          this.elementRef.nativeElement.querySelector('select[name="' + obj[0].name + '"]').closest('.ui-field-contain').classList.add('custom-error');
          this.elementRef.nativeElement.querySelector('select[name="' + obj[1].name + '"]').closest('.ui-field-contain').classList.add('custom-error');
          if (!this.elementRef.nativeElement.querySelector(`#` + errorIdLabel)) {
            this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).closest('.ui-select').insertAdjacentHTML('afterEnd', errorLabel);
          }
          formValid = false;
        } else {
          this.elementRef.nativeElement.querySelector('select[name="' + obj[0].name + '"]').closest('.ui-field-contain').classList.remove('custom-error');
          this.elementRef.nativeElement.querySelector('select[name="' + obj[1].name + '"]').closest('.ui-field-contain').classList.remove('custom-error');
          if (this.elementRef.nativeElement.querySelector(`#` + errorIdLabel)) {
            this.elementRef.nativeElement.querySelector(`#` + errorIdLabel).remove();
          }
        }
      } else {
        const errorIdLabel = this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).id + '-error';
        this.elementRef.nativeElement.querySelector('select[name="' + obj[0].name + '"]').closest('.ui-field-contain').classList.remove('custom-error');
        this.elementRef.nativeElement.querySelector('select[name="' + obj[1].name + '"]').closest('.ui-field-contain').classList.remove('custom-error');
        if (this.elementRef.nativeElement.querySelector(`#` + errorIdLabel)) {
          this.elementRef.nativeElement.querySelector(`#` + errorIdLabel).remove();
        }
      }
      $('#' + this.elementRef.nativeElement.querySelector(`select[name="` + obj[0].name + `"]`).id).on('change.select2', (e) => {
        this.validateUniqueFields(formConfig);
      });
      $('#' + this.elementRef.nativeElement.querySelector(`select[name="` + obj[1].name + `"]`).id).on('change.select2', (e) => {
        this.validateUniqueFields(formConfig);
      });
      setTimeout(() => {
        if (formValid) {
          this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.remove('in');
        } else {
          this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
        }
      }, 100);
      this.animateActivityFun(formConfig);
    });
    return formValid;
  }


  public validateServerFunction(formConfig, error: IPopoverConfig, columnname) {
    if (this.elementRef.nativeElement.querySelector(`#startSlideError`) === null) {
      this.elementRef.nativeElement.querySelector(`ion-content`).insertAdjacentHTML('beforebegin', '<div id="startSlideError" class="toast-error"><strong>Form errors.</strong> Please address the issue below in red.</div>');
    }

    formConfig.fields.filter(function (obj) {
      return obj.required || obj.name === columnname;
    }).map(function (obj) {
      this.elementRef.nativeElement.querySelectorAll(`label.error`).forEach(element => {
        if (obj.required && obj.name === columnname) {
          element.innerHTML = this.translate.instant('SHARED.requiredError');
        }
        if (columnname === 'username') {
          element.innerHTML = this.translate.instant('SHARED.User_exists');
        }
        if (columnname === 'nfcID') {
          element.innerHTML = this.translate.instant('SHARED.NFC_used');
        }
      });
      if ((obj.type === 'password' || obj.type === 'text' || obj.type === 'timepicker') && this.isFieldContainerVisible(obj.name)) {
        if (this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value === '' || (obj.maxlength !== undefined && obj.maxlength < this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value.length) || error) {
          if (error && obj.name === columnname) {
            this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.add('custom-error');
            const errorId = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).id;
            const errorIdLabel = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).id + '-error';
            const errorElement = this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``);
            if (columnname === 'username' || columnname === 'nfcID') {
              if (errorElement) {
                errorElement.remove();
              }
              this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.User_exists') + '</label>');
            } else {
              this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + error.description + '</label>');
            }
          }
          this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).addEventListener('keyup', (e) => {
            if (e.target.value === '') {

              e.currentTarget.closest('.ui-field-contain').classList.add('custom-error');
            } else {
              if (error && obj.name === columnname) {
                const errorIdLabel = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).id + '-error';
                if (this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``) !== undefined &&
                  this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``) !== null) {
                  this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``).remove();
                }
              }
              e.currentTarget.closest('.ui-field-contain').classList.remove('custom-error');
            }
            this.animateActivityFun(formConfig);
          });

          if (obj.type === 'timepicker') {
            const errorId = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).id;
            const errorIdLabel = errorId + '-error';
            this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``).remove();
            if (this.elementRef.nativeElement.querySelector(`#` + errorIdLabel + ``) === undefined) {
              if (columnname === 'username' && this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value) {
                this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('fieldset').insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.User_exists') + '</label>');
              } else {
                this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).closest('fieldset').insertAdjacentHTML('afterEnd', '<label id="' + errorIdLabel + '" class="error" for="' + errorId + '" style="display: block;">' + this.translate.instant('SHARED.requiredError') + '</label>');
              }
            }
          }
        }
      } else if (obj.type === 'textarea' && this.isFieldContainerVisible(obj.name, 'textarea')) {
        if (this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).value === '' || (obj.maxlength !== undefined && obj.maxlength < this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).value.length)) {
          this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.add('custom-error');
          this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).addEventListener('keyup', (e) => {
            if (e.target.value === '') {
              e.currentTarget.closest('.ui-field-contain').classList.add('custom-error');
            } else {
              e.currentTarget.closest('.ui-field-contain').classList.remove('custom-error');
            }
            this.animateActivityFun(formConfig);
          });
        }
      } else if (obj.type === 'selectmenu' && this.isFieldContainerVisible(obj.name)) {
        if (this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).value === '') {
          this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.add('custom-error');
        }
        const that = this;
        $('#' + this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).id).on('select2:select', function (e) {
          if ($(this).val() !== null) {
            if ($(this).closest('.ui-field-contain').closest('.ui-field-contain').hasClass('custom-error')) {
              $(this).closest('.ui-field-contain').closest('.ui-field-contain').removeClass('custom-error');
              if ($(this).next().hasClass('error')) {
                $(this).next().html('');
              }
            }
          }
          that.animateActivityFun(formConfig);
        });
      }

      setTimeout(() => {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
      }, 100);
    }.bind(this));

    this.scrollByError();

    if (error) {
      this.popoverService && this.popoverService.show(error);
    }
  }


  public removeIn(formConfig) {
    let removeInClass = true;
    formConfig.fields.filter(function (obj) {
      return obj.required || obj.equalTo;
    }).map(function (obj) {
      if (includes(['number', 'customElement'], obj.type) && this.isFieldContainerVisible(obj.name) && !(<any>$(`input[name="` + obj.name + `"]`)).valid()) {
        if (this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
          this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
        }
        removeInClass = false;
      } else {
        if ((obj.type === 'password' || obj.type === 'text' || obj.type === 'timepicker') && this.isFieldContainerVisible(obj.name)) {
          const currentValue: any = this.elementRef.nativeElement.querySelector(`input[name="` + obj.name + `"]`).value;

          if (currentValue === '' || (obj.equalTo && currentValue !== this.elementRef.nativeElement.querySelector(`input[name=${obj.equalTo}]`).value) || !(<any>$(`input[name="` + obj.name + `"]`)).valid()) {
            if (this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
              this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
            }
            removeInClass = false;
          }
        } else if (obj.type === 'textarea' && this.isFieldContainerVisible(obj.name, 'textarea')) {
          if (this.elementRef.nativeElement.querySelector(`textarea[name="` + obj.name + `"]`).value === '') {
            if (this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
              this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
            }
            removeInClass = false;
          }
        } else if (obj.type === 'selectmenu' && this.isFieldContainerVisible(obj.name)) {
          if (this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).value === '') {
            const startSlideErrorElement = this.elementRef.nativeElement.querySelector(`#startSlideError`);
            if (startSlideErrorElement && startSlideErrorElement.classList.contains('in')) {
              startSlideErrorElement.classList.add('in');
            }
            removeInClass = false;
          } else {
            if ((this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).closest('.ui-field-contain').classList !== null) && this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.contains('custom-error')) {
              this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).closest('.ui-field-contain').classList.remove('custom-error');
            }
            if ((this.elementRef.nativeElement.querySelector(`#` + this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).id + `-error`) !== null) && this.elementRef.nativeElement.querySelector(`#` + this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).id + `-error`)) {
              this.elementRef.nativeElement.querySelector(`#` + this.elementRef.nativeElement.querySelector(`select[name="` + obj.name + `"]`).id + `-error`).innerHTML = '';
            }
          }
        } else if (obj.type === 'checkbox') {
          const inputField = this.elementRef.nativeElement.querySelector(`input[id="${obj.name}-checkbox"]`);
          const containerField = inputField.closest('.ui-field-contain') || inputField.closest('.ui-field-contain-break');
          let isFieldShown = false;

          if (containerField) {
            const isHidden = containerField.hasAttribute('hidden') || containerField.classList.contains('disabled');
            const isDisplayed = containerField.style.display !== 'none';

            isFieldShown = !isHidden && isDisplayed;
          }

          if (!inputField.value && isFieldShown) {
            if (this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
              this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
            }
            removeInClass = false;
          }
        }
      }
    }.bind(this));
    return removeInClass;
  }


  public validateFailed(status) {

    const fileObj = this.elementRef.nativeElement.querySelector(`input[type="file"]`);
    if (status === 'success') {
      if (this.elementRef.nativeElement.querySelector(`#startSlideError`) === null) {
        const errorMessage = '<div id="startSlideError" class="toast-error">' + this.translate.instant('SHARED.Error_Missing') + '</div>';
        this.elementRef.nativeElement.querySelector(`ion-content`).insertAdjacentHTML('beforebegin', errorMessage);
      } else {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).innerHTML = this.translate.instant('SHARED.Error_Missing');
      }

      if (this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.remove('in');
      }
      if (fileObj.closest('.ui-field-contain').classList.contains('custom-error')) {
        fileObj.closest('.ui-field-contain').classList.remove('custom-error');
      }
      if (this.elementRef.nativeElement.querySelector(`#uploadFileError`) && this.elementRef.nativeElement.querySelector(`#uploadFileError`) === null) {
        this.elementRef.nativeElement.querySelector(`#uploadFileError`).remove();
      }
    } else {
      const alertController = new AlertController();
      alertController.create({
        message: 'Your image has failed to upload. please try uploading it again',
        cssClass: 'custom-alert'
      }).then((alert: any) => {
        alert.present();
        setTimeout(() => alert.dismiss(), 2000);
      });
      if (this.elementRef.nativeElement.querySelector(`#startSlideError`) === null) {
        this.elementRef.nativeElement.querySelector(`ion-content`).insertAdjacentHTML('beforebegin', '<div id="startSlideError" class="toast-error">' + this.translate.instant('SHARED.Upload_Error') + '</div>');
      } else {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).innerHTML += this.translate.instant('SHARED.Upload_Error');
      }
      if (!this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.contains('in')) {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).classList.add('in');
      }
      fileObj.closest('.ui-field-contain').classList.add('custom-error');
      if (this.elementRef.nativeElement.querySelector(`#uploadFileError`) === null) {
        fileObj.closest('.ajax-upload-dragdrop').insertAdjacentHTML('afterEnd', '<label id="uploadFileError" class="error" for="uploadFileError" style="display: block; padding-left: 0%;">' + this.translate.instant('SHARED.uploadFileError') + '</label>');
      }

    }
  }

  private isFieldContainerVisible(fieldName: string, fieldType: string = 'input'): boolean {
    const targetField: HTMLInputElement = this.elementRef.nativeElement.querySelector(`${fieldType}[name="${fieldName}"]`);
    return get(targetField && targetField.closest('.ui-field-contain, .ui-field-contain-break'), 'style.display', 'none') !== 'none';
  }

}
