import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { APP_BASE_HREF } from '@angular/common';
import { HttpBackend, HttpClient, provideHttpClient } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

import { MomentModule } from 'ngx-moment';

import { environment } from '@env';
import { LayoutModule, SharedModule } from '@modules';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AssignPopupComponent, WorkorderPopupComponent } from './modules/observation/components';
import { FormsModule } from '@angular/forms';
import { NotePopupComponent } from './modules/observation/components/note-popup/note-popup.component';
import { MessagePopupComponent } from './modules/observation/components/message-popup/message-popup.component';
import { ArchivePopupComponent } from './modules/observation/components/archive-popup/archive-popup.component';
import { CancelworkorderPopupComponent } from './modules/observation/components/cancelworkorder-popup/cancelworkorder-popup.component';
import { CopylinkPopupComponent } from './modules/observation/components/copylink-popup/copylink-popup.component';
import { CreatePopupComponent } from '@modules/observation/components/create-popup/create-popup.component';
import { ClosePopupComponent } from './modules/observation/components/close-popup/close-popup.component';
import { CategoryPopupComponent } from './modules/observation/components/category-popup/category-popup.component';
import { FullhealthTableComponent } from './modules/observation/components/fullhealth-table/fullhealth-table.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SelectLangPipeModule } from './modules/management/pages/details/abstractDetails/selectLang.module';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CopyLinkReportPopupComponent,
  DataToIncludeFormComponent,
  DataToIncludeFormModalComponent,
} from '@modules/reporting/components';
import { InitializeService } from '@services/initialize/initialize.service';
import { APP_INITIALIZER } from '@angular/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { GoogleAnalyticsService } from '@services/GoogleAnalytics/google-analytics.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppStateEffects } from '@store/app.effects';
import { AppStateReducer } from '@store/app.reducer';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/translate/base_terms/', suffix: '.json'},
    {prefix: './assets/translate/auth/', suffix: '.json'},
    {prefix: './assets/translate/layout/', suffix: '.json'},
    {prefix: './assets/translate/observation/component/', suffix: '.json'},
    {prefix: './assets/translate/observation/pages/', suffix: '.json'},
    {prefix: './assets/translate/site-management/component/', suffix: '.json'},
    {prefix: './assets/translate/site-management/pages/', suffix: '.json'},
    {prefix: './assets/translate/reporting/pages/', suffix: '.json'},
    {prefix: './assets/translate/communications/component/', suffix: '.json'},
    {prefix: './assets/translate/shared/', suffix: '.json'},
    {prefix: './assets/translate/management/pages/details/', suffix: '.json'},
    {prefix: './assets/translate/management/list/', suffix: '.json'},
    {prefix: './assets/translate/subscriber/settings/', suffix: '.json'},
    {prefix: './assets/translate/access/', suffix: '.json'},
    {prefix: './assets/translate/bad-token/', suffix: '.json'},
    {prefix: './assets/translate/profile/components/', suffix: '.json'},
    {prefix: './assets/translate/work-notes/', suffix: '.json'},
    {prefix: './assets/translate/plugins/', suffix: '.json'},
    {prefix: './assets/translate/services/auth/', suffix: '.json'},
    {prefix: './assets/translate/services/common/', suffix: '.json'},
    {prefix: './assets/translate/services/geartypes/', suffix: '.json'},
    {prefix: './assets/translate/services/observationTable/', suffix: '.json'},
    {prefix: './assets/translate/services/observationDetail/', suffix: '.json'},
    {prefix: './assets/translate/services/subscriber/', suffix: '.json'},
    {prefix: './assets/translate/reporting/generate/', suffix: '.json'},
    {prefix: './assets/translate/sendrequest/', suffix: '.json'},
    {prefix: './assets/translate/frontline-culture/', suffix: '.json'},
    {prefix: './assets/translate/topics/', suffix: '.json'},
    {prefix: './assets/translate/pdca/pages/', suffix: '.json'},
    {prefix: './assets/translate/property/', suffix: '.json'},
    {prefix: './assets/translate/content/', suffix: '.json'},
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    AssignPopupComponent,
    WorkorderPopupComponent,
    NotePopupComponent,
    MessagePopupComponent,
    ArchivePopupComponent,
    CancelworkorderPopupComponent,
    CopylinkPopupComponent,
    ClosePopupComponent,
    CreatePopupComponent,
    CategoryPopupComponent,
    FullhealthTableComponent,
    DataToIncludeFormComponent,
    DataToIncludeFormModalComponent,
    CopyLinkReportPopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    MomentModule,
    NgxCurrencyDirective,
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    IonicStorageModule.forRoot(),
    StoreModule.forRoot({ AppStateReducer }),
    EffectsModule.forRoot([AppStateEffects]),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          tables: false,
          breaks: true,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true
    }),
    SelectLangPipeModule,
    ServiceWorkerModule.register('ngsw-worker.js', <any>{
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: APP_BASE_HREF, useValue: environment.baseHref || '/'},
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: (initializeService: InitializeService) => () => initializeService.initialize(),
      deps: [InitializeService],
      multi: true
    },
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
