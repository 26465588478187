import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  TableService,
  UserdataService,
  AccountsService,
  LoadingService
} from '@services';
import { ReportService } from 'app/modules/reporting/services/report.service';
import { ReportBaseClass } from '@modules/reporting/models';

import * as uuid from 'uuid';
import { cloneDeep, map } from 'lodash';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss'],
})
export class GenerateComponent {

  @Output() onFinish: EventEmitter<void> = new EventEmitter<void>();

  @Input('reportInstance') set reportInstance(instance: ReportBaseClass) {
    this.currentChart = this.reportModel && this.reportModel.currentReportChart;

    if (instance) {
      this.reportModel = instance;
    }
  }

  @Input('reportData')
  set reportData(data: any) {
    if (data) {
      setTimeout(() => {
        this.update(data);
      });
    }
  }

  public reportReady = false;
  public emptyTableMsg = 'SHARED.No_Results';
  public tableId = `${uuid.v4()}`;
  public tableSelector = `#${this.tableId}`;
  public showEmptyMsg = false;
  private reportModel: ReportBaseClass;
  private currentChart;
  private chartId = '#reportViewChart';

  constructor(
    private reporting: ReportService,
    private tableService: TableService,
    private userDataService: UserdataService,
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
  }

  public async update(data): Promise<void> {
    const reportId: number = +this.route.snapshot.paramMap.get('reportID') || +this.route.snapshot.paramMap.get('messageID');
    this.reportModel.tableId = this.tableId;
    this.reportReady = false;
    this.currentChart && this.currentChart.destroy();
    data.selectors.reportType = data.type;
    const timespan = cloneDeep(data.selectors.timespan);
    data.selectors.timespan = timespan.type || timespan;
    data.selectors.startTime = timespan?.range?.startTime || undefined;
    data.selectors.endTime = timespan?.range?.endTime || undefined;
    if (data.selectors.primary) {
      await this.reportModel.report(this.tableSelector, this.chartId, data, reportId);
      this.loadingService.disable();
    } else {
      if (data.type === 'checkDetail') {
        data.selectors.detailRef = 'responseID';
        if (data.selectors.extraColumns[0] && data.selectors.extraColumns[0].id) {
          data.selectors.extraColumns = map(data.selectors.extraColumns, 'id');
        }
        await this.loadingService.enable(null, null);
        await this.reportModel.report(this.tableSelector, null, data, reportId);
        this.loadingService.disable();
      } else if (data.type === 'obsDetail') {
        data.selectors.detailRef = 'observationID';
        if (data.selectors.extraColumns[0] && data.selectors.extraColumns[0].id) {
          data.selectors.extraColumns = map(data.selectors.extraColumns, 'id');
        }
        await this.loadingService.enable();
        await this.reportModel.report(this.tableSelector, null, data, reportId);
        this.loadingService.disable();
      } else if (data.type === 'check') {
        await this.loadingService.enable(null, null);
        await this.reportModel.report(this.tableSelector, null, data, reportId);
        this.loadingService.disable();
      } else if (data.type === 'worker') {
        data.selectors.detailRef = 'workerID';
        await this.loadingService.enable();
        await this.reportModel.report(this.tableSelector, null, data, reportId);
        this.loadingService.disable();

      }
    }

    this.reportReady = true;
    this.onFinish.emit();
  }
}
