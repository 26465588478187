<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "DASHBOARD.CATEGORY_POPUP_title" | translate }}</h2>
</div>
<div class="body">
  <div class="headline-small">
    <div class="headline-small-title">
      {{ "DASHBOARD.CATEGORY_POPUP_headline_small" | translate }}
    </div>
    <ion-list>
      <ion-item *ngFor="let entry of categories">
        <ion-checkbox (ngModelChange)="getCats(entry)" [(ngModel)]="entry.isChecked" label-placement="end" slot="start">{{entry.messageTitle}}</ion-checkbox>
      </ion-item>
    </ion-list>
  </div>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="submitClosing()" [disabled]="!selectedCats.length" class="page-button"
              color="secondary">{{ "SHARED.Done" | translate }}</ion-button>
</div>
