import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import {environment} from '@env';
import { last } from 'lodash';

@Component({
  selector: 'app-copylink-popup',
  templateUrl: './copylink-popup.component.html',
  styleUrls: ['./copylink-popup.component.scss'],
})

export class CopyLinkReportPopupComponent implements OnInit {
  @Input() reportID: string;

  public fullUrl = '';

  constructor(private popOver: PopoverController) {}

  ngOnInit() {
    this.fullUrl = this.getReportUrl();
  }

  public getReportUrl(): string {
    let baseHref: string = environment.baseHref;
    if (last(baseHref) !== '/') {
      baseHref += '/';
    }
    return `${document.location.origin}${baseHref}pages/reporting/${this.reportID}`
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public copyLink(): void {
    const copyText: any = document.getElementById('deepUrl');
    copyText.select();
    document.execCommand('Copy');
    this.close();
  }
}
