import { ModalController } from '@ionic/angular';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { includes, map } from 'lodash';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { GalleryItem } from 'lightgallery/lg-utils';
import { VideoSource } from 'lightgallery/plugins/video/types';

export interface IGaleryImage {
  url: string;
  thumbnailUrl?: string;
  altText?: string;
  title?: string;
  extUrl?: string;
  extUrlTarget?: string;
  time?: string;
  class?: string;
  fullName?: string;
  mediaType?: string;
  isPending?: boolean;
}

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @ViewChild('galleryRef', {static: true}) galleryElement: ElementRef<HTMLDivElement>;
  public readonly galleryId = 'galleryContainer';

  private images: IGaleryImage[] = [];
  private index: number;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    const galleryFiles: GalleryItem[] = map(this.images, (image) => {
      if (includes(image.mediaType, 'video')) {
        return {
          video: {
            source: [
              {
                src: image.url,
                type: image.mediaType,
              },
            ],
            attributes: { preload: false, controls: true } as any
          } as VideoSource
        };
      } else {
        return {
          src: image.url,
          thumb: image.thumbnailUrl,
          type: image.mediaType
        };
      }
    });

    const dynamicGallery = lightGallery(this.galleryElement.nativeElement, {
      dynamic: true,
      enableDrag: true,
      loop: false,
      closeOnTap: true,
      download: false,
      dynamicEl: galleryFiles,
      plugins: [
        lgZoom,
        lgVideo
      ]
    });
    dynamicGallery.openGallery(this.index);

    this.galleryElement.nativeElement.addEventListener('lgBeforeClose',() => {
      this.modalController.dismiss();
    });
  }
}
