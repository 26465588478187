import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ImageGalleryComponent } from '@shared/components';
import { CommsService } from '@services/comms/comms.service';
import { ObjectsService } from '@services/objects/objects.service';
import { MediaViewerComponent } from '@shared/components/media-viewer/media-viewer.component';

import { filter, includes, map, split } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  constructor(
    private modalController: ModalController,
    private commsService: CommsService,
    private objectsService: ObjectsService
  ) {}

  public showByUrl(src: string): void {
    if (src) {
      this.modalController.create({
        component: ImageGalleryComponent,
        componentProps: {
          images: [{
            url: src,
            thumbnailUrl: src,
          }],
          index: 0
        },
        cssClass: 'image-gallery-modal'
      }).then((modal) => {
        modal.present();
      });
    }
  }

  public showByType(url: string, type: string): void {
    const objectType = this.objectsService.getObjectType(type);

    if (objectType === 'image') {
      this.showByUrl(url);
    } else if (includes(['video', 'audio'], objectType)) {
      this.modalController.create(<any>{
        component: MediaViewerComponent,
        cssClass: 'media-screen-modal',
        componentProps: {URL: url, type: objectType}
      }).then((element: HTMLIonModalElement) => {
        element.present();
      });
    } else {
      window.open(url, '_blank');
    }
  }

  public showByObjectId(objectID: number): void {
    const object = this.objectsService.getCachedObjectById(objectID);

    if (object) {
      const objectUrl = this.commsService.objectURI(object.objectID, false, false, false);
      this.showByType(objectUrl, object.mediaType);
    }
  }

  public openGallery(attachments: { objectID: number; mediaType: string }[], index: number = 0) {
    attachments = this.filterAttachments(attachments);
    this.modalController.create({
      component: ImageGalleryComponent,
      componentProps: {
        images: map(attachments, (attachmentsItem) => ({
          url: this.commsService.objectURI(attachmentsItem.objectID, false),
          thumbnailUrl: this.commsService.objectURI(attachmentsItem.objectID, true),
          mediaType: attachmentsItem.mediaType
        })),
        index
      },
      cssClass: 'image-gallery-modal'
    }).then((modal) => {
      modal.present();
    });
  }

  public filterAttachments(attachments) {
    return filter(attachments, (attachmentsItem) => includes(['video', 'image'], split(attachmentsItem?.mediaType, '/')[0]) && attachmentsItem?.objectID as boolean);
  }

}
