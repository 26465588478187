<div *ngFor="let item of items">
  <div class="text-nowrap row-item my-custom-text-css">
    <div class="tree-item">
      <img class="add-tree-icon"
           (click)="hiddenItemsMap[item.id] = !hiddenItemsMap[item.id]"
           [class.with-children]="item.children" src="assets/icons/General/tree.png">
      <label class="form-check-label custom-folder-lbl fade-label"
             [class.location-text]="item.type === 'location'"
             [class.disabled]="!item.isValid">
        <ion-checkbox (ngModelChange)="applyChanges(item, $event)" slot="start" label-placement="end" [(ngModel)]="item.checked" class="message-checkbox default-checkbox">
          {{ item.text }}
        </ion-checkbox>
      </label>
    </div>
  </div>

  <app-treeview (onCheck)="onCheck.emit($event)"
                [disabled]="item.checked"
                [hidden]="hiddenItemsMap[item.id]"
                [items]="item.children"></app-treeview>
</div>
