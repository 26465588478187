import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { each, has } from 'lodash';


export interface ITab {
  key?: string;
  value: string;
  prefix?: string;
  postfix?: string;
  form?: string;
  permission?: () => boolean;
  disabled?: boolean;
  hidden?: boolean;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnChanges {
  @Input() tabs: ITab[];
  @Input() currentTab: ITab;
  @Output() currentTabChange: EventEmitter<ITab> = new EventEmitter();

  public tabList: ITab[];

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  public switchTab(tab: ITab): void {
    this.currentTab = tab;
    this.currentTabChange.emit(this.currentTab);
  }

  public init() {
    this.tabList = [];

    each(this.tabs, (tab: ITab) => {
      if (has(tab, 'permission')) {
        if (tab.permission()) {
          this.tabList.push(tab);
        }
      } else {
        this.tabList.push(tab);
      }
    });
  }
}
