<div class="form-divider-title headline-small">{{ title.main | translate }}</div>
<ng-container *ngIf="parentReorderConfig?.items?.length">
  <div class="form-divider-title sub-title">{{ title.parent | translate }}</div>
  <app-reorder-list [disabled]="true" [config]="parentReorderConfig"></app-reorder-list>
</ng-container>

<div class="form-divider-title sub-title" *ngIf="title.child">{{ title.child | translate }}</div>
<app-reorder-list (onReorder)="onReorder($any($event))" [config]="reorderConfig"></app-reorder-list>

<div class="add-block">
  <ion-button class="page-button" (click)="selectProperties()" color="secondary">{{ "PROPERTY.Select_Adjust_Property" | translate }}</ion-button>
</div>

<ng-template let-property="item" #propertyNameRef>
  <div class="title">
    <div *ngIf="!property.propertyID" class="folder-icon"></div>
    <div *ngIf="property.propertyID" class="title-value">{{property.title | translate}}</div>
    <div *ngIf="!property.propertyID" class="title-value">{{this.folderPath(property.folderID)}}</div>
  </div>
</ng-template>
<ng-template let-property="item" let-disabled="disabled" #propertyStateRef>
  <ion-toggle (ngModelChange)="onStateChanged($event, property)" [disabled]="disabled" [(ngModel)]="property.healthRelated" color="secondary"></ion-toggle>
</ng-template>
