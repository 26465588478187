import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { extend } from 'lodash';

export interface RangeInputConfig {
  min?: number;
  max?: number;
  title?: string;
  titleMinRange?: string;
  titleMaxRange?: string;
  currentValue?: number;
}

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
})
export class RangeComponent implements OnInit {

  @Output() onChangedValue: EventEmitter<number> = new EventEmitter();
  @Output() onChangingValue: EventEmitter<number> = new EventEmitter();
  public config: RangeInputConfig = {
    min: 0,
    max: 100,
    currentValue: 50,
    titleMinRange: 'SHARED.Not_Very',
    titleMaxRange: 'Very'
  };

  constructor() {
  }

  @Input('config')
  set reportData(config: RangeInputConfig) {
    if (config) {
      extend(this.config, config);
    }
  }

  ngOnInit() {
  }

  public emitChangedValue(value: string): void {
    this.onChangedValue.emit(+value);
  }

  public emitChangingValue(value: number): void {
    this.onChangingValue.emit(value);
  }

}
