import { ElementRef, HostListener, Injectable } from '@angular/core';

@Injectable()
export abstract class AbstractModalCloseHandler {

  protected isModal: boolean;
  private isBackNavigationActivated: boolean;

  constructor(protected elementRef: ElementRef) {
  }

  public abstract back();

  @HostListener('document:keydown.escape', ['$event']) onKeyDownHandler() {
    const isElementVisible: boolean = this.elementRef.nativeElement.offsetParent;
    const isPopoverNotActive: boolean = document.getElementsByTagName('ion-popover').length === 0;
    const isModalNotActive: boolean = document.getElementsByTagName('ion-modal').length === 0;

    if (!this.isBackNavigationActivated && isElementVisible && isPopoverNotActive && isModalNotActive) {
      this.setNavigationState();

      setTimeout(() => {
        this.back();
      }, 200);
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.isModal) {
      this.back();
    }
  }

  public setNavigationState(activated = true) {
    this.isBackNavigationActivated = activated;
  }
}
