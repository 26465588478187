import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { DeepLinkGuard } from '@services/deep-link/deep-link.service';

import { Observable } from 'rxjs';
import { get, split } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailLinkGuard implements CanActivate, DeepLinkGuard {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id: string = next.paramMap.get('id');
    return this.navigateToAssetPage(id);
  }

  public async navigateToAssetPage(assetId: string) {
    return this.router.navigate([`/pages/dashboard/asset/${assetId}`]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public parseUrlId(url: string): string {
    return get(split(url, '/pages/dashboard/asset/'), '[1]');
  }

  private navigateToMainPage(): boolean {
    this.router.navigate(['pages/dashboard/asset']);
    return false;
  }

}
