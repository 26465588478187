import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { BaseDetailsPage } from '@modules/management/pages/details/abstractDetails/abstractBaseDetails.page';
import { ICheck } from '@modules/management/pages/details/check/models';
import {
  ChecksService,
  FormBuilderService,
  LoadingService,
  PopoverService,
  SettingsService,
  UserdataService,
  UserService,
  UtilsService
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import { CustomValidate } from '@modules/management/pages/details/abstractDetails/validation';
import * as uuid from 'uuid';
import { get } from 'lodash';

@Component({
  selector: 'app-check-define',
  templateUrl: './check-define.page.html',
  styleUrls: ['./check-define.page.scss'],
})
export class CheckDefinePage extends BaseDetailsPage {

  @Input() parentElementRef: ElementRef;
  @Input() checkId: string;

  @Output() onDefine: EventEmitter<any> = new EventEmitter<any>();

  public title: string;
  protected disableBackNavigation = true;
  protected routeIdParameterName = 'id';
  private formPrefix = `checkForm_${uuid.v4()}`;
  public formId = `${this.formPrefix}Form`;
  protected newForm: any = {
    id: this.formId,
    cancelButton: `${this.formPrefix}Cancel`,
    saveButton: `${this.formPrefix}Save`,
  };

  protected editForm: any = {
    id: this.formId,
    cancelButton: `${this.formPrefix}Cancel`,
    saveButton: `${this.formPrefix}Save`,
    deleteButton: `${this.formPrefix}Delete`
  };

  protected formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: this.formPrefix,
    del: null,
    save: this.translate.instant('MGMT_DETAILS.Build_Check'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        title: this.translate.instant('MGMT_DETAILS.Check_Info'),
        type: 'divider'
      },
      {
        containerClass: 'check-field',
        required: true,
        inputtype: 'verbatim',
        name: 'title',
        title: this.translate.instant('SHARED.Check_Name'),
        type: 'text',
        size: 20
      },
      {
        containerClass: 'check-field',
        required: false,
        inputtype: 'verbatim',
        name: 'translation_title',
        title: this.translate.instant('MGMT_DETAILS.Check_Name_Translation'),
        type: 'textTranslations',
        fromID: 'ntranslations',
        size: 20,
        maxlength: 50
      },
      {
        containerClass: 'check-field',
        required: true,
        title: this.translate.instant('SHARED.Check_Type'),
        name: 'checkType',
        type: 'selectmenu',
        multiple: false,
        valueProperty: 'messageID',
        options: this.settingsService.checkType.data,
        func: (checkType: any) => checkType.messageTitle,
        test: (checkType) => !get(checkType, 'disabledAt'),
        placeholder: ''
      },
      {
        title: this.translate.instant('MGMT_DETAILS.Check_Description'),
        name: 'description',
        type: 'textarea',
        containerClass: 'check-field full-width',
        break: true
      },
      {
        containerClass: 'check-field',
        required: false,
        inputtype: 'verbatim',
        name: 'translation_description',
        title: this.translate.instant('MGMT_DETAILS.Check_Description_Translation'),
        type: 'textareaTranslations',
        fromID: 'translations',
        size: 20,
      },
      {
        hidden: true,
        containerClass: 'check-field',
        title: this.translate.instant('MGMT_DETAILS.This_Is_Compliance_Check'),
        name: 'isCompliance',
        type: 'flipswitch',
        value: 1,
        default: 0,
        disabledFieldNames: ['identifier']
      },
      {
        containerClass: 'check-field',
        name: 'identifier',
        title: this.translate.instant('MGMT_DETAILS.Standard_Compliance'),
        type: 'text'
      },
      {
        title: this.translate.instant('MGMT_DETAILS.Sharing_Preferences'),
        type: 'divider'
      },
      {
        containerClass: 'check-field',
        title: this.translate.instant('MGMT_LIST.Availability'),
        name: 'locations',
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'locationID',
        options: this.userService.getUserLocations(this.userdataService.locations, false),
        placeholder: '',
        func: (location: any) => location.name
      },
      {
        name: 'readOnly',
        title: '',
        type: 'checkbox',
        options: {
          checked: {
            description: this.translate.instant('MGMT_DETAILS.Cannot_Be_Edited_By_Others'),
            id: 1
          }
        }
      },
    //   {
    //     title: this.translate.instant('MGMT_DETAILS.Data_Management'),
    //     type: 'divider'
    //   },
    //   {
    //     containerClass: 'check-field',
    //     title: this.translate.instant('MGMT_DETAILS.Include_In_Operational_Data_Model'),
    //     name: 'isOperational',
    //     type: 'flipswitch',
    //     value: 1,
    //     default: 0,
    //     disabledFieldNames: ['operationalDataType']
    //   },
    //   {
    //     containerClass: 'check-field',
    //     title: this.translate.instant('MGMT_DETAILS.Data_Mapping'),
    //     name: 'operationalDataType',
    //     type: 'selectmenu',
    //     multiple: false,
    //     required: true,
    //     canClear: true,
    //     originalOrder: true,
    //     placeholder: '',
    //     options: [
    //       {
    //         id: 'safety',
    //         description: this.translate.instant('SUB_Settings.Safety')
    //       },
    //       {
    //         id: 'quality',
    //         description: this.translate.instant('SHARED.Quality')
    //       },
    //       {
    //         id: 'productivity',
    //         description: this.translate.instant('MGMT_DETAILS.Productivity')
    //       },
    //       {
    //         id: 'humanCapitalEngagement',
    //         description: this.translate.instant('MGMT_DETAILS.Human_Capital_Engagement')
    //       }
    //     ]
    //   }
    ]
  };

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    protected translate: TranslateService,
    private settingsService: SettingsService,
    private userService: UserService,
    private userdataService: UserdataService,
    private checksService: ChecksService,
    private utils: UtilsService
  ) {
    super(route, formBuilderService, elementRef, popoverService, location, loadingService, translate);
    setTimeout(() => {
      super.ionViewDidEnter();
    });
  }

  public prepareFormConfig(): void {
    this.prepareForm();
  }

  public refreshForm() {
    this.clearNewForm();
    this.prepareForm();
    this.ionViewDidEnter();
  }

  public prepareForm() {
    if (this.checkId && !this.messageID) {
      this.messageID = this.checkId;
    }

    if (this.messageID) {
      this.updatePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Update_Check_Popover_Title');
      this.updatePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Update_Check_Popover_Description');

      this.deletePopoverConfig.title = this.translate.instant('SHARED.Delete_Check_Popover_Title');
      this.deletePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Delete_Check_Popover_Description');

      this.formConfig.save = this.translate.instant('SHARED.Save');
      this.formConfig.del = this.translate.instant('SHARED.Delete_Check_Popover_Title');
      this.formConfig.enableChangeDetectionMode = true;
      this.checkId = this.messageID;
    }

    this.formId = this.messageID && !this.copyMode ? this.editForm.id : this.newForm.id;
  }

  public clearNewForm() {
    this.elementRef.nativeElement.querySelector(`#${this.newForm.id}`).innerHTML = '';
  }

  protected addHandler: any = (formData: any) => {
    this.prepareFormData(formData);
    return this.checksService.addCheck(formData);
  };

  protected updateHandler: any = (formData: any) => {
    this.prepareFormData(formData);
    formData.checkID = this.checkId;
    return this.checksService.updateCheck(formData);
  };

  protected deleteHandler: any = async () => {
    await this.checksService.removeCheck([this.checkId]);
    return this.checksService.refresh();
  };

  protected getData(): any {
    const checkTypeId: string = this.route.snapshot.paramMap.get('typeId');
    this.customValidate = new CustomValidate(this.parentElementRef, this.translate);
    let checkData: ICheck = <ICheck>{};

    if (checkTypeId) {
      checkData.checkType = +checkTypeId;
    }

    if (this.checkId) {
      checkData = this.checksService.getCheck(this.checkId) || checkData;
      this.utils.decodeTranslations(checkData, ['translation_title', 'translation_description'], ['title', 'description']);
    }

    return checkData;
  }

  protected onComplete(data?: any): void {
    this.onDefine.emit(data);
  }

  private encodeTranslations(formData): void {
    this.utils.encodeTranslations(formData, ['translation_title', 'translation_description'], ['title', 'description']);
  }

  private prepareFormData(formData) {
    this.encodeTranslations(formData);
    formData.readOnly = formData.readOnly || 0;
    formData.isCompliance = formData.isCompliance || 0;
    formData.isOperational = formData.isOperational || 0;
    formData.locations = formData.locations || [];
  }

}
