import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { Router } from '@angular/router';
import { each, find, includes, map, remove } from 'lodash';

@Component({
  selector: 'app-category-popup',
  templateUrl: './category-popup.component.html',
  styleUrls: ['./category-popup.component.scss'],
})
export class CategoryPopupComponent implements OnInit {
  @Input() observationID: number;
  public categories: any = [];
  public selectedCats: any = [];
  public textMessage = '';

  constructor(private popOver: PopoverController,
              private popOps: PopupObservationService,
              private router: Router) {
  }

  ngOnInit() {
    this.categories = this.popOps.getCategories(this.observationID);
    const preSelected = this.popOps.getObsCats(this.observationID);
    // clean out all the cats from re-loading
    map(this.categories, val => {
      val.isChecked = false;
    });
    // fill out cats pre-selected
    each(preSelected, val => {
      const catObj = find(this.categories, ['messageID', Number(val)]);
      if (catObj.messageID) {
        catObj.isChecked = true;
      }
    });

    // fill out preSelected
    this.selectedCats = preSelected;

  }

  public close(): void {
    this.popOver.dismiss();
  }


  public getCats(entry) {
    if (entry.isChecked) {
      if (!includes(this.selectedCats, entry.messageID)) {
        this.selectedCats.push(entry.messageID);
      }
    } else {
      remove(this.selectedCats, elem => elem === entry.messageID);
    }
  }

  submitClosing() {
    this.popOver.dismiss();
    const requestObject: any = {
      cmd: 'updateObservation',
      observationID: this.observationID,
      state: 'resolved',
      categories: JSON.stringify(this.selectedCats)
    };
    this.popOps.categoryChange(requestObject);
  }


}
