import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { AccountsService, CommsService, ImageUploaderService, UtilsService } from '@services';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { ImageGalleryComponent } from '@shared/components';

import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { fill, filter, findIndex, map, orderBy } from 'lodash';

interface GalleryViewImage {
  src: string;
  objectID?: number;
  actionable?: boolean;
  uploaded?: boolean;
  isEmpty?: boolean;
  mediaType?: string;
  owner?: string;
  time?: string;
  class?: string;
}

export interface ObservationGalleryImage {
  objectID: number;
  mediaType: string;
  time: number;
  ownerID: number;
  subtype?: string;
}

@Component({
  selector: 'app-observation-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ObservationImageGalleryComponent extends CustomFormComponent implements OnInit {

  @ViewChild(SlickCarouselComponent) slickCarouselComponent: SlickCarouselComponent;

  @Input() title: string;
  @Input() isAnonymous: boolean;
  @Input() type = 'observation';
  @Input() subtype = '';
  public slideConfig = {
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    infinite: false,
    arrows: false
  };
  public viewImages: GalleryViewImage[] = [];
  private images: ObservationGalleryImage[] = [];
  private isInitialised: boolean;
  private observationID: number;

  constructor(
    private imageUploaderService: ImageUploaderService,
    private commsService: CommsService,
    private utils: UtilsService,
    private modalController: ModalController,
    private accountsService: AccountsService,
    private elementRef: ElementRef,
    private observationDetailService: ObservationDetailService
  ) {
    super();
  }

  @Input('observationId') set observationId(id: number) {
    if (id) {
      this.observationID = id;
      this.refreshData();
    }
  }

  ngOnInit() {
    this.initSlider();
  }

  public refreshData() {
    if (this.observationID) {
      const attachments = this.observationDetailService.getGalleryImagesById(this.observationID);

      if (attachments?.length) {
        this.images = attachments;
        this.initImages();
      }
    }
  }

  public initSlider(): void {
    const hostWidth: number = this.elementRef.nativeElement.offsetWidth;

    if (!this.isInitialised && hostWidth) {
      const imageItemWidth = 134;
      const imageItemSpace = 60;

      this.isInitialised = true;
      this.slideConfig.slidesToShow = Math.floor(hostWidth / (imageItemWidth + imageItemSpace));
      this.initImages();
    }
  }

  public onImageClick(image): void {
    if (image.actionable) {
      this.uploadImage();
    } else if (image.uploaded) {
      this.show(image);
    }
  }

  public show(selectedImage: GalleryViewImage): void {
    if (selectedImage.objectID) {
      const images = filter(this.images, 'objectID');
      const imageIndex: number = findIndex(images, {objectID: selectedImage.objectID});

      this.modalController.create({
        component: ImageGalleryComponent,
        componentProps: {
          images: map(images, (image) => ({
            url: this.commsService.objectURI(image.objectID, false),
            thumbnailUrl: this.commsService.objectURI(image.objectID, true),
            mediaType: image.mediaType
          })),
          index: imageIndex
        },
        cssClass: 'image-gallery-modal'
      }).then((modal) => {
        modal.present();
      });
    }
  }

  private initImages(): void {
    this.images = orderBy(this.images, 'time');

    if (this.isInitialised) {
      const captureActive = {src: 'assets/images/captureActive.svg', actionable: true};
      const captureInactive = {src: 'assets/images/captureInactive.svg', isEmpty: true};

      this.viewImages = map(this.images, (savedImage) => ({
        src: this.observationDetailService.getObjectURI(savedImage.objectID),
        uploaded: true,
        mediaType: savedImage.mediaType || 'image/png',
        owner: this.accountsService.getCompactName(savedImage.ownerID),
        time: this.utils.dateTimeFormat(savedImage.time, null, true),
        objectID: savedImage.objectID,
        class: this.observationDetailService.getImageBorderColor(this.observationID, savedImage.subtype)
      }));

      if (this.viewImages.length) {
        this.viewImages.push(captureActive);

        if (this.viewImages.length < this.slideConfig.slidesToShow) {
          const inactiveItemsCount: number = this.slideConfig.slidesToShow - this.viewImages.length;
          const inactiveItems = fill(Array(inactiveItemsCount), captureInactive);
          this.viewImages.push(...inactiveItems);
        }

        if (this.viewImages.length > this.slideConfig.slidesToShow) {
          setTimeout(() => {
            this.slickCarouselComponent.slickGoTo(this.viewImages.length - this.slideConfig.slidesToShow);
          });
        }
      } else {
        const inactiveItems = fill(Array(this.slideConfig.slidesToShow - 1), captureInactive);
        this.viewImages = [
          captureActive,
          ...inactiveItems
        ];
      }
    }
  }

  private uploadImage(): void {
    const options: any = {
      acceptFiles: this.imageUploaderService.convertTypesToString({
        image: ['gif', 'jpeg', 'jpg', 'png'],
        video: ['mp4']
      }),
      formData: {
        type: this.type,
        subtype: this.subtype,
        isAnonymous: this.isAnonymous
      }
    };

    if (this.observationID) {
      options.formData.observationID = this.observationID;
    }

    this.imageUploaderService.upload(options).then(({objectID, mediaType, createdAt, creatorID}) => {
      if (objectID) {
        this.images.push({objectID, mediaType, time: createdAt, ownerID: creatorID});
        this.formValue = map(this.images, 'objectID');
        this.initImages();
      }
    });
  }

}
