import { Router } from '@angular/router';
import { ObservationService, TeamsService } from '@services';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { BaseItemList } from '../abstract-base-item-list/base-item-list';
import { ItemListComponent } from '@shared/components/item-list/item-list.component';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, filter, map, orderBy } from 'lodash';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss'],
})
export class TeamListComponent extends BaseItemList implements OnInit, OnChanges {

  @Input() isMultiple = true;
  @Input() isAssignPopUp?: boolean;
  @Output() onSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild(ItemListComponent) itemListComponent: ItemListComponent;

  public teams: any[];
  public uuid: string;

  constructor(
    private teamsService: TeamsService,
    private translate: TranslateService,
    private router: Router,
    protected observationService: ObservationService
  ) {
    super();
  }

  ngOnInit() {
    this.getTeamList();
  }

  ngOnChanges() {
    if (this.isAssignPopUp) {
      const curUrl = this.router.url.split('/');
      this.uuid = curUrl[curUrl.length - 1].split('?')[0];
      this.observationService.getObservationByUUID(this.uuid).then(
        data => {
          if (!this.teams.length) {
            this.getTeamList();
          }
          this.teams = this.teams.filter(team => !team.locations.length || team.locations.includes(data.locationID));
        }
      );
    }
  }

  getTeamList() {
    const teams: any[] = orderBy(cloneDeep(filter(this.teamsService.teams.data, {disabledAt: 0})), 'name');
    this.teams = map(teams, (team: any) => {
      team.description = '';
      team.itemID = team.groupID;
      if (team.disabledAt) {
        team.id += ' (' + this.translate.instant('SHARED.Deactivated') + ')';
      }
      return team;
    });

    this.teams = this.filterByLocation(this.teams);
  }

  public onTeamSelected(): void {
    this.onSelected.emit(this.getSelectedTeams());
  }

  public getSelectedTeams(): number[] {
    return this.itemListComponent.getSelectedItems();
  }

  public init(): void {
    this.itemListComponent.resetCheckedItems();
    this.itemListComponent.clearSearch();
  }

}
