import { Component, OnDestroy, OnInit } from '@angular/core';
import * as WordCloud from 'wordcloud';
import * as ss from 'simple-statistics';
import { Events } from '@services/events/events.service';
import { Router } from '@angular/router';
import { cloneDeep, each, sortBy } from 'lodash';

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss'],
})


export class WordCloudComponent implements OnInit, OnDestroy {

  wordCloudElement: any;
  olddimension: any;
  oldContext: any;
  wordList: any = [];
  noData = false;
  weightFactor = 1;
  optionsWordCloud: any = {
    color: (word, weight) => '#1AACE5',
    list: [],
    rotateRatio: 0,
    minSize: 0,
    weightFactor: 1,
    fontFamily: 'WorkSans-SemiBold, SourceSansPro-SemiBold, NotoColorEmoji-Regular',
    clearCanvas: true,
    gridSize: 35,
    drawOutOfBound: !1,
    shrinkToFit: 1,
    drawMask: !1,
    shuffle: 1,
    classes: 'cloud-over',
    hover: null,
    shape: 'circle',
  };

  constructor(private events: Events,
              private router: Router) {
  }

  ngOnInit() {
    this.events.subscribe('ccs:sliderEvent', this.size);
    if (!this.wordList.length) {
      this.noData = true;
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:sliderEvent', this.size);
  }

  public refresh(size: number = 1, wordList: any = []) {
    this.wordList = wordList;
    this.optionsWordCloud.list = cloneDeep(this.wordList.slice(0, 20 * size));
    this.optionsWordCloud = this.normalizeSize(this.optionsWordCloud);
    this.optionsWordCloud.gridSize = 35;
    WordCloud([
      document.getElementById('my_canvas'),
      document.getElementById('my_container'),
    ], this.optionsWordCloud);
    // setTimeout(() => {
    //   document.querySelector('#my_container').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    // }, 1000);
  }

  // method to control the size of the cloud, we display more words with less weight when we zoom out
  size = (params) => {
    this.optionsWordCloud.list = cloneDeep(this.wordList.slice(0, 20 * params));
    this.optionsWordCloud = this.normalizeSize(this.optionsWordCloud);
    // this.optionsWordCLoud.gridSize = 30 + 2.5*params;
    WordCloud([
      document.getElementById('my_canvas'),
      document.getElementById('my_container'),
    ], this.optionsWordCloud);
  };

  getList() {
    return this.wordList.slice(0, 20);
  }

  ionViewDidEnter() {

  }

  normalizeSize(list) {
    let arrayOfWeights = [];
    if (!list.list.length) {
      return list;
    }
    each(list.list, item => {
      arrayOfWeights.push(item[1]);
    });
    const maxItem = ss.max(arrayOfWeights);
    let minItem = ss.min(arrayOfWeights);
    arrayOfWeights = sortBy(arrayOfWeights);
    const lowerLimit = 20;
    let UpperLimit = 80;
    if (maxItem === minItem) {
      minItem = 0;
      UpperLimit = 40;
    }
    each(list.list, item => {
      item[1] = this.linearFunc(item[1], minItem, maxItem, lowerLimit, UpperLimit);
    });
    return list;
  }

  private linearFunc(item, minItem, maxItem, lowerLimit, UpperLimit) {
    return ((item - minItem) / (maxItem - minItem)) * (UpperLimit - lowerLimit) + lowerLimit;
  }

}

