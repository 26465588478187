import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { each } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SiteHeaderMessagingService {

  messages: any = {
    data: [],
    lastRequest: null
  };

  constructor(
    private commsService: CommsService,
    private subscriberService: SubscriberService
  ) {
  }

  public refresh() {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      const header = this.subscriberService.getPreference('safetyMessageHeader');
      const randomSafe = this.subscriberService.getPreference('randomSafetyMessage');
      const translations = this.subscriberService.getPreference('safetyMessageHeaderTranslations');
      const arrayMessage = {
        messageID: 11,
        message: header,
        checkmessage: randomSafe
      };
      if (translations && translations.length) {
        each(translations, (ref) => {
          arrayMessage[`translations_${ref.language}`] = ref.value;
        });
      }
      const buildData = [arrayMessage];
      this.messages.lastRequest = when;
      this.messages.data = buildData;
      resolve(this.messages.data);
    });
  }

  public handleAddMessage(fData) {
    return;
  }


  public handleUpdateMessage(fData) {
    return this.refresh();
  }

  public handleDeleteMessage(fData) {
    return;
  }


}
