<div *ngFor="let node of nodes | orderBy: 'label'">
  <div class="node">
    <div *ngIf="isChildren"
         (click)="hiddenItemsMap[node.id] = !hiddenItemsMap[node.id]"
         [class.clickable]="node?.nodes?.length"
         class="tree-icon">
    </div>

    <ng-container *ngIf="nodeTemplate; else defaultTemplate">
      <ion-checkbox *ngIf="multiselect" (ngModelChange)="onCheckboxChanged(node, $event)" [(ngModel)]="node.checked"></ion-checkbox>
      <div class="template-node clickable" (click)="toggleSelect(node)">
        <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{ node: node, isChildren: isChildren }"></ng-container>
      </div>
    </ng-container>

    <ng-template #defaultTemplate>
      <ion-checkbox *ngIf="multiselect && canSelectNode(node)" (ngModelChange)="onCheckboxChanged(node, $event)" [(ngModel)]="node.checked"></ion-checkbox>
      <div class="node-label clickable"
           (click)="toggleSelect(node)"
           [class.selected]="!multiselect && node.checked">
        <div *ngIf="node?.nodes?.length || node?.showFolderIcon" class="folder-icon"></div>
        <ion-label>{{ node.label | translate }}</ion-label>
      </div>
    </ng-template>
  </div>

  <app-tree-viewer *ngIf="node?.nodes?.length"
                   [disableParentSelection]="disableParentSelection"
                   [disabled]="node.checked"
                   [nodeTemplate]="nodeTemplate"
                   [hidden]="hiddenItemsMap[node.id]"
                   [class.children]="isChildren"
                   [multiselect]="multiselect"
                   [isChildren]="true"
                   (onResetSelection)="resetSelection()"
                   (onChange)="onChildSelected()"
                   [nodes]="node.nodes">
  </app-tree-viewer>
</div>
