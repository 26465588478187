import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxCurrencyDirective } from 'ngx-currency';
import { TranslateModule } from '@ngx-translate/core';
import { DetailFooterComponent } from '@modules/observation/components';

import {
  ItemListComponent,
  ItemListDragAndDropComponent,
  PopoverTemplateComponent,
  SliderComponent,
  TabsComponent,
  TeamListComponent,
  ToggleComponent,
  UserListComponent,
  ListMenuComponent,
  RangeComponent,
  RemovalPanelComponent,
  AccountCanViewExceptComponent,
  CloseButtonComponent,
  CurrencyComponent,
  ViewSwitcherComponent,
  WorkerPointGridComponent,
  MessageModalComponent,
  ImageGalleryComponent,
  WordCloudComponent,
  ImageGalleryModalComponent,
  AutoPageRefresherComponent,
  ChangesPanelComponent,
  BackNavigationComponent,
  DatePickerComponent,
  DatePickerPopupComponent,
  DateRangePickerComponent,
  FormTranslationFieldComponent,
  SelectFiltersFormModalComponent,
  ModalTableComponent,
  MediaViewerComponent,
  ManageLocationComponent,
  MediaFileComponent,
  TimeAgoComponent,
  TableAttachmentsFieldComponent,
  TreeviewComponent,
  TablesByTabsComponent,
  DataTableComponent,
  ReorderListComponent
} from '@shared/components';
import {
  AccountCanViewExceptDirective,
  PermissionCanViewDirective,
  Select2ParentDirective,
  Select2TextClearingDirective,
  ShowByModuleDirective,
  HideShowPasswordDirective,
  ShowByBrandDirective
} from './directives';
import { HumanizeNumberPipe, MenuFilterPipe, TranslateByPipe, DelimiterPipe, PermissionPipe } from '@shared/pipes';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { IonicModule } from '@ionic/angular';
import { Select2Module } from '@shared/components/ng2-select2/ng2-select2.module';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';
import { ObservationHistoryEventPipe } from '@modules/observation/pipes';
import { LightgalleryModule } from 'lightgallery/angular';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

const COMPONENTS = [
  PopoverTemplateComponent,
  ToggleComponent,
  SliderComponent,
  UserListComponent,
  TabsComponent,
  TeamListComponent,
  ItemListComponent,
  ItemListDragAndDropComponent,
  ListMenuComponent,
  RangeComponent,
  RemovalPanelComponent,
  AccountCanViewExceptComponent,
  CloseButtonComponent,
  CurrencyComponent,
  ViewSwitcherComponent,
  WorkerPointGridComponent,
  MessageModalComponent,
  ImageGalleryComponent,
  WordCloudComponent,
  ImageGalleryModalComponent,
  AutoPageRefresherComponent,
  ChangesPanelComponent,
  BackNavigationComponent,
  DatePickerComponent,
  DatePickerPopupComponent,
  DateRangePickerComponent,
  FormTranslationFieldComponent,
  SelectFiltersFormModalComponent,
  ModalTableComponent,
  MediaViewerComponent,
  ManageLocationComponent,
  MediaFileComponent,
  TimeAgoComponent,
  DetailFooterComponent,
  TableAttachmentsFieldComponent,
  TreeviewComponent,
  DataTableComponent,
  TablesByTabsComponent,
  ReorderListComponent
];

const PIPES = [
  MenuFilterPipe,
  HumanizeNumberPipe,
  TranslateByPipe,
  PermissionPipe,
  ObservationHistoryEventPipe,
  DelimiterPipe
];

const DIRECTIVES = [
  AccountCanViewExceptDirective,
  Select2ParentDirective,
  Select2TextClearingDirective,
  PermissionCanViewDirective,
  ShowByModuleDirective,
  HideShowPasswordDirective,
  ShowByBrandDirective
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  imports: [
    Select2Module,
    CommonModule,
    FormsModule,
    NgxSliderModule,
    NgxCurrencyDirective,
    TranslateModule.forChild(),
    DeferLoadModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    LightgalleryModule,
    InfiniteScrollDirective
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule {
}
