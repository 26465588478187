import { Component, Input, OnChanges } from '@angular/core';

import { Asset } from '@services/assets/asset.interfaces';
import { PropertyCategory } from '@services/property/property-model.interfaces';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { Observation, ObservationStatusCode } from '@services/observations/observation.interfaces';
import { AssetsService, Feature, PropertyService, SubscriberService } from '@services';

import { some } from 'lodash';

@Component({
  selector: 'app-asset-status-panel',
  templateUrl: './asset-status-panel.component.html',
  styleUrls: ['./asset-status-panel.component.scss'],
})
export class AssetStatusPanelComponent implements OnChanges {
  @Input() observation: Observation;
  public asset: Asset;
  public loggedStatusCode: ObservationStatusCode;
  public fixedStatusCode: ObservationStatusCode;
  public isInitialized: boolean;
  public isStatusCodeAvailable: boolean = false;

  constructor(
    private assetService: AssetsService,
    private observationDetailService: ObservationDetailService,
    private propertyService: PropertyService,
    private subscriberService: SubscriberService
  ) {}

  ngOnChanges() {
    if (this.observation) {
      this.init();
    }
  }

  public init() {
    if (this.observation.subjectType === 'asset' && this.observation.subjectID) {
      this.asset = this.assetService.getAssetById(this.observation.subjectID)?.[0];
      this.loggedStatusCode = this.observationDetailService.getAIStatus(this.observation);
      this.fixedStatusCode = this.observationDetailService.getAIStatus(this.observation, true);
      this.isInitialized = true;
      this.checkStatusCode();
    }
  }

  private checkStatusCode() {
    const isInService = some(this.asset?.properties, (assetProperty) => {
      const property = this.propertyService.getPropertyById(assetProperty?.propertyID);
      return property?.category === PropertyCategory.InService && property?.active;
    });

    this.isStatusCodeAvailable = isInService && this.subscriberService.usesFeature(Feature.PROPERTIES);
  }
}
