import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

import { MenuItem, MenuService, PermissionsService, SubscriberService } from '@services';
import { Collapse } from '@animations/collapse.animation';
import { each, filter, find } from 'lodash';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [Collapse(90, 'menuChildren')]
})
export class MenuComponent implements OnInit {

  public brand = '';
  public menuItems: MenuItem[] = this.menuService.getMenuItems();

  constructor(
    public menu: MenuController,
    private router: Router,
    public permissions: PermissionsService,
    public subscriber: SubscriberService,
    private menuService: MenuService
  ) {
  }

  ngOnInit(): void {
    const d = this.subscriber.brandInfo();
    this.brand = d.brand;
  }

  public checkAvailability() {
    this.menuItems = this.menuService.getMenuItems();
  }

  public onClickMenuItem(item: MenuItem, event: any): void {
    const isOpen = !item.isOpen;
    event.stopPropagation();

    if (item.children) {
      each(this.menuItems, (menuItem: MenuItem) => {
        menuItem.isOpen = false;
      });
      item.isOpen = isOpen;
    } else {
      this.navigateByMenuItem(item);
    }
  }

  public onClickMenuChildItem(item: MenuItem, event: any): void {
    event.stopPropagation();
    this.navigateByMenuItem(item);
  }

  public navigateByMenuItem(item: MenuItem): void {
    this.menu.close('sideMenu').then(() => {
      if (item.title === 'SHARED.Observations') {
        this.router.navigate([item.url], {replaceUrl: true, queryParams: {clearSearch: true, clearFilter: true}});
      } else if (item.title === 'SHARED.USERS') {
        this.router.navigate([item.url], {replaceUrl: true});
      } else {
        this.router.navigate([item.url], {replaceUrl: true});
      }
    });
  }

  public isAvailable(): boolean {
    this.checkAvailability();
    return filter(this.menuItems, 'isAvailable').length > 0;
  }

  public close(): void {
    this.menu.close();
  }

  // Menu item should have an url to navigate or any available children
  isMenuItemAvailable(menuItem: MenuItem) {
    const availableChildren = find(menuItem.children, 'isAvailable');
    return menuItem.isAvailable && (menuItem.url || availableChildren);
  }
}
