import { Injectable, Injector } from '@angular/core';

import { ReportService } from '@modules/reporting/services/report.service';
import {
  AccountsService,
  FormBuilderService,
  LoadingService,
  SettingsService,
  TeamsService,
  UserdataService,
  ZoneService
} from '@services';
import {
  checkDetailReport,
  checkSummaryReport,
  obsSummaryReport,
  PPEReport,
  WorkerReport,
  ReportBaseClass,
  ObsDetailReport
} from '@modules/reporting/models';
import { cloneDeep, each, filter, find, get, intersection, intersectionBy, isEmpty, map, reject, sortBy } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ReportingFormBuilderService {

  constructor(
    private reportService: ReportService,
    private formBuilderService: FormBuilderService,
    private loadingService: LoadingService,
    private accountsService: AccountsService,
    private teamService: TeamsService,
    private zoneService: ZoneService,
    private settingsService: SettingsService,
    private userdataService: UserdataService,
    private injector: Injector
  ) {
  }

  public syncGraphSecondaryField(type: string, formConfig: any, values: any[] = null): void {
    let columnOptions: any;

    // if (type === 'observation') {
    //   columnOptions = this.reportService.observationReportColumnOptions;
    // } else if (type === 'check') {
    //   columnOptions = this.reportService.checkReportColumnOptions;
    // }
    // else {
    //   columnOptions = this.reportService.workerReportColumnOptions;
    // }

    if (!values) {
      values = <any[]>$(`#${formConfig.prefix}${type}ReportColumns`).val();
    }
    values = map(values, (value) => ({id: value}));
    const options: any[] = intersectionBy(columnOptions, values, 'id');
    const targetFieldName = `${type}GraphSecondary`;
    const targetFieldId = `#${formConfig.prefix}${targetFieldName}`;
    const targetFieldValue: any = $(targetFieldId).val();
    const fieldOptions: any = Object.assign(find(formConfig.fields, {name: targetFieldName}), {options});

    this.formBuilderService.replaceSelectOptionsWith(targetFieldId, formConfig, fieldOptions, {[targetFieldName]: targetFieldValue});
  }

  public updateGraphFields(type, formConfig: any, specificFieldName?: string) {
    const primaryFieldValue: any = $(`#${formConfig.prefix}${type}PrimaryField`).val();
    const secondaryFieldValue: any = $(`#${formConfig.prefix}${type}SecondaryField`).val();
    const periodFieldValue: any = $(`#${formConfig.prefix}period`).val();
    const extraOptions: any[] = [];

    if (periodFieldValue && periodFieldValue !== 'none') {
      extraOptions.push({id: 'period', description: 'Period'});
    }

    if (specificFieldName === 'secondary' || !specificFieldName) {
      this.syncOrganizeField(type, formConfig, primaryFieldValue, `${type}SecondaryField`);
    }
    if (specificFieldName === 'primary' || !specificFieldName) {
      this.syncOrganizeField(type, formConfig, secondaryFieldValue, `${type}PrimaryField`);
    }

    this.replaceGraphFieldsBy(type, formConfig, [primaryFieldValue, secondaryFieldValue], extraOptions);
  }

  public syncFieldsBySelectedLocations(locationIds, formConfig): void {
    this.loadingService.enable().then(() => {
      const accounts: any[] = this.accountsService.getUserlist(locationIds);
      const teams: any[] = this.getTeamOptions(this.teamService.getTeamList(locationIds));
      const settingsFieldsMap: { [key: string]: string } = {
        categories: 'category',
        behaviors: 'behavior',
        mitigations: 'mitigation',
        qualitycats: 'quality',
        compliments: 'compliment',
      };

      this.updateFieldByName('zones', this.zoneService.getGroupedZonesByLocations(locationIds), formConfig);
      each(['creators', 'owners', 'users', 'recipients'], (fieldName: string) => this.updateFieldByName(fieldName, accounts, formConfig));
      each(['groups', 'targetGroups'], (fieldName: string) => this.updateFieldByName(fieldName, teams, formConfig));
      each(settingsFieldsMap, (settingsFieldKey: string, fieldName: string) => {
        this.updateFieldByName(fieldName, this.settingsService.getSettingSync(settingsFieldKey, locationIds), formConfig);
      });

      this.loadingService.disable();
    });
  }

  public updateFieldByName(name: string, options: any, formConfig: any): any {
    const fieldId = `#${formConfig.prefix}${name}`;
    const data: any = $(fieldId).val();

    const field: any = find(formConfig.fields, {name});

    if (field) {
      const fieldOptions: any = Object.assign(find(formConfig.fields, {name}), {options});
      this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldOptions, {[name]: data});
    }
  }

  public getTeamOptions(teams?: any): any[] {
    if (isEmpty(teams)) {
      teams = this.teamService.getTeamList(this.userdataService.locations);
    }

    const teamsOptions: any[] = sortBy(cloneDeep(teams), 'name');
    teamsOptions.unshift({
      name: `Viewer's Primary Team`,
      groupID: -1
    });

    return teamsOptions;
  }

  public defineObstypeVisibilityByValue(formConfig: any, types: string[]): void {
    setTimeout(() => {
      if (types && types.length) {
        $('.reportField.observationType').hide();
        each(types, (type) => {
          $('.reportField.observationType.' + type + 'Observation').show();
        });
      } else {
        $('.reportField.observationType').show();
      }
      this.syncStates(formConfig, types);
    });
  }

  public syncStates(formConfig: any, observationTypes: any[]): void {
    const states: any[] = get(find(formConfig.fields, {name: 'states'}), 'options');
    const stateId = `#${formConfig.prefix}states`;
    observationTypes = observationTypes || [];

    const observationMap: { [key: string]: string | string[] } = {
      open: 'all',
      unassigned: 'all',
      fixed: ['condition', 'quality', 'pi'],
      closed: ['condition', 'quality', 'pi'],
    };

    each(states, (state) => {
      const currentStateElement: any = $(`${stateId}${state.id}`);

      if (observationMap[state.id] === 'all' || intersection(observationMap[state.id], observationTypes).length || observationTypes.length === 0) {
        currentStateElement.prop('disabled', false).closest('ion-col').show();
      } else {
        currentStateElement.prop('disabled', true).closest('ion-col').hide();
      }
    });
  }

  public replaceGraphFieldsBy(type: string, formConfig: any, values: string[], extraOptions: any[] = []) {
    let allFieldOptions: any;

    // if (type === 'observation') {
    //   allFieldOptions = this.reportService.observationReportFieldOptions;
    // } else if (type === 'check') {
    //   allFieldOptions = this.reportService.checkReportFieldOptions;
    // }
    // } else {
    //   allFieldOptions = this.reportService.workerReportFieldOptions;
    // }

    let newOptions: any[] = filter(map(values, (value: string) => find(allFieldOptions, <any>{id: value})), Boolean) || [];
    newOptions = reject([...newOptions, ...extraOptions], <any>{id: 'none'});

    if (newOptions.length || extraOptions.length) {
      const fieldNameByType = `${type}GraphPrimary`;
      const fieldId = `#${formConfig.prefix}${fieldNameByType}`;
      const values: string[] | number[] = <string[] | number[]>$(fieldId).val();
      const fieldOptions: any = Object.assign(find(formConfig.fields, {name: fieldNameByType}), {
        options: newOptions
      });
      this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldOptions, {[fieldNameByType]: values});
    }
  }

  public syncOrganizeField(type: string, formConfig: any, rejectValue: any, targetFieldName: string): void {
    let allFieldOptions: any;

    // if (type === 'observation') {
    //   allFieldOptions = this.reportService.observationReportFieldOptions;
    // } else if (type === 'check') {
    //   allFieldOptions = this.reportService.checkReportFieldOptions;
    // }
    // else {
    //   allFieldOptions = this.reportService.workerReportFieldOptions;
    // }

    const fieldId = `#${formConfig.prefix}${targetFieldName}`;
    const options: any[] = reject(allFieldOptions, {id: rejectValue});
    const values: string[] | number[] = <string[] | number[]>$(fieldId).val();
    const fieldOptions: any = Object.assign(find(formConfig.fields, {name: targetFieldName}), {
      options
    });
    this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldOptions, {[targetFieldName]: values});
  }

  public getReportInstanceByType(type: string): ReportBaseClass {
    const typeMap = {
      PPESummary: PPEReport,
      observation: obsSummaryReport,
      obsDetail: ObsDetailReport,
      check: checkSummaryReport,
      checkDetail: checkDetailReport,
      worker: WorkerReport
    };

    const reportInstance = typeMap[type];
    return reportInstance ? new reportInstance(this.injector) : null;
  }

}
