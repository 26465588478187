import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { TreeviewComponent } from '@shared/components';
import { UserService } from '@services/user/user.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { AccountsService, Module, SubscriberService } from '@services';
import { HierarchyGroupingService } from '@services/hierarchyGrouping/hierarchy-grouping.service';

import * as uuid from 'uuid';
import { each, find, intersection, includes, map, some, values } from 'lodash';

interface FolderItem {
  id: string;
  locationId: number;
  type: 'location' | 'folder';
  folderId: string;
  text: string;
  children: FolderItem[];
  locations: number[];
  checked: boolean;
  isValid: boolean;
}

@Component({
  selector: 'app-manage-location',
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.scss']
})
export class ManageLocationComponent implements OnInit {
  @ViewChild('treeviewRef') treeviewComponent: TreeviewComponent;
  @Input() checkboxValue;
  public folders: FolderItem[] = [];
  public selectedFolders: (string | number)[] = [];
  public isCheckedAll: boolean;
  public isAllLocationsAvailable: boolean;

  private userLocations = [];
  private loggedUserLocationSelections: (string | number)[] = [];

  constructor(
    private hierarchyGroupingService: HierarchyGroupingService,
    private popoverCtrl: PopoverController,
    private userService: UserService,
    private userdataService: UserdataService,
    private accountService: AccountsService,
    private subscriber: SubscriberService
  ) {}

  ngOnInit() {
    this.isCheckedAll = includes(this.checkboxValue, `node:${0}`);
    this.defineAllLocationsAvailability();
    this.defineCurrentUserLocationSelections();
    this.defineFolders();
  }

  public close(): void {
    this.popoverCtrl.dismiss('');
  }

  public onGroupSelect() {
    this.selectedFolders = [];

    if (this.isCheckedAll) {
      this.selectedFolders = ['node:0'];
    } else {
      this.defineSelectedLocations();
    }
  }

  public toggleAll(value: boolean) {
    this.isCheckedAll = value;
    each(this.folders, (item) => this.treeviewComponent.applyCheck(item, value));
    this.onGroupSelect();
  }

  public save() {
    this.popoverCtrl.dismiss(this.selectedFolders);
  }

  private addTreeItemsByLocations() {
    const treeItems = [];
    this.userLocations = this.userService.getUserLocations(this.userdataService.locations, false);

    each(this.userLocations, (location) => {
      if (location.folderID === 0) {
        treeItems.push({
          id: uuid.v4(),
          locationId: location.locationID,
          type: 'location',
          text: location.name,
          checked: this.isCheckedAll || includes(this.checkboxValue, location.locationID),
          isValid: this.isValid(location.locationID)
        });
      }
    });

    return treeItems;
  }

  private addTreeItemsByFolders() {
    const folders = this.hierarchyGroupingService.getFolders();
    this.hierarchyGroupingService.translateData(folders);

    return map(folders, (folder) => this.addTreeItemsByFolder(folder, this.isCheckedAll));
  }

  private addTreeItemsByFolder(folder, parentChecked?: boolean) {
    const folderChildren = values(folder.children);
    const locationChildren = [];
    const isChecked = parentChecked || includes(this.checkboxValue, `node:${folder.folderID}`);
    const locationIDs: number[] = map(this.userService.getUserLocations(this.userdataService.locations, false), 'locationID');

    each(folder.locations, (locationId) => {
      if (locationId) {
        const locInfo = find(this.userService.locations.data, { locationID: locationId });

        if (locInfo) {
          locationChildren.push({
            id: uuid.v4(),
            locationId,
            type: 'location',
            folderId: folder.folderID,
            text: locInfo.name,
            children: [],
            locations: [],
            checked: isChecked || includes(this.checkboxValue, locationId),
            isValid: includes(locationIDs, locationId)
          });
        }
      }
    });

    return {
      id: uuid.v4(),
      folderId: folder.folderID,
      type: 'folder',
      text: folder.title,
      isValid: this.isValid(`node:${folder.folderID}`),
      locations: folder.locations,
      children: [
        ...locationChildren,
        ...map(folderChildren, (child) => this.addTreeItemsByFolder(child, isChecked)),
      ],
      checked: isChecked
    };
  }

  private isLocationHierarchy(type) {
    return this.subscriber.usesModule(Module.LOCATION_HIERARCHY) && (!includes(['observation', 'observer', 'coordinator'], type));
  }

  private defineCurrentUserLocationSelections() {
    const currentUser = this.accountService.getByID(+this.userdataService.userID);
    this.loggedUserLocationSelections = currentUser.locations;

    if (this.isLocationHierarchy(currentUser.type)) {
      if (currentUser.nodeSelections.length) {
        this.loggedUserLocationSelections = currentUser.nodeSelections;
      } else if (currentUser.locations.length === 0) {
        this.loggedUserLocationSelections = ['node:0'];
      }
    }
  }

  private defineFolders() {
    this.folders = [
      ...this.addTreeItemsByLocations(),
      ...this.addTreeItemsByFolders()
    ];
  }

  private isChildChecked(folder) {
    return folder.isValid || this.isChildrenChecked(folder.children);
  }

  private isChildrenChecked(folders) {
    return some(folders, (folder) => this.isChildChecked(folder));
  }

  private defineSelectedLocations(items = this.folders) {
    each(items, (item) => {
      if (item.checked) {
        if (item.type === 'folder') {
          this.selectedFolders.push(`node:${item.folderId}`);
        } else {
          this.selectedFolders.push(item.locationId);
        }
      } else {
        if (item.type === 'folder') {
          this.defineSelectedLocations(item.children);
        }
      }
    });
  }

  private defineAllLocationsAvailability() {
    this.isAllLocationsAvailable = this.userService.hasFullLocationAccess();
  }

  private isValid(id: string): boolean {
    return intersection(this.loggedUserLocationSelections, [id, 'node:0']).length > 0;
  }
}
