import { Injectable } from '@angular/core';

import {
  AccountsService,
  AssetsService,
  ChecksService,
  FoldersDataService,
  SettingsService,
  ShiftService,
  TeamsService,
  UserdataService,
  UserService,
} from '@services';
import { DeploymentService, QuestionService } from '@modules/management/pages/details/check/services';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, filter, find, forEach, get, has, intersection, split, uniqBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CheckSummaryModelService {

  private checkOpts: any;

  private columnOptions = [
    {
      id: 'count',
      description: this.translate.instant('REPORTING.Service_Number_of_Responses'),
      label: this.translate.instant('REPORTING.Service_Responses'),
      units: 'Responses',
      cellType: 'number',
      showWhen: true,
      func: (collection) => collection ? collection.length : 0
    },
    {
      id: 'count_groups',
      description: this.translate.instant('REPORTING.Service_Number_of_Grouped_Responses'),
      label: this.translate.instant('REPORTING.Service_Grouped_Responses'),
      units: 'Groups',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        const instances = uniqBy(collection, 'instanceID');
        return instances ? Object.keys(instances).length : 0;
      }
    },
    {
      id: 'count_issues',
      description: this.translate.instant('REPORTING.Service_Number_of_Comments'),
      label: this.translate.instant('REPORTING.Service_Comments'),
      units: 'Issues',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let count_issues = 0;
        forEach(collection, (ref, key) => {
          count_issues += get(ref, 'issueCount', 0);
        });
        return count_issues;
      }
    },
    {
      id: 'count_ca',
      description: this.translate.instant('REPORTING.Service_Number_of_CA'),
      label: this.translate.instant('SHARED.Observation_Count#'),
      units: 'CA',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let count_ca = 0;
        forEach(collection, (ref, key) => {
          count_ca += get(ref, 'observationCount', 0);
        });
        return count_ca;
      }
    },
    {
      id: 'time_spent',
      description: this.translate.instant('REPORTING.Service_Time_Spent'),
      label: this.translate.instant('REPORTING.Service_Time_Spent_Minutes'),
      units: this.translate.instant('REPORTING.Service_Time_Spent_Minutes'),
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let time_spent = 0;
        let resps_count = 0;
        let result = 0;
        const label = ' seconds';
        forEach(collection, (ref, key) => {
          if (ref.startTime && ref.completionTime) {
            time_spent += moment(ref.completionTime * 1000).diff(ref.startTime * 1000, 'seconds');
            resps_count += 1;
          }
        });
        result = time_spent;
        if (result > 60) {
          result = +(result / 60).toFixed(0);
        } else {
          result = 0;
        }
        return result;
      }
    },
  ];

  private fieldOptions = [
    {
      id: 'checkType',
      description: this.translate.instant('SHARED.Check_Type'),
      label: this.translate.instant('SHARED.Check_Type'),
      fieldName: 'checkType',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const check = this.settingsService.getItem('checkType', val);
        return [val, check.messageTitle];
      }
    },
    {
      id: 'checkTitle',
      description: this.translate.instant('SHARED.Check_Title'),
      label: this.translate.instant('SHARED.Check_Title'),
      fieldName: 'checkID',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = val;
        const check = this.checksService.getCheck(val);
        if (check && check.title) {
          title = check.title;
        }
        return [val, title];
      }
    },
    {
      id: 'responseID',
      description: this.translate.instant('SHARED.Response_ID'),
      label: this.translate.instant('SHARED.Response_ID'),
      fieldName: 'responseID',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = val;
        const check = this.checksService.getCheck(ref.checkID);
        if (check && check.title) {
          title = check.title;
        }
        return [val, title];
      }
    },
    {
      id: 'checkResult',
      description: this.translate.instant('SHARED.Check_Result'),
      label: this.translate.instant('SHARED.Check_Result'),
      fieldName: 'result',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = val;
        if (val === 'negative') {
          title = this.translate.instant('SHARED.Negative');
        } else if (val === 'neutral') {
          title = this.translate.instant('SHARED.Neutral');
        } else if (val === 'positive') {
          title = this.translate.instant('SHARED.Positive');
        } else if (val === 'unknown') {
          title = this.translate.instant('SHARED.None');
        }
        return [val, title];
      }
    },
    {
      id: 'groupTitle',
      description: this.translate.instant('SHARED.Group_Title'),
      label: this.translate.instant('SHARED.Group_Title'),
      fieldName: 'checkGroup',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const check = this.checksService.getCheck(ref.checkID);
        const group = find(check.checkGroups, 'groupID', val);
        let title = val;
        let groupID = val;
        if (group && group.title) {
          title = group.title;
          groupID = group.groupID;
        }
        return [groupID, title];
      }
    },
    {
      id: 'groupResult',
      description: this.translate.instant('SHARED.Group_Result'),
      label: this.translate.instant('SHARED.Group_Result'),
      fieldName: 'result',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val) => [val, val]
    },
    {
      id: 'groupStatus',
      description: this.translate.instant('SHARED.Group_Status'),
      label: this.translate.instant('SHARED.Group_Status'),
      fieldName: 'status',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if (val === 'complete') {
          label = this.translate.instant('SHARED.Completed');
        } else if (val === 'groupsCompleted') {
          label = this.translate.instant('SHARED.OptionalGroups');
        } else if (val === 'available') {
          label = this.translate.instant('SHARED.Available');
        } else if (val === 'missed') {
          label = this.translate.instant('SHARED.Missed');
        } else if (val === 'skipped') {
          label = this.translate.instant('SHARED.Skipped');
        } else if (val === 'notCompleted') {
          label = this.translate.instant('SHARED.NotCompleted');
        }
        return [val, label];
      }
    },
    {
      id: 'questionTitle',
      description: this.translate.instant('SHARED.Question_Title'),
      label: this.translate.instant('SHARED.Question_Title'),
      fieldName: 'questions',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const question = this.questionService.getQuestion(val);
        return [val, question.title];
      }
    },
    {
      id: 'assignee',
      description: this.translate.instant('SHARED.Assignee'),
      label: this.translate.instant('SHARED.Assignee'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        if (val.users && val.users.length) {
          let users: string;
          let valID = val.users[0];
          if (val.users[0] === -1) {
            users = this.translate.instant('MGMT_DETAILS.Target_User');
            const splitSig = split(ref.targetSignature, ':');
            if (+splitSig[1]) {
              users = this.accountsService.fullUserName(+splitSig[1]);
              valID = +splitSig[1];
            }
          } else if (val.users[0] === -2) {
            users = this.translate.instant('MGMT_DETAILS.User_Supervisor');
            const splitSig = split(ref.targetSignature, ':');
            const userObj = this.accountsService.getAccount(+splitSig[1]);
            if (userObj.supervisorID) {
              users = this.accountsService.fullUserName(userObj.supervisorID);
              valID = userObj.supervisorID;
            }
          } else {
            users = this.accountsService.fullUserName(val.users[0]);
          }
          return [valID, this.translate.instant('SHARED.USER') + ': ' + users];
        } else if (val.teams && val.teams.length) {
          return [val.teams[0], this.translate.instant('SHARED.Team') + ': ' + this.teamsService.teamNameByID(val.teams[0])];
        } else {
          return [0, this.translate.instant('SHARED.Unassigned')];
        }
      }
    },
    {
      id: 'assigneeShift',
      description: this.translate.instant('SHARED.Assignee_Shift'),
      label: this.translate.instant('SHARED.Assignee_Shift'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val) => {
        if (val.users && val.users.length) {
          let u: any;
          if (val.users[0] === -1) {
            u = this.accountsService.getAccount(this.userdataService.userID);
          } else {
            u = this.accountsService.getAccount(val.users[0]);
          }
          if (u && u.shift) {
            return [this.shift.name(u.shift), this.shift.name(u.shift)];
          } else {
            return [0, 'None'];
          }
        } else {
          return [0, 'None'];
        }
      }
    },

    {
      id: 'assigneeTeam',
      description: this.translate.instant('SHARED.Assignee_Team'),
      label: this.translate.instant('SHARED.Assignee_Team'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val) => {
        if (val.users && val.users.length) {
          let team: string = this.translate.instant('DASHPAGES.NA');
          let u: any;
          let teamID = 0;
          if (val.users[0] === -1) {
            u = this.accountsService.getAccount(this.userdataService.userID);
            if (u && u.groups && u.groups.length) {
              team = this.teamsService.teamNameByID(u.groups[0]);
              teamID = u.groups[0];
            }
          } else if (val.users[0] === -2) {
            team = this.translate.instant('DASHPAGES.NA');
          } else {
            u = this.accountsService.getAccount(val.users[0]);
            if (u && u.groups && u.groups.length) {
              team = this.teamsService.teamNameByID(u.groups[0]);
              teamID = u.groups[0];
            }
          }
          return [teamID, team];
        } else if (val.teams && val.teams.length) {
          return [val.teams[0], this.teamsService.teamNameByID(val.teams[0])];
        } else {
          return [0, this.translate.instant('DASHPAGES.NA')];
        }
      }
    },

    {
      id: 'questionStatus',
      description: this.translate.instant('SHARED.Question_Status'),
      label: this.translate.instant('SHARED.Question_Status'),
      fieldName: 'questions',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const answered = has(ref.answers, val);
        let title = this.translate.instant('SHARED.Not_Answered');
        let id = 0;
        if (answered) {
          title = this.translate.instant('SHARED.Answered');
          id = 1;
        }
        return [id, title];
      }
    },
    {
      id: 'deploymentTitle',
      description: this.translate.instant('SHARED.Deployment_Title'),
      label: this.translate.instant('SHARED.Deployment_Title'),
      fieldName: 'deploymentID',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const deployment = this.deployment.getDeployment(val);
        let label: any = 'None';
        if (deployment) {
          label = deployment.title;
        }
        return [val, label];
      }
    },
    {
      id: 'target',
      description: this.translate.instant('SHARED.Target'),
      label: this.translate.instant('SHARED.Target'),
      fieldName: 'target',
      fieldType: 'array',
      fieldFunc: (target, row) => {
        let val = '0';
        const targetSignature: string = row.targetSignature;
        let label: string = this.translate.instant('DASHPAGES.NA');
        if (targetSignature) {
          val = targetSignature;
          const splitSig = split(targetSignature, ':');
          if (splitSig[0] == 'loc') {
            label = this.checkDetailService.getTargetZoneName(+splitSig[1], +splitSig[2]);
          } else if (splitSig[0] == 'asset') {
            const targObj = this.assetsService.getAssetById(+splitSig[1]);
            label = 'Asset' + ': ' + targObj[0].name;
          } else if (splitSig[0] == 'worker') {
            label = 'User' + ': ' + this.accountsService.fullUserName(+splitSig[1]);
          }
        }
        return [val, label];
      }
    },
    {
      id: 'deploymentType',
      description: this.translate.instant('SHARED.Deployment_Type'),
      label: this.translate.instant('SHARED.Deployment_Type'),
      fieldName: 'deploymentID',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const deployment = this.deployment.getDeployment(val);
        let label: any = 'None';
        if (deployment) {
          label = deployment.target.targetType;
          if (label === 'assets') {
            label = this.translate.instant('MGMT_LIST.Asset');
          } else if (label === 'workers') {
            label = this.translate.instant('SHARED.Worker');
          } else if (label === 'zones') {
            label = this.translate.instant('SHARED.Zone');
          }
        }
        return [label, label];
      }
    },
    {
      id: 'state',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      fieldName: 'instanceStatus',
      fieldType: 'string',
      fieldFunc: (val) => {
        const status: string = val;
        let label: string;
        if (status === 'complete') {
          label = this.translate.instant('SHARED.Completed');
        } else if (status === 'groupsCompleted') {
          label = this.translate.instant('SHARED.OptionalGroups');
        } else if (status === 'available') {
          label = this.translate.instant('SHARED.Available');
        } else if (status === 'missed') {
          label = this.translate.instant('SHARED.Missed');
        } else if (status === 'skipped') {
          label = this.translate.instant('SHARED.Skipped');
        } else if (status === 'notCompleted') {
          label = this.translate.instant('SHARED.NotCompleted');
        }
        return [status, label];
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zoneID',
      fieldType: 'array',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + val;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, val);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'responder',
      description: this.translate.instant('SHARED.Responder'),
      label: this.translate.instant('SHARED.Responder'),
      fieldName: 'owner',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.userService.getFullname(val)]
    },
    {
      id: 'responderTeam',
      description: this.translate.instant('SHARED.Responder_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'owner',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        const u = this.accountsService.getAccount(val);
        const theGroup = u?.primaryGroup ? u.primaryGroup : u?.groups[0];
        if (theGroup) {
          if (this.checkOpts && this.checkOpts.groups && this.checkOpts.groups.length) {
            const intersectionGroups = intersection(u.groups, this.checkOpts.groups);
            if (intersectionGroups && intersectionGroups.length) {
              return [intersectionGroups[0], this.teamsService.teamNameByID(intersectionGroups[0])];
            } else {
              return [theGroup, this.teamsService.teamNameByID(theGroup)];
            }
          } else {
            return [theGroup, this.teamsService.teamNameByID(theGroup)];
          }
        } else {
          return [ 0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'locationID',
      description: this.translate.instant('SHARED.Location'),
      label: 'Location',
      fieldName: 'locationID',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = get(this.userService.findLocation(locationId), 'name');
        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    },
    {
      id: 'responder_shift',
      description: this.translate.instant('SHARED.Responder_Shift'),
      label: this.translate.instant('SHARED.Responder_Shift'),
      fieldName: 'owner',
      fieldType: 'string',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u && u.shift) {
          return [u.shift, this.shift.name(u.shift)];
        } else {
          return [0, 'None'];
        }
      }
    },
    {
      id: 'assets',
      description: this.translate.instant('SHARED.Asset'),
      label: this.translate.instant('SHARED.Asset'),
      fieldName: 'targetSignature',
      fieldType: 'string',
      fieldFunc: (targetSignature: string) => {
        const [type, assetID] = split(targetSignature, ':');

        if (type === 'asset') {
          const cellLabel = this.assetsService.getAssetById(+assetID)?.[0]?.name || this.translate.instant('SHARED.None');
          return [assetID, cellLabel];
        }
      }
    },
    {
      id: 'assetsFolder',
      description: this.translate.instant('MGMT_LIST.Asset_Folder'),
      label: this.translate.instant('MGMT_LIST.Asset_Folder'),
      fieldName: 'targetSignature',
      fieldType: 'string',
      fieldFunc: (targetSignature: string) => {
        const [type, assetID] = split(targetSignature, ':');

        if (type === 'asset') {
          const asset = this.assetsService.getAssetById(+assetID)?.[0];

          if (asset) {
            const cellLabel = this.foldersDataService.getFolderByID(asset.folderID)?.title || this.translate.instant('SHARED.None');
            return [asset.folderID, cellLabel];
          }
        }
      }
    },
  ];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private accountsService: AccountsService,
    private shift: ShiftService,
    private teamsService: TeamsService,
    private deployment: DeploymentService,
    private checkDetailService: CheckDetailService,
    private assetsService: AssetsService,
    private userdataService: UserdataService,
    private questionService: QuestionService,
    private checksService: ChecksService,
    private settingsService: SettingsService,
    private foldersDataService: FoldersDataService
  ) {}

  public getColumns() {
    const columns = filter(cloneDeep(this.columnOptions), (opt) => opt.showWhen);
    return columns;
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }
}
