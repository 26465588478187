import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { first, inRange, last } from 'lodash';

enum RiskType {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export interface RiskWidget {
  title?: string;
  value?: string;
  isDetailView?: boolean;
}

@Component({
  selector: 'app-risk-widget',
  templateUrl: './risk-widget.component.html',
  styleUrls: ['./risk-widget.component.scss'],
})
export class RiskWidgetComponent implements OnInit {

  @HostBinding('class.detail-view') isDetailView: boolean;

  @Input() config: RiskWidget;
  @Output() onSelect: EventEmitter<void> = new EventEmitter();

  public cells = [
    {class: RiskType.Medium, value: '90:30'},
    {class: RiskType.High, value: '90:60'},
    {class: RiskType.High, value: '90:90'},
    {class: RiskType.Medium, value: '60:30'},
    {class: RiskType.Medium, value: '60:60'},
    {class: RiskType.High, value: '60:90'},
    {class: RiskType.Low, value: '30:30'},
    {class: RiskType.Medium, value: '30:60'},
    {class: RiskType.Medium, value: '30:90'}
  ];

  constructor() {
  }

  public static getPoints(data, key: string): number {
    let points = 30;

    if (inRange(data[key], 40, 61)) {
      points = 60;
    } else if (inRange(data[key], 60, 101)) {
      points = 90;
    }

    return points;
  }

  public static getRiskValueBy(data: any, keys: [string, string]): string {
    return `${RiskWidgetComponent.getPoints(data, first(keys))}:${RiskWidgetComponent.getPoints(data, last(keys))}`;
  }

  ngOnInit() {
    this.isDetailView = this.config?.isDetailView;
  }

  public onSelectCell(value: string) {
    if (!this.config) {
      this.config = {};
    }

    this.config.value = value;
    this.onSelect.emit();
  }

}
