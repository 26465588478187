import { Injectable } from '@angular/core';

import { CommsService } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, flatten } from 'lodash';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

export enum ITokenScope {
  LEADERBOARD = 'leaderboard',
  READONLY = 'read_api',
  READWRITE = 'api',
}

export interface ITokenHistory {
  activity: string;
  notes: string;
  when: number;
  userID: number;
}

export interface IToken {
  active: number;
  accessToken: string;
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  expires: number;
  history: ITokenHistory[];
  lastUpdate: number;
  scopes: ITokenScope[];
  title: string;
  tokenID: number;
  userID: number;
}

@Injectable({
  providedIn: 'root'
})

export class AccessTokenService extends BaseService {
  private tokens = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IToken>
  };

  private translatedMap = {
    [ITokenScope.LEADERBOARD]: 'MGMT_DETAILS.Token_LB',
    [ITokenScope.READONLY]: 'MGMT_DETAILS.Token_ReadOnly',
    [ITokenScope.READWRITE]: 'MGMT_DETAILS.Token_ReadWrite'
  };

  constructor(
    protected commsService: CommsService,
    private events: Events,
    private translate: TranslateService
  ) {
    super(commsService);
  }

  public scopeName(theScope: ITokenScope): string {
    return this.translate.instant(this.translatedMap[theScope]);
  }

  public refresh(): Promise<IObjectStringKeyMap<IToken>> {
    const requestData: any = {
      cmd: 'getTokens',
      lastRequest: this.tokens.lastRequest,
      includeToken: 1,
      includeHistory: 1,
      historyRows: 100
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.tokens.data = response.result.accessTokens;
        this.tokens.lastRequest = response.result.timestamp;
        this.events.publish('ccs:tokensUpdate', true);
      }

      return this.tokens.data;
    });
  }

  public async getTokens(): Promise<IObjectStringKeyMap<IToken>> {
    if (!Object.keys(this.tokens.data).length) {
      await this.refresh();
    }
    return this.tokens.data;
  }

  public getTokenById(id: number): IToken {
    return cloneDeep(this.tokens.data[id]);
  }

  public addToken(data: any): Promise<any> {
    const requestData = {
      cmd: 'addToken',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public updateToken(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateToken',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public deleteToken(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteToken',
      tokens: flatten([ids])
    };

    return this.handleRequest(requestData);
  }
}
