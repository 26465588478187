import { NGXLogger } from 'ngx-logger';
import { AfterContentInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Events } from '@services/events/events.service';
import { Router } from '@angular/router';

import {
  DashboardHeaderIcon,
  IDashboardHomeData,
  IHeaderSetting, ILocationHealth,
  IMenuIcon,
  Module,
  PermissionsService,
  UserdataService
} from '@services';
import { ObservationDashboardUIService } from '@services/observationDashboardUI/observation-dashboard-ui.service';
import { ObservationService } from '@services/observations/observation.service';
import { DashboardChartServiceService } from '@services/dashboardChartService/dashboard-chart-service.service';
import { Feature, SubscriberService } from '@services/subscriber/subscriber.service';

import { TranslateService } from '@ngx-translate/core';
import { IViewSwitcherConfig } from '@shared/components';
import { filter, find, forIn, has, isEmpty, orderBy, size, sum } from 'lodash';

@Component({
  selector: 'app-observation-home-dashboard',
  templateUrl: './observation-home-dashboard.page.html',
  styleUrls: ['./observation-home-dashboard.page.scss'],
})
export class ObservationHomeDashboardPage implements AfterContentInit {
  @ViewChild('barCanvas') barCanvas: ElementRef;
  @ViewChild('lineCanvas') lineCanvas: ElementRef;
  @ViewChild('globalLegend') globalLegend: ElementRef;
  @Output() childEvent = new EventEmitter();

  public optForAnimation = false;
  public showCurtain = false;
  public isValid = false;
  public currentUrl: number = undefined;
  public searching = 'gateway';
  public searchingGateway = 'SEARCHING FOR GATEWAY';
  public connectingDirectly = 'CONNECTING DIRECTLY';
  public onlyInReload = false;
  public viewSwitcherConfig: IViewSwitcherConfig = {
    viewTypes: [
      {icon: {activeIconName: 'iconPieWhite.svg', inactiveIconName: 'iconPie.svg'}, key: 'pie'},
      {icon: {activeIconName: 'iconChartWhite.svg', inactiveIconName: 'iconChart.svg'}, key: 'chart'}
    ],
    selectedType: 'pie'
  };

  public dashboardHomeData: IDashboardHomeData = {};
  public isHomeReady = false;
  public pieChartLabel: any[] = [];
  public lineChartLabel: any[] = [];
  public pieDetail: boolean;
  public pieDataAvailable: boolean;
  public lineDataAvailable: boolean;
  public chartDetail: boolean;
  public selectedIconMenu: IMenuIcon;
  public selectedRange = '';
  public headerSetting: IHeaderSetting = {
    title: 'SHARED.Dashboard',
    filter: {
      showFilter: false,
      isActive: true
    },
    icon: [
      DashboardHeaderIcon.SiteAwareness,
      DashboardHeaderIcon.Observations,
      DashboardHeaderIcon.SupervisorDashboard,
      DashboardHeaderIcon.Leaderboards,
      DashboardHeaderIcon.WorkStatus,
      DashboardHeaderIcon.WorkZone,
      DashboardHeaderIcon.AssetStatus,
      DashboardHeaderIcon.FrontlineCulture
    ]
  };
  public rangeOptions: any = [
    {
      id: 'today',
      text: 'SHARED.Today'
    },
    {
      id: '7days',
      text: 'SHARED.7_Days'
    },
    {
      id: '14days',
      text: 'SHARED.14_Days'
    },
    {
      id: '30days',
      text: 'SHARED.30_Days'
    },
    {
      id: '90days',
      text: 'SHARED.90_Days'
    },
    {
      id: '365days',
      text: 'SHARED.365_days'
    }
  ];
  public siteSorterData = [];
  public selectedSorter: string = this.getStoredDataByKey('sort') || this.siteSorterData[0]?.id;
  public brand = 'corvex';
  public usingREI = true;
  public options = {
    minimumResultsForSearch: Infinity,
    sorter: (data) => data
  };
  private pieChartReference: any = {};
  private lineChartReference: any = {};
  private chartData: any = {};
  private storedDataKey = 'observationDashboard';
  private siteSorterDataByModules = [{
    id: 'name',
    text: 'SHARED.Locations'
  }, {
    id: 'openCount',
    text: 'SHARED.Open_Conditions',
    module: Module.CONDITION
  }, {
    id: 'avgTimeSeconds',
    text: 'SHARED.Avg_Time_Open',
    module: Module.CONDITION
  }, {
    id: 'riskIndex',
    text: 'SHARED.Risk_Exposure_Index',
    module: Module.CONDITION
  }, {
    id: 'qualityCount',
    text: 'SHARED.Quality',
    module: Module.QUALITY
  }, {
    id: 'waitingTime',
    text: 'SHARED.Waiting_Time',
    module: Module.PROCESS_IMPROVEMENT
  }];

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private dashService: ObservationDashboardUIService,
    private observationService: ObservationService,
    private chartService: DashboardChartServiceService,
    private subscriberService: SubscriberService,
    private permissions: PermissionsService,
    private userDataService: UserdataService,
    private translate: TranslateService,
    private events: Events
  ) {
    this.initSiteSorterDataByModules();
  }

  ngAfterContentInit(): void {
    this.initSiteSorterDataByModules();
    this.dashboardPieChart();
    const storedChartViewTypeKey: string = this.getStoredDataByKey('chartViewType');
    this.initLocationSorting();
    if (storedChartViewTypeKey) {
      this.menuSelected(storedChartViewTypeKey);
    } else {
      this.viewSwitcherConfig.selectedType = 'pie';
    }
  }

  execStep5() {
    if (this.currentUrl !== 1) {
      this.optForAnimation = true;
      this.isValid = true;
    } else {
      setTimeout(() => {
        this.optForAnimation = true;
        this.isValid = true;
      }, 3000);
    }
    return 'done ';
  }

  ionViewWillEnter() {
    this.brand = this.subscriberService.brandInfo().brand;
    this.usingREI = this.subscriberService.usesFeature(Feature.CONDITION);

    this.chartDetail = true;
    this.pieDataAvailable = true;
    if (size(this.dashboardHomeData) === 0) {
      this.isHomeReady = false;
    }
    if (isEmpty(this.observationService.observations.open)) {
      this.observationService.updateObservations().then(data => {
        this.dashboardHomeData = this.dashService.prepareDashboardHomeDataSet();
      });
    } else {
      this.dashboardHomeData = this.dashService.prepareDashboardHomeDataSet();
      this.lineChart();
    }

    this.initLocationSorting();

    this.events.subscribe('ccs:observationUpdate', this.observationListener);
    this.events.subscribe('ccs:subscriptionUpdate', this.subscriberListener);

    this.selectedRange = this.getStoredDataByKey('range') || this.dashService.observationHomePreference.rangeSelected || this.rangeOptions[1].id;

    if (this.currentUrl !== 1) {
      this.execStep5();
    } else {
      this.onlyInReload = true;
    }
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:observationUpdate', this.observationListener);
    this.events.unsubscribe('ccs:subscriptionUpdate', this.subscriberListener);
  }

  public menuSelected(value) {
    this.viewSwitcherConfig.selectedType = value;

    if (value === 'chart') {
      this.chartDetail = true;
      this.pieDetail = false;
      this.lineChart();
    } else {
      this.chartDetail = false;
      this.pieDetail = true;
      this.dashboardPieChart();
    }

    this.setStoredDataByKey('chartViewType', value);
  }

  public siteSorter(newValue: string) {
    // store this data in observationDataService as a preference
    this.logger.log(newValue);
    this.dashService.observationHomePreference.locationHealthSelector = newValue;
    const dir = newValue === 'name' ? 'asc' : 'desc';
    this.dashboardHomeData.locationHealth = orderBy(
      this.dashboardHomeData.locationHealth,
      [newValue],
      [dir]
    ) as ILocationHealth[];
    this.setStoredDataByKey('sort', newValue);
  }

  public rangeSorter(event) {
    // store this data in observationDataService as a preference
    this.dashService.observationHomePreference.rangeSelected = event.value;
    // this should filter the data set for pie and line chart
    this.dashboardPieChart();
    this.lineChart();
    this.setStoredDataByKey('range', event.value);
  }

  public lineChart() {
    if (!this.lineCanvas) {
      return false;
    }
    const selectedRange = this.dashService.observationHomePreference.rangeSelected;
    if (selectedRange) {
      this.chartData = this.dashService.filterChartData(selectedRange);

    } else {
      this.chartData = this.dashService.filterChartData('today');
    }

    if (this.chartData.line.datasets && ((this.chartData.line.datasets.length) > 0)) {
      this.lineDataAvailable = true;
    } else {
      this.lineDataAvailable = false;
    }
    this.logger.log('lineCanvas', this.lineCanvas);
    this.logger.log('chartData', this.chartData);

    this.lineChartReference = this.chartService.lineChart(this.lineCanvas, this.chartData.line);
    this.logger.log('lineChartReference', this.lineChartReference);
    this.lineChartLabel = this.dashService.generateLabel(this.lineChartReference);
    this.logger.log('lineChartLabel', this.lineChartLabel);
    this.logger.log('dashService', this.dashService);
    // hide/show previously selected values
    forIn(this.dashService.lineChartIndices, (key, val) => {
      if (this.lineChartReference) {
        const ci = this.lineChartReference;
        const meta = ci.getDatasetMeta(+val);
        meta.hidden = key;
        ci.update();
        this.lineChartLabel[+val].unchecked = key;
      }
    });
  }

  public dashboardPieChart() {
    if (!this.barCanvas) {
      return false;
    }
    const selectedRange = this.dashService.observationHomePreference.rangeSelected;
    if (selectedRange) {
      this.chartData = this.dashService.filterChartData(selectedRange);

    } else {
      this.chartData = this.dashService.filterChartData('today');
    }
    // tslint:disable-next-line:max-line-length
    if (this.chartData.pie.datasets[0].data && ((this.chartData.pie.datasets[0].data.length) > 0) && sum(this.chartData.pie.datasets[0].data)) {
      this.pieDataAvailable = true;
    } else {
      this.pieDataAvailable = false;
    }
    this.pieChartReference = this.chartService.pieChart(this.barCanvas, '', this.chartData.pie, () => null, this.globalLegend);
    this.pieChartLabel = this.dashService.generateLabel(this.pieChartReference);
    // hide/show previously selected values
    forIn(this.dashService.pieChartIndices, (isUnchecked: boolean, id: string) => {
      if (this.pieChartReference) {
        let i;
        let ilen;
        let meta;
        for (i = 0, ilen = (this.pieChartReference.data.datasets || []).length; i < ilen; ++i) {
          meta = this.pieChartReference.getDatasetMeta(i);
          meta.data[+id].hidden = isUnchecked;
        }
        this.pieChartReference.update();
        this.pieChartLabel[+id].unchecked = isUnchecked;
      }
    });
  }

  public pieChartLegend(index) {
    // put the indexs on and off the list
    if (this.dashService.pieChartIndices[index]) {
      this.dashService.pieChartIndices[index] = false;
    } else {
      this.dashService.pieChartIndices[index] = true;
    }
    if (this.pieChartReference) {
      let i;
      let ilen;
      let meta;
      for (i = 0, ilen = (this.pieChartReference.data.datasets || []).length; i < ilen; ++i) {
        meta = this.pieChartReference.getDatasetMeta(i);
        meta.data[index].hidden = !meta.data[index].hidden;
      }
      this.pieChartReference.update();
    }
  }

  public lineChartLegend(index) {
    // put the indexs on and off the list
    if (this.dashService.lineChartIndices[index]) {
      this.dashService.lineChartIndices[index] = false;
    } else {
      this.dashService.lineChartIndices[index] = true;
    }
    if (this.lineChartReference) {
      const ci = this.lineChartReference;
      const meta = ci.getDatasetMeta(index);
      meta.hidden = this.dashService.lineChartIndices[index];
      ci.update();
    }
  }

  ionViewDidEnter() {
    this.userDataService.savePreference('lastDashboardType', '/pages/dashboard/main');
    setTimeout(() => {
      this.optForAnimation = true;
    }, 1000);
  }

  private initSiteSorterDataByModules() {
    this.siteSorterData = filter(
      this.siteSorterDataByModules,
      item => !has(item, 'module') || this.subscriberService.usesModule(item.module, true)
    );
    if (!find(this.siteSorterData, {id: this.selectedSorter} as any)) {
      this.selectedSorter = this.siteSorterData[0]?.id;
    }
  }

  private getStoredDataByKey(key: string): any {
    return this.userDataService.getStoreDataByKey(this.storedDataKey, key);
  }

  private setStoredDataByKey(key: string, value: any): any {
    return this.userDataService.setStoreDataByKey(this.storedDataKey, key, value);
  }

  private observationListener = (data) => {
    if (data) {
      this.dashboardHomeData = this.dashService.prepareDashboardHomeDataSet();

      if (this.pieDetail) {
        this.dashboardPieChart();
      } else {
        this.lineChart();
      }
      this.childEvent.emit(this.execStep5());

      setTimeout(() => {
        this.siteSorter(this.selectedSorter);
      });
    }
  };

  private subscriberListener = (data) => {
    console.log('subscriberListener', data);
    this.observationListener(data);
    this.initLocationSorting();
    this.initSiteSorterDataByModules();
  };

  private initLocationSorting() {
    const storedSortingKey: string = this.getStoredDataByKey('sort');
    if (storedSortingKey) {
      this.selectedSorter = storedSortingKey;
    }
    this.siteSorter(this.selectedSorter);
  }
}
