import { Injectable } from '@angular/core';

import { DataTableNavigationService } from './data-table-navigation.service';

import { TranslateService } from '@ngx-translate/core';
import { map } from 'lodash';

@Injectable()
export abstract class BaseDataTableNavigationService {

  public abstract listTableId: string;
  public abstract mainNavigationPage: string;
  protected abstract mainNavigationPageTitle: string;
  protected abstract itemIdKey: string;

  constructor(
    protected dataTableNavigationService: DataTableNavigationService,
    protected translate: TranslateService
  ) {
  }

  public registerCustomSortProvider(dataTableOptions) {
    this.dataTableNavigationService.registerCustomSortProvider(dataTableOptions);
  }

  public getFooterData(currentId: number) {
    const relatedIds = map(this.getRelatedItemsById(currentId), this.itemIdKey);
    const footerData: { [key: string]: any } = {
      nav: this.mainNavigationPage,
      name: this.translate.instant(this.mainNavigationPageTitle),
      prev: {disabled: true, data: null},
      next: {disabled: true, data: null}
    };

    if (this.getActiveTabKey) {
      footerData.activeTab = this.getActiveTabKey(currentId);
    }

    const nextId = this.getNextId(currentId, relatedIds);
    const previousId = this.getPreviousId(currentId, relatedIds);

    if (nextId) {
      footerData.next.disabled = false;
      footerData.next.data = this.getDetailPageUrl(nextId);
    }

    if (previousId) {
      footerData.prev.disabled = false;
      footerData.prev.data = this.getDetailPageUrl(previousId);
    }

    return footerData;
  }

  protected abstract getData(itemId?: string | number): any[];

  protected abstract getDetailPageUrl(itemId?: string | number): string;

  protected abstract getColumns(itemId?: string | number): any[];

  protected getActiveTabKey?(itemId?: string | number): string;

  protected getTableId?(itemId?: string | number): string;

  protected getCachedListOrder(id: number | string) {
    const cachedKey = this.getTableId && this.getTableId(id) || this.listTableId;
    return this.dataTableNavigationService.getCachedOrder(cachedKey, this.mainNavigationPage);
  }

  protected getNextId(currentId: number, ids: number[]): number {
    return <number>this.dataTableNavigationService.getNextId(currentId, ids);
  }

  protected getPreviousId(currentId: number, ids: number[]): number {
    return <number>this.dataTableNavigationService.getPreviousId(currentId, ids);
  }

  private getRelatedItemsById(itemId) {
    const columns = this.getColumns(itemId);
    const listOrder = this.getCachedListOrder(itemId);
    const dataItems = this.getData(itemId);

    return this.dataTableNavigationService.sortTableData(dataItems, listOrder, columns);
  }

}
