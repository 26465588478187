import { Injectable } from '@angular/core';

import {
  AccountsService,
  AssetsService,
  ChecksService,
  SettingsService,
  ShiftService,
  SubscriberService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService
} from '@services';
import { DeploymentService, QuestionService } from '@modules/management/pages/details/check/services';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { Response, IQuestionOption } from '@modules/management/pages/details/check/models';
import { DetailReportTableService } from '@modules/reporting/services/detail-report-table.service';
import { IObjectStringKeyMap } from '@shared/models';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, each, filter, find, forEach, get, has, intersection, join, map, orderBy, split, toString as _toString } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CheckDetailModelService {

  private checkOpts: any;

  private columnOptions = [
    {
      id: 'checkResult',
      description: this.translate.instant('SHARED.Check_Result'),
      label: this.translate.instant('SHARED.Check_Result'),
      units: 'checkResult',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        const val = collection[0].result;
        let title = this.translate.instant('SHARED.None');
        if (val === 'negative') {
          title = this.translate.instant('SHARED.Negative');
        } else if (val === 'neutral') {
          title = this.translate.instant('SHARED.Neutral');
        } else if (val === 'positive') {
          title = this.translate.instant('SHARED.Positive');
        } else if (val === 'unknown') {
          title = this.translate.instant('SHARED.None');
        }
        return title;
      }
    },
    {
      description: this.translate.instant('SHARED.Duration'),
      label: this.translate.instant('SHARED.Duration'),
      id: 'duration',
      units: 'duration',
      cellType: 'string',
      showWhen: true,
      func: (collection, type?: string) => {
        let label = 0;

        if (collection[0]?.completionTime && collection[0]?.startTime) {
          label = +moment(collection[0].completionTime * 1000).diff(collection[0].startTime * 1000, 'seconds');
        }

        if (type === 'sort') {
          return label;
        } else if (type === 'export') {
          return _toString(label);
        } else {
          return this.utils.secsToDuration(label);
        }
      }
    },
    {
      id: 'answerDetails',
      description: this.translate.instant('SHARED.Answer_Details'),
      label: this.translate.instant('SHARED.Answer_Details'),
      units: 'answerDetails',
      cellType: 'string',
      showWhen: true,
      multipleColumn: true,
      disabledFor: 'answerDetailsDocumentation',
      func: (collection) => this.translate.instant('SHARED.None'),
      multipleColumnHandler: (response: Response, columns: Object) => {
        this.buildAnswerDetails(response, columns);
      },
    },
    {
      id: 'answerDetailsDocumentation',
      description: this.translate.instant('SHARED.Answer_Details_Documentation'),
      label: this.translate.instant('SHARED.Answer_Details_Documentation'),
      units: 'answerDetailsDocumentation',
      cellType: 'string',
      showWhen: true,
      multipleColumn: true,
      disabledFor: 'answerDetails',
      func: (collection) => this.translate.instant('SHARED.None'),
      multipleColumnHandler: (response: Response, columns: Object) => {
        this.buildAnswerDetails(response, columns, 'answerDetailsDocumentation');
      },
    },
    {
      id: 'completionTime',
      description: this.translate.instant('SHARED.Completion_Time'),
      label: this.translate.instant('SHARED.Completion_Time'),
      units: 'completionTime',
      cellType: 'string',
      nowrap: 'nowrap',
      showWhen: true,
      func: (collection, type?: string) => {
        if (type === 'sort') {
          return collection[0]?.completionTime;
        } else if (type === 'export') {
          return this.detailReportTableService.getExcelFormatDate(collection?.[0]?.completionTime);
        } else {
          return this.userService.locationDateTimeFormat(collection[0].locationID, collection[0]?.completionTime, this.translate.instant('SHARED.None'));
        }
      }
    },
    {
      id: 'startTime',
      description: this.translate.instant('SHARED.Start_Time'),
      label: this.translate.instant('SHARED.Start_Time'),
      units: 'startTime',
      cellType: 'string',
      nowrap: 'nowrap',
      showWhen: true,
      func: (collection, type?: string) => {
        if (type === 'sort') {
          return collection[0]?.startTime;
        } else if (type === 'export') {
          return this.detailReportTableService.getExcelFormatDate(collection?.[0]?.startTime);
        } else {
          return this.userService.locationDateTimeFormat(collection[0].locationID, collection[0]?.startTime, this.translate.instant('SHARED.None'));
        }
      }
    },
    {
      id: 'claimTime',
      description: this.translate.instant('SHARED.Claim_Time'),
      label: this.translate.instant('SHARED.Claim_Time'),
      units: 'claimTime',
      cellType: 'string',
      nowrap: 'nowrap',
      showWhen: true,
      func: (collection, type?: string) => {
        if (type === 'sort') {
          return collection[0]?.claimTime;
        } else if (type === 'export') {
          return this.detailReportTableService.getExcelFormatDate(collection?.[0]?.claimTime);
        } else {
          return this.userService.locationDateTimeFormat(collection[0].locationID, collection[0]?.claimTime, this.translate.instant('SHARED.None'));
        }
      }
    },
    {
      id: 'availableTime',
      description: this.translate.instant('SHARED.Available_Time'),
      label: this.translate.instant('SHARED.Available_Time'),
      units: 'availableTime',
      cellType: 'string',
      nowrap: 'nowrap',
      showWhen: true,
      func: (collection, type?: string) => {
        if (type === 'sort') {
          return collection[0]?.availableTime;
        } else if (type === 'export') {
          return this.detailReportTableService.getExcelFormatDate(collection?.[0]?.availableTime);
        } else {
          return this.userService.locationDateTimeFormat(collection[0].locationID, collection[0]?.availableTime, this.translate.instant('SHARED.None'));
        }
      }
    },
    {
      id: 'expiresTime',
      description: this.translate.instant('SHARED.Expires_Time'),
      label: this.translate.instant('SHARED.Expires_Time'),
      units: 'expiresTime',
      cellType: 'string',
      nowrap: 'nowrap',
      showWhen: true,
      func: (collection, type?: string) => {
        if (type === 'sort') {
          return collection[0]?.expiresTime;
        } else if (type === 'export') {
          return this.detailReportTableService.getExcelFormatDate(collection?.[0]?.expiresTime);
        } else {
          return this.userService.locationDateTimeFormat(collection[0].locationID, collection[0]?.expiresTime, this.translate.instant('SHARED.None'));
        }
      }
    },
    {
      id: 'Status',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      units: 'Status',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].status) {
          const val = collection[0].status;
          if (val === 'complete') {
            label = this.translate.instant('SHARED.Completed');
          } else if (val === 'groupsCompleted') {
            label = this.translate.instant('SHARED.OptionalGroups');
          } else if (val === 'available') {
            label = this.translate.instant('SHARED.Available');
          } else if (val === 'missed') {
            label = this.translate.instant('SHARED.Missed');
          } else if (val === 'skipped') {
            label = this.translate.instant('SHARED.Skipped');
          } else if (val === 'notCompleted') {
            label = this.translate.instant('SHARED.NotCompleted');
          }
        }
        return label;
      }
    },
    {
      id: 'responder',
      description: this.translate.instant('SHARED.Responder'),
      label: this.translate.instant('SHARED.Responder'),
      units: 'Responder',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.Unassigned');
        if (collection[0] && collection[0].owner) {
          label = this.userService.getFullname(collection[0].owner);
        }
        return label;
      }
    },
    {
      id: 'deploymentName',
      description: this.translate.instant('SHARED.Deployment_Title'),
      label: this.translate.instant('SHARED.Deployment_Title'),
      units: 'DeploymentName',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].deploymentID) {
          const deployment = this.deployment.getDeployment(collection[0].deploymentID);
          if (deployment) {
            label = deployment.title;
          }
        }
        return label;
      }
    },
    {
      id: 'checkDetailName',
      description: this.translate.instant('SHARED.Check_Title'),
      label: this.translate.instant('SHARED.Check_Title'),
      units: 'CheckDetailName',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].checkID) {
          const check = this.checksService.getCheck(collection[0].checkID);
          if (check && check.title) {
            label = check.title;
          }
        }
        return label;
      }
    },
    {
      id: 'checkType',
      description: this.translate.instant('SHARED.Check_Type'),
      label: this.translate.instant('SHARED.Check_Type'),
      units: 'CheckType',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].checkID) {
          const check = this.checksService.getCheck(collection[0].checkID);
          if (check) {
            const checkType = this.settingsService.getItem('checkType', check.checkType);
            if (checkType) {
              label = checkType.messageTitle;
            }
          }
        }
        return label;
      }
    },
    {
      id: 'target',
      description: this.translate.instant('SHARED.Target'),
      label: this.translate.instant('SHARED.Target'),
      units: 'Target',
      cellType: 'string',
      showWhen: true,
      func: (collection: Response[]) => {
        if (collection.length) {
          const target = collection[0].deployment.target;
          const targetSignature = collection[0].targetSignature;
          let label = target.targetType;
          if (targetSignature) {
            const splitSig = split(targetSignature, ':');
            if (splitSig[0] == 'loc') {
              label = this.checkDetailService.getTargetZoneName(+splitSig[1], +splitSig[2]);
            } else if (splitSig[0] == 'asset') {
              const targObj = this.assetsService.getAssetById(+splitSig[1]);
              label = 'Asset' + ': ' + targObj[0].name;
            } else if (splitSig[0] == 'worker') {
              label = 'User' + ': ' + this.accountsService.fullUserName(+splitSig[1]);
            }
          }
          return label;
        } else {
          return 'None';
        }
      }
    },
    {
      id: 'assignedTo',
      description: this.translate.instant('SHARED.Assignee'),
      label: this.translate.instant('SHARED.Assignee'),
      units: 'AssignedTo',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        const label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].assignedTo) {
          const val = collection[0].assignedTo;
          if (val.users && val.users.length) {
            let users: string;
            if (val.users[0] === -1) {
              users = this.translate.instant('MGMT_DETAILS.Target_User');
              const splitSig = split(collection[0].targetSignature, ':');
              if (+splitSig[1]) {
                users = this.accountsService.fullUserName(+splitSig[1]);
              }
            } else if (val.users[0] === -2) {
              users = this.translate.instant('MGMT_DETAILS.User_Supervisor');
            } else {
              users = this.accountsService.fullUserName(val.users[0]);
            }
            return this.translate.instant('SHARED.USER') + ': ' + users;
          } else if (val.teams && val.teams.length) {
            return this.translate.instant('SHARED.Team') + ': ' + this.teamsService.teamNameByID(val.teams[0]);
          } else {
            return this.translate.instant('SHARED.Unassigned');
          }
        }
        return label;
      }
    },
    {
      id: 'skipReason',
      description: this.translate.instant('SHARED.Skip_Reason'),
      label: this.translate.instant('SHARED.Skip_Reason'),
      units: 'SkipReason',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0] && collection[0].skipReason) {
          label = collection[0].skipReason;
        }
        return label;
      }
    },
    {
      id: 'skipNotes',
      description: this.translate.instant('SHARED.Skip_Notes'),
      label: this.translate.instant('SHARED.Skip_Notes'),
      units: 'SkipNotes',
      cellType: 'string',
      showWhen: true,
      func: (collection, type?: string) => {
        let label: string = this.translate.instant('SHARED.None');
        if (collection[0]?.skipNotes) {
          const notes = filter(collection[0].skipNotes, { type: 'text' });
          const separator = type === 'export' ? ', ' : `<br>`;
          label = join(map(notes, 'value'), separator);
        }
        return label;
      }
    },
    {
      id: 'issueCount',
      description: this.translate.instant('SHARED.Issue_Count'),
      label: this.translate.instant('SHARED.Issue_Count#'),
      units: 'IssueCount',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let label = 0;
        if (collection[0] && collection[0].issueCount) {
          label = collection[0].issueCount;
        }
        return label;
      }
    },
    {
      id: 'observationCount',
      description: this.translate.instant('SHARED.Observation_Count'),
      label: this.translate.instant('SHARED.Observation_Count#'),
      units: 'ObservationCount',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let label = 0;
        if (collection[0] && collection[0].observationCount) {
          label = collection[0].observationCount;
        }
        return label;
      }
    },
    {
      id: 'location',
      description: this.translate.instant('SHARED.Location'),
      label: this.translate.instant('SHARED.Location'),
      units: 'Location',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let locationName: string = this.translate.instant('SHARED.None');
        if (collection.length && collection[0].locationID) {
          locationName = get(this.userService.findLocation(collection[0].locationID), 'name');
          if (!locationName) {
            locationName = this.translate.instant('DASHPAGES.NA');
          }
        }
        return locationName;
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      units: 'ZoneID',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        const locRef = this.userService.findLocation(collection[0].locationID);
        const zoneRef = this.userService.findAnyZone(locRef, collection[0].zoneID);
        if (zoneRef) {
          return `${locRef.name}: ${zoneRef.name}`;
        } else {
          if (locRef) {
            return locRef.name + '/Site-wide';
          } else {
            return 'Site-wide';
          }
        }
      }
    },
    {
      id: 'questionCategory',
      description: this.translate.instant('SHARED.Question_Category'),
      label: this.translate.instant('SHARED.Question_Category'),
      units: 'QuestionCategory',
      cellType: 'string',
      showWhen: true,
      multipleColumn: true,
      multipleColumnHandler: (response: Response, columns: Object) => {
        this.buildQuestionCategory(response, columns);
      },
      func: (collection) => this.translate.instant('SHARED.None')
    },
    {
      id: 'detailTags',
      description: this.translate.instant('SHARED.Detail_Tags'),
      label: this.translate.instant('SHARED.Detail_Tags'),
      units: 'DetailTags',
      cellType: 'string',
      showWhen: true,
      multipleColumn: true,
      multipleColumnHandler: (response: Response, columns: Object) => {
        this.buildDetailTags(response, columns);
      },
      func: (collection) => this.translate.instant('SHARED.None')
    },
    {
      id: 'group',
      description: this.translate.instant('MGMT_DETAILS.Group'),
      label: this.translate.instant('MGMT_DETAILS.Group'),
      units: 'Group',
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = '';
        if (collection[0] && collection[0].checkID && collection[0].checkGroup) {
          const check = this.checksService.getCheck(collection[0].checkID);
          if (check && check.checkGroups) {
            const checkGroup = find(check.checkGroups, <any>{groupID: collection[0].checkGroup});
            if (checkGroup && checkGroup.title) {
              label = checkGroup.title;
            }
          }
        }
        return label;
      }
    },
    {
      id: 'powerpoints',
      description: this.translate.instant('MGMT_DETAILS.Points'),
      label: this.translate.instant('MGMT_DETAILS.Points'),
      units: 'Points',
      cellType: 'number',
      showWhen: true,
      func: (collection) => collection?.[0]?.powerpoints
    },
  ];

  private fieldOptions = [
    {
      id: 'checkType',
      description: this.translate.instant('SHARED.Check_Type'),
      label: this.translate.instant('SHARED.Check_Type'),
      fieldName: 'checkType',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const check = this.settingsService.getItem('checkType', val);
        return [val, check.messageTitle];
      }
    },
    {
      id: 'checkTitle',
      description: this.translate.instant('SHARED.Check_Title'),
      label: this.translate.instant('SHARED.Check_Title'),
      fieldName: 'checkID',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = this.translate.instant('SHARED.None');
        const check = this.checksService.getCheck(val);
        if (check && check.title) {
          title = check.title;
        }
        return [val, title];
      }
    },
    {
      id: 'responseID',
      description: this.translate.instant('SHARED.Response_ID'),
      label: this.translate.instant('SHARED.Response_ID'),
      fieldName: 'responseID',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = val;
        const check = this.checksService.getCheck(ref.checkID);
        if (check && check.title) {
          title = check.title;
        }
        return [val, title];
      }
    },
    {
      id: 'checkResult',
      description: this.translate.instant('SHARED.Check_Result'),
      label: this.translate.instant('SHARED.Check_Result'),
      fieldName: 'result',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let title = val;
        if (val === 'negative') {
          title = this.translate.instant('SHARED.Negative');
        } else if (val === 'neutral') {
          title = this.translate.instant('SHARED.Neutral');
        } else if (val === 'positive') {
          title = this.translate.instant('SHARED.Positive');
        } else if (val === 'unknown') {
          title = this.translate.instant('SHARED.None');
        }
        return [val, title];
      }
    },
    {
      id: 'groupTitle',
      description: this.translate.instant('SHARED.Group_Title'),
      label: this.translate.instant('SHARED.Group_Title'),
      fieldName: 'checkGroup',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const check = this.checksService.getCheck(ref.checkID);
        const group = find(check.checkGroups, 'groupID', val);
        let title = val;
        let groupID = val;
        if (group && group.title) {
          title = group.title;
          groupID = group.groupID;
        }
        return [groupID, title];
      }
    },
    {
      id: 'groupResult',
      description: this.translate.instant('SHARED.Group_Result'),
      label: this.translate.instant('SHARED.Group_Result'),
      fieldName: 'result',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val) => [val, val]
    },
    {
      id: 'groupStatus',
      description: this.translate.instant('SHARED.Group_Status'),
      label: this.translate.instant('SHARED.Group_Status'),
      fieldName: 'status',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if (val === 'complete') {
          label = this.translate.instant('SHARED.Completed');
        } else if (val === 'available') {
          label = this.translate.instant('SHARED.Available');
        } else if (val === 'groupsCompleted') {
          label = this.translate.instant('SHARED.OptionalGroups');
        } else if (val === 'missed') {
          label = this.translate.instant('SHARED.Missed');
        } else if (val === 'skipped') {
          label = this.translate.instant('SHARED.Skipped');
        } else if (val === 'notCompleted') {
          label = this.translate.instant('SHARED.NotCompleted');
        }
        return [val, label];
      }
    },
    {
      id: 'questionTitle',
      description: this.translate.instant('SHARED.Question_Title'),
      label: this.translate.instant('SHARED.Question_Title'),
      fieldName: 'questions',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const question = this.questionService.getQuestion(val);
        return [val, question.title];
      }
    },
    {
      id: 'assignee',
      description: this.translate.instant('SHARED.Assignee'),
      label: this.translate.instant('SHARED.Assignee'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        if (val.users && val.users.length) {
          let users: string;
          let valID = val.users[0];
          if (val.users[0] === -1) {
            users = this.translate.instant('MGMT_DETAILS.Target_User');
            const splitSig = split(ref.targetSignature, ':');
            if (+splitSig[1]) {
              users = this.accountsService.fullUserName(+splitSig[1]);
              valID = +splitSig[1];
            }
          } else if (val.users[0] === -2) {
            users = this.translate.instant('MGMT_DETAILS.User_Supervisor');
            const splitSig = split(ref.targetSignature, ':');
            const userObj = this.accountsService.getAccount(+splitSig[1]);
            if (userObj.supervisorID) {
              users = this.accountsService.fullUserName(userObj.supervisorID);
              valID = userObj.supervisorID;
            }
          } else {
            users = this.accountsService.fullUserName(val.users[0]);
          }
          return [valID, this.translate.instant('SHARED.USER') + ': ' + users];
        } else if (val.teams && val.teams.length) {
          return [val.teams[0], this.translate.instant('SHARED.Team') + ': ' + this.teamsService.teamNameByID(val.teams[0])];
        } else {
          return [0, this.translate.instant('SHARED.Unassigned')];
        }
      }
    },
    {
      id: 'assigneeShift',
      description: this.translate.instant('SHARED.Assignee_Shift'),
      label: this.translate.instant('SHARED.Assignee_Shift'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val) => {
        if (val.users && val.users.length) {
          let u: any;
          if (val.users[0] === -1) {
            u = this.accountsService.getAccount(this.userdataService.userID);
          } else {
            u = this.accountsService.getAccount(val.users[0]);
          }
          if (u && u.shift) {
            return [this.shift.name(u.shift), this.shift.name(u.shift)];
          } else {
            return [0, 'None'];
          }
        } else {
          return [0, 'None'];
        }
      }
    },

    {
      id: 'assigneeTeam',
      description: this.translate.instant('SHARED.Assignee_Team'),
      label: this.translate.instant('SHARED.Assignee_Team'),
      fieldName: 'assignedTo',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val) => {
        if (val.users && val.users.length) {
          let team: string = this.translate.instant('DASHPAGES.NA');
          let u: any;
          let teamID = 0;
          if (val.users[0] === -1) {
            u = this.accountsService.getAccount(this.userdataService.userID);
            const theGroup = u?.primaryGroup ? u.primaryGroup : u?.groups[0];
            if (theGroup) {
              team = this.teamsService.teamNameByID(theGroup);
              teamID = theGroup
            }
          } else if (val.users[0] === -2) {
            team = this.translate.instant('DASHPAGES.NA');
          } else {
            u = this.accountsService.getAccount(val.users[0]);
            const theGroup = u?.primaryGroup ? u.primaryGroup : u?.groups[0];
            if (theGroup) {
              team = this.teamsService.teamNameByID(theGroup);
              teamID = theGroup;
            }
          }
          return [teamID, team];
        } else if (val.teams && val.teams.length) {
          return [val.teams[0], this.teamsService.teamNameByID(val.teams[0])];
        } else {
          return [0, this.translate.instant('DASHPAGES.NA')];
        }
      }
    },

    {
      id: 'questionStatus',
      description: this.translate.instant('SHARED.Question_Status'),
      label: this.translate.instant('SHARED.Question_Status'),
      fieldName: 'questions',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const answered = has(ref.answers, val);
        let title = this.translate.instant('SHARED.Not_Answered');
        let id = 0;
        if (answered) {
          title = this.translate.instant('SHARED.Answered');
          id = 1;
        }
        return [id, title];
      }
    },
    {
      id: 'deploymentTitle',
      description: this.translate.instant('SHARED.Deployment_Title'),
      label: this.translate.instant('SHARED.Deployment_Title'),
      fieldName: 'deploymentID',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const deployment = this.deployment.getDeployment(val);
        let label: any = 'None';
        if (deployment) {
          label = deployment.title;
        }
        return [val, label];
      }
    },
    {
      id: 'target',
      description: this.translate.instant('SHARED.Target'),
      label: this.translate.instant('SHARED.Target'),
      fieldName: 'target',
      fieldType: 'array',
      fieldFunc: (target, row) => {
        let val = '0';
        const targetSignature: string = row.targetSignature;
        let label: string = this.translate.instant('DASHPAGES.NA');
        if (targetSignature) {
          val = targetSignature;
          const splitSig = split(targetSignature, ':');
          if (splitSig[0] == 'loc') {
            label = this.checkDetailService.getTargetZoneName(+splitSig[1], +splitSig[2]);
          } else if (splitSig[0] == 'asset') {
            const targObj = this.assetsService.getAssetById(+splitSig[1]);
            label = 'Asset' + ': ' + targObj[0].name;
          } else if (splitSig[0] == 'worker') {
            label = 'User' + ': ' + this.accountsService.fullUserName(+splitSig[1]);
          }
        }
        return [val, label];
      }
    },
    {
      id: 'deploymentType',
      description: this.translate.instant('SHARED.Deployment_Type'),
      label: this.translate.instant('SHARED.Deployment_Type'),
      fieldName: 'deploymentID',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const deployment = this.deployment.getDeployment(val);
        let label: any = 'None';
        if (deployment) {
          label = deployment.target.targetType;
          if (label === 'assets') {
            label = this.translate.instant('MGMT_LIST.Asset');
          } else if (label === 'workers') {
            label = this.translate.instant('SHARED.Worker');
          } else if (label === 'zones') {
            label = this.translate.instant('SHARED.Zone');
          }
        }
        return [label, label];
      }
    },
    {
      id: 'state',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      fieldName: 'instanceStatus',
      fieldType: 'string',
      fieldFunc: (val) => {
        const status: string = val;
        let label: string;
        if (status === 'complete') {
          label = this.translate.instant('SHARED.Completed');
        } else if (status === 'groupsCompleted') {
          label = this.translate.instant('SHARED.OptionalGroups');
        } else if (status === 'available') {
          label = this.translate.instant('SHARED.Available');
        } else if (status === 'missed') {
          label = this.translate.instant('SHARED.Missed');
        } else if (status === 'skipped') {
          label = this.translate.instant('SHARED.Skipped');
        } else if (status === 'notCompleted') {
          label = this.translate.instant('SHARED.NotCompleted');
        }
        return [status, label];
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zoneID',
      fieldType: 'array',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + val;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, val);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'responder',
      description: this.translate.instant('SHARED.Responder'),
      label: this.translate.instant('SHARED.Responder'),
      fieldName: 'owner',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.userService.getFullname(val)]
    },
    {
      id: 'responderTeam',
      description: this.translate.instant('SHARED.Responder_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'owner',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        const u = this.accountsService.getAccount(val);
        const theGroup = u?.primaryGroup ? u.primaryGroup : u?.groups[0];
        if (theGroup) {
          if (this.checkOpts && this.checkOpts.groups && this.checkOpts.groups.length) {
            const intersectionGroups = intersection(u.groups, this.checkOpts.groups);
            if (intersectionGroups && intersectionGroups.length) {
              return [intersectionGroups[0], this.teamsService.teamNameByID(intersectionGroups[0])];
            } else {
              return [theGroup, this.teamsService.teamNameByID(theGroup)];
            }
          } else {
            return [theGroup, this.teamsService.teamNameByID(theGroup)];
          }
        } else {
          return [ 0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'locationID',
      description: this.translate.instant('SHARED.Location'),
      label: 'Location',
      fieldName: 'locationID',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = get(this.userService.findLocation(locationId), 'name');
        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    },
    {
      id: 'responder_shift',
      description: this.translate.instant('SHARED.Responder_Shift'),
      label: this.translate.instant('SHARED.Responder_Shift'),
      fieldName: 'owner',
      fieldType: 'string',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u && u.shift) {
          return [val, this.shift.name(u.shift)];
        } else {
          return [0, 'None'];
        }
      }
    },
  ];

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private shift: ShiftService,
    private teamsService: TeamsService,
    private deployment: DeploymentService,
    private checkDetailService: CheckDetailService,
    private assetsService: AssetsService,
    private userdataService: UserdataService,
    private questionService: QuestionService,
    private checksService: ChecksService,
    private settingsService: SettingsService,
    private subscriber: SubscriberService,
    private detailReportTableService: DetailReportTableService
  ) {
  }

  public getColumns() {
    return filter(cloneDeep(this.columnOptions), (opt) => opt.showWhen);
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

  public getItemElement(row): string {
    let colData = {
      'pass' : {
        'value': this.translate.instant('MGMT_DETAILS.Pass'),
      },
      'fail': {
        'value': this.translate.instant('MGMT_DETAILS.Fail'),
      }
    }
    return colData[row.value].value;
  }

  private buildQuestionCategory(response: Response, columns: Object) {
    const categoryField = this.getQuestionCategory();

    forEach(response?.questions, (questionID) => {
      const question = this.questionService.getQuestion(questionID);
      const columnKey = `questionCategory_${questionID}`;

      if (question) {
        columns[columnKey] = {
          id: columnKey,
          label: question.title,
          func: (responses: Response[]) => {
            const closingCategory = responses[0]?.answers?.[questionID]?.closingCategory;
            const category = find(categoryField, ['id', closingCategory]);

            return category?.description || this.translate.instant('DASHPAGES.NA');
          }
        }
      }
    });
  }

  private buildDetailTags(response: Response, columns: Object) {
    forEach(response?.questions, (questionID) => {
      const question = this.questionService.getQuestion(questionID);
      const columnKey = `detailTags_${questionID}`;

      if (question) {
        columns[columnKey] = {
          id: columnKey,
          label: question.title,
          func: (responses: Response[]) => {
            const closingTags = responses[0]?.answers?.[questionID]?.closingTags;
            return join(filter(map(closingTags, (tag) => {
              const tagObj = find(this.settingsService.customTags.data, ['tagID', tag]);
              return tagObj?.tag;
            })), ', ') || this.translate.instant('DASHPAGES.NA');
          }
        }
      }
    });
  }

  private buildAnswerDetails(response: Response, columns: Object, key = 'answerDetails') {
    forEach(response?.questions, (questionID) => {
      const question = this.questionService.getQuestion(questionID);
      const columnKey = `${key}_${questionID}`;

      if (question) {
        columns[columnKey] = {
          id: columnKey,
          label: question.title,
          func: (responses: Response[], type?: string) => {
            const separator = type === 'export' ? ' ' : `;<br />`;
            const currentAnswer = responses[0]?.answers?.[questionID];
            let label = '';

            if (currentAnswer && question.questionID) {
              if (currentAnswer.responseValue?.[0]) {
                const questionOptions = this.questionService.getQuestionOptions(questionID);
                each(currentAnswer.responseValue, row => {
                  if (label !== '') {
                    label += separator;
                  }
                  let item = row.value;
                  if (item === 'pass' || item === 'fail') {
                    item = this.getItemElement(row);
                  } else if (get(item, 'units')) {
                    const unit = item.units;
                    const val = get(item, unit);
                    item = val + ' ' + unit;
                  } else if (question.type === 'textInput' && has(row, 'optionID')) {
                    // this one has a value and an option, do we need the string?
                    if (has(questionOptions, row.optionID)) {
                      const prompt = questionOptions[row.optionID];
                      item = this.checkDetailService.translateData(prompt) + ': ' + item;
                    }
                  }
                  label += item;
                });
              } else if (currentAnswer?.response !== '0') {
                const respID = currentAnswer.response;
                label = '';
                if (Array.isArray(respID)) {
                  // we have a LIST of responses - stick them in
                  const questionOptions = this.questionService.getQuestionOptions(question.questionID);
                  each(respID, item => {
                    if (this.checkDetailService.isUUID(item)) {
                      const questionOption = questionOptions[item];
                      if (label !== '') {
                        label += separator;
                      }
                      label += this.checkDetailService.translateData(questionOption);
                    }
                  });
                } else {
                  if (this.checkDetailService.isUUID(respID)) {
                    const questionOptions = this.questionService.getQuestionOptions(question.questionID);
                    const questionOption = questionOptions[respID];
                    label = this.checkDetailService.translateData(questionOption);
                  }
                }
              }
              const notes = currentAnswer.notes;
              if (notes && notes.length) {
                label += ' - ' + join(map(notes, 'value'), ', ');
              }
            }

            if (key === 'answerDetailsDocumentation') {
              label = this.setAnswerCustomCell(label, responses[0], questionID, type);
            }

            return label || (currentAnswer ? this.translate.instant('DASHPAGES.NA') : '');
          }
        }
      }
    });
  }

  public getQuestionCategory() {
    let categoryField = [];
    const locationId = this.subscriber.locationID();
    const observationTypes: string[] = ['category', 'pi', 'quality'];
    const titleMap = {
      category: this.translate.instant('SHARED.Unsafe_Conditions'),
      pi: this.translate.instant('SHARED.Process_Improvements'),
      quality: this.translate.instant('SHARED.Quality_Issues')
    };

    each(observationTypes, (type) => {
      const categoriesByType: any[] = orderBy(this.settingsService.getSettingSync(type, locationId, true), 'messageTitle');
      categoryField = [
        ...categoryField,
        ...map(categoriesByType, (category) => {
          const observationType: string = type === 'category' ? 'condition' : type;
          return {
            id: `${observationType}:${category.messageID}`,
            description: `${titleMap[type]} - ${category.messageTitle}`
          };
        })
      ];
    });
    return categoryField;
  }

  public setAnswerCustomCell(val: string, response: Response, questionId: string, type?: string) {
    const linkTextValues = {
      positive: this.translate.instant('MGMT_DETAILS.Pass'),
      negative: this.translate.instant('MGMT_DETAILS.Fail'),
      neutral: val
    };
    const currentAnswer = response?.answers?.[questionId];

    if (!currentAnswer) {
      return val;
    }

    if (type === 'export') {
      return linkTextValues[currentAnswer.result];
    }

    const question: IObjectStringKeyMap<IQuestionOption> = this.questionService.getQuestionOptions(currentAnswer.questionID);
    const questionOption: IQuestionOption = question[currentAnswer.response]

    let colLabel: string = (questionOption ? this.userService.translateItem(questionOption, 'label') : linkTextValues[currentAnswer.result]) ||
      this.translate.instant('DASHPAGES.NA');
    let linkElement = $(`<span class="link"><span class="link-col col-${currentAnswer.result}">${colLabel}</span></span>`);
    let linkName = '';

    if (get(currentAnswer, 'observationID')) {
      // ca
      linkName = 'MGMT_DETAILS.View_Action';
    } else if (get(currentAnswer, 'flaggedIssue')) {
      // issue
      linkName = 'MGMT_DETAILS.View_Issue';
    } else if (get(currentAnswer, 'result') && (currentAnswer.notes?.length || currentAnswer.images?.length)) {
      // pass
      linkName = 'MGMT_DETAILS.View_Validation';
    }

    if (linkName) {
      linkElement = $(`<span class="link"><span class="link-col col-${currentAnswer.result}">${colLabel}</span><span class="col-link">${this.translate.instant(linkName)}</span></span>`);
    }

    linkElement.attr('data-question-id', questionId);

    return linkElement[0].outerHTML;
  }

}
