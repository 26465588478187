import { Injectable } from '@angular/core';

import { CommsService } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { cloneDeep, each, flatten } from 'lodash';

export interface IRelay {
  active: number;
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  title: string;
  signature: string;
  relayID: number;
  folderID: number;
  locationID: number;
  zoneID: number;
  images: number[];
}

@Injectable({
  providedIn: 'root'
})

export class RelayService {
  public relays = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IRelay>
  };

  constructor(
    private commsService: CommsService,
    private events: Events,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {}

  public refresh(updated?: number): Promise<IObjectStringKeyMap<IRelay>> {
    if (updated && updated < this.relays.lastRequest) {
      this.logger.log(`local relays cache already up to date: ${updated}, ${this.relays.lastRequest}`);
      return Promise.resolve(this.relays.data);
    } else {
    const requestData: any = {
      cmd: 'getRelays',
      lastRequest: this.relays.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCache(response);
      }

      return this.relays.data;
    });
    }
  }

  public updateCache(data) {
        this.relays.data = data.result.relays;
        this.relays.lastRequest = data.result.timestamp;
        this.events.publish('ccs:relaysUpdate', true);
  }

  public getLocalRelays() {
    return cloneDeep(this.relays.data);
  }



  public getRelaysAsList(includeInactive: boolean = false, includeDisabled: boolean = false): IRelay[] {
    const theList: IRelay[] = [];

    each(this.relays.data, (item) => {
      if (!includeInactive && !item.active) {
        return;
      }
      if (!includeDisabled && item.disabledAt) {
        return;
      }
      theList.push(item);
    });

    return theList;
  }

  public getRelays(): IObjectStringKeyMap<IRelay> {
    return this.relays.data;
  }

  public getRelayById(id: number): IRelay {
    return cloneDeep(this.relays.data[id]);
  }

  public addRelay(data: any): Promise<any> {
    const requestData = {
      cmd: 'addRelay',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public updateRelay(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateRelay',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deleteRelay(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteRelay',
      relays: flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }
}
