import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserdataService } from '@services/userdata/userdata.service';
import { CommsService } from '@services/comms/comms.service';
import { Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { StorageService } from '@services/storage/storage.service';
import { ObservationService } from '@services/observations/observation.service';
import { CacheService } from '@services/cache/cache.service';
import { HeartbeatService } from '@services/heartbeat/heartbeat.service';
import { MessagesService } from '@services/messages/messages.service';
import { PermissionsService } from '@services/permissions/permissions.service';
import { AccessService } from '@services/access/access.service';
import { AccountsService, AccountTypes } from '@services/accounts/accounts.service';
import { environment } from '@env';


import * as moment from 'moment';
// import localization from 'moment/locale/es';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalDBService } from '@services/localdb.service.ts/localdb';
import { clone, get, has } from 'lodash';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public onAuthEvent: Observable<Boolean>;
  public prevObservationRoute: string;
  public userProfile: any;
  private readonly authEventSubject: Subject<Boolean> = new Subject<Boolean>();
  private dontStoreState: boolean;
  private authResult: any = null;
  private requestedScopes = 'openid profile email';
  private auth0Authenticated = false;
  private lastAuthCheck = 0;

  constructor(
    private logger: NGXLogger,
    private userData: UserdataService,
    private accountsService: AccountsService,
    private commsService: CommsService,
    public subscriber: SubscriberService,
    private router: Router,
    private storageService: StorageService,
    private observations: ObservationService,
    private cacheService: CacheService,
    private heartbeat: HeartbeatService,
    private messagesService: MessagesService,
    private permissionsService: PermissionsService,
    private accessService: AccessService,
    private localdb: LocalDBService,
    protected translate: TranslateService,
  ) {
    this.onAuthEvent = this.authEventSubject.asObservable();
  }

  public isUserRemembered(): boolean {
    const userData = this.userData.getCachedUserData();
    return !!userData.Remember;
  }

  public isUserLogged(): boolean {
    if (this.userData.isRunning()) {
      // we are running;
      const s: any = this.storageService.getState();
      if (s) {
        if (!this.userData.Token) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public emitEvent(): void {
    this.storageService.storeState();
    this.authEventSubject.next(this.isUserLogged());
  }

  public handleSignin(userModel): Promise<void> {
    return this.sendAuthenticate(userModel).then( async () => {
    // it worked - show the preparing page while we set things up
    this.router.navigate(['pages/login/preparing']);
    // capture the user information if they asked is to do so
    this.userData.UserName = userModel.UserName;
    this.userData.Password = userModel.Password;
    this.userData.Remember = userModel.remember ? 1 : 0;
    if (StorageService) {
      if (this.userData.Remember) {
        // window.StorageService.setItem(`userdata${environment.baseHref}`, JSON.stringify(this.userData));
      } else {
        StorageService.removeItem(`userdata${environment.baseHref}`);
        StorageService.removeItem(`prefs${environment.baseHref}`);
      }
    }
      const [res, err] = await awaitHandler( this.processSignin() );
      this.navigateToHomePage();
    });
  }

  // support for auth0

  public navigateToHomePage() {
    if (this.subscriber.branding() === 'hop') {
      this.router.navigate(['pages/management/explore']);
    } else {
      if (this.userData.type === AccountTypes.Reporting) {
        this.router.navigate(['pages/reporting']);
      } else {
        this.router.navigate([this.userData.getPreference('lastDashboardType') || 'pages/dashboard/main']);
      }
    }
  }

  public handleSignout(): void {
    // this.userData.savePreference('lastDashboardType', this.router.url);
    this.cacheService.stopListening();
    this.messagesService.stopPolling();
    this.messagesService.clearCache();
    // this.observations.stopUpdating();
    this.heartbeat.stopHeartbeat();
    this.heartbeat.clearCache();
    this.userData.clear();
    this.cacheService.clear();
    this.storageService.auth0ClearCache();
    this.storageService.clearState();

    // make sure everything is initialized
    const userLang = navigator.language;
    const myLang = userLang.replace(/-.*$/, '').toLowerCase();
    if (myLang === 'en' || myLang === 'es') {
      this.translate.use(myLang);
    } else {
      this.translate.use('en');
    }
    this.authEventSubject.next(false);
    this.commsService.token = null;
    if (!this.auth0Authenticated) {

      if (get(this.userData, 'preferences.rememberMe') === 1) {
        StorageService.removeItem(`userdata${environment.baseHref}`);
      }
      StorageService.removeItem(`prefs${environment.baseHref}`);
      StorageService.removeItem(`state${environment.baseHref}`);
      // app.dontStoreState = true;
      this.router.navigate(['pages/login'], {replaceUrl: true});
    } else {
      this.auth0Logout();
    }
  }

  public handleNoAuth(): void {
    // we got a noauth from the backend; we are logged out.
    // first, clear any token in storage
    this.logger.warn('Got a noauth message from the backend');
    this.storageService.clearState();
    this.handleSignout();
  }

  public sendAuthenticate(userModel) {
    const msg = {
      cmd: 'authenticate',
      username: userModel.UserName,
      password: userModel.Password,
      nfcID: userModel.nfcID || '',
      authToken: userModel.authToken || '',
      version: environment.appVersion
    };
    return this.commsService.sendMessage(msg, false, true).then((data) => {
      if (data.reqStatus === 'OK') {
        this.storageService.clearState();
        this.userData.Password = userModel.Password;
        this.userData.handleAccountData(data.result);
        const hasAccess: boolean = this.userData.hasAccess();

        if (hasAccess) {
          // the authentication worked
          this.userData.Registered = 1;
          // remember my token for future communications
          this.commsService.token = data.result.token;
          if (data.result.hasOwnProperty('lastWebVersion')) {
            this.userData.lastVersion = data.result.lastWebVersion;
            if (this.userData.newerVersion(this.subscriber.carouselVersion)) {
              this.userData.showCarousel = true;
            }
          }
          // does the subscriberID match?
          // this.subscriber.subInfo.subscriberName = data.result.subscriberName; // TODO [azyulikov] remove it once config is ready
          // remember that we are actually logged in
          if (this.storageService.storeState()) {
            this.userData.setRunning();
          }
          return Promise.resolve(true);
        } else {
          this.userData.clear();
          this.accessService.goToAccessPage();
          return Promise.reject(null);
        }
      } else {
        // the authentication failed
        return Promise.reject({
          status: data.reqStatus,
          statusText: this.translate.instant('AUTH_SERVICE.Authentication_failed') + data.reqStatusText
        });
      }
    }).catch((err) => {
      if (err) {
        return Promise.reject({
          status: 'FAIL',
          statusText: this.translate.instant('AUTH_SERVICE.Communication_failed') + err.status + ' ' + err.statusText
        });
      } else {
        return Promise.reject(null);
      }
    });
  }

  public async authenticateWithToken(token: string): Promise<boolean> {
    if (token) {
      // try to get a result
      const prevNoAuth = this.commsService.noAuthHandler;
      this.commsService.noAuthHandler = null;
      const [res, err] = await awaitHandler(this.commsService.sendMessage({cmd: 'checkAccess', readOnly: 1, token}));
      this.commsService.noAuthHandler = prevNoAuth;
      if (err || res?.result?.status !== 'OK' ) {
        return false;
      }
      // the result was OK - so this token is usable
      this.commsService.token = token;
      this.userData.userID = res.result.userID;
      await this.userData.fetch();

      const hasAccess: boolean = this.userData.hasAccess();

      if (hasAccess) {
        this.logger.debug(`access with token granted`);
        // the authentication worked
        this.userData.Registered = 1;
        this.userData.Token = token;
        // remember that we are actually logged in
        if (this.storageService.storeState()) {
          this.userData.setRunning();
        }
        return true;
      } else {
        this.userData.clear();
        this.accessService.goToAccessPage();
        throw new Error(`token user does not have access`);
      }
    }
    return false;
  }

  public auth0RestoreResult(): boolean {
    if (this.usesAuth0()) {
      const s: any = this.storageService.getState();
      if (s) {
        if (has(s, 'auth0') && has(s.auth0, 'accessToken')) {
          this.authResult = s.auth0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public auth0CheckAuth(redirectOnFail: boolean = true): Promise<any> {
    if (!this.auth0Token()) {
      return Promise.resolve(null);
    }
    if (this.lastAuthCheck && this.auth0isAuthenticated()) {
      return Promise.resolve(this.auth0GetProfile());
    }
    this.logger.debug('AUTH0: Verifying authentication token');
    return new Promise((resolve, reject) => {
      const o: any = {
        clientID: this.subscriber.Auth0Config.auth0ClientID
      };
      if (this.subscriber.Auth0Config.auth0Connection) {
        // this auth0 user is going to avoid the universal login page
        o.connection = this.subscriber.Auth0Config.auth0Connection;
      }
      this.subscriber.auth0().checkSession(
        o,
        (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.logger.debug('AUTH0: token is valid');
            this.lastAuthCheck = Date.now();
            window.location.hash = '';
            this.auth0SetSession(authResult);
            this.auth0Authenticated = true;
            this.auth0GetProfile()
              .then((profile) => {
                resolve(profile);
              })
              .catch((err) => {
                resolve(null);
              });
          } else if (err) {
            this.logger.debug(`AUTH0: token is NOT valid: ${err.error}`);
            this.authResult = null;
            this.storageService.auth0ClearCache();
            // there was some authentication failure - caller will deal with it
            if (redirectOnFail) {
              this.router.navigate(['/pages/login']);
            }
            this.logger.log(err);
            resolve(null);
          }
        });
    });
  }

  public auth0Token(): string {
    const accessToken = get(this.authResult, 'accessToken', null);
    return accessToken;
  }

  public auth0Login(): Promise<any> {
    const ret: any = {};
    const o: any = {
      clientID: this.subscriber.Auth0Config.auth0ClientID
    };
    if (this.subscriber.Auth0Config.auth0Connection) {
      // this auth0 user is going to avoid the universal login page
      o.connection = this.subscriber.Auth0Config.auth0Connection;
    }
    return new Promise((resolve, reject) => {
      this.subscriber.auth0().popup.authorize(o, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.lastAuthCheck = Date.now();
          this.auth0SetSession(authResult);
          this.auth0Authenticated = true;
          this.auth0GetProfile()
            .then((profile) => {
              resolve(profile);
            })
            .catch((err) => {
              reject(err);
            });
        } else if (err) {
          // there was some authentication failure - caller will deal with it
          this.router.navigate(['/pages/login']);
          this.logger.log(err);
          reject('Error: <%= "${err.error}" %>. Check the console for further details.');
        }
      });
    });
  }

  public auth0PopupCallback(theHash: string) {
    this.subscriber.auth0().popup.callback(
      {hash: theHash}
    );
  }

  public auth0GetSession(): any {
    let ret = {};
    if (this.auth0Authenticated) {
      ret = this.authResult;
    }
    return ret;
  }

  public auth0UpdateSession(authResult): void {
    // called from storage service if there is auth0 data in there
    if (authResult && has(authResult, 'access_token')) {
      // there is auth0 data being updated; is it current
      if (!this.authResult || !has(this.authResult, 'auth_token')) {
        this.authResult = authResult;
      }
    }
  }

  public auth0Logout(): boolean {
    if (this.subscriber.usesModule(Module.SSO) && this.auth0Authenticated) {
      this.lastAuthCheck = 0;
      this.authResult = null;
      this.userProfile = null;
      this.auth0Authenticated = false;
      // Go back to the home route
      const o = {
        clientID: this.subscriber.Auth0Config.auth0ClientID,
        returnTo: this.subscriber.baseURL() + '/pages/login',
      };
      this.subscriber.auth0().logout(o);
      return false;
    } else {
      return false;
    }
  }

  public auth0isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(this.authResult.expiresAt);
    return new Date().getTime() < expiresAt;
  }

  public auth0UserHasScopes(scopes: Array<string>): boolean {
    const grantedScopes = JSON.parse(this.authResult.scopes).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  public auth0GetProfile(): Promise<any> {
    const accessToken = this.authResult.accessToken;
    if (!accessToken) {
      return Promise.reject('Access Token must exist to fetch profile');
    }

    if (this.userProfile) {
      return Promise.resolve(this.userProfile);
    }

    return new Promise((resolve, reject) => {

      this.subscriber.auth0().client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          return resolve(profile);
        } else {
          return reject('No profile returned');
        }
      });
    });
  }

  public usesAuth0(): boolean {
    return this.subscriber.auth0() === null ? false : true;
  }

  public async processSignin(): Promise<void> {
    // set the user's language
    const lg = this.userData.getLanguage();
    if (lg) {
      this.translate.use(lg);
      moment.locale(lg); // , localization);
    } else {
      this.translate.use('en');
      moment.locale('en');
    }

    // get any subscriber terms
    await this.subscriber.updateCustomTerms();

    this.dontStoreState = false;
    this.observations.initialize();
    this.cacheService.clearTableSearch();

    // force getting subscriber info with credentials so we get all the info
    await this.subscriber.refresh();
    await Promise.all([this.cacheService.fetchAll(), this.accountsService.refresh(), this.localdb.initialize(this.subscriber.subscriberID(), this.userData.userID)]);

    // start observation loading
    this.observations.restoreAndUpdate();

    this.heartbeat.startHeartbeat();
    this.authEventSubject.next(true);

    // this should start up the SSE service
    if (this.cacheService.listenForEvents()) {
      this.heartbeat.doHeartbeat(true);
    } else {
      this.heartbeat.doHeartbeat();
    }

    this.messagesService.startPolling();
  }

  private auth0SetSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    // If there is a value on the scope param from the authResult,
    // use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested,
    // set it to nothing
    const scopes = authResult.scope || this.requestedScopes || '';
    const s = clone(authResult);
    s.expiresAt = expiresAt;
    s.scopes = JSON.stringify(scopes);
    this.authResult = s;
    this.storageService.auth0Cache(s);
  }
}
