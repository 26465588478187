import { NGXLogger } from 'ngx-logger';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import {
  CertificationsService,
  ComplianceService,
  DashboardChartServiceService,
  DashboardHeaderIcon,
  GearService,
  IHeaderSetting,
  LoadingService,
  ResizeHandlerService,
  TableService,
  UserdataService,
  UserService,
  UtilsService
} from '@services';
import {
  ComplianceDetailsTablePopupComponent,
  DateRangeType,
  DateSelectorComponent,
  IDateModel
} from './../../components';
import { IChartWidgetConfig } from '../../components/chart-widget/chart-widget.component';
import { AutoPageRefresherComponent } from '@shared/components';

import * as moment from 'moment';
import * as Chart from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { filter, find, get, inRange, isNumber, map, split, uniqBy } from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements AfterViewInit {

  @ViewChild(AutoPageRefresherComponent, {static: true}) autoPageRefresherComponent: AutoPageRefresherComponent;
  @ViewChild(DateSelectorComponent) dateSelectorComponentComponent: DateSelectorComponent;

  public tableWrapperId = 'homeComplianceInfo';
  public isWidgetLoaded = false;
  public widgetConfigs: IChartWidgetConfig[] = [];
  public headerSetting: IHeaderSetting = {
    title: this.translate.instant('SHARED.Dashboard'),
    filter: {
      showFilter: false,
      isActive: true
    },
    icon: [
      DashboardHeaderIcon.SiteAwareness,
      DashboardHeaderIcon.Observations,
      DashboardHeaderIcon.SupervisorDashboard,
      DashboardHeaderIcon.Leaderboards,
      DashboardHeaderIcon.WorkStatus,
      DashboardHeaderIcon.WorkZone,
      DashboardHeaderIcon.AssetStatus,
      DashboardHeaderIcon.FrontlineCulture
    ]
  };
  public timedMapping = {
    NCZones: {
      type: 'arc',
      detFilter: 'zoneID',
      detTitle: this.translate.instant('DASHPAGES.HOME_TS_detZones'),
      collection: 'zones',
      total: 'totalTime',
      nc: 'ncTime',
      title: this.translate.instant('SHARED.Zones')
    },
    NCUsers: {
      type: 'arc',
      detFilter: 'userID',
      detTitle: this.translate.instant('DASHPAGES.HOME_TS_detWorkers'),
      collection: 'users',
      total: 'totalTime',
      nc: 'ncTime',
      title: this.translate.instant('SHARED.Workers')
    },
    NCGear: {
      type: 'horizontalBar',
      detFilter: 'gearID',
      detTitle: this.translate.instant('DASHPAGES.HOME_TS_detRequirements'),
      collection: 'gear',
      total: 'totalTime',
      nc: 'ncTime',
      title: this.translate.instant('SHARED.Requirements')
    },
    NCCerts: {
      type: 'arc',
      detFilter: 'certification',
      detTitle: this.translate.instant('DASHPAGES.HOME_TS_detCertifications'),
      collection: 'localCerts',
      total: 'total',
      nc: 'nonCompliance',
      title: this.translate.instant('SHARED.Certifications')
    }
  };
  public currentActivatedTableId: string;
  private tableId = 'widgetTable';
  private isLoading: boolean;
  private data: any;
  private currentDateType: DateRangeType;
  private gearID: number;
  private previousGearID: number;
  private isTableVisible = false;
  private requirementsWidgetType: string;
  private columnMap: any = {
    name: {
      id: 'name',
      title: this.translate.instant('SHARED.HOME_TS_ZONE'),
      headerClass: 'table-header'
    },
    location: {
      id: 'locationID',
      title: this.translate.instant('SHARED.LOCATION'),
      priority: 4,
      func: (id) => {
        const ref = this.userService.findLocation(id);
        return ref && ref.name || 'Unknown Location';
      },
      headerClass: 'table-header'
    },
    pct: {
      id: 'pct',
      class: ' ',
      width: '100',
      title: this.translate.instant('DASHPAGES.HOME_TS_NONCOMPLIANT'),
      func: (d) => isNumber(d) ? `${d}%` : 'n/a',
      headerClass: 'table-header'
    },
    out: {
      id: 'out',
      class: ' ',
      width: '100',
      title: this.translate.instant('DASHPAGES.HOME_TS_NONCOMPLIANT'),
      func: this.utilsService.secsToDuration,
      headerClass: 'table-header',
      cellprop: 'data-sort',
      cellval: 'out'
    },
    total: {
      id: 'total',
      class: ' ',
      width: '100',
      title: this.translate.instant('DASHPAGES.HOME_TS_CORE_TIME'),
      func: this.utilsService.secsToDuration,
      headerClass: 'table-header',
      cellprop: 'data-sort',
      cellval: 'total'
    },
    userID: {
      id: 'userID',
      title: this.translate.instant('SHARED.HOME_TS_WORKER'),
      func: (id) => this.userService.getFullname(id) || `Unknown (${id})`,
      headerClass: 'table-header'
    },
    workStartTime: {
      id: 'workStartTime',
      class: ' ',
      width: '15%',
      title: this.translate.instant('SHARED.HOME_TS_START_TIME'),
      headerClass: 'table-header',
      cellprop: 'data-sort',
      cellval: 'login'
    }
  };

  constructor(
    private logger: NGXLogger,
    private complianceService: ComplianceService,
    private loadingService: LoadingService,
    private gearService: GearService,
    private certificationService: CertificationsService,
    private userService: UserService,
    private utilsService: UtilsService,
    private tableService: TableService,
    private popoverController: PopoverController,
    private resizeHandler: ResizeHandlerService,
    private dashboardChartService: DashboardChartServiceService,
    private translate: TranslateService,
    private userData: UserdataService
  ) {
  }

  ionViewWillLeave() {
    this.autoPageRefresherComponent.stop();
  }

  ionViewDidEnter() {
    this.userData.savePreference('lastDashboardType', '/pages/dashboard/home');
    this.autoPageRefresherComponent.start();
  }

  ngAfterViewInit() {
    this.initRowSelectListening();
  }

  public onUpdatePageEvent(): void {
    const selectedDate: IDateModel = this.dateSelectorComponentComponent.getSelectedDate();

    if (selectedDate.dateType !== DateRangeType.PastDay) {
      this.getDataByDate(selectedDate.dateType, selectedDate.range, false);
    }
  }

  public onSelectDate(type: IDateModel): void {
    this.autoPageRefresherComponent.start();
    this.getDataByDate(type.dateType, type.range);
  }

  public renderTable(id: string, refresh: boolean = false): void {
    if (this.currentActivatedTableId !== id || (id === 'NCGear' && this.previousGearID !== this.gearID) || refresh) {
      if (id === 'NCGear') {
        this.previousGearID = this.gearID;
      }
      this.currentActivatedTableId = id;
      this.initTable();

      if (id === 'NCZones') {
        this.showZoneTable();
      } else if (id === 'NCUsers') {
        this.showUserTable();
      } else {
        if (this.gearID) {
          this.showGearTable(this.gearID);
        }
      }

      if ($(`#${this.tableId} thead`).length) {
        this.isTableVisible = true;

        const dataTableOptions: any = {
          paging: false,
          searching: false,
          info: false,
          stateSave: true,
          dom: 'Blfrtip',
          buttons: {
            buttons: [
              {
                extend: 'excel',
                text: this.translate.instant('SHARED.Save_to_Excel'),
                className: 'button-styled'
              }
            ]
          }
        };

        this.showTable(this.tableId, dataTableOptions);
      } else {
        this.isTableVisible = false;
      }
    }
  }

  private getDataByDate(dateType: DateRangeType = DateRangeType.Today, dateRange?: any, isLoadingEnabled?: boolean): void {
    if (!this.isLoading) {
      this.currentDateType = dateType;

      if (!dateRange) {
        dateRange = {
          startTime: moment().startOf('day').valueOf(),
          endTime: moment().valueOf()
        };
      }

      this.setLoadingState(true, isLoadingEnabled);
      this.complianceService.getComplianceInfo(dateRange.startTime, dateRange.endTime).then((res) => {
        this.isWidgetLoaded = true;
        this.data = res;

        this.widgetConfigs = this.getWidgetConfigsByTypes(['NCZones', 'NCUsers', 'NCGear'], res);
        this.setLoadingState(false, isLoadingEnabled);

        if (this.isTableVisible) {
          this.renderTable(this.currentActivatedTableId, true);
        }

        if (this.widgetConfigs.length === 0) {
          this.initTable();
        }

        this.resizeHandler.addResizeHandler('resizeList', () => this.tableService.handleResize('.dataTables_scrollBody:visible:first'));
      });
    }
  }

  private getWidgetConfigsByTypes(types: string[], data: any): any {
    return filter(map(types, (type: string) => this.getWidgetConfigByType(type, data)), (config: any) => config && config.isEmpty !== true);
  }

  private setLoadingState(value: boolean, isLoadingEnabled: boolean = true): void {
    if (isLoadingEnabled) {
      if (value) {
        this.isLoading = true;
        this.loadingService.enable();
      } else {
        this.isLoading = false;
        this.loadingService.disable();
      }
    }
  }

  private getWidgetConfigByType(item: string, data): any {
    if (this.timedMapping[item].type === 'arc') {
      const gdata: any = {
        labels: [
          this.translate.instant('DASHPAGES.HOME_TS_label0'),
          this.translate.instant('DASHPAGES.HOME_TS_label1')
        ],
        datasets: [{
          backgroundColor: [
            '#279F3E',
            '#D0021B'
          ]
        }]
      };
      const mapRef = this.timedMapping[item];
      const colRef = data[mapRef.collection];
      if (colRef && typeof (colRef) === 'object') {
        const total = parseInt(colRef[mapRef.total]);
        const nc = parseInt(colRef[mapRef.nc]);
        const comp = total - nc;

        let pct: any = '--';
        gdata.datasets[0].data = [comp, nc];
        if (total) {
          pct = Math.round(nc / total * 100);
          pct = pct + '%';
        }

        return {
          title: this.timedMapping[item].title,
          gdata,
          pct,
          id: item,
          type: this.timedMapping[item].type,
          isEmpty: pct === '--'
        };
      }
    } else {
      const gdata: any = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: '#279F3E'
        },
          {
            data: [],
            backgroundColor: '#D0021B'
          }
        ]
      };

      const gData = get(data, 'gear.gear');
      const cData = get(data, 'certifications.certification');
      if ((cData && Object.keys(cData).length) || (gData && Object.keys(gData).length)) {
        let largest = 0;
        if (gData && Object.keys(gData).length) {
          // get a sorted gear list
          const slist = gData.sort((a, b) => {
            const na = this.gearService.gearNameFromType(a.gearID);
            const nb = this.gearService.gearNameFromType(b.gearID);

            if (na < nb) {
              return -1;
            } else if (nb < na) {
              return 1;
            } else {
              return 0;
            }
          });
          data.gear.gear = slist;

          $.each(slist, (idx, ref) => {
            const gearType = ref.gearID;
            const name = this.gearService.gearNameFromType(gearType);
            gdata.labels.push(name);
            const total: number = parseInt(ref.totalTime);
            if (total > largest) {
              largest = total;
            }
            const nc: number = parseInt(ref.ncTime);

            gdata.datasets[0].data.push(parseInt(<any>(total - nc)));
            gdata.datasets[1].data.push(nc);
          });
        }
        if (cData && Object.keys(cData).length) {
          $.each(cData, (idx, ref) => {
            const name = this.certificationService.certificationNameFromType(ref.certification, false);
            gdata.labels.push(name);
            const total = parseInt(ref.totalTime);
            if (total > largest) {
              largest = total;
            }
            const nc: any = parseInt(ref.ncTime);
            const comp: any = total - nc;

            gdata.datasets[0].data.push(parseInt(comp));
            gdata.datasets[1].data.push(parseInt(nc));
          });
        }
        const h = (ev, b) => {
          let numGear;
          let itemIndex;
          if (data.gear && data.gear.gear && Object.keys(data.gear.gear).length) {
            numGear = Object.keys(data.gear.gear).length;
          }
          this.complianceService.complianceSelection.subitem = null;

          if (b && b[0]) {
            itemIndex = b[0]._index;
            //which data item was clicked?
          } else {
            const currentChartInstance: any = this.dashboardChartService.charts[`#${item}`];
            const mousePoint: any = Chart.helpers.getRelativePosition(ev, currentChartInstance.chart);
            const scale: any = currentChartInstance.scales['y-axis-0'];
            const ctx = (<any>document.getElementById(item)).getContext('2d');

            const currentLabelIndex: number = scale.getValueForPixel(mousePoint.y);
            const width = ctx.measureText(scale.ticks[currentLabelIndex]).width;
            const bottom = currentChartInstance.getDatasetMeta(0).data[0]._yScale.bottom;
            const top = currentChartInstance.getDatasetMeta(0).data[0]._yScale.top;

            let x = scale.right;
            if (scale.options.position === 'left') {
              x -= scale.options.ticks.padding;
            } else {
              x += scale.options.ticks.padding;
            }

            if (x - width < ev.layerX && ev.layerX < x && inRange(ev.layerY, top, bottom)) {
              itemIndex = currentLabelIndex;
            }
          }

          if (isNumber(itemIndex)) {
            if (numGear === null || itemIndex >= numGear) {
              // it isn't gear - it is a certification
              this.requirementsWidgetType = 'cert';
              let myItem = itemIndex;
              if (numGear !== null) {
                myItem = itemIndex - numGear;
              }
              this.gearID = data.certifications.certification[myItem].certification;
            } else {
              this.requirementsWidgetType = 'gear';
              this.gearID = data.gear.gear[itemIndex].gearID;
            }
          }
        };
        // recalibrate data set into percentage
        $.each(gdata.datasets[0].data, (idx) => {
          const v1 = gdata.datasets[0].data[idx];
          gdata.datasets[0].data[idx] = v1 / largest * 100;
          const v2 = gdata.datasets[1].data[idx];
          gdata.datasets[1].data[idx] = v2 / largest * 100;
        });
        return {
          title: this.timedMapping[item].title,
          gdata,
          pct: h,
          id: item,
          type: this.timedMapping[item].type
        };
      }
    }

  }

  private showGearTable(gearId): void {
    if (this.requirementsWidgetType === 'gear') {
      let gtable;
      let gList;
      let gRef = null;
      if (get(this.data, 'gear.gear.length')) {
        $.each(this.data.gear.gear, (i, ref) => {
          if (ref.gearID === gearId) {
            gRef = ref;
            return false;
          }
        });
      }
      if (gRef) {
        // we have a reference to a gear record with zones in it.  Show them
        gtable = {
          title: this.gearService.gearNameFromType(gearId),
          class: 'js-home-details clickable table-text-home',
          rowprop: 'data-selection',
          rowvaluefrom: 'zoneID',
          columns: [
            this.columnMap.location,
            this.columnMap.name,
            this.columnMap.pct,
            this.columnMap.out,
            this.columnMap.total
          ]
        };
        gList = [];
        if (Object.keys(gRef.zones).length) {
          // we have some missing gear
          $.each(gRef.zones, (i, ref) => {
            // for each gear item, what zones was it missing in
            // get a location object to be certain this location still exists
            const lRef = this.userService.findLocation(ref.locationID);
            if (!lRef) {
              // this location is no longer in the system; skip it
              return;
            }
            // get the zone information
            let zRef = this.userService.findAnyZone(lRef, ref.zoneID);
            // create default blank zone if the location ID is unavailable or alert user to update zones
            if (zRef === null) {
              zRef = this.userService.findAnyZone(lRef, '0');
            }
            const zItem: any = {
              locationID: zRef.locationID,
              zoneID: `${ref.locationID}:${ref.zoneID}`,
              name: zRef.name,
              out: parseInt(ref.ncTime),
              total: parseInt(ref.totalTime)
            };
            zItem.in = zItem.total - zItem.out;
            if (zItem.total || zItem.out) {
              zItem.pct = parseInt(<any>(zItem.out / zItem.total * 100));
            } else {
              zItem.pct = 0;
            }
            gList.push(zItem);
          });
        }
        this.tableService.showTable($(`#${this.tableId}`), gtable, gList);
      }
    } else {
      let gRef;
      if (get(this.data, 'certifications.certification.length')) {
        $.each(this.data.certifications.certification, (i, ref) => {
          if (ref.certification === this.gearID) {
            gRef = ref;
            return false;
          }
        });
      }
      if (gRef) {
        // we have a reference to a certification record with zones in it.  Show them
        const gtable = {
          title: this.certificationService.certificationNameFromType(this.gearID, false),
          class: 'js-home-details clickable table-text-home',
          rowprop: 'data-selection',
          rowvaluefrom: 'zoneID',
          columns: [
            this.columnMap.location,
            this.columnMap.name,
            {
              ...this.columnMap.pct,
              cellprop: 'data-sort',
              cellval: 'pct'
            },
            this.columnMap.out,
            this.columnMap.total
          ]
        };
        const gList = [];
        if (Object.keys(gRef.zones).length) {
          // we have some missing gear
          $.each(gRef.zones, (i, ref) => {
            // for each gear item, what zones was it missing in
            // get a location object to be certain this location still exists
            const lRef = this.userService.findLocation(ref.locationID);
            if (lRef === null) {
              // this location is no longer in the system; skip it
              return;
            }
            let zRef = this.userService.findAnyZone(lRef, ref.zoneID);
            if (zRef === null) {
              zRef = this.userService.findAnyZone(lRef, 0);
            }
            const zItem: any = {
              locationID: zRef.locationID,
              zoneID: `${ref.locationID}:${ref.zoneID}`,
              name: zRef.name,
              out: parseInt(ref.ncTime),
              total: parseInt(ref.totalTime)
            };
            if (ref.zoneID === 0) {
              zItem.name = 'Site';
            }
            zItem.in = zItem.total - zItem.out;
            if (zItem.total || zItem.out) {
              zItem.pct = parseInt(<any>(zItem.out / zItem.total * 100));
            } else {
              zItem.pct = 0;
            }
            gList.push(zItem);
          });
        }
        this.tableService.showTable($(`#${this.tableId}`), gtable, gList);
      }
    }
  }

  private showUserTable(): void {
    let utable;
    if (this.currentDateType === DateRangeType.Today) {
      utable = {
        class: 'js-home-details clickable table-text-home',
        rowprop: 'data-selection',
        rowvaluefrom: 'userID',
        columns: [{
          ...this.columnMap.userID,
          class: ' ',
          width: '20%'
        },
          {
            headerClass: 'table-header',
            id: 'lastLocation',
            title: this.translate.instant('SHARED.CURRENT_LOCATION'),
            width: '15%',
            func: (lastLocation) => {
              if (lastLocation === null) {
                return '--';
              } else {
                const zRef = this.userService.findLocation(lastLocation);
                if (zRef) {
                  return zRef.name;
                } else {
                  return '--';
                }
              }
            }
          },
          {
            headerClass: 'table-header',
            id: 'lastZone',
            title: this.translate.instant('SHARED.CURRENT_ZONE'),
            width: '15%',
            func: (zoneID) => {
              if (zoneID === null) {
                return '--';
              } else {
                const zRef = this.userService.findAnyZoneNoLoc(zoneID);
                if (zRef) {
                  return zRef.name;
                } else {
                  return '--';
                }
              }
            }
          },
          {
            ...this.columnMap.pct,
            sorter: 'percentage',
            cellprop: 'data-sort',
            cellval: 'pct',
            class: ' ',
          },
          this.columnMap.workStartTime,
          this.columnMap.total
        ]
      };
    } else {
      utable = {
        class: 'js-home-details clickable table-text-home',
        rowprop: 'data-selection',
        rowvaluefrom: 'userID',
        columns: [{
          ...this.columnMap.userID,
          class: ' ',
          width: '20%'
        },
          {
            ...this.columnMap.pct,
            cellprop: 'data-sort',
            cellval: 'pct'
          },
          this.columnMap.workStartTime,
          this.columnMap.total
        ]
      };
    }
    const d = this.data.users.users;
    const uList = [];
    $.each(d, (i, ref) => {
      if (1 || ref.ncTime) {
        const udata: any = {
          userID: ref.userID,
          in: parseInt(ref.totalTime) - parseInt(ref.ncTime),
          out: parseInt(ref.ncTime),
          total: parseInt(ref.totalTime),
          login: parseInt(ref.login),
          workStartTime: () => {
            if (ref.login === undefined) {
              return '---';
            } else {
              return this.utilsService.dateTimeFormat(ref.login);
            }
          },
          batteryLevel: Math.floor((Math.random() * 100) + 1) + '%',
          workerStatus: 'Active',
          currentZone: () => {
            if (ref.zones && ref.zones[0]) {
              const zoneObject = this.userService.findAnyZoneNoLoc(ref.zones[0].zoneID);
              if (zoneObject) {
                return zoneObject.name;
              } else {
                return 'Unknown Zone';
              }
            } else {
              return 'Unknown Zone';
            }
          },
        };
        if (!ref.logout && !ref.idle) {
          udata.lastZone = ref.lastZone;
          udata.lastLocation = ref.lastLocation;
        } else {
          udata.lastZone = null;
          udata.lastLocation = null;
        }
        if (udata.in || udata.out) {
          udata.pct = parseInt(<any>(udata.out / (udata.in + udata.out) * 100));
        } else {
          udata.pct = 0;
        }
        uList.push(udata);
      }
    });
    this.tableService.showTable($(`#${this.tableId}`), utable, uList);
  }

  private showZoneTable(): void {
    const ztable = {
      class: 'js-home-details clickable table-text-home',
      rowprop: 'data-selection',
      rowvaluefrom: 'zoneID',
      columns: [
        this.columnMap.location,
        this.columnMap.name,
        {
          ...this.columnMap.pct,
          sorter: 'percentage',
          cellprop: 'data-sort',
          cellval: 'pct'
        },
        {
          priority: 2,
          ...this.columnMap.out
        },
        {
          priority: 3,
          ...this.columnMap.total
        }
      ]
    };
    const d = this.data.zones.zones;
    const zList = [];
    $.each(d, (i, ref) => {
      // if (ref.ncTime !== undefined && parseInt(ref.ncTime)) {
      let zRef;
      if (ref.locationID) {
        const lRef = this.userService.findLocation(ref.locationID);
        if (lRef) {
          zRef = this.userService.findAnyZone(lRef, ref.zoneID);
        } else {
          zRef = this.userService.findAnyZoneNoLoc(ref.zoneID);
        }
      } else {
        zRef = this.userService.findAnyZoneNoLoc(ref.zoneID);
      }
      let zName = '';
      if (zRef) {
        zName = zRef.name;
      } else if (ref.zoneID === '0') {
        zName = 'Site';
      }
      if (zName !== '') {
        const zdata: any = {
          locationID: ref.locationID,
          zoneID: `${ref.locationID}:${ref.zoneID}`,
          name: zName,
          out: parseInt(ref.ncTime),
          in: parseInt(ref.totalTime) - parseInt(ref.ncTime),
          total: parseInt(ref.totalTime)
        };
        if (zdata.in || zdata.out) {
          zdata.pct = parseInt(<any>(zdata.out / (zdata.in + zdata.out) * 100));
        } else {
          zdata.pct = 0;
        }
        zList.push(zdata);
      }
      // }
    });
    this.tableService.showTable($(`#${this.tableId}`), ztable, zList);
  }

  private initTable(): void {
    if ($(`#${this.tableId} thead`).length) {
      $(`#${this.tableId}`).DataTable().destroy();
    }
    $(`#${this.tableWrapperId}`).html(`<table class="display"  id="${this.tableId}"></table>`);
  }

  private initRowSelectListening(): void {
    $(`#${this.tableWrapperId}`).on('click', '.js-home-details', (ev) => {
      const t = $(ev.target).closest('tr');
      const sel = t.attr('data-selection');
      const det = this.currentActivatedTableId;

      let tDef = null;
      let zRef = null;
      let tList = [];

      if (det === 'NCZones') {
        zRef = this.getZoneById(this.data.zones.zones, sel);

        // table of participants in zone
        tDef = {
          class: 'home-comp-popup table-text-home',
          rowClassFunc: (row) => {
            let ret = '';
            const user = this.userComplianceInfo(row.userID);
            if (user && !user.logout && !user.idle && user.lastZone === zRef.zoneID) {
              ret = 'tr-active-zone';
            }
            return ret;
          },
          columns: [
            this.columnMap.userID,
            {
              ...this.columnMap.pct,
              cellprop: 'data-sort',
              cellval: 'pct'
            },
            {
              priority: 2,
              ...this.columnMap.out
            },
            {
              priority: 3,
              ...this.columnMap.total
            }
          ]
        };
        $.each(zRef.users, (i, ref) => {
          // look at all the users to determine what was missing and the times
          const uRec: any = {
            userID: ref.userID
          };
          uRec.out = parseInt(ref.ncTime);
          uRec.total = parseInt(ref.totalTime);
          uRec.lastZone = parseInt(ref.lastZone);
          uRec.idle = parseInt(ref.idle);
          uRec.in = uRec.total - uRec.out;
          if (uRec.in || uRec.out) {
            uRec.pct = parseInt(<any>(uRec.out / (uRec.in + uRec.out) * 100));
          } else {
            uRec.pct = 0;
          }
          tList.push(uRec);
        });
        tList = uniqBy(tList, 'userID');
      } else if (det === 'NCUsers') {
        // get the compliance data
        let uRef = null;
        $.each(this.data.users.users, (i, ref) => {
          if (ref.userID === +sel) {
            uRef = ref;
            return false;
          }
        });

        // table of zones participant was noncompliant in
        tDef = {
          class: 'home-comp-popup table-text-home',
          rowClassFunc: (row) => {
            let ret = '';
            if (!uRef.logout && !uRef.idle && uRef.lastZone === row.zoneID) {
              ret = 'tr-active-zone';
            }
            return ret;
          },
          columns: [
            this.columnMap.location,
            {
              id: 'zoneID',
              title: this.translate.instant('SHARED.HOME_TS_ZONE'),
              func: (d) => {
                const ref = this.userService.findAnyZoneNoLoc(d);
                if (ref) {
                  return ref.name;
                } else {
                  return 'Unknown (' + d + ')';
                }
              },
              headerClass: 'table-header'
            },
            {
              ...this.columnMap.pct,
              cellprop: 'data-sort',
              cellval: 'pct'
            },
            {
              priority: 2,
              ...this.columnMap.out
            },
            {
              priority: 3,
              ...this.columnMap.total
            }
          ]
        };
        tList = [];
        $.each(uRef.zones, (i, ref) => {
          const uRec: any = {
            locationID: ref.locationID,
            zoneID: ref.zoneID,
          };
          if (ref.totalTime) {
            uRec.total = parseInt(ref.totalTime);
          } else {
            uRec.total = 0;
          }
          if (ref.ncTime) {
            uRec.out = parseInt(ref.ncTime);
          } else {
            uRec.out = 0;
          }
          if (uRec.total || uRec.out) {
            uRec.pct = parseInt(<any>(uRec.out / (uRec.total) * 100));
          } else {
            uRec.pct = 0;
          }
          tList.push(uRec);
        });
      } else if (det === 'NCGear') {
        tDef = {
          class: 'home-comp-popup table-text-home',
          columns: [
            this.columnMap.userID,
            {
              ...this.columnMap.pct,
              cellprop: 'data-sort',
              cellval: 'pct'
            },
            {
              priority: 2,
              ...this.columnMap.out
            },
            {
              priority: 3,
              ...this.columnMap.total
            }
          ]
        };
        // we are interrogating a zone within a piece of gear... use subitem
        if (this.requirementsWidgetType === 'gear') {
          let gRef = null;
          $.each(this.data.gear.gear, (i, ref) => {
            if (ref.gearID === this.gearID) {
              gRef = ref;
              return false;
            }
          });
          if (gRef) {
            zRef = this.getZoneById(gRef.zones, sel);

            if (zRef) {
              // we have a reference to this zone - grab out all the users
              $.each(zRef.users, (i, ref) => {
                const uItem: any = {
                  userID: ref.userID,
                  total: parseInt(ref.totalTime),
                  out: parseInt(ref.ncTime)
                };
                uItem.in = uItem.total - uItem.out;
                if (uItem.total) {
                  uItem.pct = parseInt(<any>(uItem.out / (uItem.total) * 100));
                } else {
                  uItem.pct = 0;
                }
                tList.push(uItem);
              });
            }
          }
        } else {
          let cRef = null;
          $.each(this.data.certifications.certification, (i, ref) => {
            if (ref.certification === this.gearID) {
              cRef = ref;
              return false;
            }
          });
          if (cRef) {
            zRef = this.getZoneById(cRef.zones, sel);

            if (zRef) {
              $.each(zRef.users, (i, ref) => {
                const uItem: any = {
                  userID: ref.userID,
                  total: parseInt(ref.totalTime),
                  out: parseInt(ref.ncTime)
                };
                uItem.in = uItem.total - uItem.out;
                if (uItem.total) {
                  uItem.pct = parseInt(<any>(uItem.out / (uItem.total) * 100));
                } else {
                  uItem.pct = 0;
                }
                tList.push(uItem);
              });
            }
          }
        }
      }

      this.popoverController.create(<any>{
        component: ComplianceDetailsTablePopupComponent,
        animated: false,
        componentProps: {
          title: this.getPopoverTitle()
        }
      }).then((element: HTMLIonPopoverElement) => {
        element.present().then(() => {
          this.tableService.showTable('#complianceDetailsTable', tDef, tList);
          const dataTableOptions: any = {
            paging: false,
            searching: false,
            info: false,
            stateSave: true
          };
          this.showTable('complianceDetailsTable', dataTableOptions, true);
        });
      });

    });
  }

  private getPopoverTitle(): string {
    let title: string = this.translate.instant('DASHPAGES.HOME_TS_detZones');

    if (this.currentActivatedTableId === 'NCUsers') {
      title = this.translate.instant('DASHPAGES.HOME_TS_detWorkers');
    } else if (this.currentActivatedTableId === 'NCGear') {
      title = this.translate.instant('DASHPAGES.HOME_TS_detRequirements');
    }

    return title;
  }

  private userComplianceInfo(userID) {
    let ret = null;
    $.each(this.data.users.users, (idx, ref) => {
      if (ref.userID === userID) {
        ret = ref;
        return false;
      }
    });
    return ret;
  }

  private showTable(id: string, dataTableOptions: any, isHeightFixed: boolean = false): void {
    if ($(`#${id} thead`).length) {

      if (isHeightFixed) {
        const offset: number = $(`#${id}`).offset().top;
        const height: number = $(window).height() - offset - 125;

        dataTableOptions.scrollY = height + 'px';
        dataTableOptions.scrollCollapse = true;
      }

      $(`#${id}`).DataTable(dataTableOptions).search('').draw();
    }
  }

  private getZoneById(zones: any[], groupedZoneId: string): any {
    const locationID: number = +split(groupedZoneId, ':')[0];
    const zoneID: number = +split(groupedZoneId, ':')[1];
    return find(zones, <any>{zoneID, locationID});
  }

}
