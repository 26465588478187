import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { SelectFiltersFormModalComponent } from '@shared/components';

import { TranslateService } from '@ngx-translate/core';
import { compact, map, orderBy } from 'lodash';

@Component({
  selector: 'app-select-filters-form',
  templateUrl: './select-filters-form.component.html',
  styleUrls: ['./select-filters-form.component.scss'],
})
export class SelectFiltersFormComponent extends CustomFormComponent implements OnInit {

  public btnTitle: string = this.translate.instant('SHARED.Add_Filters');
  public options: any[];
  public reportID: string;
  public selectedItems: any[];
  public optsList: any[];
  public filters: any[];

  constructor(private popoverController: PopoverController, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.optsList = map(this.options, (item) => ({id: item.name, name: item.title}));

    this.selectedItems = compact(this.selectedItems);
  }

  public showDataToIncludeForm(): void {
    this.popoverController.create(<any>{
      component: SelectFiltersFormModalComponent,
      animated: false,
      componentProps: {
        options: orderBy(this.optsList, 'name'),
        selectedItems: this.selectedItems
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event) => {
        if (event.data && event.data !== this.selectedItems) {
          this.formValue = event.data;
        }
      });
    });
  }

}
