import { NGXLogger } from 'ngx-logger';
import { Injectable, SecurityContext } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { Events } from '@services/events/events.service';

import { Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { share } from 'rxjs/operators';
import { each, filter, find, includes, isEmpty, last, orderBy, remove, some } from 'lodash';

export enum MessageType {
  REMOVALS = 'removals',
  EMERGENCIES = 'emergencies',
  CANCELS = 'cancels',
  MESSAGES = 'message',
  NOTIFICATIONS = 'notifications',
  UPDATES = 'updates',
  PERSONALNOTES = 'personalNotes',
  SHIFTNOTES = 'shiftNotes',
  PINNEDNOTES = 'pinnedNotes',
  REMOVED_NOTIFICATIONS = 'removed_notifications',
  REMOVED_MESSAGES = 'removed_message'
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  public usingSSE = false;
  public messageData = {
    lastRequest: null,
    data: {}
  };
  public messages: any = [];
  public notifications: any = [];
  public shiftNotes: any = [];
  public pinnedNotes: any = [];
  public personalNotes: any = [];
  public showUnreadNotes = false;
  private observable: Observable<any>;
  private observableMessageAliases: Map<any, Observable<any>> = new Map();
  private clearTimer: () => void;
  private startTimer: () => void;
  private stopTimer: () => void;
  private updateMessagesCount: () => void;
  private updateNotificationsCount: () => void;
  private updateWorkNoteCount: () => void;
  private sent: any = [];
  private emergencies: any = [];
  private byLocation: any = {};
  private MESSAGE_CHECK_INTERVAL = this.subscriber.HEARTBEAT_TIME ? this.subscriber.HEARTBEAT_TIME * 1000 : 60000;
  private isFetching = true;

  constructor(
    private logger: NGXLogger,
    private userData: UserdataService,
    private comms: CommsService,
    private subscriber: SubscriberService,
    private sanitizer: NgDompurifySanitizer,
    protected translate: TranslateService,
    private events: Events
  ) {
  }

  checkInterval(time?: number) {
    if (time) {
      this.MESSAGE_CHECK_INTERVAL = time;
      this.clearTimer && this.clearTimer();
    }
    return this.MESSAGE_CHECK_INTERVAL;
  }

  clearCache() {

    // buffers
    this.sent = [];
    this.messages = [];
    this.emergencies = [];
    this.notifications = [];
    this.personalNotes = [];
    this.shiftNotes = [];

    this.messageData = {
      lastRequest: 1,
      data: {}
    };
    this.isFetching = false;
  }

  changeRecipientState(theMessage: number | number[], action: string): Promise<any> {

    const messages = typeof theMessage === 'number' ? [theMessage] : theMessage;

    const eData: any = {
      cmd: 'setMessageStatus',
      messages: JSON.stringify(messages),
      action
    };

    let noRecipient = true;

    each(messages, (messageID) => {
      const ref = this.getRecipientInfo(messageID, this.userData.userID);
      if (ref) {
        // this person was a recipient of the message
        // if the action is removed, then take it out of our local cache too
        noRecipient = false;
        if (action === 'removed') {
          // this was one we had cached
          const theType = this.messageData.data[messageID].type;
          delete this.messageData.data[messageID];
          let bucket = '';

          if (theType === 'notification') {
            bucket = 'notifications';
            this.updateNotificationsCount && this.updateNotificationsCount();
          } else if (theType === 'message') {
            bucket = 'messages';
            this.updateMessagesCount && this.updateMessagesCount();
          } else if (theType === 'emergency') {
            bucket = 'emergencies';
          } else if (theType === 'note') {
            this.updateWorkNoteCount && this.updateWorkNoteCount();
          }

          let idx = null;
          $.each(this[bucket], (i, ID) => {
            if (ID === messageID) {
              idx = i;
              return false;
            }
          });
          if (idx !== null) {
            this[bucket].splice(idx, 1);
          }
        } else {
          ref.state = action;
        }
      }
    });
    if (noRecipient) {
      return new Promise((resolve, reject) => {
        reject('User is not a recipient for any message ');
      });
    } else {
      return this.comms.sendMessage(eData, false, false).then((res) => {
        this.events.publish('ccs:refreshHeaderNotifications');
        return res;
      });
    }
  }

  /**
   * getMessage - get a reference to a message data
   *
   * @param messageID - the unique ID for this message
   *
   * @returns a reference to a message object or null if the message is not available.
   *
   */
  getMessage(messageID) {
    if (messageID && this.messageData && this.messageData.data && this.messageData.data[messageID]) {
      return this.messageData.data[messageID];
    } else {
      return null;
    }
  }

  /**
   * getRecipientInfo - get a recipient object from a message
   *
   * @param {Integer} messageID - a the unique ID of a message
   * @param {Integer} recipientID - a userID of a message recipient
   *
   * @returns {Object} - recipient status
   */

  getRecipientInfo(messageID, recipientID) {
    let ret = null;

    const ref = this.getMessage(messageID);
    if (ref && ref.hasOwnProperty('recipients')) {
      $.each(ref.recipients, (i, r) => {
        if (r.userID === recipientID) {
          ret = r;
          return false;
        }
      });
    }

    return ret;
  }

  public refreshMessages(): void {
    if (this.isFetching) {
      this.startTimer();
    }
  }

  public unread(alias: MessageType): Observable<number> {
    const countAlias = `${alias}_count`;

    this.createMessageObservable();
    this.createAliasMessageObservable(<MessageType>countAlias, (observer: Observer<number>, res) => {
      if (this.isNewMessage(res, alias) && this.isFetching) {
        if (alias === MessageType.MESSAGES) {
          const count: number = this.getCachedUnreadCount(alias);
          observer.next(count);

          this.updateMessagesCount = () => {
            const count: number = this.getCachedUnreadCount(alias);
            observer.next(count);
          };
        } else if (alias === MessageType.NOTIFICATIONS) {
          const count: number = this.getCachedUnreadCount(alias);
          observer.next(count);

          this.updateNotificationsCount = () => {
            const count: number = this.getCachedUnreadCount(alias);
            observer.next(count);
          };
        } else if (alias === MessageType.SHIFTNOTES) {
          const count: number = this.getCachedUnreadCount(alias);
          observer.next(count);

          this.updateWorkNoteCount = () => {
            const count: number = this.getCachedUnreadCount(alias);
            observer.next(count);
          };
        } else {
          observer.next(res[alias].length);
        }
      }
    });

    return this.observableMessageAliases.get(<MessageType>countAlias);
  }

  public getMessages(alias: MessageType): Observable<any[]> {
    this.createMessageObservable();
    this.createAliasMessageObservable(alias, (observer: Observer<any[]>, res) => {
      if (this.isNewMessage(res, alias) && this.isFetching) {

        if (alias === MessageType.PINNEDNOTES) {
          // check in res what updated?
          if (!isEmpty(res.updates)) {
            each(res.updates, data => {
              // is it note?
              if (data.type === 'note') {
                if (data.subtype === 'shift') {
                  // okay, did pin change for this user?
                  const newStatus = this.getRecipientInfo(data.messageID, this.userData.userID);
                  // if read, this means it was unpinned, if pinned it means it was read, remove
                  switch (newStatus.pinned) {
                    case 0: {
                      // remove from pinned note.
                      remove(this.pinnedNotes, (toRemove: any) => toRemove.messageID === data.messageID);
                      break;
                    }
                    case 1: {
                      // add to pinned note.

                      // is this note already in there?
                      const pinObj = find(this.pinnedNotes, ['messageID', data.messageID]);
                      if (!pinObj) {
                        this.pinnedNotes.push(data);
                      }
                      orderBy(this.pinnedNotes, ['created'], ['desc']);
                    }
                  }
                  // in case of replies, adjust old data in shift array with the updated value.
                  remove(this.shiftNotes, (toRemove: any) => toRemove.messageID === data.messageID);
                  this.shiftNotes.push(data);
                } else if (data.subtype === 'personal') {
                  const status = this.getRecipientInfo(data.messageID, this.userData.userID);
                  if (status.state === 'removed') {
                    // remove from personal note.
                    remove(this.personalNotes, (toRemove: any) => toRemove.messageID === data.messageID);
                  }
                }
              }
            });
            observer.next(res.updates);
          }
          // any new stuff incoming?
          if (!isEmpty(res.personalNotes)) {
            each(res.personalNotes, data => {
              const existingData = find(this.personalNotes, ['messageID', data.messageID]);
              if (!existingData) {
                this.personalNotes.push(data);
              }
            });
            observer.next(res.personalNotes);
          }

          if (!isEmpty(res.shiftNotes)) {
            each(res.shiftNotes, data => {
              const existingData = find(this.shiftNotes, ['messageID', data.messageID]);
              // now make sure this is a not a part of existing thread
              const existingThread = this.findAllMessageThread(data.messageID);
              // if this is a part of thread, we have reference to this already, skip ahead
              if (!existingData && !existingThread.length) {
                this.shiftNotes.push(data);
              }
            });
            observer.next(res.shiftNotes);
          }
          // currently using all removals to remove personal notes, we don't have provision to delete any other note, refactor this once we actually support deleting other note types
          if (!isEmpty(res.removals)) {
            each(res.removals, rid => {
              remove(this.personalNotes, (toRemove: any) => toRemove.messageID === +rid);
            });
            observer.next(res.removals);
          }

        } else {
          if (res[alias].length) {
            observer.next(res[alias]);
          }
        }


      }
    });

    return this.observableMessageAliases.get(alias);
  }

  public isNew(type: MessageType, ids: number[]): boolean {
    let isNew = false;

    if (type === MessageType.MESSAGES) {
      const items: any[] = filter(this.messageData.data, (item: any) => item.type === type && includes(ids, item.messageID));

      each(items, (item: any) => {
        isNew = some(item.recipients, (recipient: any) => recipient.state !== 'read' && recipient.userID === this.userData.userID);
        if (isNew) {
          return false;
        }
      });
    } else if (type === MessageType.NOTIFICATIONS) {
      each(ids, (notificationId: number) => {
        const notification: any = this.getMessage(notificationId) || {};
        if (notification.recipients && notification.recipients[0] && notification.recipients[0].state !== 'read') {
          isNew = true;
          return false;
        }
      });
    }

    return isNew;
  }

  /**
   * stopPolling - start checking the server for messages
   */
  startPolling() {
    this.isFetching = true;
    if (this.observable) {
      this.startTimer && this.startTimer();
    } else {
      this.createMessageObservable();
      this.startTimer && this.startTimer();
    }
  }

  /**
   * stopPolling - stop checking the server for messages
   */
  stopPolling() {
    this.isFetching = false;
    // also clear the caches
    // buffers
    this.sent = [];
    this.messages = [];
    this.emergencies = [];
    this.notifications = [];

    this.messageData = {
      lastRequest: 0,
      data: {}
    };

    this.stopTimer && this.stopTimer();
  }

  /**
   * findAllMessageThread - Returns all the previous message IDs tied with this mid
   */
  findAllMessageThread(mid) {
    const msgList = [];
    const message = this.getMessage(mid);
    // if this messageExists add to the queue
    if (message) {
      msgList.push(mid);
    } else {
      return [];
    }
    // get all the previous messages from the thread.
    if (message.previousMessageID) {
      let nextMessage = message.previousMessageID;
      while (nextMessage) {
        // push this nextMessage only if we have it in the bucket
        if (this.messageData.data[nextMessage]) {
          msgList.push(nextMessage);
        }
        const newMessage = this.getMessage(nextMessage);
        if (newMessage) {
          nextMessage = newMessage.previousMessageID;
        } else {
          nextMessage = null;
        }

      }
    }
    if (message.nextMessageID) {
      let anotherMessage = message.nextMessageID;
      while (anotherMessage) {
        if (this.messageData.data[anotherMessage]) { // this should be carried out so that data is returned properly for this nextID
          msgList.push(anotherMessage);
        }
        const tempMessage = this.getMessage(anotherMessage);
        if (tempMessage) {
          anotherMessage = tempMessage.nextMessageID;
        } else {
          anotherMessage = null;
        }
      }
    }
    return msgList.sort((a, b) => a - b);
  }

  public unreadTeam(tList) {
    let team: number;
    let tObj: any = {};
    each(this.shiftNotes, (shiftID: any) => {
      if (this.isUnreadMessage(shiftID.messageID)) {
        // check if this user actually falls in this team
        if (includes(this.userData.teams, shiftID.groups[0])) {
          // creating object map to find alphabetically top ordered team
          if (tObj[shiftID.groups[0]]) {
            tObj[shiftID.groups[0]]++;
          } else {
            tObj = {
              [shiftID.groups[0]]: 1,
            };
          }
        }
      }
    });

    // from tObj, find the top most item
    each(tList, val => {
      if (tObj[val]) {
        team = val;  // we found our team
        return false;
      }
    });

    // if no team, the return primary team
    if (!team) {
      if (this.userData.primaryGroup) {
        team = this.userData.primaryGroup;
      } else {
        team = this.userData.teams[0];
      }
    }
    return team;
  }

  public getCachedUnreadCount(type: MessageType): number {
    let count = 0;

    if (type === MessageType.MESSAGES) {
      each(this.messages, (messageId: number) => {
        if (this.isUnreadMessage(messageId)) {
          count++;
        }
      });
    } else if (type === MessageType.NOTIFICATIONS) {
      each(this.notifications, (notificationId: number) => {
        if (this.isUnreadMessage(notificationId)) {
          count++;
        }
      });
    } else if (type === MessageType.SHIFTNOTES) {
      each(this.shiftNotes, (shiftID: any) => {
        if (this.isUnreadMessage(shiftID.messageID)) {
          count++;
        }
      });
    }

    return count;
  }

  public isUnreadMessage(id: number): boolean {
    const messageState: any = this.getRecipientInfo(id, this.userData.userID);
    return messageState && (messageState.state === 'new' || messageState.state === 'delivered');
  }

  public isUnreadChat(id: number): boolean {
    const messageThread: number[] = this.findAllMessageThread(id);
    return some(messageThread, (id: number) => this.isUnreadMessage(id));
  }

  /**
   * setMessageThreadAsRead - sets the latest message ID in the thread as read, this will set all the prior message as read
   *
   * @param - messageIDList - the list of message IDs currently being displayed
   */
  public setMessageThreadAsRead(messageIDList: number[]): void {
    let isNeedToMarkMessages: boolean;

    each(messageIDList, (messageId: number) => {
      const unreadMessage: any = find(this.messageData.data[messageId].recipients, (recipient: any) => recipient.userID === this.userData.userID && recipient.state !== 'read');

      if (unreadMessage) {
        unreadMessage.state = 'read';

        if (!isNeedToMarkMessages) {
          isNeedToMarkMessages = true;
        }
      }
    });

    if (isNeedToMarkMessages) {
      this.changeRecipientState(+last(messageIDList), 'read');
    }
  }

  public createNewMessage(params: any): any {
    const requestObject: any = {
      cmd: 'addMessage',
      type: 'message',
      message: params.text,
      sendTime: Date.now()
    };

    if (params.users && params.users.length) {
      requestObject.users = JSON.stringify(params.users);
    }

    if (params.groups && params.groups.length) {
      requestObject.groups = JSON.stringify(params.groups);
    }

    if (params.objectID) {
      requestObject.objectID = params.objectID;
    }

    if (params.source) {
      requestObject.source = params.source;
    }

    if (params.sourceID) {
      requestObject.sourceID = params.sourceID;
    }

    return this.comms.sendMessage(requestObject, false, true).then((res) => {
      if (params.groups && params.groups.length) {
        res.result.groups = params.groups;
      }

      this.messages.push(res.result.messageID);
      this.messageData.data[res.result.messageID] = res.result;

      return res.result;
    });
  }

  public replyToMessage(params: any): any {
    const requestObject: any = {
      cmd: 'addMessage',
      type: 'message',
      message: params.text,
      previousMessageID: params.previousMessageID,
      sendTime: Date.now()
    };

    if (params.objectID) {
      requestObject.objectID = params.objectID;
    }

    if (params.type) {
      requestObject.type = params.type;
    }

    if (params.subtype) {
      requestObject.subtype = params.subtype;
    }

    if (params.groups) {
      requestObject.groups = params.groups;
    }

    return this.comms.sendMessage(requestObject, false, true);
  }

  public getChatCount(): number {
    const messageIds: number[] = this.messages;
    let count = 0;

    each(messageIds, (messageId: number) => {
      const message: any = this.getMessage(messageId);
      if (message && !message.nextMessageID) {
        count++;
      }
    });

    return count;
  }

  public getNotificationsCount(): number {
    let count = 0;

    each(this.notifications, (notificationId: number) => {
      const notification: any = this.getMessage(notificationId);
      if (notification) {
        count++;
      }
    });

    return count;
  }

  public isUnreadThread(messageId: number, userId: string): boolean {
    const threadMessageIds: number[] = this.findAllMessageThread(messageId);
    let isUnread = false;

    each(threadMessageIds, (messageId: number) => {
      const unreadMessage: any = find(this.messageData.data[messageId].recipients, (recipient: any) => recipient.userID === userId && recipient.state !== 'read');

      if (unreadMessage) {
        isUnread = true;
        return false;
      }
    });

    return isUnread;
  }

  public setMessageFieldStatus(messageID: number, keyValuePair: any): Promise<any> {
    const edata: any = {
      messageID,
      cmd: 'setMessageStatus'
    };
    each(keyValuePair, (val, key) => {
      edata[key] = val;
    });
    return new Promise(async (resolve, reject) => {
      const resObject = await this.comms.sendMessage(edata, false, false);
      resolve(resObject);
    });
  }

  private createMessageObservable(): void {
    if (!this.observable) {
      this.observable = new Observable<any>((observer: Observer<any>) => {
        let timer: any;

        const refresh = () => {
          timer = setTimeout(() => {
            if (!this.usingSSE) {
              if (this.isFetching) {
                this.updateMessages(observer);
              }
              refresh();
            }
          }, this.MESSAGE_CHECK_INTERVAL);
        };

        this.updateMessages(observer);
        refresh();

        this.clearTimer = () => {
          clearTimeout(timer);
          refresh();
        };

        this.stopTimer = () => {
          clearTimeout(timer);
        };

        this.startTimer = () => {
          clearTimeout(timer);
          refresh();
          this.updateMessages(observer);
        };
      }).pipe(share());

      this.observable.subscribe();
    }
  }

  private createAliasMessageObservable(alias: MessageType, callback): void {
    if (!this.observableMessageAliases.has(alias)) {
      const aliasObservable = new Observable<any[]>((observer: Observer<any[]>) => {
        this.observable.subscribe(res => {
          callback && callback(observer, res);
        });
      }).pipe(share());

      this.observableMessageAliases.set(alias, aliasObservable);
    }
  }

  private isNewMessage(res: any, alias: MessageType): boolean {
    let isNew = false;

    switch (alias) {
      case MessageType.UPDATES:
        if (Object.keys(res[alias]).length >= 0) {
          isNew = true;
        }
        break;
      case MessageType.REMOVALS:
        if (res.didRemove && res[alias].length >= 0) {
          isNew = true;
        }
        break;
      default:
        if (res[alias].length >= 0) {
          isNew = true;
        }
    }
    return isNew;
  }

  private updateMessages(observer: Observer<any>) {
    if (this.comms.token) {
      const params: any = {
        cmd: 'getMessages',
        startTime: this.messageData.lastRequest,
        lastRequest: this.messageData.lastRequest,
        sendTime: Date.now()
      };

      this.comms.sendMessage(params, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            // lists of indices that can be passed to callbacks
            const newEmergencies = [];
            const newCancels = [];
            const newMessages = [];
            const newNotifications = [];
            const newPersonalNotes = [];
            const newShiftNotes = [];
            const newPinnedNotes = [];
            const newRemovedNotifications = [];
            const newRemovedMessages = [];

            const updated = {};

            $.each(data.result.messages, (i, mref) => {
              if (mref.hasOwnProperty('object') && mref.object.objectID) {
                mref.object.objectURL = this.comms.objectURI(mref.object.objectID, true);
              }
              // put the message references into the letious structures
              if (this.messageData.data[mref.messageID]) {
                // we already know about this message....
                // if it was an emergency, has the state changed to something interesting?
                if (mref.type === 'emergency' && mref.state === 'canceled') {
                  newCancels.push(mref.messageID);
                }
                // remember the new information
                this.messageData.data[mref.messageID] = mref;
                updated[mref.messageID] = mref;
              } else {
                // this is a new message altogether
                // let's ensure it is cleaned up
                mref.message = this.sanitizer.sanitize(SecurityContext.HTML, mref.message);
                this.messageData.data[mref.messageID] = mref;
                if (mref.type === 'emergency') {
                  this.emergencies.push(mref.messageID);
                  if (mref.state === 'canceled') {
                    newCancels.push(mref.messageID);
                  } else {
                    newEmergencies.push(mref.messageID);
                  }
                } else if (mref.type === 'notification') {
                  const nref = this.getRecipientInfo(mref.messageID, this.userData.userID);
                  if (nref) {
                    if (mref.source !== 'feedback') {
                      this.notifications.push(mref.messageID);
                      newNotifications.push(mref.messageID);
                    }
                  }
                } else if (mref.type === 'message') {
                  // this is a message... am I a recipient?
                  const rref = this.getRecipientInfo(mref.messageID, this.userData.userID);
                  if (rref) {
                    this.messages.push(mref.messageID);
                    newMessages.push(mref.messageID);
                  } else {
                    this.sent.push(mref.messageID);
                  }
                } else if (mref.type === 'note') {
                  if (mref.subtype === 'shift') {
                    const nref = this.getRecipientInfo(mref.messageID, this.userData.userID);
                    newShiftNotes.push(mref);
                    this.shiftNotes.push(mref);
                    // now search in this structure any pinned notes..
                    if (nref.pinned) {
                      newPinnedNotes.push(mref);
                      this.pinnedNotes.push(mref);
                    }

                  } else if (mref.subtype === 'personal' && (mref.userID === this.userData.userID)) {
                    newPersonalNotes.push(mref);
                    this.personalNotes.push(mref);
                  }
                }
              }
              this.messageData.lastRequest = data.result.timestamp;
            });

            // remove things from local cache
            let didRemove = false;
            $.each(data.result.removals, (i, messageID) => {
              if (this.messageData.data[messageID]) {
                // this was one we had cached
                const theType = this.messageData.data[messageID].type;
                let bucket = '';

                if (theType === 'notification') {
                  bucket = 'notifications';
                  newRemovedNotifications.push(messageID);
                } else if (theType === 'message') {
                  bucket = 'messages';
                  const chatLastMessageId: number = last(this.findAllMessageThread(messageID));
                  if (!includes(newRemovedMessages, chatLastMessageId)) {
                    newRemovedMessages.push(chatLastMessageId);
                  }
                } else if (theType === 'emergency') {
                  bucket = 'emergencies';
                }

                delete this.messageData.data[messageID];
                didRemove = true;

                let idx = null;
                $.each(this[bucket], (i, ID) => {
                  if (ID === messageID) {
                    idx = i;
                    return false;
                  }
                });
                if (idx !== null) {
                  this[bucket].splice(idx, 1);
                }
              }
            });

            observer.next({
              didRemove,
              [MessageType.REMOVALS]: data.result.removals,
              [MessageType.EMERGENCIES]: newEmergencies,
              [MessageType.CANCELS]: newCancels,
              [MessageType.MESSAGES]: newMessages,
              [MessageType.NOTIFICATIONS]: newNotifications,
              [MessageType.PERSONALNOTES]: newPersonalNotes,
              [MessageType.SHIFTNOTES]: newShiftNotes,
              [MessageType.UPDATES]: updated,
              [MessageType.PINNEDNOTES]: newPinnedNotes,
              [MessageType.REMOVED_MESSAGES]: newRemovedMessages,
              [MessageType.REMOVED_NOTIFICATIONS]: newRemovedNotifications
            });
          }
        }).catch((err) => {
        this.logger.log(err);
      });
    }
  }

}
