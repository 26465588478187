import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { MenuFilterPipe } from '@shared/pipes';
import { each, filter, find, includes, lowerCase, map, sortBy } from 'lodash';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
  providers: [MenuFilterPipe]
})
export class ItemListComponent implements OnChanges {

  @Input() isInfinityScrollEnabled: boolean;
  @Input() isMultiple = true;
  @Input() isSelectAllEnabled = false;
  @Input() isSearchable = true;
  @Input() idProperty = 'itemID';
  @Input() filter: string;
  @Input() items: any[];
  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();

  public viewItems: any[] = [];
  public searchModel: string;
  public selectAllEnabled: boolean = false;

  private itemsPerPage = 15;

  constructor(private menuFilterPipe: MenuFilterPipe) {
  }

  public init(): void {
    setTimeout(() => {
      this.loadNextItems();
    });
  }

  ngOnChanges() {
    this.searchModel = '';
    this.viewItems = this.items;
  }

  public selectItem(item: any): void {
    if (this.isMultiple) {
      item.checked = !item.checked;
    } else {
      this.resetCheckedItems();
      item.checked = true;
    }

    this.onSelected.emit();
  }

  public changeSearchModel(searchModel: any): void {
    this.searchModel = searchModel.detail.value;

    if (this.isInfinityScrollEnabled) {
      this.viewItems = [];
      this.loadNextItems();
    } else {
      this.viewItems = this.menuFilterPipe.transform(this.items, this.searchModel, this.filter);
    }
  }

  public getSelectedItems(): any[] {
    const teamMember = filter(this.items, 'checked');
    return map(sortBy(teamMember, (sortTeam) => lowerCase(sortTeam.name)), this.idProperty);
  }

  public getSelectedByID(): any[] {
    return filter(this.items, 'checked');
  }

  public setSelectedItems(ids: number[]): void {
    each(this.items, (item: any) => {
      item.checked = includes(ids, item[this.idProperty]);
    });
    this.setSelectAll();
  }

  public clearSearch(): void {
    this.searchModel = '';
  }

  public resetCheckedItems(): void {
    map(this.items, (item) => {
      item.checked = false;
    });
  }

  public loadNextItems(): void {
    if (this.isInfinityScrollEnabled) {
      const items: any = this.menuFilterPipe.transform(this.items, this.searchModel, this.filter);
      const startIndex: number = this.viewItems.length;
      const endIndex: number = startIndex + this.itemsPerPage;

      this.viewItems.push(...items.slice(startIndex, endIndex));
    } else if (this.viewItems.length === 0) {
      this.viewItems = this.items;
    }
  }

  private setSelectAll() {
    this.selectAllEnabled = !find(this.items, (item) => item.checked === false);;
  }

  public selectAll() {
    this.selectAllEnabled = !this.selectAllEnabled;
    map(this.viewItems, (item) => item.checked = this.selectAllEnabled);
    this.onSelected.emit();
  }
}
