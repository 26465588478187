import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { each } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GeartypesService {

  gearTypes = {
    lastRequest: 0,
    data: [
      {
        id: 'vest',
        description: this.translate.instant('GEAR_SERVICE.Protective_Apparel'),
        image: 'apparel.svg',
        training: 'vests.mp4'
      },
      {
        id: 'respirator',
        description: this.translate.instant('GEAR_SERVICE.Respiratory_Protection'),
        image: 'respiratory.svg',
        training: 'respirator.mp4'
      },
      {
        id: 'eyewear',
        description: this.translate.instant('GEAR_SERVICE.Eye_Face'),
        image: 'eye.svg',
        training: 'glasses.mp4'
      },
      {
        id: 'fall',
        description: this.translate.instant('GEAR_SERVICE.Fall_Protection'),
        image: 'fall.svg',
        training: 'harness.mp4'
      },
      {
        id: 'boots',
        description: this.translate.instant('GEAR_SERVICE.Foot_Protection'),
        image: 'foot.svg',
        training: 'hhppe.mp4'
      },

      {
        id: 'gloves',
        description: this.translate.instant('GEAR_SERVICE.Hand_Protection'),
        image: 'hand.svg',
        training: 'gloves.mp4'
      },
      {
        id: 'ear',
        description: this.translate.instant('GEAR_SERVICE.Hearing_Protection'),
        image: 'hearing.svg',
        training: 'hearing.mp4'
      },

      {
        id: 'helmet',
        description: this.translate.instant('GEAR_SERVICE.Head_Protection'),
        image: 'head.svg',
        training: 'hhppe.mp4'
      },
      {
        id: 'sensor',
        description: this.translate.instant('GEAR_SERVICE.Smart_PPE'),
        image: 'head.svg',
        training: 'hhppe.mp4'
      }
    ]
  };

  constructor(protected translate: TranslateService) {
  }

  public info(type: any) {
    let ret = null;
    each(this.gearTypes.data, (gear) => {
      if (gear.id === type) {
        ret = gear;
      }
    });
    return ret;
  }
}
